import React from "react"
import s from "./HikingHomeHeroButton.module.scss"

class HikingHomeHeroButton extends React.Component {
  render() {
    const { button_text } = this.props
    return (
      <div className={s.button_wrapper}>
        <button className={s.to_home_button} type="button">
          {button_text}
        </button>
      </div>
    )
  }
}

export default HikingHomeHeroButton
