import React from "react"
import Bowser from "bowser"

import { checkDesktopSafari } from "../../helper"
import s from "./Button.module.scss"

class Button extends React.Component {
  constructor(props) {
    super(props)
    this.buttonRef = React.createRef()
    this.state = {
      isDesktopSafari: false,
    }
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      const isDesktopSafari = checkDesktopSafari(window, Bowser)
      if (isDesktopSafari) {
        this.setState({
          isDesktopSafari,
        })
      }
    }
  }

  // onMouseInOrOut = isIn => {
  //   if (this.props.hoverColor) {
  //     const ref = this.props.innerRef ? this.props.innerRef : this.buttonRef
  //     if (isIn) {
  //       ref.current.style.color = this.props.textColor || "#ffffff"
  //       ref.current.style.backgroundColor = this.props.hoverColor
  //     } else {
  //       ref.current.style.color = ""
  //       ref.current.style.backgroundColor = ""
  //       if (this.props.style) {
  //         Object.keys(this.props.style).forEach(key => {
  //           ref.current.style[key] = this.props.style[key]
  //         })
  //       }
  //     }
  //   }
  // }

  renderHoverEffect = (color) => {
    const { isDesktopSafari } = this.state
    if (isDesktopSafari) return null
    let classNames = [s.hoverEffect]
    let colorToPass = color
    if (!colorToPass) {
      const { type } = this.props
      if (type === "primary") classNames.push(s.hoverEffectPrimary)
      else if (type === "default") classNames.push(s.hoverEffectDefault)
    }
    return <span style={{ color }} className={classNames.join(" ")}></span>
  }

  render() {
    const classNames = [s.btn]
    const {
      children,
      btnClassName,
      onClick,
      type,
      innerRef,
      hoverColor,
      sanitized = false,
      buttonBackgroundColor,
      ...props
    } = this.props
    const { isDesktopSafari } = this.state

    if (type === "primary") {
      classNames.push(s.primary)
      if (isDesktopSafari) {
        classNames.push(s.primaryHover)
      }
    } else if (type === "default") {
      classNames.push(s.default)
      if (isDesktopSafari) {
        classNames.push(s.defaultHover)
      }
    }

    if (btnClassName) classNames.push(btnClassName)

    const filteredProps = Object.keys(props)
    .filter(key => !['wrapperClassName', 'textColor', 'isSmallerSkew'].includes(key))
    .reduce((obj, key) => {
      obj[key] = props[key];
      return obj;
    }, {});

    return (
      <>
        {sanitized ? (
          <button
            ref={innerRef ? innerRef : this.buttonRef}
            onClick={onClick}
            className={`${classNames.join(" ")}`}
            // onMouseEnter={() => this.onMouseInOrOut(true)}
            // onMouseLeave={() => this.onMouseInOrOut(false)}
            style={{ backgroundColor: buttonBackgroundColor }}
            {...filteredProps}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: children,
              }}
            ></span>
            {this.renderHoverEffect(hoverColor)}
          </button>
        ) : (
          <button
            ref={innerRef ? innerRef : this.buttonRef}
            onClick={onClick}
            className={`${classNames.join(" ")}`}
            // onMouseEnter={() => this.onMouseInOrOut(true)}
            // onMouseLeave={() => this.onMouseInOrOut(false)}
            style={{ backgroundColor: buttonBackgroundColor }}
            {...filteredProps}
          >
            {children}
            {this.renderHoverEffect(hoverColor)}
          </button>
        )}
      </>
    )
  }
}

export default React.forwardRef((props, ref) => (
  <Button innerRef={ref} {...props} />
))
// export default Button
