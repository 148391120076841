import React from "react"
import Helmet from "react-helmet"
import s from "./ImpressionenDetailsSection.module.scss"
import PromotionSectionWrapper from "../PromotionSectionWrapper/PromotionSectionWrapper"
import { ChildrenButtonRenderer } from "../../"

class ImpressionenDetailsSection extends React.Component {
  render() {
    const { title_one_text, description, itemProp } = this.props
    return (
      <PromotionSectionWrapper
        itemProp={itemProp}
        className={s.impressionen_section}
      >
        <Helmet>
          <meta
            property="og:description"
            content={title_one_text + " - " + description}
          />
        </Helmet>
        <div className={s.impressionen_details_wrapper}>
          <h1 className={s.title}>{title_one_text}</h1>
          <p className={s.description}>{description}</p>
          <ChildrenButtonRenderer buttonData={this.props.innerBlocks} />
        </div>
      </PromotionSectionWrapper>
    )
  }
}

export default ImpressionenDetailsSection
