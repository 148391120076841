import React, { Fragment } from "react"
import s from "./RefreshPromoTwentyOne.module.scss"

import SpinningWheelSection from "./components/SpinningWheelSection/SpinningWheelSection"
import NotWin from "./components/NotWin/NotWin"
import Form from "./components/Form/Form"
import Success from "./components/Success/Success"
import Error from "./components/Error/Error"

import { getCurrentLanguage } from "../../helper"

import { confirmRefreshV3Entry, getParameterByName } from "./api"

const frDefault = {
  wheelImage: "/wheel_520x520_fr.png",
  packageReceiverTitle: "J'envoie le colis à:",
  description_desktop: `<strong>Saisir son adresse électronique et faire tourner la roue de la fortune.</strong><br/><br/>Tu verras immédiatement après si tu as gagné et si tu peux faire envoyer le coffret. En raison de la forte demande, l'expédition peut prendre jusqu'à deux semaines. Tu ne peux faire tourner la roue de la fortune qu'une fois par adresse électronique.`,
  description_mobile: `<strong>Saisir son adresse électronique et faire tourner la roue de la fortune.</strong><br/><br/>Tu verras immédiatement après si tu as gagné et si tu peux faire envoyer le coffret. En raison de la forte demande, l'expédition peut prendre jusqu'à deux semaines. Tu ne peux faire tourner la roue de la fortune qu'une fois par adresse électronique.`,
  description_warning:
    "Attention : Un seul colis peut être envoyé par adresse postale.",
  emailTerms: () => (
    <a href="/fr/refresh-cdp" target="_blank" rel="noopener noreferrer">
      Conditions de Participations
    </a>
  ),
  congratulationsTitle: "Félicitations, tu as gagné!",
  email_address: "Adresse E-mail*",
  spin_button: "Tourne la roue",
  fillAddressPrompt: `Tu peux maintenant remplir le formulaire ci-dessous et te rafraîchir ou rafraîchir quelqu’un de proche.`,
  receiverTitle: "Quelqu‘un d‘autre que j‘apprécie",
  senderReceiverTitleDesktop: "Moi-même (même adresse pour l‘envoi)",
  senderReceiverTitleMobile: "Moi-même",
  formFieldsInfo: {
    sender: {
      header: { title: "Expéditeur" },
      mr_mrs: {
        placeholder: "Civilité",
        mr: { label: "M.", value: "M." },
        mrs: { label: "Mme.", value: "Mme." },
      },
      title: {
        placeholder: "Titre",
        empty: { label: " ", value: " " },
        dr: { label: "Dr.", value: "Dr." },
      },
      last_name: { placeholder: "Prénom" },
      first_name: { placeholder: "Nom" },
      email: { placeholder: "Adresse E-mail" },
    },
    reciever: {
      header: { title: "Expéditeur/Destinaire" },
      header_two: { title: "Destinaire" },
      mr_mrs: {
        placeholder: "Civilité",
        mr: { label: "M.", value: "M." },
        mrs: { label: "Mme.", value: "Mme." },
      },
      title: {
        placeholder: "Titre",
        empty: { label: " ", value: " " },
        dr: { label: "Dr.", value: "Dr." },
      },
      last_name: { placeholder: "Nom" },
      first_name: { placeholder: "Prénom" },
      email: { placeholder: "Adresse E-mail" },
      street: { placeholder: "Rue" },
      house_no: { placeholder: "Numéro" },
      postal_code: { placeholder: "NPA" },
      town: { placeholder: "Localité" },
      delivery_warning:
        "* Veuillez noter que l'expédition n'est possible qu'à l'intérieur de la Suisse.",
    },
    checkboxes: {
      newsletter: {
        placeholder: "Oui, je souhaite m‘abonner à la newsletter Rivella.",
      },
      termsAndContidition: {
        placeholder: () => (
          <span>
            * Oui, j’accepte{" "}
            <a href="/fr/refresh-cdp" target="_blank" rel="noopener noreferrer">
              les conditions de participation.
            </a>
          </span>
        ),
      },
    },
    submit: { placeholder: "ENVOYER" },
  },
}

const translations = {
  de: {
    wheelImage: "/wheel_520x520.png",
    congratulationsTitle: "Herzlichen Glückwunsch, du hast gewonnen!",
    description_desktop:
      "<strong>E-Mailadresse angeben und am Glücksrad drehen</strong><br/><br/>Du siehst direkt im Anschluss, ob du gewonnen hast und kannst das Paket verschicken. Aufgrund der grossen Nachfrage, kann der Versand bis zu zwei Wochen dauern. Pro E-Mailadresse kann nur einmal am Glücksrad gedreht werden.",
    description_mobile:
      "<strong>E-Mailadresse angeben und am Glücksrad drehen</strong><br/><br/>Du siehst direkt im Anschluss, ob du gewonnen hast und kannst das Paket verschicken. Aufgrund der grossen Nachfrage, kann der Versand bis zu zwei Wochen dauern. Pro E-Mailadresse kann nur einmal am Glücksrad gedreht werden.",
    description_warning:
      "Achtung: Wir verschicken pro Postadresse nur ein Paket.",
    emailTerms: () => (
      <a href="/de/refresh-tnb" target="_blank" rel="noopener noreferrer">
        Zu den Teilnahmebedingungen
      </a>
    ),
    email_address: "E-Mailadresse*",
    spin_button: "glücksrad drehen",
    fillAddressPrompt: `Du kannst nun das untenstehende Formular ausfüllen und dich oder jemanden, der dir im Kopf herumschwebt, erfrischen.`,
    packageReceiverTitle: "Ich sende das Paket an:",
    receiverTitle: "Jemand anderes, den ich mag",
    senderReceiverTitleDesktop: "mich selbst (gleiche Empfänger Adresse)",
    senderReceiverTitleMobile: "mich selbst",
    // form
    formFieldsInfo: {
      sender: {
        header: { title: "Absender" },
        mr_mrs: {
          placeholder: "Anrede",
          mr: { label: "Herr", value: "Herr" },
          mrs: { label: "Frau", value: "Frau" },
        },
        title: {
          placeholder: "Titel",
          empty: { label: " ", value: " " },
          dr: { label: "Dr.", value: "Dr." },
        },
        last_name: { placeholder: "Nachname" },
        first_name: { placeholder: "Vorname" },
        email: { placeholder: "E-mail" },
      },
      reciever: {
        header: { title: "Absender/Empfänger" },
        header_two: { title: "Empfänger" },
        mr_mrs: {
          placeholder: "Anrede",
          mr: { label: "Herr", value: "Herr" },
          mrs: { label: "Frau", value: "Frau" },
        },
        title: {
          placeholder: "Titel",
          empty: { label: " ", value: " " },
          dr: { label: "Dr.", value: "Dr." },
        },
        last_name: { placeholder: "Nachname" },
        first_name: { placeholder: "Vorname" },
        email: { placeholder: "E-mail" },
        street: { placeholder: "Strasse" },
        house_no: { placeholder: "Hausnummer" },
        postal_code: { placeholder: "PLZ" },
        town: { placeholder: "Ort" },
        delivery_warning:
          "* Bitte beachte, dass ein Versand nur innerhalb der Schweiz möglich ist.",
      },
      checkboxes: {
        newsletter: {
          placeholder: "Ja, ich möchte den Rivella Newsletter abonnieren.",
        },
        termsAndContidition: {
          placeholder: () => (
            <span>
              * Ja, ich stimme den{" "}
              <a
                href="/de/refresh-tnb"
                target="_blank"
                rel="noopener noreferrer"
              >
                Teilnahmebedingungen
              </a>{" "}
              zu. *
            </span>
          ),
        },
      },
      submit: { placeholder: "Absenden" },
    },
  },
  fr: frDefault,
  fr_fr: frDefault,
  lu_fr: frDefault,
}

const RefreshPromoTwentyOne = (props) => {
  const [formState, setFormState] = React.useState("closed")
  const [failMessage, setFailMessage] = React.useState(undefined)
  const [mail, setMail] = React.useState("")

  const confirmEntry = async () => {
    const hash = getParameterByName("confirm", props.location.href)

    confirmRefreshV3Entry(hash)
      .then((res) => {
        if (res.result === "error") {
          setFailMessage(res.message)
          setFormState("failed_error")
        } else {
          setFormState("success_confirm")
          if (
            typeof window !== "undefined" &&
            typeof document !== "undefined"
          ) {
            window.scrollTo({
              top: document.body.scrollHeight - 650,
              left: 0,
              behavior: "smooth",
            })
          }
        }
      })
      .catch((err) => {
        console.warn(err)
      })
  }

  React.useEffect(() => {
    const confirm = getParameterByName("confirm", props.location.href)
    if (confirm) {
      confirmEntry()
    }
  }, [])

  const showForm = (m) => {
    setMail(m)
    setFormState("receiver")
    if (typeof window !== "undefined") {
      window.scrollBy({
        top: 600,
        left: 0,
        behavior: "smooth",
      })
    }
  }

  const showNotWin = (msg) => {
    setFailMessage(msg)
    setFormState("failed_notwin")
    if (typeof window !== "undefined") {
      window.scrollBy({
        top: 500,
        left: 0,
        behavior: "smooth",
      })
    }
  }

  const showError = (msg) => {
    setFailMessage(msg)
    setFormState("failed_error")
    if (typeof window !== "undefined") {
      window.scrollBy({
        top: 500,
        left: 0,
        behavior: "smooth",
      })
    }
  }

  const showSuccess = () => {
    setFormState("success")
  }

  const { lang } = getCurrentLanguage(props.location)

  return (
    <Fragment>
      <SpinningWheelSection
        {...props}
        {...{ showForm, showNotWin, showError }}
        {...translations[lang]}
      />
      {/* Don't display anything when user just visits promo page */}
      {/* {formState === "closed" && ( */}
      <div
        className={`${s.closed_space} ${
          formState !== "closed" ? s.wheel_padding : ""
        }`}
      />
      {/* )} */}
      {/* Display when user fails spinning wheel */}
      {formState === "failed_notwin" && (
        <NotWin {...props} location={props.location} already={failMessage} />
      )}
      {formState === "failed_error" && (
        <Error
          {...props}
          formState={formState}
          failMessage={failMessage}
          location={props.location}
        />
      )}
      {/* Form */}
      {["sender", "receiver"].includes(formState) && (
        <Form
          {...props}
          showError={showError}
          showSuccess={showSuccess}
          email={mail}
          {...translations[lang]}
        />
      )}
      {/* Success */}
      {formState === "success" && <Success location={props.location} />}
      {formState === "success_confirm" && (
        <Success type="confirm" location={props.location} />
      )}
    </Fragment>
  )
}

export default RefreshPromoTwentyOne
