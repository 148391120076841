import React from "react"
import moment from "moment"
import Helmet from "react-helmet"
import { debounce } from "throttle-debounce"

import s from "./MainPageHero.module.scss"
import { ImgFluid, SkewedWrapper, MainPageVideoBottle } from "../../components"
import { extractImageNameFromUrl, getCurrentLanguage } from "../../helper"

import ArrowDown from "../../assets/svgs/arrow_down.svg"
import { starts_in, LANGUAGE_ABBREVATIONS } from "../../constants"

const itemSpecificParallaxRatios = {
  bottle: { x: 0.04, y: 0.006 },
  title: { x: 0.007, y: 0.008 },
  backgroundImg: { x: 0.0005, y: 0.001 },
}

const MOVEMENT_VIDEO_LENGTH = 5000
const INITIAL_DROP_VIDEO_LENGTH = 5000
const INITIAL_BOTTLE_ANIMATION_LENGTH = 1200

class MainPageHero extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentVideo: null,
      doNotChangeVideoTill: null,
      bottleEntered: false,
    }
    this.timeTheBottleWillBeFullyDropped = null
    this.backToIdleTimeout = null
    this.lastMousePositions = null
    this.lastMousePositionUpdated = null
    this.lastParallaxUpdated = null
    this.title = React.createRef()
    this.bottle = React.createRef()
    this.backgroundImg = React.createRef()
  }
  componentDidMount() {
    if (typeof document !== undefined) {
      document.addEventListener("mousemove", (e) => this.onMouseMovement(e))
    }
    if(typeof window !== "undefined") {
      window.addEventListener("resize", this.onResize)
    }
    this.timeTheBottleWillBeFullyDropped =
      Date.now() + INITIAL_BOTTLE_ANIMATION_LENGTH * 1.2
    this.makeBottleEnter()

    // set title font size
    setTimeout(() => {
      this.setTitleFontSize()
    }, 20);
  }

  componentWillUnmount() {
    if (typeof document !== undefined) {
      document.removeEventListener("mousemove", (e) => this.onMouseMovement(e))
    }

    if (typeof window !== "undefined") {
      window.removeEventListener("mousemove", this.onResize)
    }
  }

  makeBottleEnter = () => {
    setTimeout(() => {
      this.setState(
        {
          bottleEntered: true,
        },
        () => {
          this.startInitialVideo()
        }
      )
    }, 50) // small unrecognizeable time to give browser do initial render
  }
  startInitialVideo = () => {
    setTimeout(() => {
      this.setMovementVideo("initialDrop", INITIAL_DROP_VIDEO_LENGTH)
    }, INITIAL_BOTTLE_ANIMATION_LENGTH * 0.66)
  }

  setCurrentVideo = (to, doNotChangeVideoTill) => {
    if (this.state.currentVideo !== to) {
      this.setState({
        currentVideo: to,
        doNotChangeVideoTill,
      })
    }
  }

  setMovementVideo = (to, doNotChangeVideoTill) => {
    const now = Date.now()
    clearTimeout(this.backToIdleTimeout)
    this.setCurrentVideo(to, doNotChangeVideoTill + now)
    this.backToIdleTimeout = setTimeout(() => {
      this.setCurrentVideo("idle", null)
    }, MOVEMENT_VIDEO_LENGTH - 1000)
  }

  setTitleFontSize = () => {
    if (typeof window === "undefined") return

    const title = this.title.current
    if (title) {
      // make h1 inside title fill the title width
      const titleWidth = title.offsetWidth
      const span = title.querySelector("span")
      if (span) {
        let fontSize = 0
        span.style.fontSize = `${fontSize}px`
        while (
          span.offsetWidth < Math.min(titleWidth, window.innerWidth * 0.8)
          && span.offsetHeight < window.innerHeight * 0.6
          && fontSize < 230
        ) {
          fontSize += 1
          span.style.fontSize = `${fontSize}px`
        }

        title.style.opacity = 1
      }

    }
  }

  onResize = debounce(200, () => {
    console.log('resize')
    this.setTitleFontSize()
  })

  calcMouseSpeed = (e) => {
    if (typeof Date === "undefined") return

    const { clientX: x, clientY: y } = e
    const { doNotChangeVideoTill } = this.state
    const deriveTime = 50 // ms
    const now = Date.now()
    if (
      (!this.lastMousePositionUpdated ||
        this.lastMousePositionUpdated + deriveTime < now) &&
      (!doNotChangeVideoTill || doNotChangeVideoTill < now)
    ) {
      if (this.lastMousePosition) {
        const x_vel = (x - this.lastMousePosition.x) / deriveTime
        // const y_vel = (y - this.lastMousePosition.y) / deriveTime
        this.lastMousePosition = {
          x,
          y,
        }
        this.lastMousePositionUpdated = now
        if (x_vel > 0.2) {
          this.setMovementVideo("moveLeft", 1000)
          // this.setMovementVideo("moveLeft", MOVEMENT_VIDEO_LENGTH)
        } else if (x_vel < -0.2) {
          this.setMovementVideo("moveRight", 1000)
          // this.setMovementVideo("moveRight", MOVEMENT_VIDEO_LENGTH)
        }
      } else {
        this.lastMousePosition = {
          x,
          y,
        }
      }
    }
  }

  onMouseMovement = (e) => {
    if (typeof window === "undefined") return
    const { innerWidth } = window
    if (innerWidth < 1200) return // do not change the layout for tablet and mobile
    this.calcMouseSpeed(e)
    this.calcParallaxRatios(e)
  }

  calcParallaxRatios = (e) => {
    const { innerWidth, innerHeight } = window

    if (typeof Date === "undefined") return

    const now = Date.now()
    if (!this.lastParallaxUpdated || this.lastParallaxUpdated + 50 < now) {
      const { clientX: mouseX, clientY: mouseY } = e

      const xRatio = mouseX - innerWidth / 2
      const yRatio = mouseY - innerHeight / 2
      // if (this.bottle.current && now > this.timeTheBottleWillBeFullyDropped) {
      //   this.bottle.current.style.transition = "transform 0.2s ease-out"
      //   this.bottle.current.style.transform = this.translateStyleConstructor(
      //     itemSpecificParallaxRatios.bottle.x * xRatio,
      //     itemSpecificParallaxRatios.bottle.y * yRatio
      //   )
      // }
      // Disabled title movement on mouse
      // if (this.title.current) {
      //   this.title.current.style.transform = this.translateStyleConstructor(
      //     itemSpecificParallaxRatios.title.x * xRatio,
      //     itemSpecificParallaxRatios.title.y * yRatio
      //   )
      // }
      if (this.backgroundImg.current)
        this.backgroundImg.current.style.transform =
          this.translateStyleConstructor(
            itemSpecificParallaxRatios.backgroundImg.x * xRatio,
            itemSpecificParallaxRatios.backgroundImg.y * yRatio
          )
      this.lastParallaxUpdated = now
    }
  }

  onArrowDown = () => {
    if (typeof this.props.onScrollTo === "function") {
      this.props.onScrollTo({
        behavior: "smooth",
        block: "start",
      })
    }
  }

  translateStyleConstructor = (x, y) => {
    return `translate(${x}%, ${y}%)`
  }

  render() {
    const { currentVideo, bottleEntered } = this.state
    const {
      title_main,
      title_main_color,
      title_one_text,
      title_one_color,
      title_two_text,
      title_two_color,
      background_img,
      background_position_desktop,
      background_position_mobile,
      countdown_date,
      link_target,
      banner_is_hidden,
      banner_type,
      banner_img,
      banner_img_alt,
      banner_img_width,
      banner_img_1,
      banner_img_alt_1,
      banner_img_2,
      banner_img_alt_2,
      link_target_1,
      link_target_2,
      location,
    } = this.props

    const frenchLangs = [
      LANGUAGE_ABBREVATIONS.france.combined,
      LANGUAGE_ABBREVATIONS.french.combined,
      LANGUAGE_ABBREVATIONS.luxembourg.combined,
      LANGUAGE_ABBREVATIONS.italian.combined,
    ]

    const currentLang = getCurrentLanguage(location).combined
    const isFrenchDate = frenchLangs.includes(currentLang)

    let imgName = "Hero_Background.png"
    if (background_img) {
      imgName = extractImageNameFromUrl(background_img)
    }

    const BottleClasses = bottleEntered
      ? [s.Bottle]
      : [s.Bottle, s.BottleHidden]

    let timeTillPromotion = ""
    // if(moment(countdown_date).diff(Date.now()) < 0) {
    // logic to change depending of if passed can be written here
    // }
    timeTillPromotion = moment(countdown_date).fromNow(true)

    const backgroundImageStyle = {};
    if(background_position_mobile && window.innerWidth < 760) {
      backgroundImageStyle.objectPosition = background_position_mobile;
    } else if(background_position_desktop && window.innerWidth >= 760) {
      backgroundImageStyle.objectPosition = background_position_desktop;
    }

    return (
      <section
        key="section"
        className={s.Hero}
        style={{ zIndex: this.props.sectionZindex }}
        ref={this.props.sectionRef}
      >
        <div className={s.BackgroundImageWrapper}>
          <div ref={this.backgroundImg} className={s.ImageRefWrapper}>
            <ImgFluid
              cn={s.Image}
              fadeIn={false}
              fileName={imgName}
              alt={title_main}
              loading="eager"
              imgStyle={backgroundImageStyle}
            />
          </div>
        </div>
        <div className={BottleClasses.join(" ")} ref={this.bottle}>
          <MainPageVideoBottle
            currentVideo={currentVideo}
            imageName="rivella-rot-detail-1.png"
            loading="eager"
            durationFadeIn={0}
          />
          {/* <ImgFluid
            cn={s.Image}
            fileName="rivella-rot-detail.png"
            alt="Bottle"
          /> */}
        </div>
        <div className={s.TitleMainWrapper} ref={this.title} style={{ opacity: 0 }}>
          <h1
            className={[s.TitleMain, title_main_color].join(" ")}
            itemProp="slogan"
          ><span dangerouslySetInnerHTML={{ __html: title_main }}/></h1>
        </div>
        <Helmet>
          <meta property="og:description" content={title_one_text} />
        </Helmet>
        <div
          className={[s.TitleWrapper, banner_type === "double_image" ? s.DoubleBubblesWrapper : ''].join(" ")}
          style={{ visibility: banner_is_hidden ? "hidden" : null }}
        >
          {banner_type === "double_image" && (banner_img_1 || banner_img_2) ? (
            <div className={s.DoubleBubbles}>
              { link_target_1 &&
              <a href={link_target_1} rel="noopener noreferrer" className={s.TitleWrapperLink} target="_blank">
                <img src={banner_img_1} alt={banner_img_alt_1} />
              </a>}
              { link_target_2 &&
              <a href={link_target_2} rel="noopener noreferrer" className={s.TitleWrapperLink} target="_blank">
                <img src={banner_img_2} alt={banner_img_alt_2} />
                </a>}
            </div>
          ) : null}
          {banner_type === "image" && banner_img ? (
            <a href={link_target} rel="noopener noreferrer" className={s.TitleWrapperLink} target="_blank">
              <img
                src={banner_img}
                alt={banner_img_alt}
                style={banner_img_width ? { width: `${banner_img_width}` } : { width: '400px'}}
              />
            </a>
          ) : null}
          {banner_type === "countdown" ? (
            <SkewedWrapper
              type="primary"
              wrapperClassName={[
                s.ParentSkewedWrapper,
                isFrenchDate ? s["wide_banner"] : "",
              ].join(" ")}
            >
              <h3
                className={[s.Title, s.CountdownTitle].join(" ")}
                dangerouslySetInnerHTML={{
                  __html: title_one_text,
                }}
              />
              <h4
                className={[
                  s.Title,
                  s.CountdownDays,
                  isFrenchDate ? s["wide_banner"] : "",
                ].join(" ")}
              >
                {`${starts_in[currentLang]} ${timeTillPromotion}`}
              </h4>
            </SkewedWrapper>
          ) : null}
          {banner_type === "link" || !banner_type ? (
            <a href={link_target} rel="noopener noreferrer" target="_blank">
              <SkewedWrapper
                type="primary"
                wrapperClassName={s.ParentSkewedWrapper}
              >
                <h2
                  className={[s.Title, s.BoldTitle, title_one_color].join(" ")}
                >
                  {title_one_text}
                </h2>
                <h3
                  className={[s.Title, s.LightTitle, title_two_color].join(" ")}
                >
                  {title_two_text}
                </h3>
                {/* <div className={s.ChildSkewedWrapper}>
                  <h4
                    className={[s.Title, s.BoldTitle, s.TerceryTitle].join(" ")}
                  >
                    {title_three_text}
                  </h4>
                </div> */}
              </SkewedWrapper>
            </a>
          ) : null}
        </div>
        <ArrowDown className={s.ArrowDown} onClick={this.onArrowDown} />
        <div className={s.WhiteCorner}></div>
      </section>
    )
  }
}

export default MainPageHero
