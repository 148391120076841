import React from "react"
import s from "./NotWin.module.scss"
import { getCurrentLanguage } from "../../../../helper"

const NotWin = (props) => {
  const { lang } = getCurrentLanguage(props.location)
  const fr = {
    not_win: {
      title: `Dommage, tu n'as malheureusement pas gagné.`,
    },
    already: {
      title: "Nice Try.",
      text: `Cet e-mail a déjà été utilisé. Procure-toi ton rafraîchissement dans les magasins dès maintenant ou visite-nous sur nos réseaux sociaux.`,
    },
  }

  const it = {
    not_win: {
      title: `Peccato che non abbiate vinto.`,
    },
    already: {
      title: "Nice Try.",
      text: `Questa e-mail è già stata utilizzata. Rinfrescatevi subito nei negozi o venite a trovarci sui nostri social network.`,
    },
  }

  const translations = {
    de: {
      not_win: {
        title: "Schade, du hast leider Heute nicht gewonnen.",
      },
      already: {
        title: "Nice Try.",
        text: `Diese E-Mail wurde bereits verwendet. Hol dir jetzt deine Erfrischung auch im Handel oder besuche uns auf Social Media.`,
      },
    },
    fr: fr,
    fr_fr: fr,
    lu_fr: fr,
    it: it,
  }

  return (
    <section className={s.section}>
      <div className={s.failed_title}>
        <h2>
          {translations[lang][props.already ? "already" : "not_win"].title}
        </h2>
        { props.already && <div className={s.failed_description}>
            <p>{translations[lang].already.text}</p>
        </div>}
      </div>
    </section>
  )
}

export default NotWin
