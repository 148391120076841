import React from "react"
import { navigate, Link } from "gatsby"

import s from "./LinkWithTransition.module.scss"
import { ROUTING_ANIMATION_FADEIN_LENGTH } from "../../constants"
import { CustomTransitionConsumer } from "../../store/"

class LinkWithTransition extends React.Component {
  onClick = (event, dispatch, actions) => {
    const { itemIndex, currentIndex, to } = this.props

    // stop Gatsby Link from routing immediately
    event.preventDefault()

    if (itemIndex && itemIndex === currentIndex) {
      dispatch(actions.triggerRouteAnimation())
      // after first half of animation completes route
      setTimeout(() => {
        navigate(to, {
          state: {
            customTransition: true,
          },
        })
      }, ROUTING_ANIMATION_FADEIN_LENGTH * 0.9)
    } else if (itemIndex && itemIndex !== currentIndex) {
      return null
    } else {
      dispatch(actions.triggerRouteAnimation())
      // after first half of animation completes route
      setTimeout(() => {
        navigate(to, {
          state: {
            customTransition: true,
          },
        })
      }, ROUTING_ANIMATION_FADEIN_LENGTH * 0.9)
    }
  }
  render() {
    const { children, className } = this.props
    return (
      <CustomTransitionConsumer>
        {({ dispatch, actions }) => {
          return (
            <Link
              onClick={(e) => this.onClick(e, dispatch, actions)}
              {...this.props}
              className={[s.Link, className].join(" ")}
            >
              {children}
            </Link>
          )
        }}
      </CustomTransitionConsumer>
    )
  }
}

export default LinkWithTransition
