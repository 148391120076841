export const langData = {
  de: {
    text: "Wir verwenden Cookies - sie erleichtern die Benutzung der Seite. Weitere Informationen findest Du in der Datenschutzerklärung. Klick auf „Akzeptieren“, um der Nutzung von Cookies zuzustimmen und diesen Hinweis dauerhaft auszublenden.",
    more: "Mehr Informationen",
    button: "Akzeptieren",
    ext: "https://www.rivella-group.com/de/extras/datenschutz",
  },
  fr: {
    text: `Nous utilisons des cookies - ils rendent le site plus facile à utiliser. Tu peux trouver plus d'informations dans la politique de confidentialité. Clique sur "Accepter" pour accepter l'utilisation des cookies et pour masquer définitivement cet avis.`,
    more: `Plus d'informations`,
    button: "Accepter",
    ext: "https://www.rivella-group.com/fr/extras/protection-des-donn%C3%A9es",
  },
  it: {
    text: `Usiamo i cookies - rendono il sito più facile da usare. Potete trovare maggiori informazioni nella politica sulla privacy. Clicca su "Accetta" per accettare l'uso dei cookie e per nascondere definitivamente questo avviso.`,
    more: "Più informazioni",
    button: "Accetta",
    ext: "https://www.rivella-group.com/it/extras/protezione-dei-dati",
  },
}
