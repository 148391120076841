import React from "react"
import s from "./AlternatePromotionPage.module.scss"

import { ImgFluid } from "../../components"
import { ChildrenButtonRenderer } from "../"
import { extractImageNameFromUrl } from "../../helper"

class AlternatePromotionPage extends React.Component {
  render() {
    const {
      side_image,
      title_one_text,
      list_items,
      description_one_text,
      description_two_text,
    } = this.props
    return (
      <section className={s.Section}>
        <div className={[s.Col, s.Left].join(" ")}>
          <h1 dangerouslySetInnerHTML={{ __html: title_one_text }} />
          <p dangerouslySetInnerHTML={{ __html: description_one_text }} />
          {list_items && list_items.length ? (
            <ul>
              {list_items.map((L) => (
                <li key={L} dangerouslySetInnerHTML={{ __html: L }} />
              ))}
            </ul>
          ) : null}
          <p dangerouslySetInnerHTML={{ __html: description_two_text }} />
          <ChildrenButtonRenderer buttonData={this.props.innerBlocks} />
        </div>
        <div className={[s.Col, s.Right].join(" ")}>
          <ImgFluid
            fileName={extractImageNameFromUrl(side_image)}
            alt={title_one_text}
          />
        </div>
      </section>
    )
  }
}

export default AlternatePromotionPage
