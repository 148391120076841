import React from "react"
import Helmet from "react-helmet"
import Slider from "react-slick"
import { Link } from "gatsby"
import bowser from "bowser"

import {
  SkewedSectionWithBg,
  SkewedCard,
  SkewedWrapper,
  SkewedButton,
  ImgFluid,
} from "../../components"
import s from "./EventsNewsSection.module.scss"
import {
  extractImageNameFromUrl,
  getPosition,
  getCurrentLanguage,
  checkFirefox,
} from "../../helper"
import { reshapeEventsData } from "../../util/reshapeEvents"
import { learn_more_third_alternate } from "../../constants"
import { routes } from "../Header/LanguagesData"

const EmptySlideCardSpot = () => {
  return (
    <SkewedWrapper
      wrapperClassName={`${s.SlideCard} ${s.SlideCardHidden}`}
      verticalInner
    />
  )
}

const SlideCard = ({
  eventImg,
  eventTitle,
  descriptionPreview,
  button_one_text,
  slug,
  detailPageHideOption,
}) => {
  // split text at proper location by determining text length and <br /> points
  // let descriptionToRender = description
  // let foundIndex = -2,
  //   lastFoundIndex = 0,
  //   currentIteration = 0
  // const maxTextLength = 300
  // while (foundIndex !== -1) {
  //   lastFoundIndex = foundIndex
  //   foundIndex = descriptionToRender.indexOf("<br>", currentIteration++)
  //   if (maxTextLength < foundIndex) {
  //     break
  //   }
  // }
  // if (lastFoundIndex > 0) {
  //   descriptionToRender = descriptionToRender.slice(0, lastFoundIndex)
  // }
  // // fires if there is no <br> before the maxLimit
  // if (descriptionToRender.length > maxTextLength) {
  //   descriptionToRender = descriptionToRender.slice(0, maxTextLength) + "..."
  // }

  return (
    <SkewedWrapper wrapperClassName={`${s.SlideCard}`} verticalInner>
      <div className={s.CardImage}>
        {detailPageHideOption === "hide_link" ? (
          <a className={s.event_item_wrapper}>
            <ImgFluid
              fileName={extractImageNameFromUrl(eventImg)}
              alt={eventTitle}
            />
          </a>
        ) : (
          <Link className={s.event_item_wrapper} to={slug}>
            <ImgFluid
              fileName={extractImageNameFromUrl(eventImg)}
              alt={eventTitle}
            />
          </Link>
        )}
      </div>
      <div className={s.CardContent}>
        <h3
          className={s.Title}
          dangerouslySetInnerHTML={
            detailPageHideOption === "hide_link" ? { __html: eventTitle } : null
          }
        >
          {detailPageHideOption === "hide_link" ? null : (
            <Link
              className={s.event_item_wrapper}
              to={slug}
              dangerouslySetInnerHTML={{ __html: eventTitle }}
            />
          )}
        </h3>
        <p
          className={s.Description}
          dangerouslySetInnerHTML={{
            __html: descriptionPreview,
          }}
        />
        {detailPageHideOption === "hide_link" ? null : (
          <Link className={s.Link} to={slug}>
            <SkewedButton wrapperClassName={s.SlideCardButton} type="primary">
              {button_one_text}
            </SkewedButton>
          </Link>
        )}
      </div>
    </SkewedWrapper>
  )
}

class EventsNewsSection extends React.Component {
  constructor(props) {
    super(props)
    this.cardTitleRef = React.createRef()
    this.slider = React.createRef()
    this.sliderWrapperRef = React.createRef()
    this.containerRef = React.createRef()
    this.state = {
      slidIn: false,
      desktopCardBaseWidth: 400,
      distFromTopToSlideIn: 9999999,
      desktopCarouselCurrentSlide: 0,
      sliderShouldSelfCorrect: false,
      isFirefox: false,
      slideData: reshapeEventsData(
        props.rawEvents,
        "news",
        getCurrentLanguage(props.location).slug
      ).eventData,
    }
  }

  componentDidMount() {
    this.calculateEleDistFromTop()
    this.checkFirefoxHandler()
    if (typeof document !== "undefined") {
      this.updateDesktopCarouselBaseWidth()
      window.addEventListener("resize", this.updateDesktopCarouselBaseWidth)
      window.addEventListener("resize", this.resetCarouselHeight)
      document.addEventListener("scroll", this.shouldSlideIn)
    }
  }
  componentWillUnmount() {
    if (typeof document !== "undefined") {
      window.removeEventListener("resize", this.updateDesktopCarouselBaseWidth)
      window.removeEventListener("resize", this.resetCarouselHeight)
      document.removeEventListener("scroll", this.shouldSlideIn)
    }
  }

  checkFirefoxHandler = () => {
    if (typeof window !== "undefined") {
      const isFirefox = !!checkFirefox(window, bowser)
      this.setState({
        isFirefox,
      })
    }
  }

  shouldSlideIn = () => {
    const { slidIn, distFromTopToSlideIn } = this.state
    if (!slidIn && distFromTopToSlideIn < window.scrollY) {
      this.setState({
        slidIn: true,
      })
    }
  }

  updateDesktopCarouselBaseWidth = () => {
    const sliderWrapper = this.sliderWrapperRef.current
    if (sliderWrapper) {
      const desktopCardBaseWidth = this.sliderWrapperRef.current.clientWidth
        ? this.sliderWrapperRef.current.clientWidth / 4
        : 400
      this.setState({
        desktopCardBaseWidth,
      })
    }
  }

  calculateEleDistFromTop = () => {
    const { y } = getPosition(this.cardTitleRef.current)
    const wrapper = this.containerRef && this.containerRef.current
    const calced = y - wrapper.clientHeight / 2 - window.innerHeight * 0.75
    const distFromTopToSlideIn = calced > 0 ? calced : 0
    const isFirefox = !!checkFirefox(window, bowser)

    this.setState({
      distFromTopToSlideIn: !isFirefox
        ? distFromTopToSlideIn
        : distFromTopToSlideIn / 2,
    })
  }

  // this is black magic sorry if you have to go through this, but basically
  // the carousel is when width of elements is different is doing a self correct
  // around 200-400ms after the transition completes, I can't have that so I'm
  // doing the transition myself. Otherwise UI jumps around. Disable where
  // this function was called to see what I mean
  correctCarouselPositioning = (
    isBefore,
    { isSecondaryCorrection, correctionVal, correctionRatio = 1 } = {}
  ) => {
    try {
      const slider = this.slider.current
      let widthToCorrect =
        (this.sliderWrapperRef.current.clientWidth / 4) * correctionRatio
      widthToCorrect = isBefore ? -widthToCorrect : widthToCorrect
      const slickTrack = slider.innerSlider.list.querySelector(".slick-track")
      const transform = slickTrack.style.transform
      // input "translate3d(-2925px, 0px, 0px)" -> output "2925"
      const transformCaptureRegex = /translate3d\(-?([\d]*)px, 0px, 0px\)/
      const transformX = Number(transformCaptureRegex.exec(transform)[1])
      slickTrack.style.transition = "0.4s ease-out"
      const nextTransformXValue = correctionVal
        ? correctionVal
        : transformX - widthToCorrect
      const nextTransform = `translate3d(-${nextTransformXValue}px, 0px, 0px)`
      slickTrack.style.transform = nextTransform
      // carousel tries to reset back itself, do another timeouts to overwrite that, the timeouts are cleared on beforeSlide
      // also higher values are for slower machines. The logic injection I'm doing has a race condition which
      // gets obvious slower machines thus I'm repeating to avoid that, adding after 400 is not good as slider
      // also has a self correction algorithm embedded so it can "loop" itself, any more than 400 breaks that
      if (!isSecondaryCorrection) {
        const correctionFunction = () => {
          this.correctCarouselPositioning(isBefore, {
            isSecondaryCorrection: true,
            correctionVal: nextTransformXValue,
          })
        }
        this.correctionTimeout = window.setTimeout(
          () => correctionFunction(),
          50
        )
        this.correctionSecondTimeout = window.setTimeout(
          () => correctionFunction(),
          200
        )
        this.correctionThirdTimeout = window.setTimeout(
          () => correctionFunction(),
          300
        )
      }
    } catch (err) {
      // ignore, throws an undefined error if carousel transition is occuring when
      // routing happens thus the ref goes undefined
    }
  }

  saveCarouselHeight = () => {
    try {
      const sliderWrapper = this.sliderWrapperRef.current
      const currentHeight = sliderWrapper.clientHeight
      sliderWrapper.style.height = currentHeight + "px"
    } catch (err) {
      // ignore, throws an undefined error if carousel transition is occuring when
      // routing happens thus the ref goes undefined
    }
  }

  resetCarouselHeight = () => {
    try {
      const sliderWrapper = this.sliderWrapperRef.current
      sliderWrapper.style.height = null
    } catch (err) {
      // ignore, throws an undefined error if carousel transition is occuring when
      // routing happens thus the ref goes undefined
    }
  }

  clearSliderTimeouts = () => {
    if (typeof clearTimeout === "function") {
      clearTimeout(this.correctionTimeout)
      clearTimeout(this.correctionSecondTimeout)
      clearTimeout(this.correctionThirdTimeout)
    }
  }

  beforeCarouselSlide = (currentSlide, nextSlide) => {
    const shouldSelfCorrect = nextSlide > currentSlide
    this.clearSliderTimeouts()
    this.saveCarouselHeight()
    // if loop back to end of list from beginning
    if (nextSlide > 2 && currentSlide === 0) {
      // TODO something like solution under next if here
    }
    if (shouldSelfCorrect) {
      this.correctCarouselPositioning(true, {
        correctionRatio: nextSlide - currentSlide,
      })
    }
    this.setState({
      desktopCarouselCurrentSlide: nextSlide,
      sliderShouldSelfCorrect: shouldSelfCorrect,
    })
  }

  afterCarouselSlide = () => {
    const { sliderShouldSelfCorrect } = this.state

    if (sliderShouldSelfCorrect) {
      this.postSliderTimeout = setTimeout(() => {
        this.setState({
          sliderShouldSelfCorrect: false,
        })
      }, 450)
    }
  }

  renderTopCard = (isInSlider, withKeyVisual) => {
    const {
      card_title_one_text,
      card_title_two_text,
      card_title_one_color,
      card_title_two_color,
      card_description_one_text,
      card_description_one_color,
      card_button_text,
      card_button_link,
      card_button_is_hidden,
    } = this.props
    const { slidIn } = this.state
    let cardClasses = [s.section_bg_card]
    if (slidIn) cardClasses.push(s.section_bg_card_sliding_in)
    if (isInSlider) {
      cardClasses.push(s.TopCardAbsolute)
      if (slidIn) {
        cardClasses.push(s.TopCardAbsoluteSlidIn)
      }
    }

    return (
      <SkewedCard cardOuterCn={cardClasses.join(" ")} keyVisual={withKeyVisual}>
        <h3
          className={[s.section_bg_card_subtitle, card_title_two_color].join(
            " "
          )}
          dangerouslySetInnerHTML={{ __html: card_title_two_text }}
        />
        <h4
          ref={this.cardTitleRef}
          className={[s.section_bg_card_title, card_title_one_color].join(" ")}
          dangerouslySetInnerHTML={{ __html: card_title_one_text }}
        />
        <p
          className={[
            s.section_bg_card_description,
            card_description_one_color,
          ].join(" ")}
          dangerouslySetInnerHTML={{ __html: card_description_one_text }}
        />
        {card_button_is_hidden ? null : (
          <Link className={s.Link} to={card_button_link}>
            <SkewedButton wrapperClassName={s.CardButton} type="primary">
              {card_button_text}
            </SkewedButton>
          </Link>
        )}
        {/* <ChildrenButtonRenderer buttonData={this.props.innerBlocks} /> */}
      </SkewedCard>
    )
  }

  renderTitlesWrapper = (isMobile) => {
    const { section_title_one, section_title_two } = this.props
    const wrapperClasses = [s.TitlesWrapper]
    if (isMobile) wrapperClasses.push(s.TitlesWrapperMobile)

    return (
      <div className={wrapperClasses.join(" ")}>
        <h4 className={s.SmallTitle}>{section_title_two}</h4>
        <h3 className={s.BigTitle}>{section_title_one}</h3>
      </div>
    )
  }

  renderNewsDesktop = (slideData, button_one_text) => {
    const isLessThanFour = slideData.length < 4
    const { desktopCarouselCurrentSlide, desktopCardBaseWidth } = this.state
    if (isLessThanFour) {
      return (
        <div className={s.SlideCardsDesktop}>
          <div className={s.SlideCardWrapper}>
            {slideData[0] ? (
              <SlideCard {...slideData[0]} button_one_text={button_one_text} />
            ) : (
              <EmptySlideCardSpot />
            )}
          </div>
          <div className={s.Col}>
            <div className={s.Flex1}>{this.renderTopCard(true)}</div>
            <div className={s.SlideCardWrapper}>
              {slideData[1] ? (
                <SlideCard
                  {...slideData[1]}
                  button_one_text={button_one_text}
                />
              ) : (
                <EmptySlideCardSpot />
              )}
              {slideData[2] ? (
                <SlideCard
                  {...slideData[2]}
                  button_one_text={button_one_text}
                />
              ) : (
                <EmptySlideCardSpot />
              )}
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div
          className={s.SlideCardsDesktopCarousel}
          ref={this.sliderWrapperRef}
        >
          <div className={[s.Flex1, s.DesktopCarouselTopCard].join(" ")}>
            {this.renderTopCard(true)}
          </div>
          <Slider
            dots
            ref={this.slider}
            speed={400}
            slidesToScroll={1}
            slidesToShow={3}
            draggable={false}
            swipe={false}
            focusOnSelect
            arrows={false}
            infinite
            // className={s.SlideCardsMobile}
            cssEase="ease-out"
            dotsClass={["slick-dots", s.Dots].join(" ")}
            variableWidth
            beforeChange={this.beforeCarouselSlide}
            afterChange={this.afterCarouselSlide}
          >
            {slideData.map((data, i) => {
              const eleWidth =
                desktopCarouselCurrentSlide === i
                  ? desktopCardBaseWidth * 2
                  : desktopCardBaseWidth
              return (
                <div
                  key={i}
                  style={{
                    width: eleWidth,
                  }}
                >
                  <div
                    className={[
                      s.SlideCardWrapper,
                      // sliderShouldSelfCorrect
                      //   ? s.SlideCardWrapperTransitionSmooth
                      //   : null,
                    ].join(" ")}
                    // style={{
                    //   transform: sliderShouldSelfCorrect
                    //     ? `translateX(${baseWidth}px)`
                    //     : null,
                    // }}
                  >
                    <SlideCard {...data} button_one_text={button_one_text} />
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
      )
    }
  }

  renderNews = () => {
    const { location } = this.props
    const { slideData } = this.state
    const currentLang = getCurrentLanguage(location)
    const button_one_text = learn_more_third_alternate[currentLang.combined]
    return (
      <div className={s.NewsCarouselSection}>
        {this.renderTitlesWrapper()}
        {this.renderNewsDesktop(slideData, button_one_text)}
        <div className={s.NewsCarouselWrapper}>
          <Slider
            dots
            speed={400}
            slidesToScroll={1}
            slidesToShow={1}
            className={s.SlideCardsMobile}
            cssEase="ease-out"
            dotsClass={["slick-dots", s.Dots].join(" ")}
          >
            {slideData.map((data, i) => {
              return (
                <div key={i}>
                  <div className={s.SlideCardWrapper}>
                    <SlideCard {...data} button_one_text={button_one_text} />
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    )
  }

  render() {
    const { background_img, card_description_one_text } = this.props
    const { isFirefox } = this.state
    const showKeyVisual = false
    return (
      <section
        className={[
          s.section_wrapper,
          isFirefox ? s.section_wrapper_firefox : null,
        ].join(" ")}
        style={{ zIndex: this.props.sectionZindex }}
        ref={this.containerRef}
      >
        <Helmet>
          <meta property="og:description" content={card_description_one_text} />
        </Helmet>
        {this.renderTitlesWrapper(true)}

        <SkewedSectionWithBg
          bgImg={background_img}
          keyVisual={showKeyVisual}
          wrapperClassName={s.section_bg}
          imageWrapperClass={s.image_wrapper}
          type="parallax"
        >
          {this.renderTopCard(false, showKeyVisual)}
        </SkewedSectionWithBg>
        <div ref={this.props.sectionRef} className={s.angkor} />
        {this.renderNews()}
      </section>
    )
  }
}

export default EventsNewsSection
