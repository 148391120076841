import React from "react"
import s from "./HomepageBottleSection.module.scss"
import PromotionSectionWrapper from "../PromotionSectionWrapper/PromotionSectionWrapper"
import { ImgFluid } from "../../../components"
import { extractImageNameFromUrl } from "../../../helper"
import BottleCapIllustration from "../../../assets/svgs/bottle_cap_illustration.svg"
import BottleCapIllustrationMobile from "../../../assets/svgs/bottle_cap_illustration_mobile.svg"
import { HikingAuthConsumer } from "../../../store"
import { ChildrenButtonRenderer } from "../../"

class HomepageBottleSection extends React.Component {
  state = {
    isMobileDevice: false,
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize)
  }

  render() {
    const { isMobileDevice, title_one_text, description_one_text } = this.props
    return (
      <HikingAuthConsumer>
        {(store) => {
          const { codeStatus } = store.state
          const showGewinnNiete = codeStatus.showMessageBox
          return (
            <>
              {!showGewinnNiete ? (
                <PromotionSectionWrapper
                  className={s.bottle_description_container}
                >
                  <section className={s.image_description_wrapper}>
                    <div className={s.bottle_image_group}>
                      <ImgFluid
                        fileName={extractImageNameFromUrl("Packshot_Kombi.jpg")}
                      />
                    </div>
                    <div className={s.description_wrapper}>
                      <div className={s.icon_wrapper}>
                        {isMobileDevice ? (
                          <BottleCapIllustrationMobile />
                        ) : (
                          <BottleCapIllustration />
                        )}
                      </div>
                      <h2
                        className={s.title}
                        dangerouslySetInnerHTML={{ __html: title_one_text }}
                      ></h2>
                      <p
                        className={s.description}
                        dangerouslySetInnerHTML={{
                          __html: description_one_text,
                        }}
                      ></p>
                      <ChildrenButtonRenderer
                        buttonData={this.props.innerBlocks}
                      />
                    </div>
                  </section>
                </PromotionSectionWrapper>
              ) : null}
            </>
          )
        }}
      </HikingAuthConsumer>
    )
  }
}

export default HomepageBottleSection
