import React, { Component } from "react"
import s from "./ImageFrame.module.scss"
import { ImgFluid } from ".."

class ImageFrame extends Component {
  render() {
    const { imgFilename, className } = this.props
    return (
      <div className={[s.image_frame_outer, className].join(" ")}>
        <ImgFluid
          cn={s.image_frame_inner}
          fileName={imgFilename}
          alt="Single event from promotion gallery"
        />
      </div>
    )
  }
}

export default ImageFrame
