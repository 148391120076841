import React from "react"
import { Link, navigate } from "gatsby"
import Helmet from "react-helmet"

import s from "./ProductOverview.module.scss"
import { ImgFluid, Button, SkewedWrapper, BottleWithDroplets } from "../"
import ArrowDown from "../../assets/svgs/arrow_down.svg"
import {
  extractImageNameFromUrl,
  getCurrentLanguage,
  calculateProductLinkLangPrefix,
  paintCirclesAndPaths,
  hexToRGB_obj,
} from "../../helper"
import { LANGUAGE_ABBREVATIONS } from "../../constants"

class ProductOverview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      customTitleSize: null,
      isNavigating: false,
      sideNavDirection: null,
      nextProductColor: null,
    }
    const length = props.data && props.data.list && props.data.list.length
    this.iconsList = []
    if (length) {
      for (let i = 0; i < length; i++) {
        this.iconsList.push(React.createRef())
      }
    } else {
      this.iconsList = [
        React.createRef(),
        React.createRef(),
        React.createRef(),
        React.createRef(),
        React.createRef(),
        React.createRef(),
        React.createRef(),
        React.createRef(),
      ]
    }
  }
  componentDidMount() {
    this.setState({
      customTitleSize: this.calcFontSize(),
    })
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.fontSizeChangeCheck)
    }
    this.paintIcons()
  }

  paintIcons = () => {
    const { productColor } = this.props
    const c = hexToRGB_obj(productColor)
    const rate = 0.8
    const nextColor = `rgb(${c.r + (255 - c.r) * rate}, ${
      c.g + (255 - c.g) * rate
    }, ${c.b + (255 - c.b) * rate})`
    this.iconsList.forEach((r) =>
      paintCirclesAndPaths({
        ref: r,
        circleColor: nextColor,
        pathColor: "white",
        circleOpacity: 0.7,
        pathOpacity: 1,
        removeClipPaths: true,
      })
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isNavigating && this.state.isNavigating) {
      this.props.triggerNavigateSync(
        this.state.sideNavDirection,
        this.state.nextProductColor
      )
    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.fontSizeChangeCheck)
    }
  }

  fontSizeChangeCheck = () => {
    const nextFontSize = this.calcFontSize()
    if (this.state.customTitleSize !== nextFontSize) {
      this.setState({
        customTitleSize: this.calcFontSize(),
      })
    }
  }

  calcFontSize = () => {
    const { title_one_text, title_two_text } = this.props.data || {}
    return this.props.calculateProductTitleSize(title_one_text, title_two_text)
  }

  onSideNav = (event, to, direction = "left", nextColor, prevColor) => {
    if (window && window.innerWidth > 900) {
      event.preventDefault()
      this.setState(
        {
          isNavigating: true,
          sideNavDirection: direction,
          nextProductColor: nextColor,
        },
        () => {
          setTimeout(() => {
            navigate(to, {
              state: {
                bottleSlideAnimate: true,
                bottleSlideDirection: direction,
                prevProductColor: prevColor,
              },
            })
          }, 400)
        }
      )
    }
  }

  getProductColor = (product) => {
    if (!product) return null

    try {
      const overview = product.childrenData.find(
        (c) => c.type === "rivella/product-overview"
      )
      return overview.props.product_primary_color
    } catch (_) {
      return null
    }
  }

  renderBottleNavIcons = ({
    isSmallScreen,
    currentProductId,
    allProducts = [],
  } = {}) => {
    const classes = isSmallScreen
      ? [s.NavigationArrows, s.NavigationArrowsMedium]
      : [s.NavigationArrows]

    const allProductsSorted = [...allProducts].sort((a, b) => {
      let return_val = 0
      try {
        const a_obj = a.childrenData.find(
          (d) => d.type === "rivella/product-overview"
        )
        const b_obj = b.childrenData.find(
          (d) => d.type === "rivella/product-overview"
        )
        const a_val = +a_obj.props.product_order_weight
        const b_val = +b_obj.props.product_order_weight
        if (a_val < b_val) return_val = 1
        else if (a_val > b_val) return_val = -1
        else return_val = 0
      } catch (err) {
        return_val = 0
      }
      return return_val
    })

    const indexOfProduct = allProductsSorted.findIndex(
      (p) => p.product_id === currentProductId
    )
    if (indexOfProduct !== -1) {
      let prevLink = "#",
        nextLink = "#",
        prevColor = null,
        nextColor = null
      const currentProductColor = this.getProductColor(
        allProductsSorted[indexOfProduct]
      )
      const currentLang = getCurrentLanguage(this.props.location)
      if (indexOfProduct + 1 === allProductsSorted.length) {
        nextLink = calculateProductLinkLangPrefix(
          allProductsSorted[0].path,
          currentLang.slug
        )
        nextColor = this.getProductColor(allProductsSorted[0])
      } else {
        nextLink = calculateProductLinkLangPrefix(
          allProductsSorted[indexOfProduct + 1].path,
          currentLang.slug
        )
        nextColor = this.getProductColor(allProductsSorted[indexOfProduct + 1])
      }
      if (indexOfProduct - 1 < 0) {
        prevLink = calculateProductLinkLangPrefix(
          allProductsSorted[allProductsSorted.length - 1].path,
          currentLang.slug
        )
        prevColor = this.getProductColor(
          allProductsSorted[allProductsSorted.length - 1]
        )
      } else {
        prevLink = calculateProductLinkLangPrefix(
          allProductsSorted[indexOfProduct - 1].path,
          currentLang.slug
        )
        prevColor = this.getProductColor(allProductsSorted[indexOfProduct - 1])
      }

      return (
        <div className={classes.join(" ")}>
          <Link
            onClick={(e) =>
              this.onSideNav(
                e,
                prevLink,
                "left",
                prevColor,
                currentProductColor
              )
            }
            to={prevLink}
            className={s.PreviousProductLink}
          >
            <ArrowDown />
          </Link>
          <Link
            onClick={(e) =>
              this.onSideNav(
                e,
                nextLink,
                "right",
                nextColor,
                currentProductColor
              )
            }
            to={nextLink}
            className={s.NextProductLink}
          >
            <ArrowDown />
          </Link>
        </div>
      )
    } else {
      return (
        <div className={classes.join(" ")}>
          <a to="#" className={s.PreviousProductLink}>
            <ArrowDown />
          </a>
          <a to="#" className={s.NextProductLink}>
            <ArrowDown />
          </a>
        </div>
      )
    }
  }

  renderBottleButtons = ({
    isSmallScreen,
    button_one_text = "Zutaten",
    button_two_text = "Ernährungsinfos",
    productColor,
  } = {}) => {
    const { onToIngredients, onToNutritions, location } = this.props
    const currentLang = getCurrentLanguage(location).combined
    const frenchLangs = [
      LANGUAGE_ABBREVATIONS.france.combined,
      LANGUAGE_ABBREVATIONS.french.combined,
      LANGUAGE_ABBREVATIONS.luxembourg.combined,
      LANGUAGE_ABBREVATIONS.italian.combined,
    ]

    const widerBtn = frenchLangs.includes(currentLang) ? "wider_btn" : ""

    const classes = isSmallScreen
      ? [s.ButtonsWrapper, s.ButtonsWrapperMedium, s[widerBtn]]
      : [s.ButtonsWrapper, s[widerBtn]]
    const { r, g, b } = hexToRGB_obj(productColor)

    const lightenConstant = 0.85
    const hoverBgColor = `rgb(${r + (255 - r) * lightenConstant}, ${
      g + (255 - g) * lightenConstant
    }, ${b + (255 - b) * lightenConstant})`
    return (
      <SkewedWrapper wrapperClassName={classes.join(" ")}>
        <Button
          onClick={onToIngredients}
          type="default"
          style={{ color: productColor }}
          hoverColor={hoverBgColor}
          textColor={{ color: productColor }}
          // hoverColor={productColor}
          sanitized
        >
          {button_one_text}
        </Button>
        <Button
          onClick={onToNutritions}
          type="default"
          style={{ color: productColor }}
          hoverColor={hoverBgColor}
          textColor={{ color: productColor }}
          // hoverColor={productColor}
          sanitized
        >
          {button_two_text}
        </Button>
      </SkewedWrapper>
    )
  }
  render() {
    const { isNavigating, sideNavDirection } = this.state
    const {
      allProducts,
      currentProductId,
      productColor,
      bottleSlideAnimate,
      bottleSlideDirection,
      fadeInAnimateExceptMainBottle,
    } = this.props
    const {
      title_one_text,
      title_two_text,
      description_one_text,
      description_two_text,
      button_one_text,
      button_two_text,
      list,
      meta_description
    } = this.props.data || {}

    let bottleClasses = [s.Bottle]
    let contentWrapperClasses = [s.ContentWrapper]
    let titleWrapperClasses = [s.TitleWrapper]
    let TopTitleClasses = [s.TitleRed]
    let BottomTitleClasses = [s.TitleWhite]
    if (isNavigating) {
      contentWrapperClasses.push(s.ContentFadeOut)
      if (sideNavDirection === "left") {
        // TopTitleClasses.push(s.TitleRedFadeLeft)
        // BottomTitleClasses.push(s.TitleWhiteFadeLeft)
        titleWrapperClasses.push(s.TitleWrapperFadeRight)
        bottleClasses.push(s.BottleFadeRight)
      } else if (sideNavDirection === "right") {
        titleWrapperClasses.push(s.TitleWrapperFadeLeft)
        bottleClasses.push(s.BottleFadeLeft)
        // TopTitleClasses.push(s.TitleRedFadeRight)
        // BottomTitleClasses.push(s.TitleWhiteFadeRight)
      }
    } else if (bottleSlideAnimate) {
      contentWrapperClasses.push(s.ContentFadeIn)
      if (bottleSlideDirection === "left") {
        // TopTitleClasses.push(s.TitleRedEnterFromRight)
        // BottomTitleClasses.push(s.TitleWhiteEnterFromRight)
        titleWrapperClasses.push(s.TitleWrapperEnterFromLeft)
        bottleClasses.push(s.BottleEnterFromLeft)
      } else if (bottleSlideDirection === "right") {
        titleWrapperClasses.push(s.TitleWrapperEnterFromRight)
        bottleClasses.push(s.BottleEnterFromRight)
        // TopTitleClasses.push(s.TitleRedEnterFromLeft)
        // BottomTitleClasses.push(s.TitleWhiteEnterFromLeft)
      }
    } else if (fadeInAnimateExceptMainBottle) {
      contentWrapperClasses.push(s.FromAssortmentFadeIn)
    }

    return (
      <section className={s.ProductOverview} ref={this.props.refFromParent}>
        <Helmet>
          <meta property="og:description" content={meta_description || description_one_text} />
        </Helmet>
        <div className={s.BottleWrapper} ref={this.props.bottleRef}>
          <div className={bottleClasses.join(" ")}>
            {/* <ImgFluid fileName={this.props.imageName} /> */}
            <BottleWithDroplets
              width="100%"
              height="100%"
              imageName={this.props.imageName}
              alt={title_one_text}
              // recalcMaskSignal={this.state.recalcMaskSignal}
              itemProp="image"
              dontFadeInImage
            />
          </div>
          {this.renderBottleNavIcons({
            isSmallScreen: true,
            currentProductId,
            allProducts,
          })}
        </div>
        {this.renderBottleNavIcons({ currentProductId, allProducts })}
        <div className={[s.RedBgWrapper].join(" ")}>
          <div className={s.WhiteBg}></div>
          <div
            className={[
              s.RedBg,
              fadeInAnimateExceptMainBottle ? s.FromAssortmentFadeIn : "",
            ].join(" ")}
            // style={{ background: productColor }}
            style={{
              backgroundColor: "transparent",
              // background:
              //   section_bg_image && section_bg_image !== "#"
              //     ? `url(${section_bg_image})`
              //     : null,
            }}
          >
            <div
              className={titleWrapperClasses.join(" ")}
              style={{
                fontSize: this.state.customTitleSize,
              }}
            >
              <p
                className={TopTitleClasses.join(" ")}
                style={{
                  height: this.state.customTitleSize,
                  color: productColor,
                }}
                itemProp="name"
                dangerouslySetInnerHTML={{ __html: title_one_text }}
              />
              <p
                className={BottomTitleClasses.join(" ")}
                style={{
                  background: "none",
                }}
                itemProp="slogan"
                dangerouslySetInnerHTML={{ __html: title_two_text }}
              />
            </div>
          </div>
        </div>
        <div className={contentWrapperClasses.join(" ")}>
          <div
            className={[s.TitleWrapper, s.TitleWrapperMedium].join(" ")}
            style={{
              fontSize: this.state.customTitleSize,
            }}
          >
            <h1
              className={TopTitleClasses.join(" ")}
              style={{
                height: this.state.customTitleSize,
                color: productColor,
              }}
              dangerouslySetInnerHTML={{ __html: title_one_text }}
            />
            <h2
              className={BottomTitleClasses.join(" ")}
              style={{
                background: "none",
              }}
              dangerouslySetInnerHTML={{ __html: title_two_text }}
            />
          </div>
          <p
            className={s.ContentDescription}
            dangerouslySetInnerHTML={{
              __html: description_one_text,
            }}
            itemProp="description"
          />
          <ul className={s.ContentItems}>
            {list.map((L, i) => {
              const imageName = extractImageNameFromUrl(L.icon)
              return (
                <li key={L.text}>
                  <span className={s.ListIcon} ref={this.iconsList[i]}>
                    <ImgFluid fileName={imageName} />
                  </span>
                  {L.text}
                </li>
              )
            })}
          </ul>
          <p
            className={s.ContentSubDescription}
            dangerouslySetInnerHTML={{
              __html: description_two_text,
            }}
          ></p>
          {this.renderBottleButtons({
            button_one_text,
            button_two_text,
            productColor,
          })}
        </div>
        {this.renderBottleButtons({
          isSmallScreen: true,
          button_one_text,
          button_two_text,
          productColor,
        })}
      </section>
    )
  }
}

export default ProductOverview
