import React, { useReducer, createContext } from "react"
import { getCookie } from "../helper"

const HikingAuthContext = createContext()

HikingAuthContext.displayName = "HikingAuthContext"

const initialState = {
  isLoggedIn: Boolean(getCookie("user_hash")),
  checkedLogin: false,
  codeStatus: {
    type: "",
    shouldRegister: undefined,
    showMessageBox: false,
    submitted: false,
  },
}

const actionTypes = {
  LOGIN: "HikingAuthContext.LOGIN",
  LOGOUT: "HikingAuthContext.LOGOUT",
  CHECK_LOGIN: "HikingAuthContext.CHECK_LOGIN",
  RESET_CODE_FORM: "HikingAuthContext.RESET_CODE_FORM",
  SET_CODE_COUNT_TRIES: "HikingAuthContext.SET_CODE_COUNT_TRIES",
  SET_CODE_STATUS: "HikingAuthContext.SET_CODE_STATUS",
  SET_CODE_VALUE: "HikingAuthContext.SET_CODE_VALUE",
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case actionTypes.LOGIN:
      return {
        ...state,
        isLoggedIn: true,
      }
    case actionTypes.LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
      }
    case actionTypes.CHECK_LOGIN:
      return {
        ...state,
        isLoggedIn: !!getCookie("user_hash"),
        checkedLogin: true,
      }
    case actionTypes.RESET_CODE_FORM:
      return {
        ...state,
        shouldResetCodeForm: payload.reset,
        codeStatus: {
          ...state.codeStatus,
          showMessageBox: false,
          shouldRegister: false,
        },
      }
    case actionTypes.SET_CODE_COUNT_TRIES:
      return {
        ...state,
        totalCodeCount: payload.count,
      }
    case actionTypes.SET_CODE_STATUS:
      return {
        ...state,
        codeStatus: payload,
      }
    case actionTypes.SET_CODE_VALUE:
      return {
        ...state,
        codeValue: payload,
      }
    default:
      return state
  }
}

const login = () => ({
  type: actionTypes.LOGIN,
})

const logout = () => ({
  type: actionTypes.LOGOUT,
})

const checkLogin = () => ({
  type: actionTypes.CHECK_LOGIN,
})

const resetCodeForm = (shouldResetForm) => ({
  type: actionTypes.RESET_CODE_FORM,
  payload: { reset: shouldResetForm },
})

const setTotalCodeTries = (codeTriesCount) => ({
  type: actionTypes.SET_CODE_COUNT_TRIES,
  payload: codeTriesCount,
})

const setCodeStatus = (codeStatus) => ({
  type: actionTypes.SET_CODE_STATUS,
  payload: codeStatus,
})

const setCodeValue = (codeValue) => ({
  type: actionTypes.SET_CODE_VALUE,
  payload: codeValue,
})

const actions = ({ dispatch }) => ({
  login: () => {
    return dispatch(login())
  },
  logout: () => {
    return dispatch(logout())
  },
  checkLogin: () => {
    return dispatch(checkLogin())
  },
  resetCodeForm: (shouldResetForm = false) => {
    return dispatch(resetCodeForm(shouldResetForm))
  },
  setTotalCodeTries: (codeTriesCount) => {
    return dispatch(setTotalCodeTries(codeTriesCount))
  },
  setCodeStatus: (codeStatus) => {
    return dispatch(setCodeStatus(codeStatus))
  },
  setCodeValue: (codeValue) => {
    return dispatch(setCodeValue(codeValue))
  },
})

export const HikingAuthConsumer = HikingAuthContext.Consumer

export const HikingAuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <HikingAuthContext.Provider
      value={{
        state,
        dispatch,
        actions: actions({
          dispatch,
          getState: () => state,
        }),
      }}
    >
      {children}
    </HikingAuthContext.Provider>
  )
}
