/* eslint-disable no-useless-escape */
import React, { Fragment } from "react"
import OnlySender from "./components/OnlySender/OnlySender"
import SenderReceiver from "./components/SenderReceiver/SenderReceiver"
import { getCurrentLanguage } from "../../../../helper"

import { submitRefreshV3Form } from "../../api"

import s from "./Form.module.scss"

const Form = (props) => {
  const [type, setType] = React.useState("self") // or "friend"
  const [submitted, setSubmitted] = React.useState(false)
  const [validation, setValidation] = React.useState({
    from_title: undefined,
    from_salutation: undefined,
    from_last_name: undefined,
    from_first_name: undefined,
    from_email: undefined,
    //
    to_title: undefined,
    to_salutation: undefined,
    to_last_name: undefined,
    to_first_name: undefined,
    //
    street: undefined,
    number: undefined,
    zip: undefined,
    city: undefined,
    //
    newsletter: undefined,
    accepted: undefined,
  })
  const [body, setBody] = React.useState({
    from_title: "",
    from_salutation: "",
    from_last_name: "",
    from_first_name: "",
    from_email: props.email,
    //
    to_title: "",
    to_salutation: "",
    to_last_name: "",
    to_first_name: "",
    //
    street: "",
    number: "",
    zip: "",
    city: "",
    //
    newsletter: "",
    accepted: "",
  })
  const [formError, setFormError] = React.useState(undefined)

  const isFormValid = (vd) => {
    return Object.values(vd ? vd : validation).every((val) => val === true)
  }

  const isFieldInvalid = (field) => {
    return (
      submitted &&
      typeof validation[field] === "boolean" &&
      (validation[field] === false || validation[field] === "0")
    )
  }

  const validateForm = () => {
    const mailRe =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const numRe = /^\d+$/

    const nextValidation = { ...validation }

    // from
    nextValidation.from_title = true
    nextValidation.from_salutation = body.from_salutation.length > 1
    nextValidation.from_last_name = body.from_last_name.length > 1
    nextValidation.from_first_name = body.from_first_name.length > 1
    nextValidation.from_email = mailRe.test(body.from_email)

    // to
    if (type === "friend") {
      nextValidation.to_title = true
      nextValidation.to_salutation = body.to_salutation.length > 1
      nextValidation.to_last_name = body.to_last_name.length > 1
      nextValidation.to_first_name = body.to_first_name.length > 1
    } else {
      nextValidation.to_title = true
      nextValidation.to_salutation = true
      nextValidation.to_last_name = true
      nextValidation.to_first_name = true
    }

    // addr
    nextValidation.street = body.street.length > 5
    nextValidation.number = numRe.test(body.number)
    nextValidation.zip = numRe.test(body.zip) && `${body.zip}`.length === 4
    nextValidation.city = body.city.length > 2

    // checkboxes
    nextValidation.newsletter = true
    nextValidation.accepted = body.accepted === "1"

    setValidation(nextValidation)
    return nextValidation
  }

  React.useEffect(() => {
    validateForm()
  }, [body])

  const submitForm = async () => {
    const current_validation = validateForm()

    setSubmitted(true)

    if (!isFormValid(current_validation)) {
      return
    }

    const { lang } = getCurrentLanguage(props.location)

    try {
      const response = await submitRefreshV3Form({
        ...body,
        type,
        language: lang,
      })
      if (response.result === "success") {
        props.showSuccess()
      } else {
        setFormError(response.message)
      }
    } catch (err) {
      setFormError(err ? err.message : "Error")
    }
  }

  return (
    <Fragment>
      <section className={s.section}>
        <div className={s.congratulations_title}>
          <h2>{props.congratulationsTitle}</h2>
        </div>

        <div className={s.fill_address_prompt}>
          <p>{props.fillAddressPrompt}</p>
        </div>

        <div className={s.wrapper_bottom}>
          <h4>{props.packageReceiverTitle}</h4>

          <div className={s.check_boxes}>
            <div className={s.check_box_left}>
              <label>
                <input
                  type="checkbox"
                  name="to_myself"
                  className={s.check_box_left_input}
                  checked={type === "self"}
                  onChange={({ target: { checked } }) =>
                    checked ? setType("self") : undefined
                  }
                />
                <span className={s.check_box_left_input_desktop}>
                  {props.senderReceiverTitleDesktop}
                </span>
                <span className={s.check_box_left_input_mobile}>
                  {props.senderReceiverTitleMobile}
                </span>
              </label>
            </div>

            <div className={s.check_box_right}>
              <label>
                <input
                  type="checkbox"
                  name="to_friend"
                  className={s.check_box_right_input}
                  checked={type === "friend"}
                  onChange={({ target: { checked } }) =>
                    checked ? setType("friend") : undefined
                  }
                />
                {props.receiverTitle}
              </label>
            </div>
          </div>
        </div>
      </section>

      <section className={s.form_section}>
        <form>
          <div className={s.form_grid}>
            {type === "self" && (
              <SenderReceiver
                {...props}
                type="self"
                body={body}
                setBody={setBody}
                isFieldInvalid={isFieldInvalid}
              />
            )}
            {type === "friend" && (
              <>
                <OnlySender
                  {...props}
                  body={body}
                  setBody={setBody}
                  isFieldInvalid={isFieldInvalid}
                />
                <SenderReceiver
                  {...props}
                  isFieldInvalid={isFieldInvalid}
                  body={body}
                  setBody={setBody}
                  type="friend"
                />
              </>
            )}
          </div>

          {/* Checkboxes and submit */}
          <div className={s.form_check_box}>
            <div className={isFieldInvalid("newsletter") ? s.field_error : ""}>
              <label>
                <input
                  type="checkbox"
                  className={s.newsletter_check_box}
                  checked={body.newsletter === "1" ? true : false}
                  onChange={({ target: { checked } }) =>
                    setBody((p) => ({ ...p, newsletter: checked ? "1" : "0" }))
                  }
                />
                {props.formFieldsInfo.checkboxes.newsletter.placeholder}
              </label>
            </div>

            <div className={isFieldInvalid("accepted") ? s.field_error : ""}>
              <label>
                <input
                  type="checkbox"
                  className={s.terms_check_box}
                  checked={body.accepted === "1" ? true : false}
                  onChange={({ target: { checked } }) => {
                    setBody((p) => ({
                      ...p,
                      accepted: checked ? "1" : "0",
                    }))
                  }}
                />
                {props.formFieldsInfo.checkboxes.termsAndContidition.placeholder(
                  "https://rivella.ch/"
                )}
              </label>
            </div>
          </div>

          <div className={s.form_error_wrapper}>{formError}</div>

          <div className={s.submit_button_div}>
            <button
              className={s.submit_button}
              type="button"
              onClick={submitForm}
            >
              {props.formFieldsInfo.submit.placeholder}
            </button>
          </div>
        </form>
      </section>
    </Fragment>
  )
}

export default Form
