import React from "react"
import s from "./RegistrationForm.module.scss"
import { PromotionSectionWrapper } from ".."
import { WinHikeForm } from "../../../components"
import { fetchHikingData } from "../../../helper"

const errorMessage = "An error occured while verifying your code"

class RegistrationForm extends React.Component {
  state = {
    loading: false,
  }

  componentDidMount() {
    const { prefillUserData, userHash } = this.props
    if (prefillUserData && userHash) {
      this.prefillUserInfo(userHash)
    }
  }

  prefillUserInfo = async (userHash) => {
    this.setState({ loading: true })

    const response = await fetchHikingData("prefill", {
      method: "POST",
      body: JSON.stringify({ user_hash: userHash }),
    })

    const { data, result } = response

    if (result === "error") {
      this.setState({ error: true, loading: false })
    } else {
      this.setState({
        profileDetails: data,
        error: false,
        loading: false,
      })
    }
  }

  render() {
    const { profileDetails, error, loading } = this.state
    const {
      prefillUserData,
      formMessage,
      lang,
      email,
      promoCode,
      onRegisterCallback,
      onRevertedWinCallback,
      displayLoginForm,
      registerFormFields,
    } = this.props

    const uniqueFormProps = {
      lang,
      onRegisterCallback,
      onRevertedWinCallback,
      displayLoginForm,
      registerFormFields,
    }

    return (
      <>
        {formMessage && <h4 className={s.form_message}>{formMessage}</h4>}
        <PromotionSectionWrapper className={s.registration_form_container}>
          {!loading && error && <h4>{errorMessage}</h4>}
          {!loading && prefillUserData && (
            <WinHikeForm profileDetails={profileDetails} {...uniqueFormProps} />
          )}
          {!loading && !prefillUserData && (
            <WinHikeForm
              enteredEmail={email}
              promoCode={promoCode}
              {...uniqueFormProps}
            />
          )}
        </PromotionSectionWrapper>
      </>
    )
  }
}

export default RegistrationForm
