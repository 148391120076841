import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { Link, navigate } from "gatsby"

import { BottleWithDroplets, ImgFluid, SkewedButton } from "../../components"
import s from "./AssortmentProductsList.module.scss"

import { learn_more } from "../../constants"
import {
  extractImageNameFromUrl,
  getCurrentLanguage,
  calculateProductLinkLangPrefix,
  getPosition,
} from "../../helper"

const NAVIGATION_START_LENGTH = 700
const NAVIGATE_FINAL_DELAY = 200

const TOTAL_NAVIGATION_LENGTH = NAVIGATION_START_LENGTH + NAVIGATE_FINAL_DELAY

class AssortmentProductsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      renderVideo: false,
      renderMobile: false,
      isNavigating: false,
      navigatingBottleData: null,
      isContentFadingOut: false,
    }
    this.bottlesRefs = []
    for (let i = 0; i < this.props.allProducts.length; i++) {
      this.bottlesRefs.push(React.createRef())
    }
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      const renderVideo = true
      // const renderVideo = window.screen.width > 800
      const renderMobile = window.screen.width < 500
      this.setState({
        renderVideo,
        renderMobile,
      })
    }
  }

  componentWillUnmount() {
    clearTimeout(this.navigating)
  }
  renderBottle = (bottle, index) => {
    const currentLang = getCurrentLanguage(this.props.location)
    const { isContentFadingOut, navigatingBottleData } = this.state
    let {
      fileName,
      title_one_text,
      title_two_text,
      product_primary_color,
      product_secondary_color,
    } = bottle
    const imageUrl =
      (bottle.childrenData &&
        (bottle.childrenData[0].props.image ||
          bottle.childrenData[1].props.image ||
          bottle.childrenData[2].props.image)) ||
      {}
    const imageName = extractImageNameFromUrl(imageUrl)
    const titleDataHoldingBlock =
      bottle.childrenData &&
      bottle.childrenData.find((c) => c.type === "rivella/product-overview")
    if (titleDataHoldingBlock) {
      title_one_text = titleDataHoldingBlock.props.title_one_text
      title_two_text = titleDataHoldingBlock.props.title_two_text
      product_primary_color = titleDataHoldingBlock.props.product_primary_color
      product_secondary_color =
        titleDataHoldingBlock.props.product_secondary_color
    }

    const bottle_link = calculateProductLinkLangPrefix(
      bottle.path,
      currentLang.slug
    )

    if (this.state.renderMobile) {
      return (
        <div className={s.SliderSpacer} key={title_one_text}>
          <div className={s.SliderItemWrapper}>
            <div className={s.Product} style={{ position: "relative" }}>
              <div className={s.Bottle}>
                <Link to={bottle_link} className={s.Link}>
                  {this.state.renderVideo ? (
                    <BottleWithDroplets
                      width="100%"
                      height="100%"
                      imageName={imageName ? imageName : fileName}
                    />
                  ) : (
                    <ImgFluid fileName={imageName} alt={imageName} />
                  )}
                </Link>
              </div>
              <div className={s.ProductDetailsWrapper}>
                <h2
                  style={{ color: product_primary_color }}
                  className={s.ProductName}
                  dangerouslySetInnerHTML={{ __html: title_one_text }}
                />
                <h3
                  style={{ color: product_secondary_color }}
                  className={s.ProductSlang}
                  dangerouslySetInnerHTML={{ __html: title_two_text }}
                />
                {/* <Link to={bottle_link} className={s.Link}>
                  <SkewedButton
                    type="primary"
                    wrapperClassName={s.SeeProductButton}
                    btnClassName={s.ProductDetailsButton}
                  >
                    {learn_more[currentLang.langHref]}
                  </SkewedButton>
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className={s.SliderSpacer} key={title_one_text}>
          <div className={s.SliderItemWrapper}>
            <Link
              to={bottle_link}
              className={s.Link}
              onClick={(e) =>
                this.onNavigate(e, {
                  bottleImage: imageName,
                  bottle: this.bottlesRefs[index].current,
                  bottleLink: bottle_link,
                  bottleIndex: index,
                })
              }
            >
              <div
                className={s.Product}
                style={{
                  opacity:
                    isContentFadingOut &&
                    navigatingBottleData.bottleIndex === index
                      ? 0
                      : null,
                }}
              >
                <div className={s.Bottle} ref={this.bottlesRefs[index]}>
                  {this.state.renderVideo ? (
                    <BottleWithDroplets
                      width="100%"
                      height="100%"
                      imageName={imageName ? imageName : fileName}
                      alt={title_one_text}
                    />
                  ) : (
                    <ImgFluid fileName={imageName} alt={title_one_text} />
                  )}
                </div>
                <h2
                  style={{ color: product_primary_color }}
                  className={s.ProductName}
                  dangerouslySetInnerHTML={{ __html: title_one_text }}
                />
                <h3
                  // style={{ color: product_secondary_color }}
                  style={{ color: product_primary_color }}
                  className={s.ProductSlang}
                  dangerouslySetInnerHTML={{ __html: title_two_text }}
                />
                <SkewedButton
                  type="primary"
                  wrapperClassName={s.SeeProductButton}
                  btnClassName={s.ProductDetailsButton}
                >
                  {learn_more[currentLang.langHref]}
                </SkewedButton>
              </div>
            </Link>
          </div>
        </div>
      )
    }
  }

  calcNavigationStartLength = (bottleIndex, rawLength) => {
    const res =
      bottleIndex < 2 ? rawLength : rawLength * Math.log2(bottleIndex + 1)
    return res
  }

  onNavigate = (e, { bottleImage, bottleLink, bottle, bottleIndex }) => {
    if (
      typeof window !== "undefined" &&
      window.innerWidth > 1200 &&
      !this.state.isNavigating
    ) {
      e.preventDefault()

      const { x, y } = getPosition(bottle)
      // note from pierre on 2022-04-07
      // getPosition is not working as "bottle" is not the one we are clicking on but a copy used by slick slider, which is displayed further right than the one we are clicking on
      // this is why the transition bottle is coming from the right
      const dynamicNavigationLength = this.calcNavigationStartLength(
        bottleIndex,
        NAVIGATION_START_LENGTH
      )
      try {
        this.setState({
          isNavigating: true,
          navigatingBottleData: {
            bottleLink,
            bottleImage,
            bottleIndex,
            pos: {
              x,
              y,
            },
            visibility: "hidden",
            size: {
              w: bottle.clientWidth,
              h: bottle.clientHeight,
            },
            transitionBeginningLength: dynamicNavigationLength,
          },
        })
      } catch (_) {
        navigate(bottleLink)
      }
    }
  }

  finalizeNavigate = (count = 0) => {
    const { navigatingBottleData } = this.state
    if (navigatingBottleData) {
      const { bottleLink, bottleIndex } = navigatingBottleData
      const dynamicNavigationLength = this.calcNavigationStartLength(
        bottleIndex,
        NAVIGATION_START_LENGTH
      )
      const { w, h } = this.state.navigatingBottleData.size
      const newWidth = 180
      const newHeight = (newWidth / w) * h
      this.setState({
        isContentFadingOut: true,
        navigatingBottleData: {
          ...this.state.navigatingBottleData,
          bottleIndex,
          pos: {
            transform: `translate(-50%, -50%)`,
            x: "16%",
            y: 550,
          },
          visibility: "visible",
          size: {
            w: newWidth,
            y: newHeight,
          },
          padding: 0,
        },
      })
      this.navigating = setTimeout(() => {
        navigate(bottleLink, {
          state: {
            isComingFromSortiment: true,
            initialScrollTop: window.scrollY,
          },
        })
      }, dynamicNavigationLength + NAVIGATE_FINAL_DELAY)
    } else {
      if (count < 50) {
        setTimeout(() => {
          this.finalizeNavigate(++count)
        }, 20)
      }
    }
  }

  render() {
    const dataToRender = this.props.allProducts
    const { isNavigating, navigatingBottleData, isContentFadingOut } =
      this.state
    return (
      <section
        className={s.AssortmentProductList}
        style={{ zIndex: this.props.sectionZindex }}
      >
        <div
          className={s.BannerWrapper}
          style={{
            opacity: isContentFadingOut ? 0 : null,
            transitionDuration: TOTAL_NAVIGATION_LENGTH + "ms",
          }}
        >
          <div className={s.RedBanner}>
            <h1 className={s.PageTitle}>{this.props.title}</h1>
            <h2 className={s.PageSubtitle}>{this.props.subtitle}</h2>
          </div>
        </div>
        {isNavigating ? (
          <div
            className={[s.NavigatingBottle, s.Bottle].join(" ")}
            style={{
              left: navigatingBottleData.pos.x,
              top: navigatingBottleData.pos.y,
              transform: navigatingBottleData.pos.transform,
              width: navigatingBottleData.size.w,
              height: navigatingBottleData.size.h,
              transitionDuration: `${navigatingBottleData.transitionBeginningLength}ms`,
              padding: navigatingBottleData.padding,
              visibility: navigatingBottleData.visibility,
            }}
          >
            <BottleWithDroplets
              width="100%"
              height="100%"
              imageName={navigatingBottleData.bottleImage}
              style={{ height: "100%" }}
              dontFadeInImage
              hideVideo
              onImageLoad={this.finalizeNavigate}
            />
          </div>
        ) : null}
        <div
          className={s.ProductsList}
          style={{
            opacity: isContentFadingOut ? 0 : null,
            transitionDuration: TOTAL_NAVIGATION_LENGTH + "ms",
          }}
        >
          <Slider
            dots
            infinite
            speed={500}
            slidesToScroll={1}
            cssEase="ease-out"
            slidesToShow={dataToRender.length > 5 ? 5 : dataToRender.length}
            dotsClass={["slick-dots", s.Dots].join(" ")}
            responsive={[
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow:
                    dataToRender.length > 4 ? 4 : dataToRender.length,
                },
              },
              {
                breakpoint: 800,
                settings: {
                  slidesToShow:
                    dataToRender.length > 3 ? 3 : dataToRender.length,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow:
                    dataToRender.length > 2 ? 2 : dataToRender.length,
                },
              },
              {
                breakpoint: 500,
                settings: {
                  slidesToShow:
                    dataToRender.length > 1 ? 1 : dataToRender.length,
                  centerMode: true,
                  focusOnSelect: true,
                },
              },
            ]}
          >
            {dataToRender
              .sort((a, b) => {
                let return_val = 0
                try {
                  const a_obj = a.childrenData.find(
                    (d) => d.type === "rivella/product-overview"
                  )
                  const b_obj = b.childrenData.find(
                    (d) => d.type === "rivella/product-overview"
                  )
                  const a_val = +a_obj.props.product_order_weight
                  const b_val = +b_obj.props.product_order_weight
                  if (a_val < b_val) return_val = 1
                  else if (a_val > b_val) return_val = -1
                  else return_val = 0
                } catch (err) {
                  return_val = 0
                }
                return return_val
              })
              .map((b, i) => this.renderBottle(b, i))}
          </Slider>
        </div>
      </section>
    )
  }
}

export default AssortmentProductsList
