import React from "react"
import s from "./SkewedSectionWithBg.module.scss"
import { ImgFluid } from "../../components"
import { getPosition } from "../../helper"
import bowser from "bowser"
import KeyVisual from "../KeyVisual/KeyVisual"

class SkewedSectionWithBg extends React.Component {
  constructor(props) {
    super(props)
    this.sectionRef = React.createRef()
    this.parallaxRef = React.createRef()
    // this.safariImageScrollLength = undefined
    this.state = {
      borderWidth: undefined,
      borderHeight: undefined,
      isSafari: false,
    }
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      this.calcBorderSizes()
      this.calcParallaxBgSize()
      // after ui load correction happens do it again
      setTimeout(() => {
        this.calcParallaxBgSize()
        setTimeout(() => {
          this.calcParallaxBgSize()
        }, 500)
      }, 500)
      if (!this.props.cutWithClipPath) {
        window.addEventListener("resize", this.calcBorderSizes)
      }
      window.addEventListener("resize", this.calcParallaxBgSize)
    }
    // legacy check
    // const isParallax = this.props.type === "parallax"
    this.checkIsMacSafariDesktop(true)
    // if (typeof window !== "undefined" && isSafari && isParallax) {
    //   window.addEventListener("scroll", this.onMacSafariScroll)
    // }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      if (!this.props.cutWithClipPath) {
        window.removeEventListener("resize", this.calcBorderSizes)
      }
      window.removeEventListener("resize", this.calcParallaxBgSize)
    }
    // const isParallax = this.props.type === "parallax"
    // const isSafari = this.checkIsMacSafariDesktop()
    // if (typeof window !== "undefined" && isSafari && isParallax) {
    //   window.removeEventListener("scroll", this.onMacSafariScroll)
    // }
  }

  calcParallaxBgSize = () => {
    const { innerWidth } = window
    const el = this.sectionRef.current
    const parallax = this.parallaxRef.current
    if (el && parallax) {
      if (innerWidth > 1150) {
        parallax.style.backgroundSize = null
      } else {
        parallax.style.backgroundSize =
          Number.parseInt(el.clientHeight * 2.1) + "px"
      }
    }
  }

  checkIsMacSafariDesktop = (shouldSetState) => {
    if (typeof window === "undefined") return
    const browser = bowser.getParser(window.navigator.userAgent)
    const isSafari = browser.satisfies({
      // macos: {
      //   safari: ">=9",
      // },
      ios: {
        chrome: ">22",
      },
      mobile: {
        safari: ">=5",
      },
    })
    const isIpadSafari =
      browser.satisfies({
        safari: ">=5",
      }) &&
      window.innerWidth < 1050 &&
      window.innerWidth > 600

    if (shouldSetState) {
      this.setState({
        isSafari: !!isSafari || !!isIpadSafari,
      })
    }
    return !!isSafari || !!isIpadSafari
  }

  onMacSafariScroll = () => {
    const wrapper = this.sectionRef.current
    const ele = this.parallaxRef.current
    const { y } = getPosition(wrapper)
    const { pageYOffset } = window
    const newScrollTop = pageYOffset - y
    // this.safariImageScrollLength = newScrollTop
    if (ele) {
      ele.style.backgroundPosition = `50% calc(50% + ${newScrollTop}px)`
    }
  }

  calcBorderSizes = () => {
    const borderHeight =
      this.sectionRef &&
      this.sectionRef.current &&
      this.sectionRef.current.clientWidth
        ? this.sectionRef.current.clientWidth * 0.17
        : 10
    const borderWidth =
      this.sectionRef &&
      this.sectionRef.current &&
      this.sectionRef.current.clientWidth
        ? this.sectionRef.current.clientWidth
        : 10
    this.setState({
      borderWidth,
      borderHeight,
    })
  }

  render() {
    const classNames = [s.SkewedSectionWithBg]
    const parallaxClassNames = [s.ImageParallax]
    const topWidthObj = this.state.borderWidth
      ? {
          borderRightWidth: this.state.borderWidth,
          borderTopWidth: this.state.borderHeight,
        }
      : {}
    const bottomWidthObj = this.state.borderWidth
      ? {
          borderLeftWidth: this.state.borderWidth,
          borderBottomWidth: this.state.borderHeight,
        }
      : {}
    const {
      bgImg,
      children,
      wrapperClassName,
      imageWrapperClass = "",
      type,
      cutWithClipPath,
      parallaxClassName,
      keyVisual,
    } = this.props

    const isParallax = type === "parallax"

    let clipPathClassName = cutWithClipPath ? s.CutWithClipPath : ""

    if (wrapperClassName) classNames.push(wrapperClassName)

    if (this.state.isSafari) parallaxClassNames.push(s.ParallaxSafari)
    if (parallaxClassName) parallaxClassNames.push(parallaxClassName)
    return (
      <section ref={this.sectionRef} className={classNames.join(" ")}>
        <div
          className={`${s.ImageWrapper} ${imageWrapperClass} ${clipPathClassName}`}
        >
          <div className={[s.Image].join(" ")}>
            {!cutWithClipPath ? (
              <div
                className={[s.ImageSkew, s.ImageTopLeft].join(" ")}
                style={{ ...topWidthObj, zIndex: isParallax ? 0 : null }}
              />
            ) : null}
            {!isParallax ? (
              <ImgFluid fileName={bgImg} />
            ) : (
              <div
                className={parallaxClassNames.join(" ")}
                style={{
                  backgroundImage: `url(${bgImg})`,
                }}
                ref={this.parallaxRef}
              >
                {keyVisual ? <KeyVisual className={s.KeyVisual} /> : null}
              </div>
            )}
            {!cutWithClipPath ? (
              <div
                className={[s.ImageSkew, s.ImageBottomRight].join(" ")}
                style={{ ...bottomWidthObj, zIndex: isParallax ? 0 : null }}
              />
            ) : null}
          </div>
        </div>
        {children}
      </section>
    )
  }
}

export default SkewedSectionWithBg
