/* eslint-disable no-useless-escape */
import React from "react"
import Helmet from "react-helmet"
import s from "./SpinningWheelSection.module.scss"
import { submitRefreshV3Email, getParameterByName } from "../../api"
import { getCurrentLanguage } from "../../../../helper"

const SpinningWheelSection = (props) => {
  const [isLoadedScript, setIsLoadedScript] = React.useState(false)
  const wheelInitiated = React.useRef(false)
  const wheelRef = React.useRef(null)
  const [buttonState, setButtonState] = React.useState("disabled")
  const [email, setEmail] = React.useState("")
  const [wheelActive, setWheelActive] = React.useState(false)
  const [stopAngle, setStopAngle] = React.useState(0)
  const [spinned, setSpinned] = React.useState(false)
  // eslint-disable-next-line no-unused-vars
  const [winning, setWinning] = React.useState(false)

  // Initialize show form callback
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.showPrizeForm = () => {
        props.showForm(email)
      }
      window.showNotWin = () => {
        props.showNotWin(false)
      }
    }
  }, [email])

  // Check script loaded
  React.useEffect(() => {
    let interval

    if (isLoadedScript === false) {
      interval = setInterval(() => {
        if (
          typeof window !== "undefined" &&
          window.wheelLoaded === true &&
          window.gsapLoaded === true
        ) {
          setIsLoadedScript(true)
          wheelInitiated.current = true
        }
      }, 300)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [isLoadedScript])

  // Initialize Wheel
  React.useEffect(() => {
    if (isLoadedScript && typeof window !== "undefined") {
      const WinWheel = window.Winwheel
      wheelRef.current = new WinWheel({
        responsive: true,
        drawMode: "image",
        animation: {
          type: "spinOngoing",
          duration: 60,
          spins: 1,
        },
      })

      let loadedImg = new Image()

      loadedImg.onload = function () {
        wheelRef.current.wheelImage = loadedImg // Make wheelImage equal the loaded image object.
        wheelRef.current.draw() // Also call draw function to render the wheel.
      }

      loadedImg.src = props.wheelImage

      wheelRef.current.startAnimation()
    }
  }, [isLoadedScript])

  // Check email status & button toggle
  React.useEffect(() => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (re.test(email)) {
      setButtonState("active")
    } else {
      setButtonState("disabled")
    }
  }, [email])

  // Start spinning the wheel and stop at angle
  const turnWheel = (angle, win) => {
    if (!spinned) {
      wheelRef.current.stopAnimation()
      wheelRef.current.animation = {
        type: "spinToStop",
        duration: 5,
        easing: "Power4.easeOut",
        spins: 5,
        stopAngle: 360 - (angle ?? stopAngle),
        callbackFinished: win
          ? "window.showPrizeForm()"
          : "window.showNotWin()",
      }
      wheelRef.current.startAnimation()
      setSpinned(true)
    }
  }

  // Submit and check result
  const submitEmail = async () => {
    setButtonState("disabled")

    const { lang } = getCurrentLanguage(props.location)

    const response = await submitRefreshV3Email(
      email,
      lang,
      getParameterByName("src", props.location.href) === "migros_inserat"
    )
    if (response && response.result === "win") {
      setWinning(true)
      setWheelActive(true)
      setStopAngle(response.position ? response.position : 30)
      turnWheel(response.position ?? 30, true)
    } else if (response && response.result === "not_win") {
      setWinning(false)
      setStopAngle(response.position ? response.position : 55)
      setWheelActive(true)
      if (typeof response.position !== "undefined") {
        turnWheel(response.position ?? 55, false)
      } else {
        props.showNotWin(true)
      }
    } else {
      props.showError(response.message)
      console.warn("error", response)
    }
  }

  return (
    <>
      <Helmet>
        <script onLoad={"window.gsapLoaded = true;"} src="/TweenMax.min.js" />
        <script onLoad={"window.wheelLoaded = true;"} src="/Winwheel.min.js" />
      </Helmet>
      <section className={s.section}>
        <div className={s.wrapper}>
          <div className={s.left}>
            <p
              className={s.description_desktop}
              dangerouslySetInnerHTML={{ __html: props.description_desktop }}
            />

            <p
              className={s.description_mobile}
              dangerouslySetInnerHTML={{ __html: props.description_mobile }}
            />
            <p className={s.description_warning}>{props.description_warning}</p>
            <p className={s.description_terms}>{props.emailTerms()}</p>
            <input
              type="email"
              value={email}
              onChange={({ target: { value } }) => setEmail(value)}
              className={s.form_control + " " + s.description_email}
              placeholder={props.email_address}
            />

            <div className={s.button_wrapper}>
              <button
                className={`${s.button} ${
                  buttonState === "disabled" || spinned ? s.button_disabled : ""
                }`}
                type="button"
                disabled={buttonState === "disabled" || spinned}
                onClick={() => {
                  submitEmail()
                }}
              >
                <span>{props.spin_button}</span>
              </button>
            </div>
          </div>
          <div className={s.right}>
            <div className={s.wheel_container}>
              <canvas
                id="canvas"
                className={`${s.wheel_canvas} ${
                  wheelActive ? s.wheel_active : s.wheel_disabled
                }`}
                width="520"
                height="520"
                data-responsiveminwidth="250"
                data-responsivescaleheight="true" /* Optional Parameters */
              />
              <img
                className={s.wheel_pin}
                alt="wheel pin"
                src="/wheel_pin.png"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SpinningWheelSection
