import React from "react"

import {
  ImgFluid,
  WinAndHikeLogoAnimation,
  WinAndHikeMainPagePathAnimation,
  WinAndHikeGewinnAnimation,
} from "../"
import { PromotionSectionWrapper } from "../../containers/HikeAndWinContainers"
import { extractImageNameFromUrl } from "../../helper"
import s from "./WinAndHikeHero.module.scss"

class WinAndHikeHero extends React.Component {
  render() {
    const {
      hasToMobileButton,
      toMobileText,
      hasBigLogo,
      hasSecondaryPathAnimation,
      title,
      children,
      backgroundImage,
      itemProp,
      loop,
      location,
    } = this.props
    return (
      <section className={s.WinAndHikeHero}>
        {hasBigLogo ? (
          <div itemProp={itemProp} className={s.BigLogoPositioner}>
            <div className={s.BigLogoWrapper}>
              <WinAndHikeMainPagePathAnimation
                loop={loop}
                className={s.GewinnPath}
              />
              <WinAndHikeLogoAnimation
                className={s.BigLogo}
                location={location}
              >
                {hasBigLogo ? (
                  <div className={s.SmallTitleWrapper}>
                    <h2
                      className={s.SmallTitle}
                      dangerouslySetInnerHTML={{ __html: title }}
                    ></h2>
                  </div>
                ) : (
                  <div className={s.BigTitleWrapper}>
                    <h1
                      className={s.BigTitle}
                      dangerouslySetInnerHTML={{ __html: title }}
                    ></h1>
                  </div>
                )}
              </WinAndHikeLogoAnimation>
              {hasSecondaryPathAnimation && (
                <WinAndHikeGewinnAnimation loop={loop} className={s.Gewinn} />
              )}
            </div>
          </div>
        ) : null}
        {hasToMobileButton ? <button>{toMobileText}</button> : null}
        <div className={s.TopSection}>
          {/* Mountain SVG */}
          <div className={s.ImagesAndTitleWrapper}>
            <div className={s.ImageWrapper}>
              <ImgFluid
                fileName={extractImageNameFromUrl(backgroundImage)}
                itemProp={itemProp}
              />
            </div>
          </div>
          <div className={s.BorderBottom}></div>
        </div>
        <PromotionSectionWrapper className={s.Content}>
          {children}
        </PromotionSectionWrapper>
      </section>
    )
  }
}

export default WinAndHikeHero
