import React from "react"
import Bowser from "bowser"
import { debounce } from "throttle-debounce"

import { ImgFluid } from "../../../components"
import {
  extractImageNameFromUrl,
  checkMobileDeviceExceptSamsungInternet,
  checkMobileIos,
} from "../../../helper"

import s from "./SkewedBackgroundHero.module.scss"

class SkewedBackgroundHero extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobileDeviceExceptSamsungInternet: true,
      isMobileIos: false,
    }

    this.headlineRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.imageWrapperRef = React.createRef();
    this.checkHeaderFontSize = this.checkHeaderFontSize.bind(this)
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      this.setState({
        isMobileDeviceExceptSamsungInternet:
          checkMobileDeviceExceptSamsungInternet(window, Bowser),
        isMobileIos: checkMobileIos(window, Bowser),
      })

      // i don't how whats happening but we have to wait first
      setTimeout(() => {
        this.checkHeaderFontSize()
      }, 100);

      window.addEventListener('resize', debounce(100, () => {
	      this.checkHeaderFontSize(240)
      }))
    }
  }

  checkHeaderFontSize(fontSize = null) {
    if (!this.headlineRef.current) {
      setTimeout(() => {
        this.checkHeaderFontSize(fontSize)
      }, 50)
      return;
    }
    // set font size
    fontSize = fontSize ? fontSize : 240;
    this.headlineRef.current.style.fontSize = fontSize - 5 + 'px';

    // check if font size is too big
    const minFontSize = 40;
    const wrapperRect = this.wrapperRef.current.getBoundingClientRect();
    const imageWrapperRect = this.imageWrapperRef.current.getBoundingClientRect();
    const headlineSkewedHeight = this.headlineRef.current.offsetHeight + this.headlineRef.current.offsetWidth * 2 * 0.0791922
    const maxHeadlineHeight = (wrapperRect.height - 2 * 0.0791922 * wrapperRect.width) * 0.6
    if(
      fontSize > minFontSize
      && (
        this.headlineRef.current.offsetWidth > wrapperRect.width - imageWrapperRect.width * 0.8
        || headlineSkewedHeight > maxHeadlineHeight
      )
    ) {
      this.checkHeaderFontSize(fontSize - 5);
    } else {
      this.headlineRef.current.style.opacity = 1;
    }
  }

  render() {
    const {
      title_one_text,
      title_one_color,
      title_two_text,
      title_two_color,
      bottle_image,
    } = this.props
    const { isMobileDeviceExceptSamsungInternet, isMobileIos } = this.state

    let titleOneClasses = [s.Title]
    if (title_one_color) titleOneClasses.push(title_one_color)
    else titleOneClasses.push("WhiteColor")
    let titleTwoClasses = [s.TitleTwo]
    if (title_two_color) titleTwoClasses.push(title_two_color)
    else titleTwoClasses.push("WhiteColor")
    let sectionClasses = [s.SkewedBackgroundHero]
    if (isMobileDeviceExceptSamsungInternet)
      sectionClasses.push(s.SkewedBackgroundHeroMobileDevice)
    if (isMobileIos) sectionClasses.push(s.SkewedBackgroundHeroIphoneSafari)

    return (
      <section
        className={sectionClasses.join(" ")}
        style={{ zIndex: this.props.sectionZindex }}
      >
        <div className={s.BackgroundBanner}></div>
        <div className={s.Content}>
          <div className={s.TitleWrapper} ref={this.wrapperRef}>
            <h1
              className={titleOneClasses.join(" ")}
              dangerouslySetInnerHTML={{ __html: title_one_text }}
              ref={this.headlineRef}
            ></h1>
          </div>
          <div className={s.TitleAndImageWrapper}>
            <div className={s.TitleAndImageGrouper}>
              <h2
                className={titleTwoClasses.join(" ")}
                dangerouslySetInnerHTML={{ __html: title_two_text }}
              ></h2>
              <div className={s.ImageWrapper} ref={this.imageWrapperRef}>
                <ImgFluid
                  fileName={extractImageNameFromUrl(bottle_image)}
                  alt={title_one_text}
                />
              </div>
              <div className={s.Shadow}>
                <div className={s.ShadowWrapper}>
                  <ImgFluid fileName={"bottle_shadow.png"} loading="eager" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default SkewedBackgroundHero
