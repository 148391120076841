import React from "react"

import { SkewedHero } from "../../components"
import s from "./AboutUsHero.module.scss"
import { extractImageNameFromUrl } from "../../helper"
import ArrowDown from "../../assets/svgs/arrow_down.svg"

class AboutUsHero extends React.Component {
  onArrowDown = () => {
    if (typeof this.props.onScrollTo === "function") {
      this.props.onScrollTo()
    }
  }

  titleRef = React.createRef()

  animateHeroTitle = () => {
    const { current: titleElement } = this.titleRef
    setTimeout(() => titleElement.classList.add(s.animated_title), 300)
  }

  componentDidMount() {
    this.animateHeroTitle()
  }

  render() {
    const { title_one_text, title_one_color, background_img } = this.props

    return (
      <section
        className={s.hero_wrapper}
        style={{ zIndex: this.props.sectionZindex }}
        ref={this.props.sectionRef}
      >
        <SkewedHero
          imgFilename={extractImageNameFromUrl(background_img)}
          imgAlt={title_one_text}
          cn={s.about_us_skewed_hero}
        >
          <h1
            ref={this.titleRef}
            className={[s.hero_title, title_one_color].join(" ")}
          >
            {title_one_text}
          </h1>
          <ArrowDown className={s.ArrowDown} onClick={this.onArrowDown} />
        </SkewedHero>
      </section>
    )
  }
}

export default AboutUsHero
