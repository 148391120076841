import React from "react"

import { EventSummaryCard } from "../../components"

import { learn_more_alternate } from "../../constants"
import { getCurrentLanguage } from "../../helper"
import s from "./EventsContestSection.module.scss"
import { reshapeEventsData } from "../../util/reshapeEvents"

class EventsContestSection extends React.Component {
  constructor(props) {
    super(props)
    const currentLang = getCurrentLanguage(props.location)
    this.state = {
      currentLang,
      contests_data: reshapeEventsData(
        props.rawEvents,
        "contest",
        currentLang.slug
      ).eventData,
    }
  }
  render() {
    const { title_one_text, title_one_color, title_two_text, title_two_color } =
      this.props
    const { currentLang, contests_data } = this.state
    return (
      <section className={s.EventsContentSection} ref={this.props.sectionRef}>
        <div className={s.Background}></div>
        <div className={s.ContestsWrapper}>
          <div className={s.TitlesWrapper}>
            <h3
              className={[s.SubTitle, title_two_color].join(" ")}
              dangerouslySetInnerHTML={{ __html: title_two_text }}
            ></h3>
            <h2
              className={[s.MainTitle, title_one_color].join(" ")}
              dangerouslySetInnerHTML={{ __html: title_one_text }}
            ></h2>
          </div>
          {contests_data.map((contest) => (
            <div key={contest.title} className={s.ContestCardWrapper}>
              <EventSummaryCard
                cardIndex={0}
                currentSlide={0}
                title={contest.eventTitle}
                date={""}
                location={""}
                description={contest.descriptionPreview}
                linkTitle={learn_more_alternate[currentLang.combined]}
                linkTo={contest.slug}
                imageUrl={contest.eventImg}
                isEventListCard
                disableImageWhiteCorners
                isSmallerSkew
              />
            </div>
          ))}
        </div>
      </section>
    )
  }
}

export default EventsContestSection
