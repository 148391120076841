import React from "react"
import { navigate, Link } from "gatsby"

import s from "./EventSummaryCard.module.scss"
import ClockIcon from "../../assets/svgs/clock-icon-white.svg"
import AlternateClockIcon from "../../assets/svgs/clock-icon-black.svg"
import AlternatePinIcon from "../../assets/svgs/location-icon-black.svg"
import Arrow from "../../assets/svgs/arrow-white.svg"
import { SkewedButton } from ".."
import { andWordTranslation } from "../../constants"

class EventSummaryCard extends React.Component {
  onImageClick = (evt) => {
    const { cardIndex, currentSlide, linkTo, isStaticSliderChild } = this.props

    evt.preventDefault()
    if (cardIndex !== currentSlide && !isStaticSliderChild) return
    navigate(linkTo)
  }

  renderTopRightIcon = (icon) => {
    return icon.startsWith("http") ? (
      <img alt={"icon"} className={s.TopRightIcon} />
    ) : (
      <span
        className={s.TopRightIcon}
        dangerouslySetInnerHTML={{ __html: icon }}
      />
    )
  }

  render() {
    const {
      title,
      date,
      alternateDate,
      location,
      description,
      linkTitle,
      imageUrl,
      isStaticSliderChild,
      isEventListCard,
      disableImageWhiteCorners,
      disableContentPadding,
      isSmallerSkew,
      eventDateText,
      lang,
      imageStyle = {},
    } = this.props
    return (
      <div>
        <div
          className={[
            s.Card,
            isStaticSliderChild ? s.CardStatic : null,
            isEventListCard ? s.EventListPageCard : null,
          ].join(" ")}
          ref={this.cardRef}
        >
          {/* {topRightIcon ? this.renderTopRightIcon(topRightIcon) : null} */}
          { imageUrl &&
            <div className={s.CardImage}>
              {/*<KeyVisual2 className={s.key_visual}/>*/}
              {disableImageWhiteCorners ? null : (
                <div className={[s.ImageSkew, s.ImageTopLeft].join(" ")} />
              )}
              <Link
                onClick={this.onImageClick}
                className={s.event_item_wrapper}
                to={this.props.linkTo}
              >
                {/* <ImgFluid
                  fileName={extractImageNameFromUrl(imageUrl)}
                  alt={title}
                /> */}
                <img src={imageUrl} alt={title} style={{ ...imageStyle }} />
              </Link>
            </div>
          }
          <div
            className={[
              s.CardContent,
              disableContentPadding ? s.DisableContentPadding : null,
              isSmallerSkew ? s.CardContentSmallSkew : null,
            ].join(" ")}
          >
            <div className={s.Date}>
              {date ? (
                <span>
                  {isEventListCard ? <AlternateClockIcon /> : <ClockIcon />}
                  {eventDateText ? (
                    <span>{eventDateText}</span>
                  ) : (
                    <span>
                      {date}
                      {alternateDate
                        ? ` ${andWordTranslation[lang]} ${alternateDate}`
                        : ""}
                    </span>
                  )}
                </span>
              ) : null}
              {/* {isEventListCard ? null : location ? (
                <span className={[s.Location, s.LocationMobile].join(" ")}>
                  <PinIcon />
                  <span>{location}</span>
                </span>
              ) : null} */}
            </div>
            <h3 className={s.Title}>
              <Link
                onClick={this.onImageClick}
                className={s.event_item_wrapper}
                to={this.props.linkTo}
                dangerouslySetInnerHTML={{ __html: title }}
              ></Link>
            </h3>
            {location ? (
              <p className={s.Location}>
                <AlternatePinIcon />
                <span>{location}</span>
              </p>
            ) : null}
            <p
              className={s.Description}
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
            {isEventListCard ? (
              <Link to={this.props.linkTo}>
                <SkewedButton
                  type="primary"
                  wrapperClassName={s.EventListPageButton}
                  isSmallerSkew={isSmallerSkew}
                >
                  {linkTitle}
                </SkewedButton>
              </Link>
            ) : (
              <Link
                className={s.Link}
                onClick={this.onImageClick}
                to={this.props.linkTo}
              >
                <span className={s.LinkWrapper}>{linkTitle}</span>
                <span>
                  <Arrow />
                </span>
              </Link>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default EventSummaryCard
