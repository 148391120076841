import React from "react"
import { getCookie, setCookie } from "../../helper"

import styles from "./CookieBanner.module.scss"

import { langData } from "./data"

export const CookieBanner = ({ currentLang }) => {
  const { lang } = currentLang ? currentLang : { lang: "de" }

  const strings = langData[lang] ? langData[lang] : langData.de

  const [hasConsent, setHasConsent] = React.useState(() =>
    getCookie("has_consent")
  )

  const onAccept = () => {
    setCookie("has_consent", "true", 90)
    setHasConsent(true)
  }

  if (hasConsent) {
    return null
  }

  return (
    <div className={styles.CookieConsent_Wrapper}>
      <div className={styles.CookieConsent_ContentContainer}>
        <div className={styles.CookieConsent_Content}>
          {strings.text}
          <a
            className={styles.CookieConsent_Anchor}
            href={strings.ext}
            target="_blank"
            rel="noopener noreferrer"
          >
            {strings.more}
          </a>
        </div>
        <div className={styles.CookieConsent_ButtonWrapper}>
          <button className={styles.CookieConsent_Button} onClick={onAccept}>
            {strings.button}
          </button>
        </div>
      </div>
    </div>
  )
}
