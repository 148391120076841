import React from "react"

import s from "./HikeAndWinButton.module.scss"
import { Link } from "gatsby"

class HikeAndWinButton extends React.Component {
  render() {
    const { className, type, children, ...props } = this.props
    return (
      <>
        {type === "link" ? (
          <Link
            className={[s.HikeAndWinButton, className].join(" ")}
            {...props}
          >
            {children}
          </Link>
        ) : (
          <button
            className={[s.HikeAndWinButton, className].join(" ")}
            {...props}
          >
            {children}
          </button>
        )}
      </>
    )
  }
}

export default HikeAndWinButton
