import React from "react"
import { ImgFluid } from "../"

import s from "./EngagementCard.module.scss"
import PlayButton from "../../assets/svgs/play-button.svg"

class EngagementCard extends React.Component {
  render() {
    const {
      title,
      description,
      imgFile,
      children,
      videoLink,
      cornerIcon,
      isSizeAuto,
    } = this.props

    const contentClasses = isSizeAuto
      ? [s.CardContent]
      : [s.CardContent, s.CardContentSizeFull]
    return (
      <div>
        <div className={s.Card} ref={this.cardRef}>
          <div
            className={s.CardImage}
            onClick={() => this.props.onOpenModal(videoLink)}
            style={{ cursor: videoLink && videoLink !== "#" && "pointer" }}
          >
            <ImgFluid fileName={imgFile} alt={title} />
            <div className={[s.ImageSkew, s.ImageTopLeft].join(" ")} />
            {videoLink && videoLink !== "#" && (
              <PlayButton className={s.PlayButton} />
            )}
            {cornerIcon ? (
              <ImgFluid fileName={cornerIcon} cn={s.CornerIcon} />
            ) : null}
          </div>
          <div className={contentClasses.join(" ")}>
            <h5
              className={s.Title}
              dangerouslySetInnerHTML={{ __html: title }}
            ></h5>
            <p
              className={s.Description}
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
            <div className={s.card_body}>{children}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default EngagementCard
