/* eslint-disable */
import React from "react"
import Helmet from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import { SITE_URL, SITE_SCOPES } from "../../constants"
import { getCommonIdentifier } from "../../helper"
import LanguagesData from "../../containers/Header/LanguagesData"

const getUri = (scope, combined, commonIdentifier) => {
  const linkObj = LanguagesData[scope][combined].leftLinks.find(
    (el) => el.commonIdentifier === commonIdentifier
  )
  return linkObj ? linkObj.uri : "/"
}

const getAlternateLinks = (scope = SITE_SCOPES.main, location) => {
  let links = []
  const commonIdentifier = getCommonIdentifier()

  if (scope === SITE_SCOPES.main && commonIdentifier === "mainIndex") {
    links = [
      <link
        rel="alternate"
        hreflang="x-default"
        href={`${location.origin}/de`}
      />,
      <link rel="alternate" hreflang="de-CH" href={`${location.origin}/de`} />,
      <link rel="alternate" hreflang="it-CH" href={`${location.origin}/it`} />,
      <link rel="alternate" hreflang="fr-CH" href={`${location.origin}/fr`} />,
      <link
        rel="alternate"
        hreflang="fr-FR"
        href={`${location.origin}/fr-fr/`}
      />,
      <link
        rel="alternate"
        hreflang="fr-LU"
        href={`${location.origin}/lu-fr/`}
      />,
    ]
  } else if (scope === SITE_SCOPES.main && commonIdentifier !== "mainIndex") {
    links = [
      <link
        rel="alternate"
        hreflang="x-default"
        href={`${location.origin}/de${getUri(scope, "de", commonIdentifier)}`}
        key="lang-default"
      />,
      <link
        rel="alternate"
        hreflang="it-CH"
        href={`${location.origin}/it${getUri(scope, "it", commonIdentifier)}`}
        key="lang-it-ch"
      />,
      <link
        rel="alternate"
        hreflang="fr-CH"
        href={`${location.origin}/fr${getUri(scope, "fr", commonIdentifier)}`}
        key="lang-fr-ch"
      />,
      <link
        rel="alternate"
        hreflang="fr-FR"
        href={`${location.origin}/fr-fr${getUri(
          scope,
          "fr_fr",
          commonIdentifier
        )}`}
        key="lang-fr-fr"
      />,
      <link
        rel="alternate"
        hreflang="fr-LU"
        href={`${location.origin}/lu-fr${getUri(
          scope,
          "lu_fr",
          commonIdentifier
        )}`}
        key="lang-fr-lu"
      />,
    ]
  } else if (scope === SITE_SCOPES.winAndHike) {
    links = [
      <link
        rel="alternate"
        hreflang="x-default"
        href={`${location.origin}/de${getUri(scope, "de", commonIdentifier)}`}
        key="lang-default"
      />,
      <link
        rel="alternate"
        hreflang="it-CH"
        href={`${location.origin}/it${getUri(scope, "it", commonIdentifier)}`}
        key="lang-it-ch"
      />,
      <link
        rel="alternate"
        hreflang="fr-CH"
        href={`${location.origin}/fr${getUri(scope, "fr", commonIdentifier)}`}
        key="lang-fr-ch"
      />,
    ]
  }
  links.push(
    <link rel="canonical" href={`${location.href?.replace("www.", "")}`} key="lang-canonical" />
  )
  return links
}
const HelmetGenerator = (props) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const { siteMetadata } = data.site
  const {
    environmentConstant,
    currentEnvironment,
    pageLang,
    location,
    siteScope,
    pageDescription,
  } = props
  const links = getAlternateLinks(siteScope, location)
  const pageTitle = `${siteMetadata.title} | ${props.title.replace(
    "&#8217;",
    "'"
  )}`
  return (
    <Helmet>
      <html lang={pageLang || "de"} />
      <title>{pageTitle}</title>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="author" content={siteMetadata.author} />
      <meta name="description" content={pageDescription} />
      <meta name="keywords" content="Rivella, Drink, Switzerland" />
      <meta
        name="facebook-domain-verification"
        content="xjg727fvg7ecihdv1fsq7w75pvfblh"
      />

      {/* Twitter card */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@rivella" />
      <meta name="twitter:description" content={pageDescription} />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:creator" content="@rivella" />

      {/* Open graph */}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`${SITE_URL}/${location.pathname}`} />
      <meta property="og:description" content={pageDescription} />
      {/* <meta  property="og:image" content="siteimgurl" /> */}

      {/* Hreflang tags */}
      {[...links]}

      {/* PRODUCTION GOOGLE TAG MANAGER */}
      {/* {currentEnvironment === environmentConstant.production && (
        <script>
          {(function(w, d, s, l, i) {
            w[l] = w[l] || []
            w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != "dataLayer" ? "&l=" + l : ""
            j.async = true
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
            f.parentNode.insertBefore(j, f)
          })(window, document, "script", "dataLayer", "GTM-N35TVP5")}
        </script>
      )} */}
      {/* STAGING GOOGLE TAG MANAGER */}
      {/* {currentEnvironment === environmentConstant.staging && (
        <script>
          {(function(w, d, s, l, i) {
            w[l] = w[l] || []
            w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != "dataLayer" ? "&l=" + l : ""
            j.async = true
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
            f.parentNode.insertBefore(j, f)
          })(window, document, "script", "dataLayer", "GTM-N35TVP5")}
        </script>
      )} */}

      {/* STAGING OR PRODUCTION FULLSTORY TRACKER */}
      {/* {(currentEnvironment === environmentConstant.staging ||
        currentEnvironment === environmentConstant.production) && (
        <script>
          {(function(m, n, e, t, l, o, g, y) {
            window["_fs_debug"] = false
            window["_fs_host"] = "fullstory.com"
            window["_fs_script"] = "edge.fullstory.com/s/fs.js"
            window["_fs_org"] = "WJSB9"
            window["_fs_namespace"] = "FS"
            if (e in m) {
              if (m.console && m.console.log) {
                m.console.log(
                  'FullStory namespace conflict. Please set window["_fs_namespace"].'
                )
              }
              return
            }
            g = m[e] = function(a, b, s) {
              g.q ? g.q.push([a, b, s]) : g._api(a, b, s)
            }
            g.q = []
            o = n.createElement(t)
            o.async = 1
            o.crossOrigin = "anonymous"
            o.src = "https://" + window._fs_script
            y = n.getElementsByTagName(t)[0]
            y.parentNode.insertBefore(o, y)
            g.identify = function(i, v, s) {
              g(l, { uid: i }, s)
              if (v) g(l, v, s)
            }
            g.setUserVars = function(v, s) {
              g(l, v, s)
            }
            g.event = function(i, v, s) {
              g("event", { n: i, p: v }, s)
            }
            g.anonymize = function() {
              g.identify(!!0)
            }
            g.shutdown = function() {
              g("rec", !1)
            }
            g.restart = function() {
              g("rec", !0)
            }
            g.log = function(a, b) {
              g("log", [a, b])
            }
            g.consent = function(a) {
              g("consent", !arguments.length || a)
            }
            g.identifyAccount = function(i, v) {
              o = "account"
              v = v || {}
              v.acctId = i
              g(o, v)
            }
            g.clearUserCookie = function() {}
            g._w = {}
            y = "XMLHttpRequest"
            g._w[y] = m[y]
            y = "fetch"
            g._w[y] = m[y]
            if (m[y])
              m[y] = function() {
                return g._w[y].apply(this, arguments)
              }
            g._v = "1.2.0"
          })(window, document, window["_fs_namespace"], "script", "user")}
        </script>
      )} */}
    </Helmet>
  )
}

export default HelmetGenerator
