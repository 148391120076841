import React, { Component } from "react"
import s from "./PromotionSectionWrapper.module.scss"

class PromotionSectionWrapper extends Component {
  render() {
    const { children, className = "", ...props } = this.props
    const classNames = [s.container, className].join(" ")
    return (
      <section className={classNames} {...props}>
        {children}
      </section>
    )
  }
}

export default PromotionSectionWrapper
