import React from "react"
import { Link } from "gatsby"
import Supercluster from "supercluster"
import moment from "moment"

import { EventSummaryCard, ImgFluid, Map, MapPin } from "../../components"
import s from "./EventsList.module.scss"
import {
  getCurrentLanguage,
  ssrFriendlyFetch,
  extractImageNameFromUrl,
  scrollToRef,
} from "../../helper"
import { reshapeEventsData } from "../../util/reshapeEvents"
import { for_sampling } from "../../constants"

const eventColors = {
  swimming: "#E22219",
  "swimming-fr": "#E22219",
  running: "#64666A",
  "running-fr": "#64666A",
  "running-2": "#64666A",
  sampling: "#E22219",
  skiing: "#64666A",
  "skiing-fr": "#64666A",
  winter: "#0052B4",
  dhiver: "#0052B4",
  "im-schnee-2023": "#0052B4",
  default: "#E22219",
}

const filterDates = (event) => {
  const dt = moment(event.rawDate)
  const alt_dt = moment(event.rawAlternateDate)
  const now = moment()

  return (
    dt.isSameOrAfter(now) ||
    (event.rawAlternateDate && alt_dt.isSameOrAfter(now))
  )
}

class EventsList extends React.Component {
  constructor(props) {
    super(props)
    const currentLang = getCurrentLanguage(this.props.location)
    const { eventData, allCategories = [] } = reshapeEventsData(
      props.rawEvents,
      "event",
      currentLang.slug,
      currentLang.lang
    )
    this.supercluster = new Supercluster({
      radius: 120,
    })
    this.mapRef = React.createRef()
    this.eventListRef = React.createRef()

    this.state = {
      selectedEventType: null,
      currentMapCenter: null,
      bounds: undefined,
      allEvents: eventData.filter(filterDates),
      eventData: (eventData ? eventData : []).filter(filterDates),
      allCategories,
      eventIndex: 0,
      currentLang,
      mapZoomValue: 8,
    }
  }

  componentDidMount() {
    const { allCategories } = this.state
    this.fetchCategoryIcons(allCategories)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.eventData && this.state.eventData) {
      const { allCategories } = this.state
      this.fetchCategoryIcons(allCategories)
    }
  }

  fetchCategoryIcons = async (allCategories) => {
    let updatedCategories = []
    for (let c of allCategories) {
      if (c.icon && c.icon.endsWith(".svg")) {
        // const index = allCategories.findIndex(ac => ac.id = c.id);
        const iconText = await ssrFriendlyFetch(c.icon).then((res) =>
          res.text()
        )
        updatedCategories.push({ ...c, icon: iconText })
      } else {
        updatedCategories.push(c)
      }
    }
    this.setState({
      allCategories: updatedCategories,
    })
  }

  changeMapZoomValue = (direction) => {
    let nextZoom = this.state.mapZoomValue
    const changePerCall = 1
    const maxZoom = 14
    if (direction === "zoomout") {
      nextZoom = nextZoom > changePerCall ? nextZoom - changePerCall : nextZoom
    } else {
      nextZoom =
        maxZoom >= nextZoom + changePerCall
          ? nextZoom + changePerCall
          : nextZoom
    }
    this.setState({
      mapZoomValue: nextZoom,
    })
  }

  toggleEventType = (eventType) => {
    const { allEvents } = this.state

    if (eventType !== this.state.selectedEventType) {
      this.setState({
        selectedEventType: eventType,
        eventData: allEvents.filter((e) => e.categories[0].slug === eventType),
      })
    } else {
      this.setState({ selectedEventType: null })
    }
  }

  handleEventChange = (index) => {
    if (this.state.eventIndex !== index) {
      this.setState({ eventIndex: index })
    }
  }

  renderTabItem = ({ icon, eventType, slug, id }) => {
    const { selectedEventType: selectedEvent } = this.state
    const activeClassname = slug === selectedEvent ? s.active : ""
    return (
      <button
        key={id}
        className={`${s.event_tab} ${activeClassname}`}
        onClick={() => this.toggleEventType(slug)}
      >
        {icon && icon.startsWith("http") ? (
          <span className={s.event_tab_icon}>
            <img src={icon} alt={eventType} />
          </span>
        ) : (
          <span
            className={s.event_tab_icon}
            dangerouslySetInnerHTML={{ __html: icon }}
          />
        )}
        <h2 className={s.event_tab_title}>{eventType}</h2>
      </button>
    )
  }

  renderEventItem = (
    { eventImg, eventTitle, eventLocation, eventDate, slug },
    index
  ) => {
    const { eventIndex, currentLang } = this.state
    const selectedEventClassname = index === eventIndex ? s.selected_event : ""
    this[`eventRef-${index}`] = React.createRef()

    return (
      <Link
        key={index}
        to={`/${currentLang.slug}/events/${slug}`}
        className={s.event_item_wrapper}
      >
        <div className={s.event_item} ref={this[`eventRef-${index}`]}>
          <div
            className={`${s.event_item_image} ${selectedEventClassname}`}
            onClick={() => {
              this.handleEventChange(index)
            }}
          >
            {/* <img src={eventImg} alt={eventTitle} /> */}
            <ImgFluid
              fileName={extractImageNameFromUrl(eventImg)}
              alt={eventTitle}
            />
          </div>
          <div className={s.event_item_details}>
            <h1 className={s.event_item_title}>{eventTitle}</h1>
            <p className={s.event_item_location}>{eventLocation}</p>
            <p className={s.event_item_date}>{eventDate}</p>
          </div>
        </div>
      </Link>
    )
  }

  filterEvents = () => {
    const { eventData, selectedEventType, allCategories } = this.state

    const filtered = (eventData ? eventData : []).filter((event, _index) => {
      const eventCategory =
        event.categories && event.categories.length ? event.categories[0] : {}

      const foundCategoryData = allCategories.find(
        (c) => eventCategory.id === c.id
      )

      if (foundCategoryData) {
        if (selectedEventType && selectedEventType !== foundCategoryData.slug)
          return false
      } else {
        if (selectedEventType) return false
      }
      return true
    })

    return filtered
  }

  clusterify = () => {
    const { mapZoomValue, bounds } = this.state
    const filtered = this.filterEvents()

    if (!bounds) {
      return []
    }

    const points = filtered.map((event) => ({
      type: "Feature",
      properties: { cluster: false, event },
      geometry: {
        type: "Point",
        coordinates: [
          parseFloat(event.coords?.lng ?? "0"),
          parseFloat(event.coords?.lat ?? "0"),
        ],
      },
    }))

    this.supercluster.load(points)

    const clusters = this.supercluster.getClusters(
      [bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat],
      Math.floor(mapZoomValue)
    )

    const aggregatedClusters = clusters.map((cluster) => {
      if (cluster.properties.cluster) {
        const itemsOfCluster = this.supercluster.getLeaves(
          cluster.properties.cluster_id,
          1,
          0
        )
        return {
          ...cluster,
          properties: {
            ...cluster.properties,
            firstChild:
              itemsOfCluster && itemsOfCluster[0]
                ? itemsOfCluster[0]
                : undefined,
          },
        }
      } else {
        return cluster
      }
    })

    return aggregatedClusters
  }

  renderMarker = (event) => {
    const { eventIndex, allCategories } = this.state

    const selectedEvent = this.state.eventData[eventIndex ? eventIndex : 0]
    const selectedEventId = selectedEvent ? selectedEvent.id : -1

    const selectedEventClassname =
      event.id === selectedEventId ? s.selected_event : ""
    let icon = ""
    const eventCategory =
      event.categories && event.categories.length ? event.categories[0] : {}

    const foundCategoryData = allCategories.find(
      (c) => eventCategory.id === c.id
    )
    if (foundCategoryData) {
      icon = foundCategoryData.icon
    }

    // icon fallback
    if(!icon) {
      icon = "https://dev.rivella.com/backend/wp-content/uploads/2022/04/default-marker-1.png"
    }

    return (
      <MapPin
        key={event.id}
        {...event.coords}
        iconClassName={`${s.map_pin} ${selectedEventClassname}`}
        handleClick={() => {
          if (window.innerWidth < 1000) {
            scrollToRef(this.eventListRef)
          }
          this.handleEventChange(
            (this.state.eventData ? this.state.eventData : []).findIndex(
              (el) => el.id === event.id
            )
          )
        }}
        onMouseEnter={() => {
          if (window.innerWidth < 1000) {
            scrollToRef(this.eventListRef)
          }
          this.handleEventChange(
            (this.state.eventData ? this.state.eventData : []).findIndex(
              (el) => el.id === event.id
            )
          )
        }}
        eventData={event}
      >
        {icon && icon.startsWith("http") ? (
          <span className={s.event_tab_icon}>
            <img src={icon} alt={event.name} />
          </span>
        ) : (
          <span dangerouslySetInnerHTML={{ __html: icon }}></span>
        )}
      </MapPin>
    )
  }

  renderCluster = (cluster, i) => {
    const child = cluster ? cluster.properties.firstChild : undefined
    const childEvent = child ? child.properties.event : undefined
    const childEventCategory =
      childEvent && childEvent.categories && childEvent.categories[0]
        ? childEvent.categories[0]
        : undefined
    const childEventCategorySlug = childEventCategory
      ? childEventCategory.slug
      : "default"
    const childEventCategoryIcon = childEventCategory
      ? childEventCategory.icon
      : undefined

    return (
      <button
        key={cluster.id}
        lng={cluster.geometry.coordinates[0]}
        lat={cluster.geometry.coordinates[1]}
        style={{
          appearance: "none",
          border: "none",
          backgroundColor: "transparent",
          cursor: "pointer",
          zIndex: i + 11,
        }}
        onClick={() => {
          const expansionZoom = this.supercluster.getClusterExpansionZoom(
            Number(cluster.properties.cluster_id)
          )
          this.setState({
            mapZoomValue: expansionZoom,
            currentMapCenter: {
              lat: cluster.geometry.coordinates[1],
              lng: cluster.geometry.coordinates[0],
            },
          })
        }}
      >
        <div style={{ position: "relative", marginTop: "-40px" }}>
          {childEventCategoryIcon &&
          childEventCategoryIcon.startsWith("http") ? (
            <span className={s.event_tab_icon}>
              <img src={childEventCategoryIcon} />
            </span>
          ) : (
            <span
              dangerouslySetInnerHTML={{ __html: childEventCategoryIcon }}
            ></span>
          )}
          <div
            className="counter"
            style={{
              position: childEventCategoryIcon ? "absolute" : "relative",
              right: childEventCategoryIcon ? -3 : 0,
              top: childEventCategoryIcon ? -8 : 0,
              backgroundColor: eventColors[
                childEventCategorySlug ? childEventCategorySlug : "default"
              ]
                ? eventColors[
                    childEventCategorySlug ? childEventCategorySlug : "default"
                  ]
                : eventColors.default,
              fontSize: "18px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              zIndex: 10,
              fontFamily: "Tungsten, sans-serif",
              width: "24px",
              height: "24px",
              lineHeight: "24px",
              borderRadius: "999px",
            }}
          >
            {cluster.properties.point_count}
          </div>
        </div>
      </button>
    )
  }

  render() {
    const {
      eventData,
      currentLang,
      selectedEventType,
      eventIndex,
      allCategories,
      mapZoomValue,
    } = this.state

    const selectedEvent =
      eventData && eventData[eventIndex] ? eventData[eventIndex] : {}

    let icon = ""
    const eventCategory =
      selectedEvent.categories && selectedEvent.categories.length
        ? selectedEvent.categories[0]
        : {}
    const foundCategoryData = allCategories.find(
      (c) => eventCategory.id === c.id
    )
    if (foundCategoryData) {
      if (
        !(selectedEventType && selectedEventType !== foundCategoryData.slug)
      ) {
        icon = foundCategoryData.icon
      }
    }
    return (
      <section
        className={s.map_events_section}
        style={{ zIndex: this.props.sectionZindex }}
      >
        <div
          ref={this.props.sectionRef}
          style={{ top: "0", paddingBottom: "100px" }}
        />
        <div className={s.TitleWrapper}>
          <h3 className={s.section_subtitle}>{this.props.title_two_text}</h3>
          <h2 className={s.section_title}>{this.props.title_one_text}</h2>
        </div>
        <section className={s.map_events_wrapper}>
          <aside className={`${s.map_section} ${s.skewed_container}`}>
            <div className={s.map_pin_group}>
              <div className={s.map_wrapper}>
                <Map
                  // ref={(_ref) => (this.mapRef.current = _ref)}
                  customMapOptions={{
                    zoomControl: false,
                    fullscreenControl: false,
                  }}
                  onChange={(map) => {
                    this.setState({
                      bounds: map.bounds,
                      mapZoomValue: map.zoom,
                    })
                  }}
                  zoomValue={mapZoomValue}
                  // handleMapChange={this.handleMapChange}
                  // zoomValue={mapOptions.mapZoomValue}
                  // mapCenterCoords={mapOptions.defaultCenter}
                  currentCenterCoords={this.state.currentMapCenter}
                >
                  {this.clusterify().map((point, index) => {
                    if (point.properties.cluster) {
                      return this.renderCluster(point, index)
                    } else {
                      return this.renderMarker(point.properties.event)
                    }
                  })}
                </Map>
              </div>
              <div className={s.ZoomButtonWrapper}>
                <span
                  className={s.ZoomButton}
                  onClick={() => this.changeMapZoomValue("zoomin")}
                >
                  <span>&#9537;</span>
                </span>
                <span
                  className={s.ZoomButton}
                  onClick={() => this.changeMapZoomValue("zoomout")}
                >
                  <span>&#9472;</span>
                </span>
              </div>
            </div>
          </aside>
          <aside className={s.events_section}>
            <div className={s.EventCardContainer} ref={this.eventListRef}>
              <EventSummaryCard
                cardIndex={0}
                currentSlide={0}
                // eventImg, eventTitle, eventLocation, eventDate, slug
                title={selectedEvent.eventTitle}
                date={selectedEvent.eventDate}
                alternateDate={selectedEvent.eventAlternateDate}
                location={selectedEvent.eventLocation}
                lang={currentLang.lang}
                description={selectedEvent.descriptionPreview}
                eventDateText={selectedEvent.eventDateText}
                linkTitle={for_sampling[currentLang.combined]}
                linkTo={selectedEvent.slug}
                imageUrl={selectedEvent.eventImg}
                isEventListCard
                disableContentPadding
                disableImageWhiteCorners
                topRightIcon={icon}
                imageStyle={{
                  objectFit: "cover",
                  OObjectFit: "unset",
                  // clipPath: "none",
                }}
              />
            </div>
          </aside>
        </section>
      </section>
    )
  }
}

export default EventsList
