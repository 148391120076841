import React from "react"
import InputMask from "react-input-mask"

import { Button, ImgFluid, Modal } from "../../components"
import s from "./MigrosPromoForm.module.scss"
import {
  getCurrentLanguage,
  submitMigrosEntry,
  confirmMigrosEntry,
  sanitizeEmojisInText,
  scrollToRef,
} from "../../helper"

const t = {
  de: {
    form_title: "",
    success_message:
      "Vielen Dank für deine Teilnahme! Bitte bestätige diese per Link, den wir soeben an deine E-Mail Adresse verschickt haben. Erfrischende Grüsse, dein Rivella Team",
    confirm_message: "Herzlichen Dank - wir haben deine Teilnahme registriert",
    repeat_message:
      "Keine Teilnahme möglich - diese E-Mail wurde bereits verwendet",
    error_message: " Bitte überprüfe die rot markierten Felder",
    submit: "ABSENDEN",
    salutation: {
      placeholder: "Anrede",
      title: "Anrede",
    },
    salutation_values: ["Herr", "Frau"],
    titel: {
      placeholder: "Titel",
      title: "Titel",
    },
    title_values: [" ", "Dr."],
    firstname: {
      placeholder: "Vorname",
      title: "Vorname",
    },
    lastname: {
      placeholder: "Nachname",
      title: "Nachname",
    },
    email: {
      placeholder: "E-mail",
      title: "E-mail",
    },
    phone: {
      placeholder: "Telefonnummer",
      title: "Telefonnummer",
    },
    birthdate: {
      placeholder: "DD/MM/YYYY",
      title: "Geburtsdatum",
    },
    address: {
      placeholder: "Adresse",
      title: "Adresse",
    },
    zip: {
      placeholder: "PLZ",
      title: "PLZ",
    },
    city: {
      placeholder: "Ort",
      title: "Ort",
    },
    country: {
      placeholder: "Land",
      title: "Land",
    },
    countries: [
      ["CH", "Schweiz"],
      // ["FR", "Frankreich"],
      // ["DE", "Deutschland"],
      // ["IT", "Italien"],
      ["LH", "Liechtenstein"],
    ],
    newsletter: {
      text: `Ja, ich möchte weitere Informationen zu Rivella erhalten (Newsletter)`,
    },
    terms: {
      link: `spar-promotion/terms`,
      link2: `https://www.rivella-group.com/de/extras/datenschutz`,
      text: [
        `Ich habe von den `,
        `Teilnahmebedingungen`,
        ` und der `,
        `Datenschutzinformation`,
        `Kenntnis genommen und akzeptiere diese.`,
      ],
    },
  },
  fr: {
    form_title: "",
    success_message:
      "Merci pour ta participation! Merci de confirmer via le lien que nous venons d'envoyer à ton adresse e-mail. Salutations rafraîchissantes, ton équipe Rivella",
    confirm_message: "Nous avons confirmé avec succès",
    repeat_message: "Impossible de participer - cet e-mail a déjà été utilisé",
    error_message: "Vérifie les champs marqués en rouge",
    submit: "ENVOYER",
    salutation: {
      placeholder: "Salutation",
      title: "Salutation",
    },
    salutation_values: ["M.", "Mme."],
    titel: {
      placeholder: "Titre",
      title: "Titre",
    },
    title_values: [" ", "Dr."],
    firstname: {
      placeholder: "Prénom",
      title: "Prénom",
    },
    lastname: {
      placeholder: "Nom",
      title: "Nom",
    },
    email: {
      placeholder: "E-mail",
      title: "E-mail",
    },
    phone: {
      placeholder: "Numéro de téléphone",
      title: "Numéro de téléphone",
    },
    birthdate: {
      placeholder: "JJ/MM/AAAA",
      title: "Date de naissance",
    },
    address: {
      placeholder: "Adresse",
      title: "Adresse",
    },
    zip: {
      placeholder: "NPA",
      title: "NPA",
    },
    city: {
      placeholder: "Localité",
      title: "Localité",
    },
    country: {
      placeholder: "Pays",
      title: "Pays",
    },
    countries: [
      ["CH", "Suisse"],
      // ["FR", "France"],
      // ["DE", "Allemagne"],
      // ["IT", "Italie"],
      ["LH", "Liechtenstein"],
    ],
    newsletter: {
      text: `Oui, je souhaite recevoir plus d'informations sur Rivella (newsletter)`,
    },
    terms: {
      link: `spar-promotion/terms`,
      link2: `https://www.rivella-group.com/fr/extras/protection-des-donn%C3%A9es`,
      text: [
        `J'ai lu et j'accepte les `,
        `conditions de participation`,
        ` et les `,
        `informations sur la protection`,
        ` des données`,
      ],
    },
  },
}

class MigrosPromoForm extends React.Component {
  constructor(props) {
    super(props)
    this.formFieldRef = React.createRef()
    this.BlurHackRef = React.createRef()

    // Refs for dropdowns
    this.salutationOfSenderRef = React.createRef()
    this.countryOfSenderRef = React.createRef()
    this.titleOfSenderRef = React.createRef()

    this.state = {
      isRepeat: false,
      formIsDisabled: false,
      submitDisabled: false,
      displaySuccess: false,
      youSentAlready: false,
      confirmMessage: false,
      fieldsAreValid: true,
      showForm: true,
      formData: {
        salutationOfSender: {
          name: "salutation",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        titleOfSender: {
          name: "sender_title",
          value: "",
          ref: React.createRef(),
        },
        nameOfSender: {
          name: "first_name",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        surnameOfSender: {
          name: "last_name",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        emailOfSender: {
          name: "email",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        phoneOfSender: {
          name: "phone",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        birtdateOfSender: {
          name: "dob",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        addressOfSender: {
          name: "address",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        zipOfSender: {
          name: "zip",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        cityOfSender: {
          name: "city",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        countryOfSender: {
          name: "country",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        newsletterSignup: {
          name: "newsletter",
          value: false,
          ref: React.createRef(),
        },
        termsAndConditions: {
          name: "privacy",
          value: false,
          required: true,
          ref: React.createRef(),
        },
      },
    }
  }

  componentDidMount() {
    if (this.props.location.search.split("=")[0] === "?confirm") {
      this.confirmEntry()
    }
  }

  onSubmit = (e) => {
    if (e) e.preventDefault()
    if (this.state.submitDisabled) return
    const isValid = this.validateFields()
    this.setState({ fieldsAreValid: isValid, youSentAlready: false })

    if (isValid) this.submitForm()
  }

  scrollToConfirmationMsg = () => {
    const { current: formField } = this.formFieldRef
    if (formField) {
      window.scroll(0, formField.offsetTop - 300)
    }
  }

  submitForm = async () => {
    this.setState(
      {
        submitDisabled: true,
        confirmMessage: false,
      },
      () => {
        // in case an uncaught error happens, reenable button back after 5 seconds
        setTimeout(() => {
          if (this.state.submitDisabled) {
            this.setState({
              submitDisabled: false,
            })
          }
        }, 5000)
      }
    )
    const { formData } = this.state
    let body = {}
    const { lang } = getCurrentLanguage(this.props.location)
    body.lang = lang
    body.user_agent = navigator && navigator.userAgent
    Object.keys(formData).forEach((key) => {
      const entry = formData[key]
      if (key === "newsletter" || key === "privacy") {
        body[entry.name] = entry.value ? 1 : 0
      } else {
        body[entry.name] = entry.value
      }
    })

    submitMigrosEntry(body, lang).then((res) => {
      if (res) {
        if (res.result === "error") {
          const errorFields = []
          if (`${res.message}`.toLowerCase().includes("mail")) {
            errorFields.push("emailOfSender")
          }
          if (`${res.message}`.toLowerCase().includes("phone")) {
            errorFields.push("phoneOfSender")
          }
          if (`${res.message}`.toLowerCase().includes("name")) {
            errorFields.push("surnameOfSender")
            errorFields.push("nameOfSender")
          }
          if (`${res.message}`.toLowerCase().includes("birth")) {
            errorFields.push("birthdateOfSender")
          }
          if (`${res.message}`.toLowerCase().includes("city")) {
            errorFields.push("cityOfSender")
          }
          if (`${res.message}`.toLowerCase().includes("country")) {
            errorFields.push("countryOfSender")
          }
          if (`${res.message}`.toLowerCase().includes("zip")) {
            errorFields.push("zipOfSender")
          }
          if (`${res.message}`.toLowerCase().includes("salutation")) {
            errorFields.push("salutationOfSender")
          }
          if (`${res.message}`.toLowerCase().includes("terms")) {
            errorFields.push("termsAndConditions")
          }
          const existingFormData = { ...formData }
          for (const field of errorFields) {
            existingFormData[field].warnRequired = true
          }
          this.setState({
            ...this.state,
            submitDisabled: false,
            fieldsAreValid: false,
            formData: {
              ...existingFormData,
            },
          })
        } else if (res.result === "repeat") {
          this.setState({
            ...this.state,
            isRepeat: true,
            submitDisabled: false,
            fieldsAreValid: false,
            formData: {
              ...this.state.formData,
              emailOfSender: {
                ...this.state.formData.emailOfSender,
                warnRequired: true,
              },
            },
          })
        } else {
          this.setState({
            submitDisabled: false,
            displaySuccess: true,
            showForm: false,
          })
          this.scrollToConfirmationMsg()
        }
      } else {
        this.setState({
          submitDisabled: false,
        })
      }
    })
  }

  confirmEntry = async () => {
    let body = {}
    body.hash = this.props.location.search.split("=")[1]
    this.scrollToConfirmationMsg()

    confirmMigrosEntry(body.hash).then((res) => {
      if (res) {
        if (res.result !== "error") {
          this.setState({
            confirmMessage: true,
            showForm: false,
          })
          this.scrollToConfirmationMsg()
        } else {
          this.setState({
            confirmMessage: false,
            showForm: false,
          })
          this.scrollToConfirmationMsg()
        }
      }
    })
  }

  setSelectVisible = (to, name) => {
    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        [name]: {
          ...this.state.formData[name],
          visible: to,
        },
      },
    })
  }

  onSelectChange = (value, name, refToBlur) => {
    const formValue = value
    this.setState(
      {
        ...this.state,
        formData: {
          ...this.state.formData,
          [name]: {
            ...this.state.formData[name],
            value: formValue,
            visible: false,
            warnRequired: false,
          },
        },
      },
      () => {
        if (refToBlur && refToBlur.current) {
          refToBlur.current.blur()
        }
      }
    )
  }

  onInputChange = (v, name, { numberOnly, sanitizeEmojis, maxLength } = {}) => {
    let value = v
    if (numberOnly) {
      if (isNaN(value)) return
      else value = +value
    }
    if (sanitizeEmojis) {
      value = sanitizeEmojisInText(value)
    }
    if (maxLength) {
      if (value.length > maxLength) return
    }

    this.setState({
      ...this.state,
      displaySuccess: false,
      formData: {
        ...this.state.formData,
        [name]: {
          ...this.state.formData[name],
          value,
          warnRequired: false,
        },
      },
    })
  }

  validateField = (fieldObj) => {
    const fieldValue =
      typeof fieldObj.value === "string"
        ? `${fieldObj.value}`.trim()
        : fieldObj.value
    if (
      (fieldObj.required && !fieldValue) ||
      (fieldObj.name.toLowerCase().includes("email") &&
        !fieldValue.includes("@"))
    ) {
      fieldObj.warnRequired = true
      return false
    }
    if (fieldObj.name === "dob") {
      const [dd, mm, yyyy] = fieldValue.split("/")
      const year = new Date().getFullYear()
      if (
        isNaN(+dd) ||
        isNaN(+mm) ||
        isNaN(+yyyy) ||
        +dd > 31 ||
        +dd < 0 ||
        +mm < 0 ||
        +mm > 12 ||
        +yyyy >= year ||
        +yyyy < 1900
      ) {
        fieldObj.warnRequired = true
        return false
      }
    }
    // if (fieldObj.name === "zip" && !validateSwissPostCode(fieldValue)) {
    //   fieldObj.warnRequired = true
    //   return false
    // }
    return true
  }

  validateFields = () => {
    let formDataCopied = { ...this.state.formData }
    let isValid = true

    Object.keys(formDataCopied).forEach((key) => {
      const nextIsValid = this.validateField(formDataCopied[key])
      if (!nextIsValid && isValid) {
        scrollToRef(formDataCopied[key].ref, {
          behavior: "smooth",
          block: "center",
        })
      }
      isValid = nextIsValid && isValid
    })

    if (!isValid) {
      this.setState({
        formData: formDataCopied,
      })
    }

    return isValid
  }

  // resetRequiredWarning = key => {
  //   this.setState({
  //     formData: {
  //       ...this.state.formData,
  //       [key]: {
  //         ...this.state.formData[key],
  //         warnRequired: false,
  //       },
  //     },
  //   })
  // }

  // the mouse over event bugs the tablet/mobile, so we only want mouseover
  // working when it's laptop or above
  selectMouseEventConstructor = (to, name) => {
    if (typeof window !== "undefined" && window.innerWidth > 1300)
      return this.setSelectVisible(to, name)
    return function () {}
  }

  renderForm = () => {
    const { location } = this.props
    const { formData } = this.state
    const { lang } = getCurrentLanguage(location)

    return (
      <form onSubmit={this.onSubmit} className={s.Form} ref={this.BlurHackRef}>
        <div className={s.Row}>
          <div className={s.Col_2}>
            <div className={`${s.Row} ${s.TwoColumns}`}>
              <div
                className={`${s.FormGroup} ${s.FormIsSelect} ${
                  formData.salutationOfSender.warnRequired ? s.WarnRequired : ""
                }`}
                ref={formData.salutationOfSender.ref}
              >
                <label
                  className={`${
                    formData.salutationOfSender.value.length > 0
                      ? s.LabelVisible
                      : ""
                  } ${
                    formData.salutationOfSender.warnRequired ? s.LabelError : ""
                  }`}
                >
                  {`${t[lang].salutation.title}${
                    formData.salutationOfSender.required ? "*" : ""
                  }`}
                </label>
                <div
                  tabIndex={0}
                  ref={this.salutationOfSenderRef}
                  className={[
                    s.Select,
                    s.Small,
                    formData.salutationOfSender.warnRequired
                      ? s.WarnRequired
                      : null,
                    formData.salutationOfSender.visible
                      ? s.SelectVisible
                      : null,
                  ].join(" ")}
                  onMouseEnter={() =>
                    this.selectMouseEventConstructor(true, "salutationOfSender")
                  }
                  onMouseLeave={() =>
                    this.selectMouseEventConstructor(
                      false,
                      "salutationOfSender"
                    )
                  }
                  onFocus={() =>
                    this.setSelectVisible(true, "salutationOfSender")
                  }
                  onBlur={() =>
                    this.setSelectVisible(false, "salutationOfSender")
                  }
                >
                  <input
                    disabled
                    value={formData.salutationOfSender.value}
                    name={formData.salutationOfSender.name}
                    required={formData.salutationOfSender.required}
                    placeholder={`${t[lang].salutation.placeholder}${
                      formData.salutationOfSender.required ? "*" : ""
                    }`}
                  />
                  <ul>
                    {t[lang].salutation_values.map((sal) => (
                      <li
                        key={sal}
                        className={
                          formData.salutationOfSender.value === sal
                            ? s.Selected
                            : null
                        }
                        onClick={() =>
                          this.onSelectChange(
                            sal,
                            "salutationOfSender",
                            this.salutationOfSenderRef
                          )
                        }
                      >
                        {sal}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className={`${s.Row} ${s.TwoColumns}`}>
              <div
                className={`
                  ${s.FormGroup} ${
                  formData.nameOfSender.warnRequired ? s.WarnRequired : ""
                }
                `}
                ref={formData.nameOfSender.ref}
              >
                <label
                  className={`${
                    formData.nameOfSender.value.length > 0 ? s.LabelVisible : ""
                  } ${formData.nameOfSender.warnRequired ? s.LabelError : ""}`}
                >
                  {`${t[lang].firstname.title}${
                    formData.nameOfSender.required ? "*" : ""
                  }`}
                </label>
                <input
                  className={[
                    s.Medium,
                    formData.nameOfSender.warnRequired ? s.WarnRequired : null,
                  ].join(" ")}
                  placeholder={`${t[lang].firstname.placeholder}${
                    formData.nameOfSender.required ? "*" : ""
                  }`}
                  value={formData.nameOfSender.value}
                  name={formData.nameOfSender.name}
                  onChange={(e) =>
                    this.onInputChange(e.target.value, "nameOfSender")
                  }
                  required={formData.nameOfSender.required}
                />
              </div>
              <div
                className={`
                  ${s.FormGroup} ${
                  formData.surnameOfSender.warnRequired ? s.WarnRequired : ""
                }
                `}
                ref={formData.surnameOfSender.ref}
              >
                <label
                  className={`${
                    formData.surnameOfSender.value.length > 0
                      ? s.LabelVisible
                      : ""
                  } ${
                    formData.surnameOfSender.warnRequired ? s.LabelError : ""
                  }`}
                >
                  {`${t[lang].lastname.title}${
                    formData.surnameOfSender.required ? "*" : ""
                  }`}
                </label>
                <input
                  className={[
                    s.Small,
                    formData.surnameOfSender.warnRequired
                      ? s.WarnRequired
                      : null,
                  ].join(" ")}
                  placeholder={`${t[lang].lastname.placeholder}${
                    formData.surnameOfSender.required ? "*" : ""
                  }`}
                  value={formData.surnameOfSender.value}
                  name={formData.surnameOfSender.name}
                  onChange={(e) =>
                    this.onInputChange(e.target.value, "surnameOfSender")
                  }
                  required={formData.surnameOfSender.required}
                />
              </div>
            </div>
            <div className={`${s.Row} ${s.TwoColumns}`}>
              <div
                className={`
                  ${s.FormGroup} ${
                  formData.emailOfSender.warnRequired ? s.WarnRequired : ""
                }
                `}
                ref={formData.emailOfSender.ref}
              >
                <label
                  className={`${
                    formData.emailOfSender.value.length > 0
                      ? s.LabelVisible
                      : ""
                  } ${formData.emailOfSender.warnRequired ? s.LabelError : ""}`}
                >
                  {`${t[lang].email.title}${
                    formData.emailOfSender.required ? "*" : ""
                  }`}
                </label>
                <input
                  className={[
                    s.Medium,
                    formData.emailOfSender.warnRequired ? s.WarnRequired : null,
                  ].join(" ")}
                  value={formData.emailOfSender.value}
                  placeholder={`${t[lang].email.placeholder}${
                    formData.emailOfSender.required ? "*" : ""
                  }`}
                  name={formData.emailOfSender.name}
                  onChange={(e) =>
                    this.onInputChange(e.target.value, "emailOfSender")
                  }
                  required={formData.emailOfSender.required}
                />
              </div>
              <div
                className={`
                  ${s.FormGroup} ${
                  formData.phoneOfSender.warnRequired ? s.WarnRequired : ""
                }
                `}
                ref={formData.phoneOfSender.ref}
              >
                <label
                  className={`${
                    formData.phoneOfSender.value.length > 0
                      ? s.LabelVisible
                      : ""
                  } ${formData.phoneOfSender.warnRequired ? s.LabelError : ""}`}
                >
                  {`${t[lang].phone.title}${
                    formData.phoneOfSender.required ? "*" : ""
                  }`}
                </label>
                <InputMask
                  className={[
                    s.Medium,
                    formData.phoneOfSender.warnRequired ? s.WarnRequired : null,
                  ].join(" ")}
                  mask={lang === "fr" ? "99 99 99 99 99" : "999 999 99 99"}
                  placeholder={`${t[lang].phone.placeholder}${
                    formData.phoneOfSender.required ? "*" : ""
                  }`}
                  onChange={(e) =>
                    this.onInputChange(e.target.value, "phoneOfSender")
                  }
                  value={formData.phoneOfSender.value}
                  name={formData.phoneOfSender.name}
                  inputMode="tel"
                />
              </div>
            </div>
            <div className={`${s.Row} ${s.TwoColumns}`}>
              <div
                className={`
                ${s.FormGroup} ${
                  formData.addressOfSender.warnRequired ? s.WarnRequired : ""
                }
              `}
                ref={formData.addressOfSender.ref}
              >
                <label
                  className={`${
                    formData.addressOfSender.value.length > 0
                      ? s.LabelVisible
                      : ""
                  } ${
                    formData.addressOfSender.warnRequired ? s.LabelError : ""
                  }`}
                >
                  {`${t[lang].address.title}${
                    formData.addressOfSender.required ? "*" : ""
                  }`}
                </label>
                <input
                  className={[
                    s.Medium,
                    formData.addressOfSender.warnRequired
                      ? s.WarnRequired
                      : null,
                  ].join(" ")}
                  value={formData.addressOfSender.value}
                  placeholder={`${t[lang].address.placeholder}${
                    formData.addressOfSender.required ? "*" : ""
                  }`}
                  name={formData.addressOfSender.name}
                  onChange={(e) =>
                    this.onInputChange(e.target.value, "addressOfSender")
                  }
                  required={formData.addressOfSender.required}
                />
              </div>
              <div className={s.ZipCityContainer}>
                <div
                  className={`
                  ${s.FormGroup} ${
                    formData.zipOfSender.warnRequired ? s.WarnRequired : ""
                  }
                `}
                  style={{ width: "33%" }}
                  ref={formData.zipOfSender.ref}
                >
                  <label
                    className={`${
                      `${
                        formData.zipOfSender.value
                          ? formData.zipOfSender.value
                          : ""
                      }`.length > 0
                        ? s.LabelVisible
                        : ""
                    } ${formData.zipOfSender.warnRequired ? s.LabelError : ""}`}
                  >
                    {`${t[lang].zip.title}${
                      formData.zipOfSender.required ? "*" : ""
                    }`}
                  </label>
                  <input
                    className={[
                      s.Small,
                      formData.zipOfSender.warnRequired ? s.WarnRequired : null,
                    ].join(" ")}
                    value={formData.zipOfSender.value}
                    name={formData.zipOfSender.name}
                    placeholder={`${t[lang].zip.placeholder}${
                      formData.zipOfSender.required ? "*" : ""
                    }`}
                    onChange={(e) =>
                      this.onInputChange(e.target.value, "zipOfSender", {
                        numberOnly: true,
                      })
                    }
                    inputMode="numeric"
                    maxLength={5}
                    required={formData.zipOfSender.required}
                  />
                </div>
                <div
                  className={`
                  ${s.FormGroup} ${
                    formData.cityOfSender.warnRequired ? s.WarnRequired : ""
                  }
                `}
                  style={{ width: "63%" }}
                  ref={formData.cityOfSender.ref}
                >
                  <label
                    className={`${
                      formData.cityOfSender.value.length > 0
                        ? s.LabelVisible
                        : ""
                    } ${
                      formData.cityOfSender.warnRequired ? s.LabelError : ""
                    }`}
                  >
                    {`${t[lang].city.title}${
                      formData.cityOfSender.required ? "*" : ""
                    }`}
                  </label>
                  <input
                    className={[
                      s.Small,
                      formData.cityOfSender.warnRequired
                        ? s.WarnRequired
                        : null,
                    ].join(" ")}
                    value={formData.cityOfSender.value}
                    placeholder={`${t[lang].city.placeholder}${
                      formData.cityOfSender.required ? "*" : ""
                    }`}
                    name={formData.cityOfSender.name}
                    onChange={(e) =>
                      this.onInputChange(e.target.value, "cityOfSender")
                    }
                    required={formData.cityOfSender.required}
                  />
                </div>
              </div>
            </div>
            <div className={`${s.Row} ${s.TwoColumns}`}>
              <div
                className={`${s.FormGroup} ${s.FormIsSelect}  ${
                  formData.countryOfSender.warnRequired ? s.WarnRequired : ""
                }`}
                ref={formData.countryOfSender.ref}
              >
                <label
                  className={`${
                    formData.countryOfSender.value.length > 0
                      ? s.LabelVisible
                      : ""
                  } ${
                    formData.countryOfSender.warnRequired ? s.LabelError : ""
                  }`}
                >
                  {`${t[lang].country.title}${
                    formData.countryOfSender.required ? "*" : ""
                  }`}
                </label>
                <div
                  tabIndex={0}
                  ref={this.countryOfSenderRef}
                  className={[
                    s.Select,
                    s.Small,
                    formData.countryOfSender.warnRequired
                      ? s.WarnRequired
                      : null,
                    formData.countryOfSender.visible ? s.SelectVisible : null,
                  ].join(" ")}
                  onMouseEnter={() =>
                    this.selectMouseEventConstructor(true, "countryOfSender")
                  }
                  onMouseLeave={() =>
                    this.selectMouseEventConstructor(false, "countryOfSender")
                  }
                  onFocus={() => this.setSelectVisible(true, "countryOfSender")}
                  onBlur={() => this.setSelectVisible(false, "countryOfSender")}
                >
                  <input
                    disabled
                    value={
                      t[lang].countries.findIndex(
                        (el) => el[0] === formData.countryOfSender.value
                      ) !== -1
                        ? t[lang].countries.find(
                            (el) => el[0] === formData.countryOfSender.value
                          )[1]
                        : ""
                    }
                    name={formData.countryOfSender.name}
                    required={formData.countryOfSender.required}
                    // placeholder={t[lang].country.placeholder}
                    placeholder={`${t[lang].country.placeholder}${
                      formData.countryOfSender.required ? "*" : ""
                    }`}
                  />
                  <ul>
                    {t[lang].countries.map(([code, name]) => (
                      <li
                        key={code}
                        className={
                          formData.countryOfSender.value === code
                            ? s.Selected
                            : null
                        }
                        onClick={() =>
                          this.onSelectChange(
                            code,
                            "countryOfSender",
                            this.countryOfSenderRef
                          )
                        }
                      >
                        {name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div
                className={`
                  ${s.FormGroup} ${
                  formData.birtdateOfSender.warnRequired ? s.WarnRequired : ""
                }
                `}
                ref={formData.birtdateOfSender.ref}
              >
                <label
                  className={`${s.LabelVisible} ${
                    formData.salutationOfSender.warnRequired ? s.LabelError : ""
                  }`}
                >
                  {`${t[lang].birthdate.title}${
                    formData.birtdateOfSender.required ? "*" : ""
                  }`}
                </label>
                <InputMask
                  className={[
                    s.Medium,
                    formData.birtdateOfSender.warnRequired
                      ? s.WarnRequired
                      : null,
                  ].join(" ")}
                  mask={"99/99/9999"}
                  // placeholder={`${t[lang].phone.placeholder}${
                  //   formData.phoneOfSender.required ? "*" : ""
                  // }`}
                  placeholder={t[lang].birthdate.placeholder}
                  onChange={(e) =>
                    this.onInputChange(e.target.value, "birtdateOfSender")
                  }
                  value={formData.birtdateOfSender.value}
                  name={formData.birtdateOfSender.name}
                  inputMode="numeric"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={[
            s.Row,
            s.RowWithPadding,
            formData.newsletterSignup.warnRequired ? s.WarnRequired : null,
          ].join(" ")}
          ref={formData.newsletterSignup.ref}
        >
          <input
            type="checkbox"
            className={s.Checkbox}
            checked={formData.newsletterSignup.value}
            name={formData.newsletterSignup.name}
            id={formData.newsletterSignup.name}
            onChange={() =>
              this.onInputChange(
                !formData.newsletterSignup.value,
                "newsletterSignup"
              )
            }
            required={formData.newsletterSignup.required}
          />
          <label
            className={s.Label}
            htmlFor={formData.newsletterSignup.name}
            dangerouslySetInnerHTML={{
              __html: t[lang].newsletter.text,
            }}
          ></label>
        </div>
        <div
          className={[
            s.Row,
            s.RowWithPadding,
            formData.termsAndConditions.warnRequired ? s.WarnRequired : null,
          ].join(" ")}
          ref={formData.termsAndConditions.ref}
        >
          <input
            type="checkbox"
            className={s.Checkbox}
            checked={formData.termsAndConditions.value}
            name={formData.termsAndConditions.name}
            id={formData.termsAndConditions.name}
            onChange={() =>
              this.onInputChange(
                !formData.termsAndConditions.value,
                "termsAndConditions"
              )
            }
            required={formData.termsAndConditions.required}
          />
          <label
            className={s.Label}
            htmlFor={formData.termsAndConditions.name}
            dangerouslySetInnerHTML={{
              __html:
                "*" +
                t[lang].terms.text
                  .map((el, i) =>
                    i === 1
                      ? `<a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="${t[lang].terms.link}"
                  >
                    ${el}
                  </a>`
                      : i === 3
                      ? `<a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="${t[lang].terms.link2}"
                >
                  ${el}
                </a>`
                      : el
                  )
                  .join(" "),
            }}
          ></label>
        </div>
        <div
          className={`${s.Row} ${s.FormFooter}`}
          style={{ paddingTop: "20px" }}
        >
          <Button
            style={{
              transform: "skew(0deg, -9deg)",
              padding: "10px 32px",
              fontWeight: "normal",
              fontSize: "18px",
              lineHeight: "20px",
            }}
            type="primary"
            onClick={this.onSubmit}
            btnClassName={s.SubmitButton}
            disabled={this.state.submitDisabled}
          >
            {t[lang].submit}
          </Button>
        </div>
      </form>
    )
  }

  render() {
    const {
      modalIsOpen,
      selectedModalImageName,
      fieldsAreValid,
      isRepeat,
      displaySuccess,
      confirmMessage,
      showForm,
    } = this.state

    const { location } = this.props
    const { lang } = getCurrentLanguage(location)

    const displayConfirmationMsg = displaySuccess
    return (
      <section ref={this.formFieldRef} className={s.ReactFriendForm}>
        <Modal
          onClose={this.onCloseModal}
          visible={modalIsOpen}
          modelWrapperClassName={s.PostcardModal}
        >
          <ImgFluid fileName={selectedModalImageName} />
        </Modal>
        {confirmMessage && (
          <h6 className={`${s.FormMessage} ${s.GoodieBoxSent}`}>
            {t[lang].confirm_message}
          </h6>
        )}
        {showForm && this.renderForm()}
        {displaySuccess && !showForm && (
          <h6 className={`${s.FormMessage} ${s.GoodieBoxSent}`}>
            {t[lang].success_message}
          </h6>
        )}
        {!fieldsAreValid && !displayConfirmationMsg && !isRepeat && (
          <h6 className={`${s.FormMessage} ${s.FormError}`}>
            {t[lang].error_message}
          </h6>
        )}
        {!fieldsAreValid && !displayConfirmationMsg && isRepeat && (
          <h6 className={`${s.FormMessage} ${s.FormError}`}>
            {t[lang].repeat_message}
          </h6>
        )}
      </section>
    )
  }
}

export default MigrosPromoForm
