import React from "react"
import {
  SITE_SCOPES,
  LANGUAGE_ABBREVATIONS,
  winAndHike_routes,
} from "../../constants"
import { routes } from "../Header/LanguagesData"
export { routes, winAndHike_routes }

const mainSiteFooter = {
  [LANGUAGE_ABBREVATIONS.english.combined]: {
    leftLinks: [
      {
        type: `external-link`,
        label: `Kontakt`,
        uri: routes[LANGUAGE_ABBREVATIONS.english.combined].contact,
      },
      {
        type: `external-link`,
        label: `Karriere`,
        uri: routes[LANGUAGE_ABBREVATIONS.english.combined].career,
      },
      {
        type: `external-link`,
        label: `Unternehmen`,
        uri: routes[LANGUAGE_ABBREVATIONS.english.combined].unternehmen,
      },
      {
        type: `external-link`,
        label: `Impressum`,
        uri: routes[LANGUAGE_ABBREVATIONS.english.combined].impressum,
      },
    ],
    midLinks: [
      {
        type: `facebook-icon`,
        uri: routes[LANGUAGE_ABBREVATIONS.english.combined].facebook,
      },
      {
        type: `instagram-icon`,
        uri: routes[LANGUAGE_ABBREVATIONS.english.combined].instagram,
      },
    ],
    rightLinks: [
      {
        type: `language`,
      },
      {
        type: `copyright`,
        content: (
          <>
            <span>All rights reserved </span>&copy; {new Date().getFullYear()}{" "}
            Rivella AG
          </>
        ),
      },
    ],
  },
  [LANGUAGE_ABBREVATIONS.german.combined]: {
    leftLinks: [
      {
        type: `external-link`,
        label: `Kontakt`,
        uri: routes[LANGUAGE_ABBREVATIONS.german.combined].contact,
      },
      {
        type: `external-link`,
        label: `Karriere`,
        uri: routes[LANGUAGE_ABBREVATIONS.german.combined].career,
      },
      {
        type: `external-link`,
        label: `Unternehmen`,
        uri: routes[LANGUAGE_ABBREVATIONS.german.combined].unternehmen,
      },
      {
        type: `external-link`,
        label: `Impressum`,
        uri: routes[LANGUAGE_ABBREVATIONS.german.combined].impressum,
      },
      {
        type: `external-link`,
        label: `Newsletter`,
        uri: routes[LANGUAGE_ABBREVATIONS.german.combined].newsletter,
      },
    ],
    midLinks: [
      {
        type: `facebook-icon`,
        uri: routes[LANGUAGE_ABBREVATIONS.german.combined].facebook,
      },
      {
        type: `instagram-icon`,
        uri: routes[LANGUAGE_ABBREVATIONS.german.combined].instagram,
      },
    ],
    rightLinks: [
      {
        type: `language`,
      },
      {
        type: `copyright`,
        content: (
          <>
            <span>All rights reserved </span>&copy; {new Date().getFullYear()}{" "}
            Rivella AG
          </>
        ),
      },
    ],
  },
  [LANGUAGE_ABBREVATIONS.french.combined]: {
    leftLinks: [
      {
        type: `external-link`,
        label: `Contact`,
        uri: routes[LANGUAGE_ABBREVATIONS.french.combined].contact,
      },
      {
        type: `external-link`,
        label: `Emplois et Carrière`,
        uri: routes[LANGUAGE_ABBREVATIONS.french.combined].career,
      },
      {
        type: `external-link`,
        label: `Entreprise`,
        uri: routes[LANGUAGE_ABBREVATIONS.french.combined].unternehmen,
      },
      {
        type: `external-link`,
        label: `Imprint`,
        uri: routes[LANGUAGE_ABBREVATIONS.french.combined].impressum,
      },
      {
        type: `external-link`,
        label: `Newsletter`,
        uri: routes[LANGUAGE_ABBREVATIONS.french.combined].newsletter,
      },
    ],
    midLinks: [
      {
        type: `facebook-icon`,
        uri: routes[LANGUAGE_ABBREVATIONS.french.combined].facebook,
      },
      {
        type: `instagram-icon`,
        uri: routes[LANGUAGE_ABBREVATIONS.french.combined].instagram,
      },
    ],
    rightLinks: [
      {
        type: `language`,
      },
      {
        type: `copyright`,
        content: (
          <>
            <span>All rights reserved </span>&copy; {new Date().getFullYear()}{" "}
            Rivella AG
          </>
        ),
      },
    ],
  },
  [LANGUAGE_ABBREVATIONS.italian.combined]: {
    leftLinks: [
      {
        type: `external-link`,
        label: `Contatto`,
        uri: routes[LANGUAGE_ABBREVATIONS.italian.combined].contact,
      },
      {
        type: `external-link`,
        label: `Jobs e Carriera`,
        uri: routes[LANGUAGE_ABBREVATIONS.italian.combined].career,
      },
      {
        type: `external-link`,
        label: `Società`,
        uri: routes[LANGUAGE_ABBREVATIONS.italian.combined].unternehmen,
      },
      {
        type: `external-link`,
        label: `Colophon`,
        uri: routes[LANGUAGE_ABBREVATIONS.italian.combined].impressum,
      },
      {
        type: `external-link`,
        label: `Newsletter`,
        uri: routes[LANGUAGE_ABBREVATIONS.italian.combined].newsletter,
      },
    ],
    midLinks: [
      {
        type: `facebook-icon`,
        uri: routes[LANGUAGE_ABBREVATIONS.italian.combined].facebook,
      },
      {
        type: `instagram-icon`,
        uri: routes[LANGUAGE_ABBREVATIONS.italian.combined].instagram,
      },
    ],
    rightLinks: [
      {
        type: `language`,
      },
      {
        type: `copyright`,
        content: (
          <>
            <span>All rights reserved </span>&copy; {new Date().getFullYear()}{" "}
            Rivella AG
          </>
        ),
      },
    ],
  },
  [LANGUAGE_ABBREVATIONS.france.combined]: {
    leftLinks: [
      {
        type: `external-link`,
        label: `Contact`,
        uri: routes[LANGUAGE_ABBREVATIONS.france.combined].contact,
      },
      {
        type: `external-link`,
        label: `Emplois et Carrière`,
        uri: routes[LANGUAGE_ABBREVATIONS.france.combined].career,
      },
      {
        type: `external-link`,
        label: `Entreprise`,
        uri: routes[LANGUAGE_ABBREVATIONS.france.combined].unternehmen,
      },
      {
        type: `external-link`,
        label: `Imprint`,
        uri: routes[LANGUAGE_ABBREVATIONS.france.combined].impressum,
      },
    ],
    midLinks: [
      {
        type: `facebook-icon`,
        uri: routes[LANGUAGE_ABBREVATIONS.france.combined].facebook,
      },
      {
        type: `instagram-icon`,
        uri: routes[LANGUAGE_ABBREVATIONS.france.combined].instagram,
      },
    ],
    rightLinks: [
      {
        type: `language`,
      },
      {
        type: `copyright`,
        content: (
          <>
            <span>All rights reserved </span>&copy; {new Date().getFullYear()}{" "}
            Rivella AG
          </>
        ),
      },
    ],
  },
  [LANGUAGE_ABBREVATIONS.luxembourg.combined]: {
    leftLinks: [
      {
        type: `external-link`,
        label: `Contact`,
        uri: routes[LANGUAGE_ABBREVATIONS.luxembourg.combined].contact,
      },
      {
        type: `external-link`,
        label: `Emplois Et Carrière`,
        uri: routes[LANGUAGE_ABBREVATIONS.luxembourg.combined].career,
      },
      {
        type: `external-link`,
        label: `Entreprise`,
        uri: routes[LANGUAGE_ABBREVATIONS.luxembourg.combined].unternehmen,
      },
      {
        type: `external-link`,
        label: `Imprint`,
        uri: routes[LANGUAGE_ABBREVATIONS.luxembourg.combined].impressum,
      },
    ],
    midLinks: [
      {
        type: `facebook-icon`,
        uri: routes[LANGUAGE_ABBREVATIONS.luxembourg.combined].facebook,
      },
      {
        type: `instagram-icon`,
        uri: routes[LANGUAGE_ABBREVATIONS.luxembourg.combined].instagram,
      },
    ],
    rightLinks: [
      {
        type: `language`,
      },
      {
        type: `copyright`,
        content: (
          <>
            <span>All rights reserved </span>&copy; {new Date().getFullYear()}{" "}
            Rivella AG
          </>
        ),
      },
    ],
  },
}

const winAndHikeFooter = {
  [LANGUAGE_ABBREVATIONS.german.combined]: {
    leftLinks: [
      {
        type: `black-white-logo`,
      },
      {
        type: `external-link`,
        label: `Kontakt`,
        uri: routes[LANGUAGE_ABBREVATIONS.german.combined].contact,
      },
      {
        type: `internal-link`,
        label: `Teilnahmebedingungen`,
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.german.combined]
          .teilnahmebedingungen,
        commonIdentifier: "hikingTerms",
      },
      {
        type: `external-link`,
        label: `Datenschutz`,
        uri: routes[LANGUAGE_ABBREVATIONS.german.combined].unternehmen,
      },
      {
        type: `external-link`,
        label: `Nutzungsbedingungen`,
        uri: routes[LANGUAGE_ABBREVATIONS.german.combined].impressum,
      },
    ],
    midLinks: [],
    rightLinks: [
      {
        type: `facebook-icon`,
        uri: routes[LANGUAGE_ABBREVATIONS.german.combined].facebook,
      },
      {
        type: `instagram-icon`,
        uri: routes[LANGUAGE_ABBREVATIONS.german.combined].instagram,
      },
      {
        type: `twitter-icon`,
        uri: `/`,
      },
      {
        type: `youtube-icon`,
        uri: `/`,
      },
    ],
  },
  [LANGUAGE_ABBREVATIONS.italian.combined]: {
    leftLinks: [
      {
        type: `black-white-logo`,
      },
      {
        type: `external-link`,
        label: `Contatto`,
        uri: routes[LANGUAGE_ABBREVATIONS.italian.combined].contact,
      },
      {
        type: `internal-link`,
        label: `Condizioni di partecipazione`,
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.italian.combined]
          .teilnahmebedingungen,
        commonIdentifier: "hikingTerms",
      },
      {
        type: `external-link`,
        label: `Protezione dei dati`,
        uri: routes[LANGUAGE_ABBREVATIONS.italian.combined].unternehmen,
      },
      {
        type: `external-link`,
        label: `Condizioni di utilizzo`,
        uri: routes[LANGUAGE_ABBREVATIONS.italian.combined].impressum,
      },
    ],
    midLinks: [],
    rightLinks: [
      {
        type: `facebook-icon`,
        uri: routes[LANGUAGE_ABBREVATIONS.italian.combined].facebook,
      },
      {
        type: `instagram-icon`,
        uri: routes[LANGUAGE_ABBREVATIONS.italian.combined].instagram,
      },
      {
        type: `twitter-icon`,
        uri: `/`,
      },
      {
        type: `youtube-icon`,
        uri: `/`,
      },
    ],
  },
  [LANGUAGE_ABBREVATIONS.french.combined]: {
    leftLinks: [
      {
        type: `black-white-logo`,
      },
      {
        type: `external-link`,
        label: `Contact`,
        uri: routes[LANGUAGE_ABBREVATIONS.french.combined].contact,
      },
      {
        type: `internal-link`,
        label: `Conditions de participation`,
        uri: winAndHike_routes[LANGUAGE_ABBREVATIONS.french.combined]
          .teilnahmebedingungen,
        commonIdentifier: "hikingTerms",
      },
      {
        type: `external-link`,
        label: `Protection des données`,
        uri: routes[LANGUAGE_ABBREVATIONS.french.combined].unternehmen,
      },
      {
        type: `external-link`,
        label: `Conditions d'utilisation`,
        uri: routes[LANGUAGE_ABBREVATIONS.french.combined].impressum,
      },
    ],
    midLinks: [],
    rightLinks: [
      {
        type: `facebook-icon`,
        uri: routes[LANGUAGE_ABBREVATIONS.french.combined].facebook,
      },
      {
        type: `instagram-icon`,
        uri: routes[LANGUAGE_ABBREVATIONS.french.combined].instagram,
      },
      {
        type: `twitter-icon`,
        uri: `/`,
      },
      {
        type: `youtube-icon`,
        uri: `/`,
      },
    ],
  },
}

export default {
  [SITE_SCOPES.main]: mainSiteFooter,
  [SITE_SCOPES.winAndHike]: winAndHikeFooter,
}
