import React from "react"
import "../../moment_config"
import moment from "moment"

import { Header, Footer } from "../"
import { Helmet, Modal } from "../../components"
import s from "./Layout.module.scss"
import "../../styles/index.scss"
import {
  CustomTransitionProvider,
  CustomTransitionConsumer,
  HikingAuthProvider,
} from "../../store"
import {
  ROUTING_ANIMATION_FADEIN_LENGTH,
  ROUTING_ANIMATION_FADEOUT_LENGTH,
  POP_UP_TEXT,
  supported_browsers_list,
} from "../../constants"
import { getCurrentLanguage, getCookie } from "../../helper"
import Logo from "../../assets/svgs/logo.svg"
import { winAndHike_routes } from "../Footer/LanguagesData"
import { Redirect } from "@reach/router"
import { CookieBanner } from "../CookieBanner"

/*
 * POLYFILL FOR INTERNET EXPLORER 9 - 11
 * SEE https://stackoverflow.com/questions/52268886/object-doesnt-support-property-or-method-foreach-ie-11
 * THE BottleSlick component crashes without this due to iteration over `divs`
 */

if (typeof NodeList !== "undefined") {
  if (typeof NodeList.prototype.forEach !== "function") {
    NodeList.prototype.forEach = Array.prototype.forEach
  }
}

const ENVIRONMENT = {
  building: -1,
  local_develop: 0,
  staging: 1,
  production: 2,
}

const homePageDescription = {
  de: "Wo immer dein Durst daheim ist",
  it: "Ovunque la tua sete è di casa.",
  fr: "Là où est ta soif",
  "fr-fr": "Ca Rafraisuisse",
  "lu-fr": "Ca Rafraisuisse",
}

class Layout extends React.Component {
  state = {
    iePopUpModal: false,
  }

  componentDidMount() {
    if (getCookie("ie_popup")) {
      this.setState({ iePopUpModal: true })
    }
    // remove google analytics param from url
    const params = new URLSearchParams(document.location.search)
    const ga = params.get("_ga")
    if (ga) {
      params.delete("_ga")
      const newP = params.toString()
      window.history.replaceState(
        null,
        document.title,
        `${document.location.origin}${document.location.pathname}${
          newP ? `?${newP}` : ""
        }`
      )
    }
  }

  constructor(props) {
    super(props)
    const currentLang = getCurrentLanguage(props.location).lang
    moment.locale(currentLang)
  }

  deleteIEPopUpCookie = () => {
    document.cookie = "ie_popup=; expires=Thu, 01 Jan 1970 00:00:00 UTC"
    this.setState({ iePopUpModal: false })
  }

  render() {
    const {
      location,
      languageOptions,
      siteScope,
      pageDescription,
      renderModal = false,
    } = this.props
    const { pageName, allProducts, allEvents, allPages } =
      this.props.pageContext || this.props
    let currentEnvironment = ENVIRONMENT.production
    let { origin, pathname } = this.props.location

    if (!origin) currentEnvironment = ENVIRONMENT.building
    else if (origin.includes("localhost"))
      currentEnvironment = ENVIRONMENT.local_develop
    else if (
      origin.includes("blueglass") ||
      origin.includes("dev.rivella") ||
      origin.includes("madebycolorelephant")
    )
      currentEnvironment = ENVIRONMENT.staging

    const currentLang = getCurrentLanguage(location)
    const currentPageLang =
      currentLang.lang + "-" + currentLang.loc.toUpperCase()

    let description = pageDescription
    const regex = new RegExp(`${currentLang.slug}/?$`)
    if (pathname.match(regex)) {
      description = homePageDescription[currentLang.slug]
    }

    const { langHref } = currentLang

    // Redirect user to account page if they aren't logged in and trying to access the account page
    if (
      !getCookie("user_hash") &&
      winAndHike_routes[langHref] &&
      location.pathname.includes(winAndHike_routes[langHref].account)
    ) {
      return (
        <Redirect
          to={`/${langHref}${winAndHike_routes[langHref].gewinnspiel}`}
          noThrow
        />
      )
    }

    // Redirect user to account page if they are logged in and trying to access the login page
    if (
      getCookie("user_hash") &&
      winAndHike_routes[langHref] &&
      location.pathname.includes(winAndHike_routes[langHref].login)
    ) {
      return (
        <Redirect
          to={`/${langHref}${winAndHike_routes[langHref].account}`}
          noThrow
        />
      )
    }

    const { iePopUpModal } = this.state

    return (
      <HikingAuthProvider>
        <CustomTransitionProvider>
          <CustomTransitionConsumer>
            {({ state }) => {
              let TransitionBackdropClasses = [s.Backdrop]
              let TransitionLogoClasses = [s.Logo]
              let animationDuration
              if (state.isRouting) {
                TransitionLogoClasses.push(s.LogoFadeIn)
                TransitionBackdropClasses.push(s.BackdropFadeIn)
                animationDuration = ROUTING_ANIMATION_FADEIN_LENGTH
              } else if (
                location &&
                location.state &&
                location.state.customTransition
              ) {
                TransitionLogoClasses.push(s.LogoFadeOut)
                TransitionBackdropClasses.push(s.BackdropFadeOut)
                animationDuration = ROUTING_ANIMATION_FADEOUT_LENGTH
              }
              return (
                <>
                  <div
                    style={{
                      animationDuration: animationDuration + "ms",
                    }}
                    className={TransitionBackdropClasses.join(" ")}
                  />
                  <div
                    style={{
                      animationDuration: animationDuration + "ms",
                    }}
                    className={TransitionLogoClasses.join(" ")}
                  >
                    <Logo />
                  </div>
                </>
              )
            }}
          </CustomTransitionConsumer>
          <div
            className={s.Layout}
            itemType="http://schema.org/Organization"
            itemScope
          >
            {pageName && (
              <Helmet
                title={pageName}
                location={location}
                siteScope={siteScope}
                environmentConstant={ENVIRONMENT}
                currentEnvironment={currentEnvironment}
                pageLang={currentPageLang}
                pageDescription={description}
              />
            )}
            <Header
              location={location}
              siteScope={siteScope}
              isTransparent={this.props.headerIsTransparent}
              languageOptions={languageOptions}
              allProducts={allProducts}
              allEvents={allEvents}
              allPages={allPages}
            />
            <main
              className={[s.Main, this.props.mainClassName].join(" ")}
              itemType={this.props.itemType ? this.props.itemType : null}
              itemScope={this.props.itemScope ? true : null}
              style={{ backgroundColor: this.props.backgroundColor }}
            >
              <>
                {renderModal && (
                  <>
                    {POP_UP_TEXT[langHref] && (
                      <Modal
                        onClose={this.deleteIEPopUpCookie}
                        className={s.Modal}
                        visible={iePopUpModal}
                      >
                        <div className={s.ModalContent}>
                          <span>{POP_UP_TEXT[langHref].text}</span>
                          <ul className={s.SupportedBrowsersList}>
                            {supported_browsers_list.map((browser, index) => (
                              <li key={index}>- {browser}</li>
                            ))}
                          </ul>
                          <span>{POP_UP_TEXT[langHref].suffix}</span>
                        </div>
                      </Modal>
                    )}
                  </>
                )}
                {this.props.children}
              </>
            </main>
            <CookieBanner currentLang={currentLang} />
            <Footer
              languageOptions={languageOptions}
              location={location}
              siteScope={siteScope}
            />
            {/* {
              location?.pathname?.endsWith('/newsletter')?null:(
                  <NewsletterPopup lang={langHref}/>
              )
            } */}
          </div>
        </CustomTransitionProvider>
      </HikingAuthProvider>
    )
  }
}

export default Layout
