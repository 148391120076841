import React from "react"
import { Link } from "gatsby"

import s from "./Search.module.scss"
import SearchIcon from "../../assets/svgs/search.svg"
import CloseIcon from "../../assets/svgs/close-icon.svg"
import { ImgFluid } from "../"
import { LANGUAGE_ABBREVATIONS } from "../../constants"
import {
  extractImageNameFromUrl,
  calculateProductLinkLangPrefix,
  getCurrentLanguage,
} from "../../helper"

const SearchTranslations = {
  [LANGUAGE_ABBREVATIONS.german.lang]: "Suche",
  [LANGUAGE_ABBREVATIONS.french.lang]: "Recherchez",
  [LANGUAGE_ABBREVATIONS.italian.lang]: "Cercare",
}
const NoResultsFoundTranslations = {
  [LANGUAGE_ABBREVATIONS.german.lang]:
    "Leider konnten wir kein Ergebnis für Sie finden.",
  [LANGUAGE_ABBREVATIONS.french.lang]:
    "Nous n’avons malheureusement trouvé aucun résultat.",
  [LANGUAGE_ABBREVATIONS.italian.lang]:
    "Purtroppo non è stato possibile trovare alcun risultato.",
}

class Search extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visibilityTouched: false,
      searchInputVisible: false,
      searchInputValue: "",
      foundResults: [],
    }
  }

  searchChildren = (data, parentIndex, searchValue) => {
    const typeOfData = typeof data

    if (data === null) return null
    else if (Array.isArray(data)) {
      return data.flatMap((D) =>
        this.searchChildren(D, parentIndex, searchValue)
      )
    } else if (typeOfData === "object") {
      return Object.keys(data).flatMap((key) => {
        // if exists and the prop is not some "type" value
        if (key !== "type") {
          return this.searchChildren(data[key], parentIndex, searchValue)
        } else {
          return undefined
        }
      })
    } else if (
      typeOfData !== "boolean" &&
      typeOfData !== "function" &&
      typeOfData !== "undefined"
    ) {
      const compareValue = data.toString().toLowerCase()
      const indexOf = compareValue.indexOf(searchValue)
      if (indexOf !== -1 && !compareValue.startsWith("http")) {
        return {
          foundIndex: parentIndex,
          foundPhrase: data,
        }
      }
    }
  }

  searchProductsAndEvents = (value) => {
    const { allProducts = [], allEvents = [], allPages = [] } = this.props
    let shownProductIndexes = []
    let shownEventIndexes = []
    let shownPageIndexes = []
    let dataToShow = []
    if (allProducts) {
      const foundProductsData = allProducts
        .flatMap((P, i) =>
          // filter undefined unfound results on top
          this.searchChildren(P.childrenData, i, value)
        )
        .filter((d) => !!d)
      foundProductsData.forEach((D) => {
        if (!shownProductIndexes.includes(D.foundIndex)) {
          let objToCast = { phrase: D.foundPhrase }
          shownProductIndexes.push(D.foundIndex)
          const { childrenData: product, path } = allProducts[D.foundIndex]
          const product_main = product.find(
            (p) => p.type === "rivella/product-overview"
          )
          objToCast.route = path
          if (product_main) {
            objToCast.image = product_main.props.image
            objToCast.title = `${product_main.props.title_one_text} - ${product_main.props.title_two_text}`
          }
          dataToShow.push(objToCast)
        }
      })
    }
    if (allEvents) {
      const foundEventsData = allEvents
        .flatMap((P, i) =>
          // filter undefined unfound results on top
          this.searchChildren(P.childrenData, i, value)
        )
        .filter((d) => !!d)
      foundEventsData.forEach((D) => {
        if (!shownEventIndexes.includes(D.foundIndex)) {
          let objToCast = { phrase: D.foundPhrase }
          shownEventIndexes.push(D.foundIndex)
          const { childrenData: event, path } = allEvents[D.foundIndex]
          const event_main = event.find(
            (p) => p.type === "rivella/single-event-hero"
          )
          objToCast.route = path
          if (event_main) {
            objToCast.image = event_main.props.background_img
            objToCast.title = `${event_main.props.title_one_text}`
          }
          dataToShow.push(objToCast)
        }
      })
    }
    const pageDataReader = {
      "rivella/hero-with-text": {
        title_key: "title_one_text",
        image_key: null,
      },
      "rivella/general-promotion-hero": {
        title_key: "title_one_text",
        image_key: "background_img",
      },
      "rivella/events-page-hero": {
        title_key: "title_one_text",
        image_key: null,
      },
      "rivella/home-hero": {
        title_key: "title_one_text",
        image_key: "background_img",
      },
      "rivella/about-us-hero": {
        title_key: "title_one_text",
        image_key: "background_img",
      },
    }
    if (allPages) {
      const foundPagesData = allPages
        .flatMap((P, i) =>
          // filter undefined unfound results on top
          this.searchChildren(P.childrenData, i, value)
        )
        .filter((d) => !!d)
      foundPagesData.forEach((D) => {
        if (!shownPageIndexes.includes(D.foundIndex)) {
          let objToCast = { phrase: D.foundPhrase }
          shownPageIndexes.push(D.foundIndex)
          const { childrenData: page, path } = allPages[D.foundIndex]
          objToCast.route = path
          Object.keys(pageDataReader).forEach((key) => {
            if (key === page[0].type) {
              const reader = pageDataReader[key]
              if (reader.title_key) {
                objToCast.title = page[0].props[reader.title_key]
              }
              if (reader.image_key) {
                objToCast.image = page[0].props[reader.image_key]
              }
            }
          })
          dataToShow.push(objToCast)
        }
      })
    }
    return dataToShow
  }

  setInputVisible = () => {
    this.setState({
      searchInputVisible: true,
      visibilityTouched: true,
      searchInputValue: "",
      foundResults: [],
    })
  }

  setInputHidden = () => {
    this.setState({
      searchInputVisible: false,
      visibilityTouched: true,
      searchInputValue: "",
      foundResults: [],
    })
  }

  toggleInputVisible = () => {
    this.setState({
      searchInputVisible: !this.state.searchInputVisible,
      visibilityTouched: true,
      searchInputValue: "",
      foundResults: [],
    })
  }

  searchSite = (e) => {
    const { value } = e.target
    let stateToCast = { searchInputValue: value }
    if (value) {
      if (value.length > 1) {
        const foundProducts = this.searchProductsAndEvents(value.toLowerCase())
        stateToCast.foundResults = foundProducts
      }
    } else {
      stateToCast.foundResults = []
    }
    this.setState({
      ...stateToCast,
    })
  }

  render() {
    const {
      searchInputVisible,
      visibilityTouched,
      foundResults,
      searchInputValue,
    } = this.state
    let inputClasses = [s.SearchInput]
    let searchIconClasses = [s.SearchIcon]
    if (searchInputVisible) {
      inputClasses.push(s.SearchInputVisible)
      searchIconClasses.push(s.SearchIconVisible)
    } else if (visibilityTouched) {
      inputClasses.push(s.SearchInputExit)
    }
    const currentLang = getCurrentLanguage(this.props.location)
    return (
      <div className={s.SearchWrapper}>
        {searchInputVisible && (
          <div
            className={s.CloseHiddenBackdrop}
            onClick={this.setInputHidden}
          ></div>
        )}
        <SearchIcon
          className={searchIconClasses.join(" ")}
          onClick={this.toggleInputVisible}
        />
        {/* <span>Search</span> */}
        <div className={s.SearchFieldWrapper}>
          <div className={s.InputWrapper}>
            <input
              className={inputClasses.join(" ")}
              onChange={this.searchSite}
              value={searchInputValue}
              placeholder={SearchTranslations[currentLang.lang]}
            />
            {searchInputValue ? (
              <CloseIcon
                className={s.CloseIcon}
                onClick={this.setInputHidden}
              />
            ) : null}
          </div>
          {searchInputVisible && foundResults && foundResults.length ? (
            <ul className={s.FoundResults}>
              {foundResults.map((R) => {
                const matchRegex = new RegExp(searchInputValue, "gmi")
                const foundExact = matchRegex.exec(R.phrase)
                const phraseWithHighlight = R.phrase.replace(
                  matchRegex,
                  `<span class="${s.Highlight}">${
                    foundExact ? foundExact[0] : ""
                  }</span>`
                )
                const route = calculateProductLinkLangPrefix(
                  R.route,
                  currentLang.slug
                )
                return (
                  <li key={R.title}>
                    <Link to={route} className={s.FoundResult}>
                      <div className={s.LeftCol}>
                        <p
                          className={s.FoundResult__Title}
                          dangerouslySetInnerHTML={{ __html: R.title }}
                        />
                        <p
                          className={s.FoundResult__MatchPhrase}
                          dangerouslySetInnerHTML={{
                            __html: phraseWithHighlight,
                          }}
                        ></p>
                      </div>
                      <div className={s.FoundResult__Image}>
                        <ImgFluid fileName={extractImageNameFromUrl(R.image)} />
                      </div>
                    </Link>
                  </li>
                )
              })}
            </ul>
          ) : searchInputValue.length > 1 ? (
            <ul className={s.FoundResults}>
              <li>
                <div className={s.FoundResult} style={{ cursor: "default" }}>
                  <div className={s.LeftCol}>
                    {NoResultsFoundTranslations[currentLang.lang]}
                    {/* <p
                      className={s.FoundResult__Title}
                      dangerouslySetInnerHTML={{ __html: R.title }}
                    />
                    <p
                      className={s.FoundResult__MatchPhrase}
                      dangerouslySetInnerHTML={{
                        __html: phraseWithHighlight,
                      }}
                    ></p> */}
                  </div>
                  <div className={s.FoundResult__Image}>
                    {/* <ImgFluid fileName={extractImageNameFromUrl(R.image)} /> */}
                  </div>
                </div>
              </li>
            </ul>
          ) : null}
        </div>
      </div>
    )
  }
}

export default Search
