import React from "react"
import ImgFluid from "../Img/ImgFluid"
import s from "./SkewedHero.module.scss"

class SkewedHero extends React.Component {
  render() {
    const {
      imgFilename,
      imgAlt,
      children,
      imgLink,
      cn = "",
      itemProp,
      videoFilename,
    } = this.props
    return (
      <section className={`${s.skewed_hero} ${cn}`}>
        <div className={s.skewed_hero_inner}>
          {videoFilename ? (
            <video
              src={videoFilename}
              className={s.Image}
              muted
              autoPlay
              loop
              disablePictureInPicture
            />
          ) : imgFilename ? (
            <ImgFluid
              cn={s.Image}
              loading="eager"
              fadeIn={false}
              fileName={imgFilename}
              alt={imgAlt}
              itemProp={itemProp}
            />
          ) : null}
          {imgLink && (
            <img
              className={s.Image}
              src={imgLink}
              alt={imgAlt}
              itemProp={itemProp}
            />
          )}
        </div>
        <div className={s.WhiteCorner}></div>
        {children}
      </section>
    )
  }
}

export default SkewedHero
