import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

import ArrowRight from "../../assets/svgs/arrow-white.svg"
import { EngagementCard, ImgFluid } from "../../components"
import s from "./AboutUsEngagement.module.scss"
import { extractImageNameFromUrl } from "../../helper"

class AboutUsEngagement extends React.Component {
  constructor(props) {
    super(props)
    this.slickRef = React.createRef()
    this.sectionRef = React.createRef()
  }
  componentDidMount() {
    const { location } = this.props
    if (
      location.hash &&
      location.hash.includes("engagement") &&
      this.sectionRef.current
    ) {
      this.sectionRef.current.scrollIntoView()
    }
  }
  render() {
    const {
      slides,
      title_one_text,
      title_one_color,
      section_height_mode,
      title_two_color,
      title_two_text,
    } = this.props
    return (
      <section
        className={s.AboutUsEngagement}
        ref={this.sectionRef}
        style={{ zIndex: this.props.sectionZindex }}
        id="engagement"
      >
        <div className={s.EventsTitles}>
          <h3 className={[s.EventsSubTitle, title_one_color].join(" ")}>
            {title_one_text}
          </h3>
          <h2 className={[s.EventsTitle, title_two_color].join(" ")}>
            {title_two_text}
          </h2>
        </div>
        <div className={s.SliderWrapper}>
          <Slider
            dots
            ref={this.slickRef}
            infinite
            speed={1000}
            slidesToShow={3}
            centerMode
            focusOnSelect
            slidesToScroll={1}
            useTransform={false}
            dotsClass={["slick-dots", s.Dots].join(" ")}
            pauseOnHover={false}
            responsive={[
              {
                breakpoint: 1800,
                settings: {
                  slidesToShow: 3,
                  centerMode: true,
                },
              },
              {
                breakpoint: 1400,
                settings: {
                  slidesToShow: 2.35,
                  centerMode: true,
                },
              },
              {
                breakpoint: 1000,
                settings: {
                  slidesToShow: 2,
                  centerMode: false,
                },
              },
              {
                breakpoint: 700,
                settings: {
                  slidesToShow: 1,
                  centerMode: false,
                },
              },
            ]}
          >
            {slides.map(
              (
                {
                  title,
                  description,
                  image,
                  logos,
                  links,
                  videoLink,
                  cornerIcon,
                },
                index
              ) => {
                const displayLogos = logos && logos.length > 0
                const displayLinks = links && links.length > 0
                return (
                  <div className={s.SliderSpacer} key={index}>
                    <div className={s.SliderItemWrapper}>
                      <EngagementCard
                        title={title}
                        description={description}
                        imgFile={extractImageNameFromUrl(image)}
                        videoLink={videoLink}
                        onOpenModal={this.props.onOpenModal}
                        cornerIcon={extractImageNameFromUrl(cornerIcon)}
                        isSizeAuto={
                          section_height_mode && section_height_mode === "auto"
                        }
                      >
                        {displayLogos && (
                          <div className={s.card_img_wrapper} key={index}>
                            {logos.map((logo, index) => (
                              <div
                                className={
                                  logos.length > 4
                                    ? [
                                        s.logo_wrapper,
                                        s.logo_wrapper_small,
                                      ].join(" ")
                                    : s.logo_wrapper
                                }
                                key={index}
                              >
                                <ImgFluid
                                  fileName={extractImageNameFromUrl(logo)}
                                  imgStyle={{ objectFit: "contain" }}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                        {displayLinks && (
                          <div className={s.card_links} key={index}>
                            {links.map(({ text, href }, index) =>
                              text ? (
                                <a
                                  key={index}
                                  href={href}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  className={s.card_link}
                                >
                                  <span className={s.link_wrapper}>{text}</span>
                                  <ArrowRight />
                                </a>
                              ) : null
                            )}
                          </div>
                        )}
                      </EngagementCard>
                    </div>
                  </div>
                )
              }
            )}
          </Slider>
        </div>
      </section>
    )
  }
}

export default AboutUsEngagement
