import React from "react"

import s from "./HikingMessageBox.module.scss"

class HikingMessageBox extends React.Component {
  render() {
    const { title_one_text, description_one_text } = this.props
    return (
      <section className={s.message_box}>
        <h2 className={s.message}>
          <span dangerouslySetInnerHTML={{ __html: title_one_text }}></span>
        </h2>
        <p
          className={s.message_description}
          dangerouslySetInnerHTML={{ __html: description_one_text }}
        ></p>
      </section>
    )
  }
}

export default HikingMessageBox
