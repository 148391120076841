import React from "react"
import s from "./FAQAccordion.module.scss"
import { PromotionSectionWrapper } from "../"

class FAQAccordion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openIndex: null,
    }
  }

  onFaqClick = (index) => {
    if (this.state.openIndex === index) {
      this.setState({
        openIndex: null,
      })
    } else {
      this.setState({
        openIndex: index,
      })
    }
  }

  render() {
    const { section_title, faq_list, itemProp } = this.props

    return (
      <PromotionSectionWrapper
        itemProp={itemProp}
        className={s.faq_accordion_container}
      >
        <h2 className={s.section_title}>{section_title}</h2>
        <div className={s.faq_accordion}>
          {faq_list.map(({ question, answer }, index) => (
            <div
              key={index}
              onClick={() => this.onFaqClick(index)}
              className={s.faq_wrapper}
            >
              <h4 className={s.faq_question_text}>{question}</h4>
              <p
                className={`${s.faq_answer_text} ${
                  this.state.openIndex === index ? s.accordion_expanded : ""
                }`}
              >
                {answer}
              </p>
            </div>
          ))}
        </div>
      </PromotionSectionWrapper>
    )
  }
}

export default FAQAccordion
