import React from "react"

import s from "./Modal.module.scss"
import CloseIcon from "../../assets/svgs/close-icon.svg"

class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      previouslyOpened: false,
    }
  }

  onCloseHandler = () => {
    const { onClose } = this.props
    this.setState({
      previouslyOpened: true,
    })
    return onClose ? onClose() : function () {}
  }

  render() {
    const { previouslyOpened } = this.state
    const {
      children,
      className = "",
      modelWrapperClassName = "",
      visible,
    } = this.props

    const contentClasses = [className, s.Content].join(" ")

    let BackdropClasses = [s.Backdrop],
      ModalClasses = [s.Modal],
      ModalWrapperClasses = [s.ModalWrapper, modelWrapperClassName]

    if (visible) {
      ModalClasses.push(s.ModalVisible)
      ModalWrapperClasses.push(s.ModalWrapperVisible)
    } else if (previouslyOpened) {
      ModalClasses.push(s.ModalExiting)
      ModalWrapperClasses.push(s.ModalWrapperExiting)
    }

    return (
      <div className={ModalWrapperClasses.join(" ")}>
        <div
          className={BackdropClasses.join(" ")}
          onClick={this.onCloseHandler}
        ></div>
        <div className={ModalClasses.join(" ")}>
          <div className={contentClasses}>
            <CloseIcon className={s.CloseIcon} onClick={this.onCloseHandler} />
            {children}
          </div>
        </div>
      </div>
    )
  }
}

export default Modal
