import React from "react"
import s from "./PartnerGalleryGrid.module.scss"
import { extractImageNameFromUrl } from "../../../helper"
import { PromotionSectionWrapper } from "../"
import { ImgFluid } from "../../../components"

class PartnerGalleryGrid extends React.Component {
  render() {
    const { restaurant_details = [] } = this.props
    const isValidRestaurantDetails =
      Array.isArray(restaurant_details) && restaurant_details.length > 0

    return (
      <PromotionSectionWrapper className={s.gallery_grid_section}>
        {isValidRestaurantDetails
          ? restaurant_details.map(
              ({
                title,
                img,
                description,
                button_is_visible,
                url,
                button_text,
              }) => {
                return (
                  <div className={s.partner_gallery_grid} key={img}>
                    {img && (
                      <div className={s.img_section}>
                        <div className={s.partner_img}>
                          <ImgFluid fileName={extractImageNameFromUrl(img)} />
                        </div>
                      </div>
                    )}
                    <div className={s.description_section}>
                      <div className={s.partner_description}>
                        <div className={s.description_wrapper}>
                          <div className={s.curved_dash_wrapper}>
                            <ImgFluid
                              cn={s.curved_dash_image}
                              fileName="curved_dash.png"
                            />
                          </div>
                          <div className={s.title_wrapper}>
                            <ImgFluid fileName="map_pin_black.png" />
                            <h2
                              className={s.title_text}
                              dangerouslySetInnerHTML={{ __html: title }}
                            ></h2>
                          </div>
                          <p
                            className={s.description_text}
                            dangerouslySetInnerHTML={{ __html: description }}
                          ></p>
                        </div>
                        {url && button_is_visible && (
                          <a
                            className={s.restaurant_cta}
                            target="_blank"
                            rel="noopener noreferrer"
                            type="link"
                            href={url}
                            dangerouslySetInnerHTML={{ __html: button_text }}
                          ></a>
                        )}
                      </div>
                    </div>
                  </div>
                )
              }
            )
          : null}
      </PromotionSectionWrapper>
    )
  }
}

export default PartnerGalleryGrid
