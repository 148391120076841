import React from "react"
import { Link } from "gatsby"
import FooterCurve1 from "../../assets/svgs/footer_curve_1.svg"
import FooterCurve2 from "../../assets/svgs/footer_curve_2.svg"

import { LanguagePicker, WinAndHikeFooter, ImgFluid } from "../../components"
import s from "./Footer.module.scss"
import FacebookIcon from "../../assets/svgs/Facebook.svg"
import InstagramIcon from "../../assets/svgs/Instagram.svg"
import { SITE_SCOPES } from "../../constants"
import {
  getCurrentLanguage,
  getUrlParameter,
  setCommonIdentifier,
} from "../../helper"

import LanguagesData from "./LanguagesData"

class Footer extends React.Component {
  renderItem = (type, options) => {
    const { uri, label, commonIdentifier } = options
    switch (type) {
      case "external-link":
        return (
          <a
            className={s.Link}
            href={uri}
            target="_blank"
            key={uri + label}
            rel="noopener noreferrer"
          >
            {label}
          </a>
        )
      case "internal-link":
        return (
          <Link
            className={s.Link}
            to={`/${getCurrentLanguage(this.props.location).slug}${uri}`}
            onClick={() => {
              if (commonIdentifier) {
                setCommonIdentifier(commonIdentifier)
              }
            }}
            key={uri + label}
          >
            {label}
          </Link>
        )
      case "facebook-icon":
        return (
          <a
            className={s.SocialLinkIcons}
            target="_blank"
            rel="noopener noreferrer"
            href={uri}
            key={uri}
          >
            <FacebookIcon />
          </a>
        )
      case "instagram-icon":
        return (
          <a
            className={s.SocialLinkIcons}
            target="_blank"
            rel="noopener noreferrer"
            href={uri}
            key={uri}
          >
            <InstagramIcon />
          </a>
        )
      case "language":
        return (
          <LanguagePicker
            key="lang"
            location={this.props.location}
            languageOptions={this.props.languageOptions}
            type="footer"
          />
        )
      case "copyright":
        return (
          <span className={s.Copyright} key="copyright">
            {options.content}
          </span>
        )
      default:
        return
    }
  }

  renderFooter = (scope) => {
    const { languageOptions, location } = this.props
    let currentLang = languageOptions
      ? languageOptions.currentLang.langHref
      : getCurrentLanguage(location).langHref
    if (!currentLang) currentLang = getUrlParameter("lang")

    const chosenLangData = LanguagesData[scope][currentLang]
    if (scope === SITE_SCOPES.main) {
      return (
        <footer className={s.MainFooter}>
          <div className={[s.Segment, s.SegmentLeft].join(" ")}>
            {chosenLangData?.leftLinks.map((L) => this.renderItem(L.type, L))}
          </div>
          <div className={[s.Segment, s.SegmentMiddle].join(" ")}>
            {chosenLangData?.midLinks.map((L) => this.renderItem(L.type, L))}
          </div>
          <div className={[s.Segment, s.SegmentEnd].join(" ")}>
            {chosenLangData?.rightLinks.map((L) => this.renderItem(L.type, L))}
          </div>
        </footer>
      )
    } else if (scope === SITE_SCOPES.winAndHike) {
      return (
        <footer className={s.HikingFooter}>
          <WinAndHikeFooter />
          <section className={s.Footer}>
            <div className={s.SegmentLeft}>
              <ImgFluid fileName="logo_black.png" cn={s.FooterLogo} />
              <div className={s.Segment}>
                {chosenLangData?.leftLinks && chosenLangData?.leftLinks.length
                  ? chosenLangData.leftLinks.map((L) =>
                      this.renderItem(L.type, L)
                    )
                  : null}
              </div>
              {chosenLangData?.midLinks && chosenLangData?.midLinks.length ? (
                <div className={[s.Segment, s.SegmentMiddle].join(" ")}>
                  {chosenLangData.midLinks.map((L) =>
                    this.renderItem(L.type, L)
                  )}
                </div>
              ) : null}
              <div className={s.FooterCurveWrapper}>
                <div className={s.FooterCurves}>
                  <FooterCurve2 />
                  <FooterCurve1 />
                </div>
              </div>
            </div>
            <div className={[s.Segment, s.SegmentEnd].join(" ")}>
              {chosenLangData?.rightLinks && chosenLangData?.rightLinks.length
                ? chosenLangData.rightLinks.map((L) =>
                    this.renderItem(L.type, L)
                  )
                : null}
            </div>
          </section>
        </footer>
      )
    }
  }
  render() {
    const { siteScope } = this.props

    return this.renderFooter(siteScope)
  }
}

export default Footer
