import React, { Fragment } from "react"
import s from "./RefreshPromoTwentyTwo.module.scss"

import SpinningWheelSection from "./components/SpinningWheelSection/SpinningWheelSection"
import NotWin from "./components/NotWin/NotWin"
import Form from "./components/Form/Form"
import Success from "./components/Success/Success"
import Error from "./components/Error/Error"
import MainPrize from "./components/MainPrize/MainPrize"

import { getCurrentLanguage } from "../../helper"

import { confirmRefreshV4Entry, confirmRefreshV4Win, getParameterByName } from "./api"

const frDefault = {
  wheelImage: "/wheel_520x520_fr.png",
  // description: `<strong>Indique ton adresse e-mail et fais tourner la roue de la fortune.</strong><br/><br/>Tu verras immédiatement après si tu as gagné. En raison de la forte demande, l'expédition peut prendre jusqu'à deux semaines.<br/>Il n'est possible de tourner la roue de la fortune qu’une seule fois par adresse e-mail.
  // `,
  description_warning:
  "Attention : Un seul colis peut être envoyé par adresse postale.",
  emailTerms: (terms_link) => (
    <a href={terms_link} target="_blank" rel="noopener noreferrer">
      Conditions de Participations
    </a>
  ),
  congratulationsTitletowel: "Félicitations, tu as gagné un chiffon microfibre Rivella Refresh.",
  congratulationsTitledelivery: "Félicitations, tu as gagné un an de boissons Rivella Refresh.",
  congratulationsTitlevoucher: "Félicitations, tu as gagné un bon de CHF 50.– valable sur Ticketcorner.",
  email_address: "Adresse E-mail*",
  spin_button: "Tourne la roue",
  fillAddressPrompt: `Tu peux maintenant remplir le formulaire ci-dessous et te rafraîchir.`,
  formFieldsInfo: {
    reciever: {
      header: { title: "Coordonnées" },
      mr_mrs: {
        placeholder: "Civilité",
        mr: { label: "M.", value: "M." },
        mrs: { label: "Mme.", value: "Mme." },
      },
      title: {
        placeholder: "Titre",
        empty: { label: " ", value: " " },
        dr: { label: "Dr.", value: "Dr." },
      },
      last_name: { placeholder: "Nom" },
      first_name: { placeholder: "Prénom" },
      email: { placeholder: "Adresse E-mail" },
      phone: { placeholder: "Numéro de téléphone" },
      street: { placeholder: "Rue" },
      house_no: { placeholder: "Numéro" },
      postal_code: { placeholder: "NPA" },
      town: { placeholder: "Localité" },
      delivery_warning:
        "* Veuillez noter que l'expédition n'est possible qu'à l'intérieur de la Suisse.",
    },
    checkboxes: {
      newsletter: {
        placeholder: "Oui, je souhaite m‘abonner à la newsletter Rivella SA.",
      },
      termsAndContidition: {
        placeholder: (terms_link) => (
          <span>
            * Oui, j’accepte{" "}
            <a href={terms_link} target="_blank" rel="noopener noreferrer">
              les conditions de participation.
            </a>
          </span>
        ),
      },
    },
    submit: { placeholder: "Envoyer" },
  },
}

const itDefault = {
  wheelImage: "/wheel_520x520_it.png",
  // description: `<strong>Inserisci l’indirizzo e-mail e gira la ruota della fortuna.</strong><br/><br/>Scoprirai subito se hai vinto un premio immediato. A causa dell’elevata domanda, la consegna può richiedere fino a due settimane.<br/>È possibile girare la ruota della fortuna una sola volta per indirizzo e-mail.`,
  description_warning:
  "Attenzione: Spediamo un solo pacchetto per indirizzo postale.",
  emailTerms: (terms_link) => (
    <a href={terms_link} target="_blank" rel="noopener noreferrer">
      Condizioni di partecipazione
    </a>
  ),
  congratulationsTitletowel: "Congratulazioni, hai vinto un panno in microfibra Rivella Refresh.",
  congratulationsTitledelivery: "Congratulazioni, hai vinto una fornitura annuale di Rivella Refresh",
  congratulationsTitlevoucher: "Congratulazioni, hai vinto un buono Ticketcorner di CHF 50.00",
  email_address: "E-Mail*",
  spin_button: "Girare la ruota",
  fillAddressPrompt: `Ora potete compilare il modulo sottostante e aggiornarvi.`,
  formFieldsInfo: {
    reciever: {
      header: { title: "Dettagli di contatto" },
      mr_mrs: {
        placeholder: "Titolo*",
        mr: { label: "M.", value: "M." },
        mrs: { label: "Ms.", value: "Mme." },
      },
      last_name: { placeholder: "Nome" },
      first_name: { placeholder: "Cognome" },
      email: { placeholder: "E-mail" },
      phone: { placeholder: "Numero di telefono" },
      street: { placeholder: "Via" },
      house_no: { placeholder: "N.*" },
      postal_code: { placeholder: "NPA*" },
      town: { placeholder: "Località" },
      delivery_warning:
        "* Si prega di notare che la spedizione è possibile solo in Svizzera.",
    },
    checkboxes: {
      newsletter: {
        placeholder: "Sì, desidero iscrivermi alla newsletter di Rivella AG.",
      },
      termsAndContidition: {
        placeholder: (terms_link) => (
          <span>
            * Sì, accetto{" "}
            <a href={terms_link} target="_blank" rel="noopener noreferrer">
            le condizioni di partecipazione.
            </a>
          </span>
        ),
      },
    },
    submit: { placeholder: "Inviare" },
  },
}


const translations = {
  de: {
    wheelImage: "/wheel_520x520.png",
    congratulationsTitletowel: "Herzlichen Glückwunsch, du hast ein Rivella Refresh Microfasertuch gewonnen!",
    congratulationsTitledelivery: "Herzlichen Glückwunsch, du hast eine Jahreslieferung Rivella Refresh gewonnen!",
    congratulationsTitlevoucher: "Herzlichen Glückwunsch, du hast einen Ticketcorner Gutschein à CHF 50.00 gewonnen!",
    congratulationsTitle: "Herzlichen Glückwunsch, du hast gewonnen!",
    // description:
    //   "<strong>E-Mailadresse angeben und am Glücksrad drehen</strong><br/><br/>Du siehst direkt im Anschluss, ob du gewonnen hast. Aufgrund der grossen Nachfrage, kann der Versand bis zu zwei Wochen dauern.<br/>Pro E-Mailadresse kann nur einmal am Glücksrad gedreht werden.",
    description_warning:
      "Achtung: Wir verschicken pro Postadresse nur ein Paket.",
    emailTerms: (terms_link) => (
      <a href={terms_link} target="_blank" rel="noopener noreferrer">
        Zu den Teilnahmebedingungen
      </a>
    ),
    email_address: "E-Mailadresse*",
    spin_button: "glücksrad drehen",
    fillAddressPrompt: `Du kannst nun das untenstehende Formular ausfüllen und dich erfrischen.`,
    // form
    formFieldsInfo: {
      sender: {
        header: { title: "Absender" },
        mr_mrs: {
          placeholder: "Anrede",
          mr: { label: "Herr", value: "Herr" },
          mrs: { label: "Frau", value: "Frau" },
        },
        title: {
          placeholder: "Titel",
          empty: { label: " ", value: " " },
          dr: { label: "Dr.", value: "Dr." },
        },
        last_name: { placeholder: "Nachname" },
        first_name: { placeholder: "Vorname" },
        email: { placeholder: "E-mail" },
      },
      reciever: {
        header: { title: "Kontaktangaben" },
        header_two: { title: "Empfänger" },
        mr_mrs: {
          placeholder: "Anrede",
          mr: { label: "Herr", value: "Herr" },
          mrs: { label: "Frau", value: "Frau" },
        },
        title: {
          placeholder: "Titel",
          empty: { label: " ", value: " " },
          dr: { label: "Dr.", value: "Dr." },
        },
        last_name: { placeholder: "Nachname" },
        first_name: { placeholder: "Vorname" },
        email: { placeholder: "E-mail" },
        phone: { placeholder: "Telefonnummer" },
        street: { placeholder: "Strasse" },
        house_no: { placeholder: "Hausnummer" },
        postal_code: { placeholder: "PLZ" },
        town: { placeholder: "Ort" },
        delivery_warning:
          "* Bitte beachte, dass ein Versand nur innerhalb der Schweiz möglich ist.",
      },
      checkboxes: {
        newsletter: {
          placeholder: "Ja, ich möchte den Rivella AG Newsletter abonnieren.",
        },
        termsAndContidition: {
          placeholder: (terms_link) => (
            <span>
              Ja, ich stimme den{" "}
              <a
                href={terms_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Teilnahmebedingungen
              </a>{" "}
              zu. *
            </span>
          ),
        },
      },
      submit: { placeholder: "Absenden" },
    },
  },
  fr: frDefault,
  fr_fr: frDefault,
  lu_fr: frDefault,
  it: itDefault,
}

const RefreshPromoTwentyTwo = (props) => {
  const [formState, setFormState] = React.useState("closed")
  const [failMessage, setFailMessage] = React.useState(undefined)
  const [mail, setMail] = React.useState("")
  const [winning, setWinning] = React.useState(0)
  const [address, setAddress] = React.useState({
    salutation: "",
    last_name: "",
    first_name: "",
    phone: "",
    street: "",
    number: "",
    zip: "",
    city: "",
  })

  const confirmEntry = async () => {
    const hash = getParameterByName("confirm", props.location.href)

    confirmRefreshV4Entry(hash)
      .then((res) => {
        if (res.result === "error") {
          setFailMessage(res.message)
          setFormState("failed_error")
        } else {
          setFormState("success_confirm")
          if (
            typeof window !== "undefined" &&
            typeof document !== "undefined"
          ) {
            window.scrollTo({
              top: document.body.scrollHeight - 650,
              left: 0,
              behavior: "smooth",
            })
          }
        }
      })
      .catch((err) => {
        console.warn(err)
      })
  }

  const confirmWin = async () => {
    const hash = getParameterByName("confirm-win", props.location.href)

    confirmRefreshV4Win(hash)
      .then((res) => {
        if (res.result === "error") {
          setFailMessage(res.message)
          setFormState("failed_error")
        } else {
          setFormState("success_confirm")
          if (
            typeof window !== "undefined" &&
            typeof document !== "undefined"
          ) {
            window.scrollTo({
              top: document.body.scrollHeight - 650,
              left: 0,
              behavior: "smooth",
            })
          }
        }
      })
      .catch((err) => {
        console.warn(err)
      })
  }

  React.useEffect(() => {
    const shouldConfirmEntry = getParameterByName("confirm", props.location.href)
    if (shouldConfirmEntry) {
      confirmEntry()
    }

    const shouldConfirmWin = getParameterByName("confirm-win", props.location.href)
    if (shouldConfirmWin) {
      confirmWin()
    }
  }, [])

  const showForm = (m, winning) => {
    setMail(m)
    setWinning(winning)
    setFormState("receiver")
    if (typeof window !== "undefined") {
      window.scrollBy({
        top: 600,
        left: 0,
        behavior: "smooth",
      })
    }
  }

  const showNotWin = (msg, email) => {
    setFailMessage(msg)
    setMail(email)
    setFormState("failed_notwin")
    if (typeof window !== "undefined") {
      window.scrollBy({
        top: 500,
        left: 0,
        behavior: "smooth",
      })
    }
  }

  const showError = (msg) => {
    setFailMessage(msg)
    setFormState("failed_error")
    if (typeof window !== "undefined") {
      window.scrollBy({
        top: 500,
        left: 0,
        behavior: "smooth",
      })
    }
  }

  const showSuccess = (address) => {
    setAddress(address)
    setFormState("success")
  }

  const showMainPrizeSuccess = (address) => {
    setFormState("success_main_prize")
  }

  const { lang } = getCurrentLanguage(props.location)

  return (
    <Fragment>
      <div className={s.wrapper}>
        { formState !== "success_confirm" && <SpinningWheelSection
          {...props}
          {...{ showForm, showNotWin, showError }}
          {...translations[lang]}
        />}
        {/* Don't display anything when user just visits promo page */}
        {/* {formState === "closed" && ( */}
        <div
          className={`${s.closed_space} ${
            formState !== "closed" ? s.wheel_padding : ""
          }`}
        />
        {/* )} */}
        {/* Display when user fails spinning wheel */}
        {formState === "failed_notwin" && (
          <NotWin {...props} location={props.location} already={failMessage} showForm={showForm} />
        )}
        {formState === "failed_error" && (
          <Error
            {...props}
            formState={formState}
            failMessage={failMessage}
            location={props.location}
          />
        )}
        {/* Form */}
        {["sender", "receiver"].includes(formState) && (
          <Form
            {...props}
            showError={showError}
            showSuccess={showSuccess}
            email={mail}
            winning={winning}
            {...translations[lang]}
            congratulationsTitle={translations[lang]["congratulationsTitle" + winning]}
          />
        )}
        {/* Success */}
        {formState === "success" && <Success location={props.location} winning={winning} />}
        {formState === "success_confirm" && (
          <Success type="confirm" location={props.location} />
        )}
        {(formState === "success_main_prize") && (
          <Success type="main_prize" location={props.location} />
        )}
        {(formState === "failed_notwin" || formState === "success") && !failMessage &&
          <MainPrize
            {...props}
            {...translations[lang]}
            email={mail}
            address={address}
            showSuccess={showMainPrizeSuccess}
          />
        }
        {(formState === "success_confirm" || formState === "success_main_prize"
          || ((formState === "failed_notwin" || formState === "success" ) && failMessage)) &&
          <div className={s.spacing}></div>
        }
      </div>
    </Fragment>
  )
}

export default RefreshPromoTwentyTwo
