import React from "react"
import Helmet from "react-helmet"

import s from "./WinnersRankingSection.module.scss"
import ArrowDownIcon from "../../../assets/svgs/down_arrow.svg"
import PromotionSectionWrapper from "../PromotionSectionWrapper/PromotionSectionWrapper"
import { fetchHikingData } from "../../../helper"
import { ChildrenButtonRenderer } from "../../"

class WinnersRankingSection extends React.Component {
  constructor(props) {
    super(props)
    this.leaderBoardRef = React.createRef()
    this.state = {
      showArrowUp: false,
      // showArrowDown: this.props.ranking_list.length > 5,
      arrowUpClick: false,
      arrowDownClick: false,
      direction: 0,
      ranking_list: [],
    }
  }

  componentDidMount() {
    this.fetchLeaderboard()
  }

  fetchLeaderboard = async () => {
    try {
      const { data: ranking_list } = await fetchHikingData("leaderboard")
      const first_ten = ranking_list.filter((_, i) => i < 10)
      this.setState({
        ranking_list: first_ten,
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  handleScroll = () => {
    const far = this.leaderBoardRef.current.clientHeight * this.state.direction
    const pos = this.leaderBoardRef.current.clientHeight + far
    this.leaderBoardRef.current.scrollTop = pos

    if (pos === 0) {
      this.toggleState("showArrowUp", false)
    }
  }

  toggleState = (state, bool) => {
    this.setState({ [state]: bool })
  }

  onScrollUp = () => {
    this.setState(
      (prevState) => ({ direction: prevState.direction - 1 }),
      () => {
        this.handleScroll()
      }
    )
  }

  onScrollDown = () => {
    const { showArrowUp } = this.state
    this.setState(
      (prevState) => ({ direction: prevState.direction + 1 }),
      () => {
        this.handleScroll()
      }
    )

    if (!showArrowUp) {
      this.toggleState("showArrowUp", true)
    }
  }

  render() {
    const { title_one_text, description_one_text, itemProp } = this.props
    const { ranking_list } = this.state

    let arrowUpHtml = null
    let arrowDownHtml = null
    if (this.state.showArrowUp) {
      arrowUpHtml = (
        <button className={s.scroll_up_btn} onClick={this.onScrollUp}>
          <ArrowDownIcon />
        </button>
      )
    }
    if (this.state.ranking_list && this.state.ranking_list.length > 5) {
      arrowDownHtml = (
        <button className={s.scroll_down_btn} onClick={this.onScrollDown}>
          <ArrowDownIcon />
        </button>
      )
    }

    return (
      <PromotionSectionWrapper
        itemScope
        itemProp={itemProp}
        itemType="https://schema.org/Action"
        className={s.highscore_section}
      >
        <Helmet>
          <meta
            property="og:description"
            content={title_one_text + " - " + description_one_text}
          />
        </Helmet>
        <div className={s.highscore_section_wrapper}>
          <section className={s.title_description_block}>
            <h2
              itemProp="alternateName"
              className={s.title}
              dangerouslySetInnerHTML={{ __html: title_one_text }}
            ></h2>
            <p
              itemProp="description"
              className={s.description}
              dangerouslySetInnerHTML={{ __html: description_one_text }}
            ></p>
            <ChildrenButtonRenderer buttonData={this.props.innerBlocks} />
          </section>
          <section className={s.ranking_list_wrapper}>
            {arrowUpHtml}
            <div className={s.ranking_list} ref={this.leaderBoardRef}>
              {ranking_list.map(
                ({ first_name, last_name_initial, location }, index) => {
                  return (
                    <div key={index} className={s.position_winner_wrapper}>
                      <div itemProp="result" className={s.ranking_position}>
                        {index + 1}
                      </div>
                      <div itemProp="agent" className={s.winner_details}>
                        {first_name} {last_name_initial}. | {location}
                      </div>
                    </div>
                  )
                }
              )}
            </div>
            {arrowDownHtml}
          </section>
        </div>
      </PromotionSectionWrapper>
    )
  }
}

export default WinnersRankingSection
