import React from "react"

import s from "./Teilnahmebedingungen.module.scss"
import { Link } from "gatsby"

const Teilnahmebedingungen = ({ pageData, main_title, data_list }) => {
  // TODO page data part is legacy static data can be removed after full backend dependency
  return pageData ? (
    <section className={s.ImpressumSection}>
      <h1 className={s.Title}>{pageData.title}</h1>
      <div>
        <p className={s.Bold}>{pageData.section_1.title}</p>
        <div className={s.content_wrapper}>
          {pageData.section_1.content && pageData.section_1.content.length
            ? pageData.section_1.content.map((el, index) => (
                <p key={index}>{el}</p>
              ))
            : null}
        </div>
      </div>
      <div>
        <p className={s.Bold}>{pageData.section_2.title}</p>
        <div className={s.content_wrapper}>
          {pageData.section_2.content && pageData.section_2.content.length
            ? pageData.section_2.content.map((el, index) => (
                <p key={index}>{el}</p>
              ))
            : null}
        </div>
      </div>
      <div>
        <p className={s.Bold}>{pageData.section_3.title}</p>
        <p className={s.Bold}>{pageData.section_3.subtitle}</p>
        <div className={s.content_wrapper}>
          {pageData.section_3.content && pageData.section_3.content.length
            ? pageData.section_3.content.map((el, index) => (
                <p key={index}>{el}</p>
              ))
            : null}
        </div>
      </div>
      <div>
        <p className={s.Bold}>{pageData.section_4.title}</p>
        <div className={s.content_wrapper}>
          {pageData.section_4.content && pageData.section_4.content.length
            ? pageData.section_4.content.map((el, index) => (
                <p key={index}>{el}</p>
              ))
            : null}
        </div>
      </div>
      <div className={s.footer}>
        <p>
          {pageData.footer}{" "}
          <Link to={pageData.footer_link_text}>
            {pageData.footer_link_text}
          </Link>
        </p>
        <p>{pageData.footer_text_two}</p>
      </div>
    </section>
  ) : (
    <section className={s.ImpressumSection}>
      <h1
        className={s.Title}
        dangerouslySetInnerHTML={{ __html: main_title }}
      ></h1>
      {data_list.map((d) => (
        <div key={d.title_one} className={s.content_wrapper}>
          <p
            className={s.Bold}
            dangerouslySetInnerHTML={{ __html: d.title_one }}
          ></p>
          <p dangerouslySetInnerHTML={{ __html: d.description }}></p>
        </div>
      ))}
    </section>
  )
}

export default Teilnahmebedingungen
