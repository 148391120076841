import React from "react"
import { navigate, Link } from "gatsby"
import s from "./MainPageProducts.module.scss"

import {
  BottleSlick,
  SkewedCard,
  SkewedWrapper,
  Button,
  SkewedButton,
} from "../../components"
import {
  extractImageNameFromUrl,
  getCurrentLanguage,
  calculateProductLinkLangPrefix,
  scrollToRef,
  hexToRGB_obj,
} from "../../helper"
import { product_range_route } from "../../constants"
import ArrowDown from "../../assets/svgs/arrow_down.svg"

const PRODUCT_SIGNALS = {
  productNextSignal: "productNextSignal",
  productPrevSignal: "productPrevSignal",
}

class MainPageProducts extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      productIndex: 0,
      isNavigating: false,
      productNextSignal: false,
      productPrevSignal: false,
      seenEndOfList: false,
    }
  }

  attachOrDetachOnScrollHandler = (isAttach) => {
    if (typeof window !== "undefined" && window.innerWidth > 1200) {
      const html = document.querySelector("html")
      if (isAttach && !this.state.seenEndOfList) {
        const productsData = this.props.productsData
        const productIndex = this.state.productIndex
        const shouldAttach = productIndex < productsData.length - 1
        if (shouldAttach) {
          scrollToRef(this.props.sectionRef)
          setTimeout(() => {
            html.style.paddingRight = "18px"
            html.style.overflowY = "hidden"
            scrollToRef(this.props.sectionRef)
            window.addEventListener("wheel", (e) => this.onScrollHandler(e))
          }, 300)
        }
      } else {
        html.style.paddingRight = 0
        html.style.overflowY = "unset"
        window.removeEventListener("wheel", (e) => this.onScrollHandler(e))
      }
    }
  }

  onScrollHandler = (e) => {
    const { deltaY } = e
    if (deltaY > 0 && !this.state.seenEndOfList) {
      const length = this.props.productsData && this.props.productsData.length
      if (length) {
        const productIndex = this.state.productIndex
        const nextIsEnd = productIndex === length - 2
        if (nextIsEnd) {
          this.setState({
            seenEndOfList: true,
            [PRODUCT_SIGNALS.productNextSignal]:
              !this.state[PRODUCT_SIGNALS.productNextSignal],
          })
          setTimeout(() => {
            this.attachOrDetachOnScrollHandler(false)
          }, 300)
        } else {
          this.setState({
            [PRODUCT_SIGNALS.productNextSignal]:
              !this.state[PRODUCT_SIGNALS.productNextSignal],
          })
        }
      }
    }
  }

  onProductSignalChange = (signalKey) => {
    this.setState((prevState) => ({
      [signalKey]: !prevState[signalKey],
    }))
  }

  productChangeHandler = (index) => {
    const length = this.props.productsData.length
    const seenEndObj = index === length - 1 ? { seenEndOfList: true } : {}
    this.setState({
      ...seenEndObj,
      productIndex: index,
    })
  }

  onFirstButtonClick = (to) => {
    this.setState(
      {
        isNavigating: true,
      },
      () => {
        setTimeout(() => {
          navigate(to, {
            state: {
              isComingFromMain: true,
            },
          })
        }, 1200)
      }
    )
  }

  renderDescription = ({ currentProduct, isSmall }) => {
    return isSmall ? (
      <p
        className={[
          s.ProductContent,
          currentProduct.content_one_color,
          s.ProductContentSmall,
        ].join(" ")}
        dangerouslySetInnerHTML={{
          __html: currentProduct.description_one_text,
        }}
      ></p>
    ) : (
      <p
        className={[s.ProductContent, currentProduct.content_one_color].join(
          " "
        )}
        dangerouslySetInnerHTML={{
          __html: currentProduct.description_one_text,
        }}
      ></p>
    )
  }

  renderFirstButton = ({
    currentProduct,
    button_one_type,
    isNavigating,
    isSmall,
  }) => {
    let firstButtonClasses = !isNavigating
      ? [s.ProductButton]
      : [s.ProductButton, s.ProductButtonZoomingIn]
    if (isSmall) firstButtonClasses.push(s.SmallSizeProductButton)
    const { r, g, b } = hexToRGB_obj(currentProduct.product_primary_color)
    const buttonColor = currentProduct.product_primary_color
    const darkenRate = 0.8
    const hoverColor = `rgb(${r * darkenRate}, ${g * darkenRate}, ${
      b * darkenRate
    })`
    return isSmall ? (
      <SkewedButton
        type={button_one_type}
        btnClassName={firstButtonClasses.join(" ")}
        onClick={() => this.onFirstButtonClick(currentProduct.button_one_link)}
        hoverColor={hoverColor}
        buttonBackgroundColor={buttonColor}
      >
        {currentProduct.button_one_text}
        {isNavigating && (
          <div
            style={{ backgroundColor: buttonColor }}
            className={s.RedExpandingAnimation}
          ></div>
        )}
      </SkewedButton>
    ) : (
      <Button
        type={button_one_type}
        btnClassName={firstButtonClasses.join(" ")}
        onClick={() => this.onFirstButtonClick(currentProduct.button_one_link)}
        buttonBackgroundColor={buttonColor}
        hoverColor={hoverColor}
      >
        {currentProduct.button_one_text}
        {isNavigating && (
          <div
            style={{ backgroundColor: buttonColor }}
            className={s.RedExpandingAnimation}
          ></div>
        )}
      </Button>
    )
  }

  renderSecondButton = ({ isSmall, link, content, type }) => {
    const linkToRender = link.startsWith("/") ? link : "/" + link
    return isSmall ? (
      <SkewedButton
        type={type}
        wrapperClassName={[s.SmallSizeProductButton, s.SlimButton].join(" ")}
      >
        <Link to={linkToRender} className={s.Link}>
          {content}
        </Link>
      </SkewedButton>
    ) : (
      <Button
        type={type}
        btnClassName={[s.ProductButton, s.SlimButton].join(" ")}
      >
        <Link to={linkToRender} className={s.Link}>
          {content}
        </Link>
      </Button>
    )
  }

  handleProductData = (
    productsData,
    {
      button_one_text,
      button_one_type,
      button_two_text,
      button_two_type,
      mini_title_color,
    }
  ) => {
    let productsToCast = []
    const currentLang = getCurrentLanguage(this.props.location)
    productsData.forEach((productPageObj) => {
      let objToCast = {}
      productPageObj.childrenData.forEach((p) => {
        if (p.type === "rivella/product-overview") {
          objToCast.id = p.props.title_one_text

          objToCast.mini_title_text = `${
            product_range_route[currentLang.langHref].title
          }`
          objToCast.mini_title_color = mini_title_color

          objToCast.title_one_text = p.props.title_one_text
          objToCast.description_one_text = p.props.description_one_text

          objToCast.image = p.props.image
          objToCast.image_alt = p.props.title_one_text

          objToCast.product_primary_color = p.props.product_primary_color
          objToCast.product_secondary_color = p.props.product_secondary_color

          objToCast.button_one_text = button_one_text
          objToCast.button_one_type = button_one_type
          objToCast.button_one_link = calculateProductLinkLangPrefix(
            productPageObj.path,
            currentLang.slug
          )

          objToCast.button_two_text = button_two_text
          objToCast.button_two_type = button_two_type
          objToCast.button_two_link = `${currentLang.slug}/${
            product_range_route[currentLang.langHref].slug
          }`
        }
      })
      productsToCast.push(objToCast)
    })

    return productsToCast.reverse()
  }

  render() {
    const { isNavigating } = this.state
    const {
      // mini_title_text,
      mini_title_color,
      button_one_text,
      button_one_type,
      button_two_text,
      button_two_type,
      productsData,
    } = this.props

    const castedDynamicProductsData = this.handleProductData(productsData, {
      button_one_text,
      button_one_type,
      button_two_text,
      button_two_type,
      mini_title_color,
    })

    const currentProduct = castedDynamicProductsData[this.state.productIndex]
    if (!currentProduct) return null

    const containerZIndex = !isNavigating ? this.props.sectionZindex : 1000000

    return (
      <section
        className={s.Products}
        ref={this.props.sectionRef}
        style={{
          zIndex: containerZIndex,
        }}
        // onMouseEnter={() => this.attachOrDetachOnScrollHandler(true)}
        // onMouseLeave={() => this.attachOrDetachOnScrollHandler(false)}
      >
        <div className={s.BottleSlick}>
          <div className={s.BottleSlickWrapper}>
            <ArrowDown
              className={[s.Arrow, s.ArrowLeft].join(" ")}
              onClick={() =>
                this.onProductSignalChange(PRODUCT_SIGNALS.productPrevSignal)
              }
            />
            <BottleSlick
              onProductChange={this.productChangeHandler}
              imagesData={castedDynamicProductsData.map((d) => ({
                image: extractImageNameFromUrl(d.image),
                alt: d.image_alt,
                id: d.id,
                product_primary_color: d.product_primary_color,
                product_secondary_color: d.product_secondary_color,
                productLink: d.button_one_link,
              }))}
              productIndex={this.state.productIndex}
              prevSignal={this.state[PRODUCT_SIGNALS.productPrevSignal]}
              nextSignal={this.state[PRODUCT_SIGNALS.productNextSignal]}
            />
            <ArrowDown
              className={[s.Arrow, s.ArrowRight].join(" ")}
              onClick={() =>
                this.onProductSignalChange(PRODUCT_SIGNALS.productNextSignal)
              }
            />
          </div>
          {this.renderDescription({ currentProduct, isSmall: true })}
          {this.renderFirstButton({
            currentProduct,
            isNavigating,
            button_one_type,
            isSmall: true,
          })}
          {this.renderSecondButton({
            isSmall: true,
            content: currentProduct.button_two_text,
            link: currentProduct.button_two_link,
            type: currentProduct.button_two_type,
          })}
        </div>
        <div className={s.CardWrapper}>
          <SkewedCard cardOuterCn={s.Card}>
            <h2
              className={[
                s.ProductsSubtitle,
                currentProduct.mini_title_color,
              ].join(" ")}
            >
              {currentProduct.mini_title_text}{" "}
              {/* <ArrowDown
                className={[s.Arrow, s.ArrowLeft].join(" ")}
                onClick={() =>
                  this.onProductSignalChange(PRODUCT_SIGNALS.productPrevSignal)
                }
              />
              <ArrowDown
                className={[s.Arrow, s.ArrowRight].join(" ")}
                onClick={() =>
                  this.onProductSignalChange(PRODUCT_SIGNALS.productNextSignal)
                }
              /> */}
            </h2>
            <h3 className={[s.ProductsTitle].join(" ")}>
              {currentProduct.title_one_text}
            </h3>
            {this.renderDescription({ currentProduct })}
            <SkewedWrapper wrapperClassName={s.ProductButtonWrapper}>
              {this.renderFirstButton({
                currentProduct,
                isNavigating,
                button_one_type,
              })}
              {/* <Button
                type={button_one_type}
                btnClassName={firstButtonClasses.join(" ")}
                onClick={() =>
                  this.onFirstButtonClick(currentProduct.button_one_link)
                }
              >
                {currentProduct.button_one_text}
                {isNavigating && (
                  <div className={s.RedExpandingAnimation}></div>
                )}
              </Button> */}
              {this.renderSecondButton({
                isSmall: false,
                content: currentProduct.button_two_text,
                link: currentProduct.button_two_link,
                type: currentProduct.button_two_type,
              })}
            </SkewedWrapper>
          </SkewedCard>
        </div>
      </section>
    )
  }
}

export default MainPageProducts
