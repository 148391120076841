import React from "react"
import { PromotionSectionWrapper } from ".."
import s from "./HowItWorksSection.module.scss"
import RegisterCheckIcon from "../../../assets/svgs/register-check-icon.svg"
import CheckMarkIcon from "../../../assets/svgs/check-mark-icon.svg"
import WinBottleIcon from "../../../assets/svgs/win-bottle-icon.svg"
import CartIcon from "../../../assets/svgs/cart-icon.svg"
import { ImgFluid } from "../../../components"
import { extractImageNameFromUrl } from "../../../helper"
import { HikingAuthConsumer } from "../../../store"

const svgIcons = [CartIcon, CheckMarkIcon, WinBottleIcon, RegisterCheckIcon]

class HowItWorksSection extends React.Component {
  render() {
    const { icons, main_title_text, customClassName, itemProp } = this.props
    return (
      <HikingAuthConsumer>
        {(store) => {
          const { codeStatus } = store.state
          const showGewinnNiete = codeStatus.showMessageBox
          return (
            <>
              {!showGewinnNiete ? (
                <PromotionSectionWrapper
                  itemProp={itemProp}
                  className={`${s.icon_section} ${customClassName}`}
                >
                  <h2 className={s.icon_section_title}>{main_title_text}</h2>
                  <div className={s.icon_group}>
                    {icons.map(
                      (
                        { icon, title, description, description_two },
                        index
                      ) => {
                        const Icon = svgIcons[index]
                        return (
                          <div key={index} className={s.icon_details_wrapper}>
                            <div className={s.icon_image_wrapper}>
                              {/* <div className={s.icon_image_circular_bg}></div> */}
                              {icon ? (
                                <ImgFluid
                                  fileName={extractImageNameFromUrl(icon)}
                                />
                              ) : (
                                <Icon />
                              )}
                            </div>
                            <h3 className={s.icon_title}>{title}</h3>
                            <p className={s.icon_description}>{description}</p>
                            {description_two ? (
                              <p className={s.icon_description_2}>
                                {description_two}
                              </p>
                            ) : null}
                          </div>
                        )
                      }
                    )}
                  </div>
                </PromotionSectionWrapper>
              ) : null}
            </>
          )
        }}
      </HikingAuthConsumer>
    )
  }
}

export default HowItWorksSection
