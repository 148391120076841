import React, { Component } from "react"
import {
  ImgFluid,
  ImageFrame,
  HikeAndWinButton,
  Map,
} from "../../../components"
import s from "./DiscoverDestinationMapSection.module.scss"
import { PromotionSectionWrapper } from "../"
import {
  extractImageNameFromUrl,
  getCurrentLanguage,
  setCommonIdentifier,
} from "../../../helper"
import {
  winAndHike_routes,
  // , LANGUAGE_ABBREVATIONS
} from "../../../constants"
import SkewedSectionWithBoxShadow from "../SkewedSectionWithBoxShadow/SkewedSectionWithBoxShadow"
import HuttenMapPin from "../../../assets/svgs/hutten_map_pin.svg"
import RegionenMapPin from "../../../assets/svgs/regionen_map_pin.svg"
// import { locationDetails } from "../../../hiking_data/partner_overview_data"
import supercluster from "points-cluster"

export const MAP_PIN_TYPES = {
  REGIONEN: "REGIONEN",
  HUTTEN: "HUTTEN",
}

const DEFAULT_CLUSTER_OPTIONS = {
  minZoom: 0,
  maxZoom: 10,
  radius: 60,
}

// const {
//   french: { combined: fr },
// } = LANGUAGE_ABBREVATIONS

class DiscoverDestinationMapSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedIndex: 0,
      pageType: "huts",
      mapOptions: {
        defaultZoom: 8,
        defaultCenter: {
          lat: 46.65115237617013,
          lng: 8.7,
        },
      },
      clusters: [],
      selectedMarker:
        props.location_details && props.location_details[0]
          ? props.location_details[0]
          : {},
    }
  }

  generateClustersByType = (clusterType) => {
    const { location_details } = this.props

    return location_details
      .filter(({ type }) => type === clusterType)
      .map(({ coords, slug, type, location_img, title }) => {
        try {
          return {
            type,
            slug,
            location_img,
            title,
            lat: Number(coords.lat),
            lng: Number(coords.lng),
          }
        } catch (err) {
          console.error(
            "Coordinates for ",
            title,
            " are not specified or not parseable. Data: "
          )
          return {}
        }
      })
  }

  getClusters = (mapOptions) => {
    const regionCoords = this.generateClustersByType(MAP_PIN_TYPES.REGIONEN)
    const hutCoords = this.generateClustersByType(MAP_PIN_TYPES.HUTTEN)

    const regionClusters = supercluster(
      regionCoords,
      DEFAULT_CLUSTER_OPTIONS
    )(mapOptions)

    const hutClusters = supercluster(
      hutCoords,
      DEFAULT_CLUSTER_OPTIONS
    )(mapOptions)

    return [...regionClusters, ...hutClusters]
  }

  createClusters = () => {
    const { mapOptions } = this.state
    const clustersList = this.getClusters(mapOptions)
    const clusters = mapOptions.bounds
      ? clustersList.map(({ wx, wy, numPoints, points }) => ({
          lat: wy,
          lng: wx,
          numPoints,
          id: `${numPoints}_${points[0].slug}`,
          points,
        }))
      : []
    this.setState({ clusters })
  }

  handleMapChange = ({ zoom, bounds }) => {
    const { defaultCenter } = this.state.mapOptions
    this.setState(
      {
        mapOptions: {
          center: defaultCenter,
          zoom,
          bounds,
        },
      },
      () => {
        this.createClusters()
      }
    )
  }

  onClusterClick = ({
    numPoints,
    selectedIndex,
    markerType,
    newCenter,
    selectedMarker,
  }) => {
    this.setState({
      selectedIndex,
      selectedMarker,
      pageType: markerType === MAP_PIN_TYPES.HUTTEN ? "huts" : "partners",
      mapOptions: {
        ...this.state.mapOptions,
        zoom: this.state.mapOptions.zoom + numPoints * 0.4,
        center: newCenter ? newCenter : this.state.mapOptions.center,
      },
    })
  }

  renderMapMarker = (markerOptions) => {
    const pointDetails = markerOptions.points[0]
    const type = pointDetails.type

    const { location_details } = this.props
    const selectedIndex = location_details.findIndex(
      (location) => location.slug === pointDetails.slug
    )

    const handleClick =
      markerOptions.numPoints <= 1
        ? () => {
            this.setState({
              selectedIndex,
              pageType:
                pointDetails.type === MAP_PIN_TYPES.HUTTEN
                  ? "huts"
                  : "partners",
              selectedMarker: pointDetails,
            })
          }
        : () =>
            this.onClusterClick({
              selectedIndex,
              selectedMarker: pointDetails,
              numPoints: markerOptions.numPoints,
              markerType: pointDetails.type,
              newCenter: { lat: markerOptions.lat, lng: markerOptions.lng },
            })

    return (
      <button
        lat={markerOptions.lat}
        lng={markerOptions.lng}
        className={s.map_pin_wrapper}
        key={markerOptions.index}
        onClick={handleClick}
        itemProp="geo"
      >
        {markerOptions.numPoints > 1 && (
          <span className={s.cluster_size}>{markerOptions.numPoints}</span>
        )}
        {type === MAP_PIN_TYPES.HUTTEN ? <HuttenMapPin /> : <RegionenMapPin />}
      </button>
    )
  }

  render() {
    const {
      // selectedIndex,
      clusters,
      mapOptions,
      pageType,
      selectedMarker,
    } = this.state

    const {
      itemProp,
      button_one_text,
      icon_one_img,
      icon_one_text,
      icon_two_img,
      icon_two_text,
      location,
      // location_details,
    } = this.props

    const { slug, langHref } = getCurrentLanguage(location)
    // const hutName = locationDetails[selectedIndex].title

    let key = ""
    if (pageType === "huts") key = "huts"
    else if (pageType === "partners") key = "partnerdestinationen"

    let slugBase = winAndHike_routes[langHref][key]
    return (
      <SkewedSectionWithBoxShadow top bottom className={s.map_wrapper}>
        <section
          itemProp={itemProp}
          itemScope
          itemType="https://schema.org/Place"
          className={s.map_container}
        >
          <Map
            handleMapChange={this.handleMapChange}
            zoomValue={mapOptions.zoom}
            mapCenterCoords={mapOptions.defaultCenter}
            currentCenterCoords={mapOptions.center}
          >
            {clusters.map(({ lat, lng, numPoints, points }, index) => {
              return this.renderMapMarker({
                lat,
                lng,
                index,
                points,
                numPoints,
              })
            })}
          </Map>
        </section>
        <PromotionSectionWrapper
          itemProp={itemProp}
          itemScope
          itemType="https://schema.org/Place"
          className={s.destination_details}
        >
          <ImageFrame
            className={s.location_image}
            imgFilename={extractImageNameFromUrl(selectedMarker.location_img)}
            itemProp="photo"
          />
          <div className={s.location_details}>
            <h3
              className={s.location_title}
              itemProp="address"
              dangerouslySetInnerHTML={{
                __html: selectedMarker ? selectedMarker.title : null,
              }}
            ></h3>
            <HikeAndWinButton
              itemProp="hasMap"
              to={`/${slug}${slugBase}/${selectedMarker.slug}`}
              type="link"
              className={s.hike_win_button}
              onClick={() => setCommonIdentifier(pageType)}
            >
              {button_one_text}
            </HikeAndWinButton>
          </div>
        </PromotionSectionWrapper>
        <PromotionSectionWrapper className={s.map_markers_wrapper}>
          <div className={s.marker_wrapper}>
            <ImgFluid fileName={extractImageNameFromUrl(icon_one_img)} />
            <span
              className={s.icon_text}
              dangerouslySetInnerHTML={{
                __html: icon_one_text,
              }}
            ></span>
          </div>
          <div className={s.marker_wrapper}>
            <ImgFluid fileName={extractImageNameFromUrl(icon_two_img)} />
            <span
              className={s.icon_text}
              dangerouslySetInnerHTML={{
                __html: icon_two_text,
              }}
            ></span>
          </div>
        </PromotionSectionWrapper>
      </SkewedSectionWithBoxShadow>
    )
  }
}

export default DiscoverDestinationMapSection
