import React from "react"
import s from "./Success.module.scss"
import { getCurrentLanguage } from "../../../../helper"

const Success = ({ type, location }) => {
  const { lang } = getCurrentLanguage(location)

  const titleSent = {
    de: `Vielen Dank für deine Teilnahme! Bitte bestätige diese per Link, den wir soeben an deine E-Mail Adresse verschickt haben.
    Erfrischende Grüsse, dein Rivella Team`,
    fr: `Merci pour ta participation! Merci de confirmer via le lien que nous venons d'envoyer à ton adresse e-mail. 
    Salutations rafraîchissantes, ton équipe Rivella`,
    fr_fr: `Merci pour ta participation! Merci de confirmer via le lien que nous venons d'envoyer à ton adresse e-mail. 
    Salutations rafraîchissantes, ton équipe Rivella`,
    lu_fr: `Merci pour ta participation! Merci de confirmer via le lien que nous venons d'envoyer à ton adresse e-mail. 
    Salutations rafraîchissantes, ton équipe Rivella`,
  }
  const titleConfirmed = {
    de: `Vielen Dank - wir haben deine Teilnahme registriert.`,
    fr: `Merci beaucoup - nous avons enregistré ta participation.`,
    fr_fr: `Merci beaucoup - nous avons enregistré ta participation.`,
    lu_fr: `Merci beaucoup - nous avons enregistré ta participation.`,
  }
  return (
    <section className={s.section}>
      <div className={s.failed_description}>
        <h2>{type === "confirm" ? titleConfirmed[lang] : titleSent[lang]}</h2>
      </div>

      {/* <div className={s.failed_description}>
        <span>
          {type === "confirm"
            ? "Your entry is confirmed."
            : "Form Submitted Successfully"}
        </span>
      </div> */}
    </section>
  )
}

export default Success
