import React from "react"
import { SkewedSectionWithBg, SkewedCard } from "../../../components"
import s from "./SkewedSectionWithCard.module.scss"
// import { extractImageNameFromUrl } from "../../helper"

class SkewedSectionWithCard extends React.Component {
  render() {
    const {
      background_img,
      title_one_text,
      title_one_color,
      description_one_text,
      description_one_color,
      increasedHeight,
      decreasedHeight,
      parallaxClassName,
      cardWrapperClassName,
      show_key_visual,
    } = this.props
    return (
      <section
        className={[
          s.section_wrapper,
          cardWrapperClassName ? cardWrapperClassName : "",
        ].join(" ")}
        style={{ zIndex: this.props.sectionZindex }}
        ref={this.props.sectionRef}
      >
        <SkewedSectionWithBg
          bgImg={background_img}
          wrapperClassName={s.section_bg}
          imageWrapperClass={s.image_wrapper}
          type="parallax"
          parallaxClassName={parallaxClassName}
          showKeyVisual={show_key_visual}
        >
          <SkewedCard
            cardOuterCn={[
              s.section_bg_card,
              increasedHeight ? s.high_section_bg_card : null,
              decreasedHeight ? s.low_section_bg_card : null,
            ].join(" ")}
          >
            <h4
              className={[s.section_bg_card_title, title_one_color].join(" ")}
            >
              {title_one_text}
            </h4>
            <p
              className={[
                s.section_bg_card_description,
                description_one_color,
              ].join(" ")}
              dangerouslySetInnerHTML={{ __html: description_one_text }}
            />
          </SkewedCard>
        </SkewedSectionWithBg>
      </section>
    )
  }
}

export default SkewedSectionWithCard
