import React from "react"
import s from "./KeyVisual.module.scss"

const KeyVisual = ({ className }) => {
  const rnd = () => Math.random().toString(36).substring(2)
  const ids = {
    clip1: rnd(),
    clip2: rnd(),
    clip3: rnd(),
    clip4: rnd(),
    grad1: rnd(),
    grad2: rnd(),
    grad3: rnd(),
    grad4: rnd(),
    grad5: rnd(),
    grad6: rnd(),
    grad7: rnd(),
    grad8: rnd(),
    grad9: rnd(),
    grad10: rnd(),
    grad11: rnd(),
    grad12: rnd(),
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="300 0 780 315"
      preserveAspectRatio="xMidYMid meet"
      className={`${s.svg} ${className || ""}`}
    >
      <defs>
        <clipPath id={ids.clip1}>
          <path d="M0,0 L1440,0 L1440,145 L0,373z" />
        </clipPath>
        <clipPath id={ids.clip2}>
          <path d="M0,0 L500,0 L500,900 L0,900z" />
        </clipPath>
        <linearGradient
          id={ids.grad1}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          x1="-7.335000038146973"
          y1="29.288000106811523"
          x2="11.286999702453613"
          y2="-29.645999908447266"
        >
          <stop offset="0%" stopColor="rgb(38,66,159)" />
          <stop offset="50%" stopColor="rgb(19,112,184)" />
          <stop offset="100%" stopColor="rgb(0,158,209)" />
        </linearGradient>
        <linearGradient
          id={ids.grad2}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          x1="-9.243000030517578"
          y1="28.86400032043457"
          x2="10.616999626159668"
          y2="-29.125"
        >
          <stop offset="0%" stopColor="rgb(19,65,157)" />
          <stop offset="50%" stopColor="rgb(9,111,183)" />
          <stop offset="100%" stopColor="rgb(0,158,209)" />
        </linearGradient>
        <linearGradient
          id={ids.grad3}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          x1="-7"
          y1="-21"
          x2="-19"
          y2="8"
        >
          <stop offset="0%" stopColor="rgb(255,255,255)" stopOpacity="1" />
          <stop offset="50%" stopColor="rgb(255,255,255)" stopOpacity="0.5" />
          <stop offset="100%" stopColor="rgb(255,255,255)" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id={ids.grad4}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          x1="-31.670000076293945"
          y1="-14.463000297546387"
          x2="13.52400016784668"
          y2="-7.203000068664551"
        >
          <stop offset="0%" stopColor="rgb(19,65,157)" />
          <stop offset="50%" stopColor="rgb(9,111,183)" />
          <stop offset="100%" stopColor="rgb(0,158,209)" />
        </linearGradient>
        <linearGradient
          id={ids.grad5}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          x1="10.739999771118164"
          y1="-35.96200180053711"
          x2="-30.38599967956543"
          y2="5.593999862670898"
        >
          <stop offset="0%" stopColor="rgb(19,65,157)" />
          <stop offset="50%" stopColor="rgb(9,111,183)" />
          <stop offset="100%" stopColor="rgb(0,158,209)" />
        </linearGradient>
        <linearGradient
          id={ids.grad6}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          x1="7.534999847412109"
          y1="0.6990000009536743"
          x2="-5.670000076293945"
          y2="-0.7129999995231628"
        >
          <stop offset="0%" stopColor="rgb(255,255,255)" stopOpacity="1" />
          <stop offset="50%" stopColor="rgb(255,255,255)" stopOpacity="0.5" />
          <stop offset="100%" stopColor="rgb(255,255,255)" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id={ids.grad7}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          x1="-31.014999389648438"
          y1="-11.164999961853027"
          x2="13.88700008392334"
          y2="-28.70800018310547"
        >
          <stop offset="0%" stopColor="rgb(19,65,157)" />
          <stop offset="50%" stopColor="rgb(9,111,183)" />
          <stop offset="100%" stopColor="rgb(0,158,209)" />
        </linearGradient>
        <linearGradient
          id={ids.grad8}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          x1="14.085000038146973"
          y1="-11.053999900817871"
          x2="-28.839000701904297"
          y2="7.98799991607666"
        >
          <stop offset="0%" stopColor="rgb(19,65,157)" />
          <stop offset="50%" stopColor="rgb(9,111,183)" />
          <stop offset="100%" stopColor="rgb(0,158,209)" />
        </linearGradient>
        <linearGradient
          id={ids.grad9}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          x1="7.534999847412109"
          y1="0.6990000009536743"
          x2="-5.670000076293945"
          y2="-0.7129999995231628"
        >
          <stop offset="0%" stopColor="rgb(255,255,255)" stopOpacity="1" />
          <stop offset="50%" stopColor="rgb(255,255,255)" stopOpacity="0.5" />
          <stop offset="100%" stopColor="rgb(255,255,255)" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id={ids.grad10}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          x1="-353"
          y1="280"
          x2="437"
          y2="-232"
        >
          <stop offset="0%" stopColor="rgb(0,158,209)" />
          <stop offset="50%" stopColor="rgb(9,111,183)" />
          <stop offset="100%" stopColor="rgb(19,65,157)" />
        </linearGradient>
        <linearGradient
          id={ids.grad11}
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          x1="50"
          y1="488"
          x2="-95"
          y2="-390"
        >
          <stop offset="0%" stopColor="rgb(233,137,24)" />
          <stop offset="50%" stopColor="rgb(244,175,12)" />
          <stop offset="100%" stopColor="rgb(255,214,0)" />
        </linearGradient>
      </defs>
      <g clipPath={`url(#${ids.clip1})`}>
        <g
          transform="matrix(0.6626937985420227,0.41138410568237305,-0.41138410568237305,0.6626937985420227,613.43115234375,-155.03900146484375)"
          opacity="1"
        >
          <g className={s.wave_2} clipPath={`url(${ids.clip2})`}>
            <g
              transform="matrix(0.9998934864997864,-0.014593084342777729,0.014593084342777729,0.9998934864997864,-6.25616455078125,7.449554443359375)"
              opacity="1"
            >
              <g
                opacity="1"
                transform="matrix(1,0,0,1,478.81298828125,490.22601318359375)"
              >
                <path
                  fill={`url(#${ids.grad11})`}
                  fillOpacity="1"
                  d=" M-85.98999786376953,-218.38499450683594 C-152.88099670410156,-94.64900207519531 -303.8219909667969,-211.7259979248047 -404.2349853515625,-79.88500213623047 C-504.64801025390625,51.95600128173828 -299.9419860839844,212.71400451660156 -246.5970001220703,246.23500061035156 C-193.25100708007812,279.7560119628906 -191.6540069580078,293.0360107421875 -155.2779998779297,326.89801025390625 C-97.40599822998047,380.9670104980469 14.156000137329102,380.6260070800781 115.6709976196289,329.5249938964844 C144.57400512695312,314.875 175.7899932861328,333.69000244140625 210.2270050048828,352.5039978027344 C238.39599609375,367.8949890136719 268.72100830078125,383.2860107421875 301.69500732421875,380.3590087890625 C413.4460144042969,370.2380065917969 504.64898681640625,289.0409851074219 497.00201416015625,203.12399291992188 C491.8269958496094,144.7570037841797 433.3819885253906,108.68599700927734 408.2699890136719,56.862998962402344 C386.6199951171875,12.230999946594238 392.593994140625,-36.47200012207031 392.6319885253906,-83.3489990234375 C392.6319885253906,-182.54400634765625 415.46099853515625,-304.0350036621094 293.0559997558594,-367.1210021972656 C271.3840026855469,-378.32000732421875 245.8300018310547,-383.2820129394531 218.3419952392578,-383.2829895019531 C104.42900085449219,-383.2869873046875 -42.83100128173828,-298.0820007324219 -85.98999786376953,-218.38499450683594z"
                />
              </g>
            </g>
          </g>
          <g className={s.wave_1}>
            <g
              transform="matrix(0.9997584223747253,0.021979093551635742,-0.021979093551635742,0.9997584223747253,12.091644287109375,-10.720916748046875)"
              opacity="1"
            >
              <g
                opacity="1"
                transform="matrix(1,0,0,1,467.3800048828125,522.6799926757812)"
              >
                <path
                  fill="rgb(19,65,157)"
                  fillOpacity="1"
                  d=" M351.8240051269531,44.042999267578125 C283.0679931640625,-30.26799964904785 330.2879943847656,-87.53199768066406 347.37200927734375,-165.22799682617188 C377.12701416015625,-300.7980041503906 288.281005859375,-423.9649963378906 149.0959930419922,-439.45098876953125 C23.534000396728516,-453.5299987792969 -50.5099983215332,-375.7950134277344 -99.44200134277344,-270.739990234375 C-131.06100463867188,-202.8979949951172 -180.1439971923828,-155.29800415039062 -247.41600036621094,-117.0199966430664 C-290.22100830078125,-92.62999725341797 -353.5350036621094,-78.51399993896484 -389.22601318359375,-43.279998779296875 C-495.7640075683594,61.88800048828125 -439.6789855957031,265.7950134277344 -275.0769958496094,293.79901123046875 C-210.3939971923828,304.7569885253906 -159.02699279785156,284.89599609375 -119.60800170898438,278.7699890136719 C-40.54100036621094,266.4800109863281 23.15399932861328,261.41900634765625 93.8489990234375,300.6090087890625 C191.4080047607422,354.6780090332031 291.70599365234375,453.5299987792969 411.5989990234375,364.79901123046875 C461.3299865722656,328.0050048828125 467.3800048828125,250.6510009765625 457.9049987792969,189.54400634765625 C446.3009948730469,114.16799926757812 395.5429992675781,91.2239990234375 351.8240051269531,44.042999267578125z"
                />
              </g>
              <g
                opacity="1"
                transform="matrix(1,0,0,1,475.35198974609375,517.448974609375)"
              >
                <path
                  fill="rgb(255,255,255)"
                  fillOpacity="1"
                  d=" M340.9989929199219,64.49299621582031 C272.281005859375,-9.817999839782715 335.36700439453125,-72.21800231933594 352.8699951171875,-149.83900451660156 C389.2829895019531,-311.0539855957031 293.3599853515625,-433.99200439453125 154.21400451660156,-449.5920104980469 C28.650999069213867,-463.7080078125 -80.77799987792969,-374.1400146484375 -129.7100067138672,-269.08599853515625 C-161.29100036621094,-201.24400329589844 -179.8209991455078,-161.89999389648438 -237.42799377441406,-111.78900146484375 C-274.14599609375,-79.71299743652344 -317.3320007324219,-74.23400115966797 -353.0220031738281,-39 C-459.55999755859375,66.16799926757812 -400.0509948730469,253.97799682617188 -251.6199951171875,279.5480041503906 C-211.05999755859375,286.5870056152344 -174.26600646972656,280.385009765625 -134.8470001220703,274.2590026855469 C-55.779998779296875,261.9309997558594 11.491000175476074,284.8370056152344 78.22899627685547,330.53399658203125 C165.21099853515625,390.1570129394531 284.03900146484375,463.7070007324219 403.97100830078125,375.052001953125 C453.739013671875,338.25799560546875 459.5610046386719,263.64300537109375 450.08599853515625,202.57400512695312 C438.3290100097656,127.27400207519531 384.7550048828125,111.63700103759766 340.9989929199219,64.49299621582031z"
                />
              </g>
              <g
                opacity="1"
                transform="matrix(1,0,0,1,467.3139953613281,523.5449829101562)"
              >
                <path
                  fill={`url(#${ids.grad10})`}
                  fillOpacity="1"
                  d=" M-102.7239990234375,-274.1919860839844 C-134.34300231933594,-206.5030059814453 -173.11599731445312,-149.2760009765625 -237.3419952392578,-107.76499938964844 C-278.7409973144531,-81.12899780273438 -328.6610107421875,-70.05699920654297 -364.35101318359375,-34.82400131225586 C-470.8900146484375,70.34400177001953 -411.3810119628906,257.96600341796875 -262.95001220703125,283.7250061035156 C-222.38900756835938,290.7640075683594 -185.59500122070312,279.1600036621094 -146.17599487304688,273.03399658203125 C-67.11000061035156,260.7439880371094 -7.90500020980835,265.42401123046875 67.28099822998047,294.83599853515625 C172.14500427246094,336.1579895019531 275.3349914550781,447.0329895019531 395.2669982910156,358.1499938964844 C445.3819885253906,320.4119873046875 470.8890075683594,258.32000732421875 461.7770080566406,196.25 C450.0950012207031,120.8740005493164 401.1260070800781,111.93299865722656 357.44500732421875,64.75199890136719 C288.7279968261719,-9.557999610900879 345.7650146484375,-79.49400329589844 362.8110046386719,-157.22900390625 C392.5660095214844,-292.7980041503906 294.8550109863281,-429.8139953613281 155.67100524902344,-445.41400146484375 C145.99099731445312,-446.50201416015625 136.55499267578125,-447.031005859375 127.36799621582031,-447.031005859375 C17.375999450683594,-447.0329895019531 -57.564998626708984,-371.3240051269531 -102.7239990234375,-274.1919860839844z"
                />
              </g>
            </g>
          </g>
        </g>
        <g className={s.drop_1}>
          <g
            transform="matrix(-0.21517565846443176,0.8944827914237976,-0.8944827914237976,-0.21517565846443176,758.4658203125,69.56929016113281)"
            opacity="1"
          >
            <g
              transform="matrix(-0.28410834074020386,1.3708691596984863,-1.3708691596984863,-0.28410834074020386,59.56635284423828,97.0754165649414)"
              opacity="1"
            >
              <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                <path
                  fill={`url(#${ids.grad7})`}
                  fillOpacity="1"
                  d=" M3.375,-61.1609992980957 C3.375,-61.1609992980957 -27,-43.67399978637695 -27,-7 C-27,12.375 -15.29699993133545,18.472999572753906 -7.296999931335449,18.472999572753906 C0.703000009059906,18.472999572753906 11.881999969482422,14.1899995803833 11.881999969482422,-3.322000026702881 C11.881999969482422,-10.675999641418457 6.646999835968018,-23.558000564575195 4.701000213623047,-33.03200149536133 C1.7860000133514404,-47.22800064086914 3.375,-61.1609992980957 3.375,-61.1609992980957z"
                />
                <path
                  stroke={`url(#${ids.grad8})`}
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  fillOpacity="0"
                  strokeMiterlimit="4"
                  strokeOpacity="1"
                  strokeWidth="4"
                  d=" M3.375,-61.1609992980957 C3.375,-61.1609992980957 -27,-43.67399978637695 -27,-7 C-27,12.375 -15.29699993133545,18.472999572753906 -7.296999931335449,18.472999572753906 C0.703000009059906,18.472999572753906 11.881999969482422,14.1899995803833 11.881999969482422,-3.322000026702881 C11.881999969482422,-10.675999641418457 6.646999835968018,-23.558000564575195 4.701000213623047,-33.03200149536133 C1.7860000133514404,-47.22800064086914 3.375,-61.1609992980957 3.375,-61.1609992980957z"
                />
              </g>
              <g
                opacity="1"
                transform="matrix(-0.19202525913715363,0.9813899993896484,-0.9813899993896484,-0.19202525913715363,-7.973999977111816,2.9590001106262207)"
              >
                <path
                  fill={`url(#${ids.grad9})`}
                  fillOpacity="1"
                  d=" M0,-9.958499908447266 C3.449651002883911,-9.958499908447266 6.250500202178955,-5.496096134185791 6.250500202178955,0 C6.250500202178955,5.496096134185791 3.449651002883911,9.958499908447266 0,9.958499908447266 C-3.449651002883911,9.958499908447266 -6.250500202178955,5.496096134185791 -6.250500202178955,0 C-6.250500202178955,-5.496096134185791 -3.449651002883911,-9.958499908447266 0,-9.958499908447266z"
                />
              </g>
            </g>
          </g>
        </g>
        <g className={s.drop_2}>
          <g
            transform="matrix(0.24464565515518188,0.7195473909378052,-0.7195473909378052,0.24464565515518188,655.4901733398438,22.0806884765625)"
            opacity="1"
          >
            <g
              transform="matrix(0.10461387783288956,1.0749213695526123,-1.0749213695526123,0.10461387783288956,76.38604736328125,132.8726043701172)"
              opacity="1"
            >
              <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                <path
                  fill={`url(#${ids.grad4})`}
                  fillOpacity="1"
                  d=" M7.5920000076293945,-56.275001525878906 C7.5920000076293945,-56.275001525878906 -27.069000244140625,-48.39500045776367 -27,-7 C-26.968000411987305,12.375 -13.168999671936035,18.62700080871582 -5.169000148773193,18.62700080871582 C2.8310000896453857,18.62700080871582 12.201000213623047,12.157999992370605 10.883999824523926,-3.621000051498413 C10.272000312805176,-10.949999809265137 4.441999912261963,-22.64699935913086 2.496000051498413,-32.12099838256836 C-0.4189999997615814,-46.31700134277344 7.5920000076293945,-56.275001525878906 7.5920000076293945,-56.275001525878906z"
                />
                <path
                  stroke={`url(#${ids.grad5})`}
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  fillOpacity="0"
                  strokeMiterlimit="4"
                  strokeOpacity="1"
                  strokeWidth="4"
                  d=" M7.5920000076293945,-56.275001525878906 C7.5920000076293945,-56.275001525878906 -27.069000244140625,-48.39500045776367 -27,-7 C-26.968000411987305,12.375 -13.168999671936035,18.62700080871582 -5.169000148773193,18.62700080871582 C2.8310000896453857,18.62700080871582 12.201000213623047,12.157999992370605 10.883999824523926,-3.621000051498413 C10.272000312805176,-10.949999809265137 4.441999912261963,-22.64699935913086 2.496000051498413,-32.12099838256836 C-0.4189999997615814,-46.31700134277344 7.5920000076293945,-56.275001525878906 7.5920000076293945,-56.275001525878906z"
                />
              </g>
              <g
                opacity="1"
                transform="matrix(-0.7093247175216675,0.7048818469047546,-0.7048818469047546,-0.7093247175216675,-11.473999977111816,2.7090001106262207)"
              >
                <path
                  fill={`url(#${ids.grad6})`}
                  fillOpacity="1"
                  d=" M0,-9.958499908447266 C3.449651002883911,-9.958499908447266 6.250500202178955,-5.496096134185791 6.250500202178955,0 C6.250500202178955,5.496096134185791 3.449651002883911,9.958499908447266 0,9.958499908447266 C-3.449651002883911,9.958499908447266 -6.250500202178955,5.496096134185791 -6.250500202178955,0 C-6.250500202178955,-5.496096134185791 -3.449651002883911,-9.958499908447266 0,-9.958499908447266z"
                />
              </g>
            </g>
          </g>
        </g>
        <g className={s.drop_3}>
          <g
            transform="matrix(0.5706800818443298,0.1497470587491989,-0.1497470587491989,0.5706800818443298,584.4066772460938,-25.54271697998047)"
            opacity="1"
          >
            <g
              transform="matrix(0.699995756149292,0,0,0.699995756149292,30.00042724609375,29.515052795410156)"
              opacity="1"
            >
              <g opacity="1" transform="matrix(1,0,0,1,100,100.02300262451172)">
                <path
                  fill={`url(#${ids.grad1})`}
                  fillOpacity="1"
                  d=" M0,-27.760000228881836 C15.331000328063965,-27.760000228881836 27.760000228881836,-15.331000328063965 27.760000228881836,0 C27.760000228881836,15.331999778747559 15.331000328063965,27.760000228881836 0,27.760000228881836 C-15.331999778747559,27.760000228881836 -27.760000228881836,15.331999778747559 -27.760000228881836,0 C-27.760000228881836,-15.331000328063965 -15.331999778747559,-27.760000228881836 0,-27.760000228881836z"
                />
              </g>
              <g opacity="1" transform="matrix(1,0,0,1,100,100.02300262451172)">
                <path
                  stroke={`url(#${ids.grad2})`}
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  fillOpacity="0"
                  strokeMiterlimit="4"
                  strokeOpacity="1"
                  strokeWidth="4"
                  d=" M0,-27.760000228881836 C15.331000328063965,-27.760000228881836 27.760000228881836,-15.331000328063965 27.760000228881836,0 C27.760000228881836,15.331999778747559 15.331000328063965,27.760000228881836 0,27.760000228881836 C-15.331999778747559,27.760000228881836 -27.760000228881836,15.331999778747559 -27.760000228881836,0 C-27.760000228881836,-15.331000328063965 -15.331999778747559,-27.760000228881836 0,-27.760000228881836z"
                />
              </g>
              <g opacity="1" transform="matrix(1,0,0,1,100,91.10299682617188)">
                <path
                  fill={`url(#${ids.grad3})`}
                  fillOpacity="1"
                  d=" M0,-10.479999542236328 C7.8429999351501465,-10.479999542236328 14.199999809265137,-5.788000106811523 14.199999809265137,0 C14.199999809265137,5.788000106811523 7.8429999351501465,10.479999542236328 0,10.479999542236328 C-7.8420000076293945,10.479999542236328 -14.199999809265137,5.788000106811523 -14.199999809265137,0 C-14.199999809265137,-5.788000106811523 -7.8420000076293945,-10.479999542236328 0,-10.479999542236328z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default KeyVisual
