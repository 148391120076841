import React from "react"

import s from "./HeroWithText.module.scss"
import { ChildrenButtonRenderer } from "../../"

class HeroWithText extends React.Component {
  render() {
    const { title_one_text, title_two_text, description } = this.props
    return (
      <section className={s.HeroWithText}>
        <div className={s.BannerWrapper}>
          <div className={s.RedBanner}>
            <h1
              className={s.PageTitle}
              dangerouslySetInnerHTML={{ __html: title_one_text }}
            ></h1>
          </div>
        </div>
        <div className={s.ContentWrapper}>
          <h2 dangerouslySetInnerHTML={{ __html: title_two_text }}></h2>
          <p dangerouslySetInnerHTML={{ __html: description }}></p>
          <ChildrenButtonRenderer buttonData={this.props.innerBlocks} />
        </div>
      </section>
    )
  }
}

export default HeroWithText
