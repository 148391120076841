import React from "react"
import s from "./AccountSection.module.scss"
// import { LANGUAGE_ABBREVATIONS } from "../../../constants"
import { getCookie } from "../../../helper"
import { HikingAuthConsumer } from "../../../store"

// const { de, fr, it } = {
//   de: LANGUAGE_ABBREVATIONS.german.lang,
//   fr: LANGUAGE_ABBREVATIONS.french.lang,
//   it: LANGUAGE_ABBREVATIONS.italian.lang,
// }

// const welcomeTranslation = {
//   [de]: "Willkommen",
//   [fr]: "Bonjour",
//   [it]: "Benvenuta",
// }

// const yourHighScoreMsg = {
//   [de]: `Deine Codezahl lautet`,
//   [fr]: `Ton score est de`,
//   [it]: `Hai già inserito`,
// }

// const enterCodesTranslation = {
//   [de]: `Gib weiter Codes ein, um deinen Highscore zu steigern und deine Chance auf einen der attraktiven Hauptpreise zu erhöhen`,
//   [fr]: `Continue à entrer des codes pour augmenter ton score et tes chances de gagner l'un des prix principaux attractifs.`,
//   [it]: `Continua a inserire codici per aumentare il tuo punteggio massimo e le tue possibilità di vincere uno dei premi principali.`,
// }

// const goodluckTranslation = {
//   [de]: "Viel Glück!",
//   [fr]: "Bonne chance!",
//   [it]: "Buona fortuna!",
// }

class AccountSection extends React.Component {
  state = {
    first_name: "",
  }

  componentDidMount() {
    const userHash = getCookie("user_hash")
    const first_name = getCookie("first_name")
    if (userHash && first_name) {
      this.setState({ first_name })
    }
  }

  render() {
    const { first_name } = this.state
    const { welcome, yourHighScoreMsg, goodluck, enterCodes } = this.props
    return (
      <HikingAuthConsumer>
        {(store) => {
          const { totalCodeCount, codeStatus } = store.state
          const showGewinnNiete = codeStatus.showMessageBox
          return (
            <>
              {!showGewinnNiete && (
                <section className={s.AccountPageContainer}>
                  <div className={s.WelcomeMessageWrapper}>
                    <h2 className={s.WelcomeMessage}>
                      <span
                        dangerouslySetInnerHTML={{ __html: welcome }}
                      ></span>
                      {` ${first_name}`}
                      {/* {`${welcomeTranslation[lang]} ${first_name}`} */}
                    </h2>
                    <p className={s.WelcomeMessage}>{`${yourHighScoreMsg}`}</p>
                    <p
                      className={`${s.WelcomeMessage} ${s.HighscoreBox}`}
                      dangerouslySetInnerHTML={{
                        __html: totalCodeCount ? totalCodeCount : "0",
                      }}
                    ></p>
                  </div>
                  <p
                    className={s.EnterCodesMessage}
                    dangerouslySetInnerHTML={{ __html: enterCodes }}
                  ></p>
                  <p
                    className={s.EnterCodesMessage}
                    dangerouslySetInnerHTML={{ __html: goodluck }}
                  ></p>
                </section>
              )}
            </>
            // <section className={s.AccountPageContainer}>
            //   <div className={s.WelcomeMessageWrapper}>
            //     <h2 className={s.WelcomeMessage}>
            //       {`${welcomeTranslation[lang]} ${first_name}`}
            //       {/* {`${welcomeTranslation[lang]} ${first_name}`} */}
            //     </h2>
            //     <p
            //       className={s.WelcomeMessage}
            //     >{`${yourHighScoreMsg[lang]}:`}</p>
            //     <p className={`${s.WelcomeMessage} ${s.HighscoreBox}`}>
            //       {totalCodeCount}
            //     </p>
            //   </div>
            //   <p className={s.EnterCodesMessage}>
            //     {enterCodesTranslation[lang]}
            //   </p>
            //   <p className={s.EnterCodesMessage}>{goodluckTranslation[lang]}</p>
            // </section>
          )
        }}
      </HikingAuthConsumer>
    )
    // return (
    //   <section className={s.AccountPageContainer}>
    //     <div className={s.WelcomeMessageWrapper}>
    //       <h2 className={s.WelcomeMessage}>
    //         {`${welcomeTranslation[lang]} ${first_name}`}
    //       </h2>
    //       <p className={s.WelcomeMessage}>{`${yourHighScoreMsg[lang]}:`}</p>
    //       <p className={`${s.WelcomeMessage} ${s.HighscoreBox}`}>
    //         {totalCodeCount}
    //       </p>
    //     </div>
    //     <p className={s.EnterCodesMessage}>{enterCodesTranslation[lang]}</p>
    //     <p className={s.EnterCodesMessage}>{goodluckTranslation[lang]}</p>
    //   </section>
    // )
  }
}

export default AccountSection
