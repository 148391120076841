import React, { useState } from "react"
import { graphql, StaticQuery } from "gatsby"

import s from "./ExternalSvg.module.scss"
import { ssrFriendlyFetch } from "../../helper"

const ExternalSvg = ({ fileName, cn, style = {}, ref }) => {
  const [svgData, setSvgData] = useState(null)

  const getSvgText = async (publicURL) => {
    // in SSR it might fail as it's publicURL, it'll pick up on client side
    const res = await ssrFriendlyFetch(publicURL)
      .then((res) => res.text())
      .catch(() => null)
    setSvgData(res)
  }
  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(filter: { extension: { eq: "svg" } }) {
            nodes {
              absolutePath
              publicURL
              base
            }
          }
        }
      `}
      render={(data) => {
        const found = data.allFile.nodes.find((element) => {
          return element.base === fileName
        })
        if (!found) return null
        if (!svgData) {
          getSvgText(found.publicURL, found.absolutePath)
        }
        return (
          <div
            ref={ref}
            dangerouslySetInnerHTML={{
              __html: svgData,
            }}
            style={{
              ...style,
            }}
            className={[s.SvgWrapper, cn].join(" ")}
          />
        )
      }}
    />
  )
}

export default ExternalSvg
