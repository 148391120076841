import React from "react"
import { Link, navigate } from "gatsby"

import s from "./Header.module.scss"
import Logo from "../../assets/svgs/logo.svg"
import { LanguagePicker, Search, ImgFluid } from "../../components"
import { HikingAuthConsumer } from "../../store"

import {
  getCurrentLanguage,
  getCookie,
  setCommonIdentifier,
} from "../../helper"
import { SITE_SCOPES, winAndHike_routes } from "../../constants"
import LanguagesData, { routes } from "./LanguagesData"
import detectBrowserLanguage from "detect-browser-language"

const getBrowserLanguage = () => {
  let browserLanguage
  const supportedLanguages = ["de", "fr", "it"]

  if ("navigator" in window && (navigator.language || navigator.userLanguage)) {
    browserLanguage = navigator.language || navigator.userLanguage
  } else {
    browserLanguage = detectBrowserLanguage()
  }
  if (!browserLanguage || !supportedLanguages.includes(browserLanguage)) {
    return "de"
  }

  return browserLanguage.slice(0, 2)
}

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      headerIsFixed: false,
      menuIsOpen: false,
      mobileLangPickerOpen: false,
    }
  }

  componentDidMount() {
    const {
      location: { pathname },
    } = this.props

    const browserLanguage = getBrowserLanguage()
    const langCookie = getCookie("current_lang")
    if (
      pathname.endsWith("/de/promotion") ||
      (pathname.endsWith("/de/promotion/") && !langCookie)
    ) {
      document.cookie = "current_lang=; expires=Thu, 01 Jan 1970 00:00:00 UTC"
      document.cookie = "current_lang=" + browserLanguage + "; path=/"
      // if (routes[browserLanguage].promotion) {
      //   navigateTo(`/${browserLanguage}${routes[browserLanguage].promotion}`)
      // }
    }

    if (typeof document !== "undefined") {
      document.addEventListener("scroll", this.shouldHeaderBeFixed)
    }
  }

  componentWillUnmount() {
    if (typeof document !== "undefined") {
      document.removeEventListener("scroll", this.shouldHeaderBeFixed)
    }
  }

  shouldHeaderBeFixed = () => {
    const { headerIsFixed } = this.state
    if (headerIsFixed && 50 > window.scrollY) {
      return this.setState({
        headerIsFixed: false,
      })
    }
    if (!headerIsFixed && 50 < window.scrollY) {
      return this.setState({
        headerIsFixed: true,
      })
    }
  }

  setMobileLangPickerOpen = (to) => {
    this.setState({
      mobileLangPickerOpen: to,
    })
  }

  toggleMenuIsOpen = () => {
    this.setMenuIsOpen(!this.state.menuIsOpen)
  }

  setMenuIsOpen = (to) => {
    this.setState({
      menuIsOpen: to,
    })
  }

  onRoute = (e, refresh, route, location) => {
    if (refresh) {
      e.preventDefault()
      const prevRefreshed =
        location && location.state && location.state.refreshed
      navigate(route, {
        state: {
          refreshed: !prevRefreshed,
        },
      })
    }
  }

  renderItem = (type, options, extraParams = {}, currentLang) => {
    const { uri, label, commonIdentifier } = options

    const LinkBoldClassName = [s.Link, s.LinkBold].join(" ")
    const uriFixed = uri && uri.length && !uri.startsWith("/") ? `/${uri}` : uri

    switch (type) {
      case "internal-link-bold":
        return (
          <HikingAuthConsumer key={currentLang + uriFixed}>
            {(store) => {
              const { resetCodeForm } = store.actions
              const { location } = this.props
              const matchesPathname =
                `/${currentLang}${uriFixed}` === location.pathname ||
                `/${currentLang}${uriFixed}/` === location.pathname
              return (
                <Link
                  className={LinkBoldClassName}
                  activeClassName={s.LinkActive}
                  to={`/${currentLang}${uriFixed}`}
                  key={uri}
                  onClick={(e) => {
                    // Only push a new history object if URL is different
                    if (!matchesPathname) {
                      this.onRoute(
                        e,
                        matchesPathname,
                        location.pathname,
                        location
                      )
                    }
                    if (
                      winAndHike_routes[currentLang] &&
                      uri === winAndHike_routes[currentLang].gewinnspiel
                    ) {
                      resetCodeForm(true)
                    }
                    if (commonIdentifier) {
                      setCommonIdentifier(commonIdentifier)
                    }
                  }}
                >
                  {label}
                </Link>
              )
            }}
          </HikingAuthConsumer>
        )
      case "hiking-login":
        return (
          <HikingAuthConsumer>
            {(store) => {
              const { resetCodeForm } = store.actions
              let isLoggedIn = store.state.isLoggedIn
              if (!store.state.checkedLogin) {
                isLoggedIn = store.actions.checkLogin()
              }
              let uriLink = `/${currentLang}${uriFixed}`
              if (isLoggedIn) {
                uriLink = `/${currentLang}${winAndHike_routes[currentLang].account}`
              } else {
                uriLink = `/${currentLang}${winAndHike_routes[currentLang].login}`
              }

              return (
                <Link
                  className={LinkBoldClassName}
                  activeClassName={s.LinkActive}
                  to={uriLink}
                  key={uri}
                  onClick={() => {
                    if (commonIdentifier) {
                      setCommonIdentifier(commonIdentifier)
                    }
                    if (
                      isLoggedIn &&
                      uri.includes(winAndHike_routes[currentLang].account)
                    ) {
                      resetCodeForm(true)
                      setCommonIdentifier("account")
                    }
                  }}
                >
                  {isLoggedIn ? options.accountLabel : label}
                </Link>
              )
            }}
          </HikingAuthConsumer>
        )
      case "external-link-top":
        return (
          <a
            className={LinkBoldClassName}
            href={uri}
            key={uri}
            rel="noopener noreferrer"
          >
            {label}
          </a>
        )
      case "external-link":
        return (
          <a
            className={s.Link}
            href={uri}
            key={uri}
            target="_blank"
            rel="noopener noreferrer"
          >
            {label}
          </a>
        )
      case "search":
        return (
          <div className={s.SearchWrapper} key="search">
            <Search
              allProducts={this.props.allProducts}
              allEvents={this.props.allEvents}
              allPages={this.props.allPages}
              location={this.props.location}
            />
          </div>
        )
      case "language":
        return (
          <LanguagePicker
            key="lang"
            location={this.props.location}
            languageOptions={this.props.languageOptions}
            type="header"
            isHikingDesign={extraParams.isHikingHeader}
          />
        )
      default:
        return
    }
  }

  // scope is whether it is a promotion or main site etc to define appropriate header
  renderHeader = (scope = SITE_SCOPES.main) => {
    const { isTransparent, languageOptions, location, allPages } = this.props
    const { headerIsFixed, menuIsOpen } = this.state

    const headerClass = isTransparent
      ? [s.Header, s.HeaderTransparent]
      : [s.Header, s.HeaderSolid]

    const linksWrapperClass = menuIsOpen
      ? [s.LinksWrapper, s.LinksWrapperSmallVisible]
      : [s.LinksWrapper]

    const BurgerButtonClasses = menuIsOpen
      ? [s.BurgerButton, s.BurgerButtonOpen]
      : [s.BurgerButton]

    if (headerIsFixed) headerClass.push(s.HeaderFixed)

    let currentLangObj = languageOptions
      ? languageOptions.currentLang
      : getCurrentLanguage(location)

    let slug = null
    let combined = null

    /**
     * On event pages languageOptions.currentLang is a string
     * Other places it's an object
     */
    if (typeof currentLangObj === "string") {
      slug = currentLangObj
      combined = currentLangObj
    } else {
      slug = currentLangObj.slug
      combined = currentLangObj.langHref
    }

    const chosenLangData = LanguagesData[scope][combined]
    const page_have_promotion = allPages?.find((p) =>
      p.path?.endsWith("winter-promotion")
    )
    if (page_have_promotion && chosenLangData) {
      chosenLangData.leftLinks[0].uri = routes[combined].winterPromotion
    }
    if (scope === SITE_SCOPES.main) {
      return (
        <header className={headerClass.join(" ")}>
          <Link
            className={s.Logo}
            itemProp="url"
            to={`/${slug}${chosenLangData?.logo.uri}`}
            onClick={() => {
              if (chosenLangData?.logo.commonIdentifier) {
                setCommonIdentifier(chosenLangData.logo.commonIdentifier)
              }
            }}
          >
            <Logo />
            <div style={{ display: "none" }}>
              <ImgFluid fileName="rivella_logo.png" itemProp="logo" />
            </div>
          </Link>
          <div className={linksWrapperClass.join(" ")}>
            <div className={s.HeaderLeft}>
              <span
                className={s.CloseButton}
                onClick={() => this.setMenuIsOpen(false)}
              >
                <div />
                <div />
              </span>
              {chosenLangData?.leftLinks.map((L) =>
                this.renderItem(L.type, L, {}, slug)
              )}
            </div>
            <div className={s.HeaderRight}>
              {chosenLangData?.rightLinks.map((L) =>
                this.renderItem(L.type, L, {}, slug)
              )}
            </div>
          </div>
          {menuIsOpen && (
            <div
              className={s.Backdrop}
              onClick={() => this.setMenuIsOpen(false)}
            />
          )}
          <div
            className={BurgerButtonClasses.join(" ")}
            onClick={this.toggleMenuIsOpen}
          >
            <div />
            <div />
            <div />
          </div>
        </header>
      )
    } else if (scope === SITE_SCOPES.winAndHike) {
      return (
        <header
          className={headerClass.join(" ")}
          itemScope
          itemType="http://schema.org/Brand"
        >
          <Link className={s.Logo} to={`/${slug}${chosenLangData?.logo.uri}`}>
            <Logo />
            <div style={{ display: "none" }}>
              <ImgFluid fileName="rivella_logo.png" itemProp="logo" />
            </div>
          </Link>
          <div className={linksWrapperClass.join(" ")}>
            <div className={s.HeaderLeft}>
              {chosenLangData?.leftLinks.map((L) =>
                this.renderItem(
                  L.type,
                  L,
                  {
                    isHikingHeader: true,
                  },
                  slug
                )
              )}
            </div>
            <div className={s.HeaderRight}>
              {chosenLangData?.rightLinks.map((L) =>
                this.renderItem(
                  L.type,
                  L,
                  {
                    isHikingHeader: true,
                  },
                  slug
                )
              )}
            </div>
          </div>
          {menuIsOpen && (
            <div
              className={s.Backdrop}
              onClick={() => this.setMenuIsOpen(false)}
            />
          )}
          <div
            className={BurgerButtonClasses.join(" ")}
            onClick={this.toggleMenuIsOpen}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
        </header>
      )
    }
  }

  render() {
    const { siteScope } = this.props
    return this.renderHeader(siteScope)
  }
}

export default Header
