import React from "react"

import s from "./ThreePrizesWithImageCount.module.scss"
import { PrizeImageWithCount } from "../../components"

class ThreePrizesWithImageCount extends React.Component {
  render() {
    const { imagesData = [] } = this.props
    return (
      <section className={s.ThreePrizesWithImageCount}>
        {imagesData.map((d) => (
          <PrizeImageWithCount
            count={d.count}
            alt={d.alt}
            img={d.img}
            key={d.alt ? d.alt : d.img}
            className={[s.PrizeImageWithCount, d.className].join(" ")}
          />
        ))}
      </section>
    )
  }
}

export default ThreePrizesWithImageCount
