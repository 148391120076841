import React from "react"
import Helmet from "react-helmet"

import {
  ProductOverview,
  ProductNutritions,
  ProductIngredients,
  BottleWithDroplets,
} from "../../components"
import { Layout } from "../../containers"
import s from "./ProductDetail.module.scss"
import { language_code_to_static, product_range_route, product_yellow_meta } from "../../constants"
import {
  extractImageNameFromUrl,
  getCurrentLanguage,
  scrollToRef,
  getPosition,
} from "../../helper"
import ArrowDownIcon from "../../assets/svgs/arrow_down.svg"

const viewNames = {
  ProductOverview: "ProductOverview",
  ProductIngredients: "ProductIngredients",
  ProductNutritions: "ProductNutritions",
}

const BOTTLE_TRANSITION_LENGTH = 600 // ALSO UPDATE IN CSS
const SECTION_TRANSACTION_MOBILE_THRESHOLD = 1200

const pageDescriptions = {
  blau: `Der volle Rivella Geschmack bei wenig Kalorien, für die besonders leichte Erfrischung.`,
  grüntee: `Die erfrischende Kombination aus Rivella und Grüntee. Die leichte Alternative mit dem gewissen Extra.`,
  holunderblüte: `Die überraschende Kombination aus Rivella und Holunderblüten-Aromen bringt noch mehr Abwechslung in deinen Alltag.`,
  refresh: `Die spritzig-leichte Variante des Originals. Ideal für alle, die sich im Alltag oder in der Freizeit einen leichten, weniger süssen Durstlöscher wünschen.`,
  rot: `Rivella Rot ist der einzigartige Geschmack der aktiven Schweiz, hergestellt nach dem geheimen Originalrezept – unverändert und unverwechselbar seit 1952.`,
  gelb: `Dürfen wir vorstellen: das voll vegane Rivella Gelb, ganz ohni Milchzügs, mit 40 % weniger Zucker als das Original. Jetzt entdecken!`,
  jaune: `Nous avons le plaisir de te présenter Rivella Jaune: sans lactosérum, 100% végan, avec 40% de sucre en moins que l’original. Découvrir maintenant!`,
  giallo: `Vi presentiamo: la Rivella Gialla, completamente vegana, senza latticini e con il 40% di zucchero in meno rispetto all'originale. Scopritela subito!`,
}

const pageNames = {
  gelb: `Rivella Gelb - voll vegan, -40% Zucker`,
  jaune: `Rivella Jaune - entièrement végan, avec 40 % de sucre`,
  giallo: `Rivella Gialla - completamente vegano, con il 40% di zucchero`,
}

class ProductDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentView: viewNames.ProductOverview,
      recalcMaskSignal: false,
      isNavigating: false,
      sideNavDirection: null,
      nextProductColor: null,
    }
    this.overviewRef = React.createRef()
    this.ingredientsRef = React.createRef()
    this.nutritionsRef = React.createRef()
    this.mainBottleRef = React.createRef()
    this.overviewBottleRef = React.createRef()
    this.ingredientsBottleRef = React.createRef()
    this.nutritionsBottleRef = React.createRef()
  }

  componentDidMount() {
    const fadeInAnimateExceptMainBottle =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.isComingFromSortiment

    if (typeof document !== "undefined") {
      if (window.innerWidth > 1150) {
        window.addEventListener("resize", () =>
          this.navigateBottleTo(this.state.currentView)
        )
        document.addEventListener("scroll", this.updateActiveCurrentSection)
      }
    }
    if (
      fadeInAnimateExceptMainBottle &&
      typeof document !== "undefined" &&
      typeof window !== "undefined" &&
      window.innerWidth > SECTION_TRANSACTION_MOBILE_THRESHOLD
    ) {
      const initialScrollTop =
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.initialScrollTop
      const htmlEle = document.querySelector("html")
      htmlEle.scroll(0, initialScrollTop)
      setTimeout(() => {
        htmlEle.scroll(0, initialScrollTop)
      }, 5)
    }
  }

  componentWillUnmount() {
    clearInterval(this.navigatingSyncInterval)
    if (typeof document !== "undefined") {
      window.removeEventListener("resize", () =>
        this.navigateBottleTo(this.state.currentView)
      )
      document.removeEventListener("scroll", this.updateActiveCurrentSection)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentView !== this.state.currentView) {
      this.recalcMaskSignalInterval = setInterval(() => {
        this.setState({
          recalcMaskSignal: !this.state.recalcMaskSignal,
        })
      }, 50)
      setTimeout(() => {
        clearInterval(this.recalcMaskSignalInterval)
      }, BOTTLE_TRANSITION_LENGTH + 50)
      this.navigateBottleTo(this.state.currentView)
    }
  }

  triggerNavigateSync = (to, nextColor) => {
    this.setState({
      isNavigating: true,
      sideNavDirection: to,
      nextProductColor: nextColor,
    })
    // this.navigatingSyncInterval = setInterval(() => {
    //   console.log("navigate sync")
    //   this.navigateBottleTo(this.state.currentView)
    // }, 10)
  }

  navigateBottleTo = (to) => {
    const mainBottle = this.mainBottleRef.current
    const refs = {
      [viewNames.ProductOverview]: this.overviewBottleRef.current,
      [viewNames.ProductIngredients]: this.ingredientsBottleRef.current,
      [viewNames.ProductNutritions]: this.nutritionsBottleRef.current,
    }
    const targetBottle = refs[to]

    if (targetBottle && mainBottle) {
      const { x, y } = getPosition(targetBottle)
      const { clientWidth, clientHeight } = targetBottle
      const transform =
        to === viewNames.ProductOverview ? "translate(-50%, -50%)" : "none"

      mainBottle.style.width = clientWidth + "px"
      mainBottle.style.height = clientHeight + "px"
      mainBottle.style.left = x + "px"
      mainBottle.style.top = y + "px"
      mainBottle.style.transform = transform
    }
  }

  updateActiveCurrentSection = () => {
    const {
      // overviewRef,
      ingredientsRef,
      nutritionsRef,
    } = this
    const { scrollY, innerHeight } = window

    // const { y: y1 } = getPosition(overviewRef.current)
    const { y: y2 } = getPosition(ingredientsRef.current)
    const { y: y3 } = getPosition(nutritionsRef.current)

    const scrollThreshold = scrollY + innerHeight / 2

    let nextView
    if (y3 < scrollThreshold) {
      nextView = viewNames.ProductNutritions
    } else if (y2 < scrollThreshold) {
      nextView = viewNames.ProductIngredients
    } else {
      nextView = viewNames.ProductOverview
    }

    if (nextView !== this.state.currentView) {
      this.setState({
        currentView: nextView,
      })
    }
  }

  onNextSection = () => {
    const { currentView } = this.state
    const sections = {
      [viewNames.ProductIngredients]: this.ingredientsBottleRef,
      [viewNames.ProductNutritions]: this.nutritionsBottleRef,
    }
    if (currentView === viewNames.ProductOverview) {
      scrollToRef(sections[viewNames.ProductIngredients], {
        behavior: "smooth",
        block: "center",
      })
      if (
        typeof window !== "undefined" &&
        window.innerWidth < SECTION_TRANSACTION_MOBILE_THRESHOLD
      ) {
        this.setState({
          currentView: viewNames.ProductIngredients,
        })
      }
    } else if (currentView === viewNames.ProductIngredients) {
      scrollToRef(sections[viewNames.ProductNutritions], {
        behavior: "smooth",
        block: "center",
      })
      if (
        typeof window !== "undefined" &&
        window.innerWidth < SECTION_TRANSACTION_MOBILE_THRESHOLD
      ) {
        this.setState({
          currentView: viewNames.ProductNutritions,
        })
      }
    }
  }

  navigateOtherView = (to) => {
    if (typeof document !== "undefined") {
      const html = document.querySelector("html")
      html.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    }
    this.setState(
      {
        currentView: to,
        recalcMaskSignal: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            recalcMaskSignal: false,
          })
        }, 510)
      }
    )
  }

  scrollToOtherView = (to) => {
    switch (to) {
      case viewNames.ProductIngredients:
        scrollToRef(this.ingredientsRef)
        if (
          typeof window !== "undefined" &&
          window.innerWidth < SECTION_TRANSACTION_MOBILE_THRESHOLD
        ) {
          this.setState({
            currentView: viewNames.ProductIngredients,
          })
        }
        break
      case viewNames.ProductNutritions:
        scrollToRef(this.nutritionsRef)
        if (
          typeof window !== "undefined" &&
          window.innerWidth < SECTION_TRANSACTION_MOBILE_THRESHOLD
        ) {
          this.setState({
            currentView: viewNames.ProductNutritions,
          })
        }
        break
      default:
        scrollToRef(this.overviewRef)
        if (
          typeof window !== "undefined" &&
          window.innerWidth < SECTION_TRANSACTION_MOBILE_THRESHOLD
        ) {
          this.setState({
            currentView: viewNames.ProductOverview,
          })
        }
        break
    }
  }

  calculateProductTitleSize = (title_one, title_two) => {
    if (typeof window === "undefined" || !title_one) return null

    let longestLength = title_one.length
    if (title_two) {
      longestLength =
        title_one.length > title_two.length
          ? title_one.length
          : title_two.length
    }

    const { innerWidth } = window
    let baseSize = 100
    let scale = 1 // default for length > 12
    if (innerWidth < 400) baseSize = 40
    else if (innerWidth < 750) baseSize = 50
    else if (innerWidth < 1200) baseSize = 80

    if (longestLength > 40) scale = 0.75
    else if (longestLength > 34) scale = 0.8
    else if (longestLength > 24) scale = 0.9

    return baseSize * scale
  }

  renderCurrentSection = (viewName, imageName) => {
    const fadeInAnimateExceptMainBottle =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.isComingFromSortiment

    const productNameObj =
      this.props.pageContext &&
      this.props.pageContext.childrenData &&
      this.props.pageContext.childrenData.find(
        (c) => c.type === "rivella/product-overview"
      )
    const productName = productNameObj
      ? productNameObj.props.title_one_text
      : null
    const productColor = productNameObj
      ? productNameObj.props.product_primary_color
      : null
    const productSecondaryColor = productNameObj
      ? productNameObj.props.product_secondary_color
      : null
    let found = {}
    const bottleSlideAnimate =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.bottleSlideAnimate
    const bottleSlideDirection =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.bottleSlideDirection
    switch (viewName) {
      case viewNames.ProductIngredients:
        found =
          this.props.pageContext &&
          this.props.pageContext.childrenData &&
          this.props.pageContext.childrenData.find(
            (c) => c.type === "rivella/product-ingredients"
          )
        return (
          <ProductIngredients
            calculateProductTitleSize={this.calculateProductTitleSize}
            imageName={imageName}
            data={found ? found.props : null}
            // onToBack={() => this.navigateOtherView(viewNames.ProductOverview)}
            onToBack={() => this.scrollToOtherView(viewNames.ProductOverview)}
            productName={productName}
            productColor={productColor}
            productSecondaryColor={productSecondaryColor}
            refFromParent={this.ingredientsRef}
            bottleRef={this.ingredientsBottleRef}
            onToNutritions={() =>
              this.scrollToOtherView(viewNames.ProductNutritions)
            }
            fadeInAnimateExceptMainBottle={fadeInAnimateExceptMainBottle}
          />
        )
      case viewNames.ProductNutritions:
        found =
          this.props.pageContext &&
          this.props.pageContext.childrenData &&
          this.props.pageContext.childrenData.find(
            (c) => c.type === "rivella/product-nutrition"
          )
        return (
          <ProductNutritions
            calculateProductTitleSize={this.calculateProductTitleSize}
            imageName={imageName}
            data={found ? found.props : null}
            // onToBack={() => this.navigateOtherView(viewNames.ProductOverview)}
            onToBack={() => this.scrollToOtherView(viewNames.ProductOverview)}
            productName={productName}
            productColor={productColor}
            productSecondaryColor={productSecondaryColor}
            refFromParent={this.nutritionsRef}
            bottleRef={this.nutritionsBottleRef}
            fadeInAnimateExceptMainBottle={fadeInAnimateExceptMainBottle}
          />
        )
      default:
        found =
          this.props.pageContext &&
          this.props.pageContext.childrenData &&
          this.props.pageContext.childrenData.find(
            (c) => c.type === "rivella/product-overview"
          )

          if(pageDescriptions[productName.toLowerCase()]) {
            found.props.meta_description = pageDescriptions[productName.toLowerCase()]
          }

        return (
          <ProductOverview
            calculateProductTitleSize={this.calculateProductTitleSize}
            imageName={imageName}
            data={found ? found.props : null}
            // onToIngredients={() =>
            //   this.navigateOtherView(viewNames.ProductIngredients)
            // }
            // onToNutritions={() =>
            //   this.navigateOtherView(viewNames.ProductNutritions)
            // }
            onToIngredients={() =>
              this.scrollToOtherView(viewNames.ProductIngredients)
            }
            onToNutritions={() =>
              this.scrollToOtherView(viewNames.ProductNutritions)
            }
            allProducts={
              this.props.pageContext && this.props.pageContext.allProducts
            }
            currentProductId={
              this.props.pageContext && this.props.pageContext.product_id
            }
            location={this.props.location}
            productColor={productColor}
            productSecondaryColor={productSecondaryColor}
            refFromParent={this.overviewRef}
            bottleRef={this.overviewBottleRef}
            bottleSlideAnimate={bottleSlideAnimate}
            bottleSlideDirection={bottleSlideDirection}
            fadeInAnimateExceptMainBottle={fadeInAnimateExceptMainBottle}
            triggerNavigateSync={this.triggerNavigateSync}
          />
        )
    }
  }
  render() {
    const { translations, allProducts, allEvents } =
      this.props.pageContext || {}
    const { currentView, isNavigating, sideNavDirection, nextProductColor } =
      this.state
    const fadeInAnimate =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.isComingFromMain

    const bottleSlideAnimate =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.bottleSlideAnimate
    const prevProductColor =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.prevProductColor
    const bottleSlideDirection =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.bottleSlideDirection

    const fadeInAnimateExceptMainBottle =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.isComingFromSortiment

    let imageName = "rivella_bottle_red.png"

    const imageUrl =
      this.props.pageContext &&
      this.props.pageContext.childrenData &&
      (this.props.pageContext.childrenData[0].props.image ||
        this.props.pageContext.childrenData[1].props.image ||
        this.props.pageContext.childrenData[2].props.image)

    if (imageUrl) imageName = extractImageNameFromUrl(imageUrl)

    let bottleClasses = [s.BottleWrapper]
    let backgroundClasses = [s.Background]
    // if (currentView === viewNames.ProductIngredients)
    //   bottleClasses.push(s.BottleIngredients)
    if (currentView === viewNames.ProductNutritions)
      bottleClasses.push(s.BottleNutritions)

    if (isNavigating && currentView === viewNames.ProductOverview) {
      if (sideNavDirection === "left") {
        bottleClasses.push(s.BottleFadeRight)
        backgroundClasses.push(s.BackgroundFadeRight)
      } else if (sideNavDirection === "right") {
        bottleClasses.push(s.BottleFadeLeft)
        backgroundClasses.push(s.BackgroundFadeLeft)
      }
    } else if (bottleSlideAnimate) {
      if (bottleSlideDirection === "left") {
        bottleClasses.push(s.BottleEnterFromLeft)
        backgroundClasses.push(s.BackgroundEnterFromLeft)
      } else if (bottleSlideDirection === "right") {
        bottleClasses.push(s.BottleEnterFromRight)
        backgroundClasses.push(s.BackgroundEnterFromRight)
      }
    }
    if (fadeInAnimateExceptMainBottle) {
      backgroundClasses.push(s.BackgroundFadeIn)
    }

    const currentLang = getCurrentLanguage(this.props.location)
    const languageOptions = {
      currentLang,
      options: translations.filter((L) => product_range_route[L.language_code] != undefined).map((L) => ({
        ...L,
        ...language_code_to_static[L.language_code],
        slug: `${L.language_code}/${
          product_range_route[L.language_code].slug
        }/${L.slug}`,
      })),
    }
    const productNameObj =
      this.props.pageContext &&
      this.props.pageContext.childrenData &&
      this.props.pageContext.childrenData.find(
        (c) => c.type === "rivella/product-overview"
      )
    const productColor = productNameObj
      ? productNameObj.props.product_primary_color
      : null

    const pageTitle = this.props.pageName || "Das Original"
    const [firstChar, ...remainingChars] = pageTitle

    const productName = firstChar.toUpperCase() + remainingChars.toLowerCase()
    let layoutPageName = pageNames[productName.toLowerCase()] || productName

    return (
      <Layout
        headerIsTransparent={this.props.headerIsTransparent}
        pageName={layoutPageName}
        location={this.props.location}
        languageOptions={languageOptions}
        allProducts={allProducts}
        allEvents={allEvents}
        siteScope={this.props.siteScope}
        itemScope
        itemType="https://schema.org/Product"
        mainClassName={s.Main}
        pageDescription={pageDescriptions[productName.toLowerCase()]}
      >
        { pageNames[productName.toLowerCase()] && (
          <Helmet>
            <title>{pageNames[productName.toLowerCase()]}</title>
          </Helmet>
        )}
        <div
          className={s.Background}
          style={{
            backgroundColor: nextProductColor
              ? nextProductColor
              : prevProductColor,
          }}
        ></div>
        <div
          className={backgroundClasses.join(" ")}
          style={{
            backgroundColor: productColor,
          }}
        ></div>
        {fadeInAnimate && (
          <div
            style={{
              backgroundColor: productColor,
            }}
            className={s.FadeInOverlay}
          ></div>
        )}
        <div className={bottleClasses.join(" ")} ref={this.mainBottleRef}>
          {/* <ImgFluid fileName={imageName} /> */}
          <BottleWithDroplets
            width="100%"
            height="100%"
            imageName={imageName}
            recalcMaskSignal={this.state.recalcMaskSignal}
            fadeInVideo
          />
        </div>
        {/* {this.renderCurrentSection(this.state.currentView, imageName)} */}
        {this.renderCurrentSection(viewNames.ProductOverview, imageName)}
        {this.renderCurrentSection(viewNames.ProductIngredients, imageName)}
        {this.renderCurrentSection(viewNames.ProductNutritions, imageName)}
        <div
          className={s.scrollTopButton}
          onClick={() => this.scrollToOtherView(viewNames.ProductOverview)}
        >
          <ArrowDownIcon />
        </div>
        <div
          className={s.scrollNextSectionButton}
          onClick={() => this.onNextSection()}
        >
          <ArrowDownIcon />
        </div>
      </Layout>
    )
  }
}

export default ProductDetail
