import React from "react"

import s from "./ImpressumSection.module.scss"

const staticData = {
  /**
   *
   *
   * GERMAN
   *
   *
   */
  de: {
    title: "NUTZUNGSBESTIMMUNGEN DER RIVELLA-WEBSITE",
    section_1: {
      bold_1: `DIENSTANBIETER DIESER WEBSITE IST DIE RIVELLA AG`,
      bold_2: `Postanschrift:`,
      normal_1: `Rivella AG`,
      normal_2: `Neue Industriestrasse 10<br />CH-4852 Rothrist<br />T +41 [0] 62 785 41 11<br />F +41 [0] 62 785 44 36<br />`,
    },
    section_2: {
      title_0: `Nutzungsbedingungen`,
      content_0: `Rechtlicher Hinweis zur Benutzung von Rivella.ch sowie deren Subseiten. Personen, welche auf Webseiten der Rivella AG Informationen abrufen, erklären sich mit den nachstehenden Bedingungen einverstanden.`,
      title_1: `1. Unverbindlichkeit`,
      content_1: `Alle Informationen der Rivella Website sind unverbindlich. Die publizierten Informationen können von der Rivella AG jederzeit und ohne vorherige Ankündigung geändert, gelöscht oder die Veröffentlichung zeitweise oder endgültig eingestellt werden. Die Rivella AG übernimmt keinerlei Gewähr (weder ausdrücklich noch stillschweigend) für Korrektheit, Vollständigkeit und Aktualität der auf der Rivella Websitebereitgestellten Informationen. Rivella ist insbesondere nicht verpflichtet, nicht mehr aktuelle Informationen von der Rivella Website zu entfernen oder sie ausdrücklich als solche zu kennzeichnen.`,
      title_2: `2. Haftungsbeschränkungen`,
      content_2: `Die Nutzung der auf der Rivella Website zur Verfügung gestellten Inhalte und Informationen erfolgt auf alleinige Gefahr der Benutzer. Rivella schliesst uneingeschränkt jede Haftung für Verluste bzw. Schäden irgendwelcher Art aus– sei es für direkte, indirekte oder Folgeschäden–, die sich aus der Benützung des Zugriffs auf die Rivella Website oder aus Links zu Websites Dritter ergeben sollten. Zudem lehnt Rivella jede Haftung für Manipulationen am EDV-System des Internet-Benutzers durch Unbefugte ab. Dabei weist Rivella ausdrücklich auf die Gefahr von Viren und die Möglichkeit gezielter Hackerangriffe hin. Auf das Öffnen von E-Mails unbekannter Herkunft und nicht erwarteter Anhänge eines E-Mails sollte grundsätzlich verzichtet werden.`,
      title_3: `3. Verknüpfte Websites (Links)`,
      content_3: `Gewisse Links auf der Rivella Website führen zu Websites Dritter. Diese sind dem Einfluss der Rivella vollständig entzogen, weshalb Rivella für Richtigkeit, Vollständigkeit und Rechtmässigkeit des Inhalts solcher Websites sowie für allfällige darauf enthaltene Angebote und (Dienst-) Leistungen keinerlei Verantwortung übernimmt. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung von Links entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht Rivella, die über Links auf die jeweilige Veröffentlichung lediglich verweist.`,
      title_4: `4. Benutzung der Rivella-Website`,
      content_4: `Der gesamte Inhalt der Rivella Website ist urheberrechtlich geschützt. Die kommerzielle Nutzung des Inhaltes der Rivella Website durch jede Art der Vervielfältigung, Verbreitung und Veränderung ist ohne vorherige schriftliche Zustimmung der Rivella nicht gestattet. Sämtliche Eigentumsrechte verbleiben bei Rivella. Die auf der Rivella Website genannten Marken und Logos sind markenrechtlich geschützt. Dritten ist die Nutzung und Verwendung sämtlicher Marken und Logos untersagt. Die auf dieser Website angebotenen Produkte und Dienstleistungen sind Personen mit Wohnsitz in bestimmten Ländern nicht zugänglich. Bitte beachten Sie die geltenden Verkaufsbeschränkungen für die entsprechenden Produkte und Dienstleistungen.`,
      title_5: `5. Datenschutzbestimmungen`,
      title_51: `5.1. Verantwortlicher und Kontakt`,
      content_51: `Verantwortliche für die Verarbeitung Ihrer personenbezogenen Daten ist die Rivella AG, Neue Industriestrasse 10, 4852 Rothrist. <br /> Fragen zur Verarbeitung der personenbezogenen Daten können an <a   href="mailto:marketing@rivella.ch"   target="_blank"   rel="noopener noreferrer" >   marketing@rivella.ch </a> oder oben genannte Andresse gesendet werden.`,
      title_52:
        "5.2 Erhebung, Verarbeitung und Nutzung personenbezogener Daten",
      content_52: `<p>
      Bei einem Besuch der Websites, werden folgende personen-bezogene
      Daten verarbeitet: Ihre IP-Adresse, Log-In-Daten, Standortdaten und
      Informationen zu dem Betriebssystem oder Browser. Weiter werden
      Zugriffsdaten auf den Websites in Server-Logfiles, wie z.B. der Name
      der angeforderten Datei, Datum und Uhrzeit des Abrufs, Dauer des
      Besuchs, übertragene Datenmenge und der anfragende Provider
      gespeichert. Die Datenerhebung erfolgt durch den Einsatz von
      unterschiedlichen Technologien, vor allem durch sogenannte „Cookies“
      (siehe hierzu Punkt 6.).
    </p>
    <p class="${s.Underline}">Kontaktformulare</p>
    <p>
      Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe
      persönlicher oder geschäftlicher Daten (Email-adressen, Namen,
      Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens
      des Benutzers auf ausdrücklich freiwilliger Basis. Die
      Inanspruchnahme aller angebotenen Dienste ist - soweit technisch
      möglich und zumutbar - auch ohne Angabe solcher Daten bzw. unter
      Angabe anonymisierter Daten oder eines Pseudonyms gestattet. Rivella
      verpflichtet sich, personenbezogene Daten streng vertraulich zu
      behandeln und nicht ausserhalb des Rivella Konzerns weiterzugeben
      oder Dritten zugänglich zu machen. Demgegenüber gelten Mitteilungen
      und Informationen, wie zum Beispiel Bemerkungen, Anregungen, Ideen,
      Rezepte usw., die ein Benutzer selber auf der Website veröffentlicht
      oder mittels E-Mail an die Website sendet, nicht als vertraulich
      oder geistiges Eigentum des Benutzers angesehen werden und können
      daher von Rivella verwendet werden. Die Nutzung der im Rahmen des
      Impressums oder vergleichbarer Angaben veröffentlichten Kontaktdaten
      wie Post-Adressen, Telefon- und Faxnummern sowie Email-Adressen
      durch Dritte zur Übersendung von nicht ausdrücklich angeforderten
      Informationen ist nicht gestattet. Rechtliche Schritte gegen die
      Versender von so genannten Spam-Mails bei Verstössen gegen dieses
      Verbot bleiben ausdrücklich vorbehalten.
    </p>`,
      title_53: "5.3 Zweck der Datenverarbeitung",
      content_53: `<ul class="${s.UnderlinedList}">
      <li>
        Personenbezogene Daten werden zu folgenden Zwecken verwendet:
      </li>
      <li>
        um die Website nutzerfreundlich zur Verfügung stellen; zu können
        und um diese Website weiter zu entwickeln und zu verbessern;
      </li>
      <li>um Analysen und Nutzungsstatistiken erstellen zu können;</li>
      <li>
        um Angriffe auf die Website erkennen, verhindern und untersuchen
        zu können;
      </li>
      <li>um Benutzer-Anfragen bearbeiten zu können.</li>
    </ul>
    <p>
      Zu den oben genannten Zwecken können personenbezogene Daten an
      beauftragte IT Dienstleister übermittelt werden.
    </p>`,
      title_54: `5.4 Speicherdauer`,
      content_54: `Personenbezogene Daten werden nur so lange aufbewahrt, wie dies
vernünftigerweise als nötig erachtet wird, um die unter Punkt 5.3.
angeführten Zwecke zu erreichen. Website Daten werden von Google
Analytics erfasst und nach einer Inaktivität des Websitenutzers von
14 Monaten im darauffolgenden Monat gelöscht. (Nähere Informationen:
<a
  href="http://www.google.com/analytics/terms/de.html"
  target="_blank"
  rel="noopener noreferrer"
>
  http://www.google.com/analytics/terms/de.html
</a>
bzw. unter
<a
  href="https://www.google.de/intl/de/policies/"
  target="_blank"
  rel="noopener noreferrer"
>
  https://www.google.de/intl/de/policies/
</a>.
<br />Personenbezogene Daten die über Kontaktformulare übermittelt
wurden, werden von der Rivella AG 1 Jahr gespeichert. Eine längere
Speicherung erfolgt nur, wenn dies zur Bearbeitung der
Benutzer-Anfrage nötig ist.`,
      title_55: `5.5 Benutzer-Rechte`,
      content_55: `Es besteht das Recht zur Auskunft, Berichtigung und Löschung der
gespeicherten personenbezogenen Daten. Zudem besteht das Recht auf
Einschränkung der Verarbeitung der personenbezogene Daten sowie der
Datenübertragbarkeit. Eine Zustimmung zur Verarbeitung der Daten
kann jederzeit über
<a
  href="mailto:marketing@rivella.ch"
  target="_blank"
  rel="noopener noreferrer"
>
  marketing@rivella.ch
</a>
widerrufen werden.`,
      title_6: `6. Google Analytics & Cookies`,
      content_6: `Diese Website benutzt Google Analytics, einen Webanalysedienst der
Google Inc. Google Analytics verwendet sog. "Cookies", Textdateien,
die auf dem Computer der Besucher gespeichert werden und die eine
Analyse der Benutzung der Website durch die Nutzer ermöglicht. Die
durch den Cookie erzeugten Informationen über die Benutzung dieser
Website (einschliesslich der IP-Adresse des Besuchers) wird an einen
Server von Google in den USA übertragen und dort gespeichert. Google
wird diese Informationen benutzen, um die Nutzung der Besucher von
dieser Webseite auszuwerten, um Reports über die Websiteaktivitäten
für die Websitebetreiber zusammenzustellen und um weitere mit der
Websitenutzung und der Internetnutzung verbundene Dienstleistungen
zu erbringen. Auch wird Google diese Informationen gegebenenfalls an
Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit
Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in
keinem Fall IP-Adressen mit anderen Daten der Google in Verbindung
bringen.
<br />
Die Website verwendet Google Analytics mit der Erweiterung
„_anonymizeIp()“. Dadurch werden IP-Adressen gekürzt
weiterverarbeitet, ein Personenbezug kann damit ausgeschlossen
werden. Soweit den über den Benutzer erhobenen Daten ein
Personenbezug zukommt, wird dieser ausgeschlossen und die
personenbezogenen Daten damit umgehend gelöscht.
<br />
Die Installation der Cookies kann durch das Herunterladen eines
Browser Plug-Ins verhindert werden:
<a
  href="http://tools.google.com/dlpage/gaoptout?hl=de"
  target="_blank"
  rel="noopener noreferrer"
>
  http://tools.google.com/dlpage/gaoptout?hl=de
</a>
<br />
In diesem Fall können jedoch gegebenenfalls nicht sämtliche
Funktionen dieser Website voll umfänglich genutzt werden.
<br />
Weiterführende Informationen zu den Datenschutz und
Nutzungsbedingungen sind unter
<a
  href="http://www.google.com/analytics/terms/de.html"
  target="_blank"
  rel="noopener noreferrer"
>
  http://www.google.com/analytics/terms/de.html
</a>
bzw. unter
<a
  href="https://support.google.com/analytics/answer/6004245?hl=de"
  target="_blank"
  rel="noopener noreferrer"
>
  https://support.google.com/analytics/answer/6004245?hl=de
</a>
abrufbar.`,
      title_7: `7. Rechtswirksamkeit dieses Haftungsausschlusses`,
      content_7: `Dieser Haftungsausschluss ist als Teil des Internetangebotes zu
betrachten, von dem aus auf diese Website verwiesen wurde. Sofern
Teile oder einzelne Formulierungen dieses Textes dem geltenden Recht
nicht, nicht mehr oder nicht vollständig entsprechen sollten,
bleiben die übrigen Teile dieses Dokumentes in ihrem Inhalt und
ihrer Gültigkeit davon unberührt.`,
    },
    copyright: "Copyright 2020 Rivella AG, Rothrist, Schweiz",
  },
  /**
   *
   *
   * FRENCH
   *
   *
   */
  fr: {
    title: "CONDITIONS D'UTILISATION",
    section_1: {
      bold_1: `LE PRETATAIRE DE SERVICES DE CE SITE INTERNET EST LA SOCIETE RIVELLA SA`,
      bold_2: `Adresse postale:`,
      normal_1: `Rivella SA`,
      normal_2: `Neue Industriestrasse 10<br />CH-4852 Rothrist<br />T +41 [0] 62 785 41 11<br />F +41 [0] 62 785 44 36<br />`,
    },
    section_2: {
      title_0: `Conditions d’utilisation`,
      content_0: `Mention juridique relative à l’utilisation de Rivella.ch et de toutes ses pages. Les personnes qui consultent les informations figurant sur les sites Web de Rivella SA acceptent les conditions ci-dessous.`,
      title_1: `1. Sans engagement`,
      content_1: `Toutes les informations du site Internet Rivella sont sans engagement. Les informations publiées peuvent être modifiées, supprimées à tout moment et sans avis préalable par la société Rivella SA, ou la publication peut être interrompue provisoirement ou définitivement. La société Rivella SA décline toute responsabilité (expresse ou tacite) concernant l´exactitude, l´exhaustivité et la validité des informations disponibles sur le site Internet Rivella. Rivella n´est pas tenue en particulier de retirer du site Internet les informations qui ne sont plus actuelles ou de les identifier expressément en tant que telles.`,
      title_2: `2. Limitations de responsabilité`,
      content_2: `L´utilisateur exploite les contenus et informations fournis sur le site Internet à ses seuls risques et périls. Rivella exclue sans restriction toute responsabilité pour des pertes et/ou dommages quelconques, qu´il s´agisse de dommages consécutifs directs ou indirects, résultant de l´usage de l´accès au site Internet Rivella ou de ses liens vers des sites Internet de tiers. Rivella décline de plus toute responsabilité pour des manipulations sur le système informatique de l´utilisateur Internet par des tiers non autorisés. Rivella met en garde à ce titre expressément contre le risque de virus et la possibilité d´attaques ciblées de hackers. L´utilisateur devra absolument éviter d´ouvrir des emails d´origine inconnue ainsi que les pièces jointes d´un email imprévu.`,
      title_3: `3. Sites Internet enchaînés (Liens)`,
      content_3: `Certains liens présents sur le site Internet Rivella conduisent à des sites Internet de tiers. Rivella n´a aucune influence sur ces sites, c´est pourquoi Rivella n´assume aucune responsabilité quant à l´exactitude, l´exhaustivité et la légalité du contenu de ces sites Internet ainsi qu´aux offres et prestations (de service) éventuellement proposées sur ces sites. Le prestataire du site auquel il a été fait référence est seul responsable de contenus illégaux, erronés ou incomplets et en particulier de dommages résultant de l´utilisation ou de la non-utilisation des liens, Rivella renvoyant uniquement par des liens à la publication respective.`,
      title_4: `4. Utilisation du site Internet`,
      content_4: `Rivella L'ensemble du contenu du site Internet Rivella est protégé par le droit d´auteur. L´utilisation commerciale du contenu du site Internet Rivella par tout moyen de reproduction, diffusion et modification est interdite sans l´accord écrit préalable de la société Rivella. Rivella conserve tous les droits de propriété. Les marques et logos mentionnés sur le site Internet Rivella sont protégés par la loi sur les marques. L´utilisation des marques et des logos est interdite à des tiers. Les produits et prestations de service proposés sur ce site Internet ne sont pas accessibles à des personnes résidant dans certains pays. Veuillez respecter les restrictions de vente en vigueur pour les produits et prestations de service correspondants.`,
      title_5: `5. Dispositions sur la protection des données`,
      title_51: `5.1 Responsable et contact`,
      content_51: `La responsable du traitement de vos données à caractère personnel est Rivella SA, Neue Industriestrasse 10, 4852 Rothrist.<br />Les questions concernant le traitement des données à caractère personnel peuvent être adressées à <a   href="mailto:marketing@rivella.ch"   target="_blank"   rel="noopener noreferrer" >   marketing@rivella.ch </a> ou à l’adresse indiquée ci-dessus.`,
      title_52:
        "5.2 Collecte, traitement et utilisation de données à caractère personnel",
      content_52: `<p>
      Lors d’une visite des sites Web, les données à caractère personnel suivantes sont traitées: votre adresse IP, les données de login, les données de position et les informations concernant le système d’exploitation ou le navigateur. Par ailleurs, les données d’accès aux sites Web sont stockées dans les logfiles du serveur, à savoir le nom du fichier recherché, la date et l’heure de la recherche, la durée de la visite, la quantité de données transférées et le fournisseur d’accès utilisé. Le relevé des données se fait par l’utilisation de plusieurs technologies et principalement par les «cookies» (voir point 6).
    </p>
    <p class="${s.Underline}">Formulaires de contact</p>
    <p>
    Dans la mesure où il est possible d’entrer des données personnelles ou commerciales (adresses e-mail, noms, adresses) sur le site Web, la divulgation de ces données par l’utilisateur est expressément volontaire. L’utilisation de tous les services proposés est autorisée – dans la mesure où cela est techniquement possible et raisonnable – sans indication de telles données ou sous la mention de données anonymes ou d’un pseudonyme. Rivella s’engage à traiter les données à caractère personnel de manière strictement confidentielle, de ne pas les transmettre hors du groupe Rivella ni à des tiers. En revanche, les messages et les informations, comme les remarques, suggestions, idées, propositions, recettes, etc., qu’un utilisateur publie lui-même sur le site Web ou qu’il envoie lui-même par e-mail au site Web ne sont pas considérés comme confidentiels ou comme propriété de l’utilisateur et peuvent donc être utilisés par Rivella. L’utilisation des données de contact publiées dans le cadre des mentions légales ou d’indications similaires comme les adresses postales, les numéros de téléphone ou de fax ainsi que les adresses e-mail par des tiers en vue de l’envoi d’informations non demandés est expressément interdite. Les recours juridiques à l’encontre d’expéditeurs de dénommés spams sont expressément réservés lors de violation de cette interdiction.
    </p>`,
      title_53: "5.3 Finalités du traitement des données",
      content_53: `<ul class="${s.UnderlinedList}">
      <li>
      Les données à caractère personnel sont utilisées aux fins suivantes:

      </li>
      <li>
      pour rendre le site Web convivial, pour continuer à développer et à améliorer ce site Web;

      </li>
      <li>pour pouvoir élaborer des analyses et des statistiques d’utilisation;
      </li>
      <li>
      pour pouvoir détecter, éviter et analyser des attaques sur le site Web;
      </li>
      <li>pour pouvoir traiter les demandes des utilisateurs.
      </li>
    </ul>
    <p>
      Les données à caractère personnel peuvent être communiquées aux fins mentionnées ci-dessus aux prestataires informatiques mandatés.
    </p>`,
      title_54: `5.4 Durée de stockage`,
      content_54: `Les données à caractère personnel ne sont conservées que le temps nécessaire pour réaliser les objectifs mentionnés au point 5.3.
      <br />
      Les données du site Web sont recueillies par Google Analytics et effacées le mois suivant au terme de 14 mois d’inactivité de l’utilisateur. (Informations complémentaires:
<a
  href="http://www.google.com/analytics/terms/fr.html"
  target="_blank"
  rel="noopener noreferrer"
>
  http://www.google.com/analytics/terms/fr.html
</a>
et sur
<a
  href="https://www.google.fr/intl/fr/policies/"
  target="_blank"
  rel="noopener noreferrer"
>
  https://www.google.fr/intl/fr/policies/
</a>.
<br />Les données à caractère personnel communiquées via les formulaires de contact sont stockées par Rivella SA pendant un an. Un stockage plus long est autorisé uniquement si ce dernier est nécessaire pour le traitement de la demande de l’utilisateur.`,
      title_55: `5.5 Droits des utilisateurs`,
      content_55: `Il existe un droit de renseignement, de correction et de suppression des données à caractère personnel stockées. Il existe également un droit de limitation du traitement des données à caractère personnel et de la transmission des données.
<br /><br />
      Le consentement au traitement des données peut être révoqué à tout moment en envoyant un e-mail à
<a
  href="mailto:marketing@rivella.ch"
  target="_blank"
  rel="noopener noreferrer"
>
  marketing@rivella.ch
</a>
widerrufen werden.`,
      title_6: `6. Google Analytics & Cookies`,
      content_6: `Ce site Web utilise Google Analytics, un service d´analyse Web de Google Inc. Google Analytics recourt à des « Cookies », des fichiers de texte qui sont mémorisés sur votre ordinateur et qui permettent d´analyser votre utilisation du site Web. Les informations générées par le Cookie relatives à votre fréquentation de ce site Internet (y compris votre adresse IP) sont transmises à un serveur de Google aux Etats-Unis où elles sont enregistrées. Google utilise ces informations pour analyser votre utilisation du site Web, afin d´établir des rapports sur les activités du site à l´intention des exploitants de ce dernier et de fournir d´autres prestations de services liées à l´utilisation du site Web et d´Internet en général. Le cas échéant, Google transmettra également ces informations à des tiers, pour autant que c´est prescrit par la loi ou que Google a chargé des tiers de traiter ces données. Google ne compare en aucun cas votre adresse IP avec d´autres données en sa possession.
<br />
Le présent site Web utilise Google Analytics avec l’extension «_anonymizeIp()». De cette manière, les adresses IP sont traitées sous forme raccourcie afin d’exclure toute identification directe d’utilisateurs. Dans la mesure où les données concernant l’utilisateur sont accompagnées d’un identifiant personnel, elles seront immédiatement exclues et les données personnelles seront immédiatement supprimées.
<br />
Vous pouvez empêcher l’installation des cookies en téléchargeant un plug-in de navigateur:
<a
  href="http://tools.google.com/dlpage/gaoptout?hl=fr"
  target="_blank"
  rel="noopener noreferrer"
>
  http://tools.google.com/dlpage/gaoptout?hl=fr
</a> le cas échéant, il se pourrait alors que vous ne puissiez plus utiliser toutes les fonctions de ce site Web.
<br />
Des informations complémentaires sur la protection des données et les conditions d’utilisation sont disponibles sur
<a
  href="http://www.google.com/analytics/terms/fr.html"
  target="_blank"
  rel="noopener noreferrer"
>
  http://www.google.com/analytics/terms/fr.html
</a>
et sur <a
  href="https://support.google.com/analytics/answer/6004245?hl=fr"
  target="_blank"
  rel="noopener noreferrer"
>
  https://support.google.com/analytics/answer/6004245?hl=fr
</a>.`,
      title_7: `7. Validité juridique de cette exclusion de responsabilité`,
      content_7: `Cette exclusion de responsabilité doit être considérée comme partie intégrante de l’offre en ligne dans laquelle il est fait référence à ce site Web. Si certaines parties ou certaines formulations du présent texte ne devaient pas respecter la législation en vigueur ou être incomplètes, les autres parties de ce document ne s’en trouveraient affectées ni dans leur contenu ni dans la validité de celui-ci.`,
    },
    copyright: "Copyright 2020 Rivella SA, Rothrist, Suisse",
  },
  /**
   *
   *
   * ITALIAN
   *
   *
   */
  it: {
    title: "TERMINI DI UTILIZZO DEL SITO WEB RIVELLA",
    section_1: {
      bold_1: `FORNITORE DI SERVIZI DI QUESTO SITO WEB È RIVELLA SA`,
      bold_2: `Recapito postale:`,
      normal_1: `Rivella SA`,
      normal_2: `Neue Industriestrasse 10<br />
      CH-4852 Rothrist<br />
      T +41 [0] 62 785 41 11<br />
      F +41 [0] 62 785 44 36<br />`,
    },
    section_2: {
      title_0: `Condizioni di impiego`,
      content_0: `Avviso legale per l´utilizzo di Rivella.ch nonché relative sottopagine. Le persone, che richiamano informazioni sulle pagine web di Rivella SA, si dichiarano concordi con le seguenti condizioni.`,
      title_1: `1. Non vincolatività`,
      content_1: `Tutte le informazioni riportate sul sito web di Rivella si intendono non vincolanti. Rivella SA ha facoltà di modificare o eliminare in qualsiasi momento e senza alcun preavviso le informazioni pubblicate nonché di sospenderne temporaneamente o definitivamente la pubblicazione. Rivella SA non si assume alcuna responsabilità (tacita o esplicita) per la correttezza, la completezza e
      <br />l´aggiornamento delle informazioni rese disponibili sul proprio sito web. In particolare, Rivella non è tenuta a eliminare dal propriosito web le informazioni non più aggiornate né a indicarle esplicitamente come tali.`,
      title_2: `2. Limitazioni di responsabilità`,
      content_2: `L´utilizzo dei contenuti e delle informazioni disponibili sul sito web di Rivella avviene a esclusivo rischio dell´utente. Rivella declina qualunque tipo di responsabilità per perdite o danni di qualsivoglia natura – danni diretti, indiretti o secondari – che dovessero essere determinati dall´accesso al proprio sito web o dall´utilizzo dei link di siti web terzi. Rivella declina inoltre qualsiasi responsabilità per manipolazioni ai sistemi di elaborazione dati degli utenti Internet da parte di soggetti non autorizzati, fra cui la contaminazione con virus informatici o l´eventualità di attacchi mirati di hacking. Rivella consiglia inoltre di non aprire mail di origine sconosciuta né allegati di e-mail inattesi.`,
      title_3: `3. Siti web collegati (link)`,
      content_3: `Il sito web di Rivella presenta determinati link da cui è possibile collegarsi a siti web terzi, su cui tuttavia Rivella non è in grado di esercitare alcun tipo di influenza. Per questa ragione Rivella declina qualunque responsabilità per la correttezza, la completezza e la legittimità dei contenuti di tali siti web nonché per le eventuali offerte e i servizi ivi proposti. La responsabilità per contenuti illegali, errati o incompleti e, in particolare, per i danni che dovessero derivare
      dall´utilizzo o dal mancato utilizzo dei link, spetta unicamente al proprietario del sito collegato e non a Rivella, che dal proprio sito offre solamente la possibilità di collegarsi alle varie pubblicazioni.`,
      title_4: `4. Utilizzo del sito web di Rivella`,
      content_4: `L´intero contenuto del sito web di Rivella è protetto dal diritto d´autore. È vietato l´utilizzo commerciale del contenuto del sito web di Rivella con ogni tipo di riproduzione, diffusione e modifica senza previo consenso scritto da parte di Rivella. Rivella mantiene tutti i diritti di proprietà. I marchi e i loghi menzionati sul sito web di Rivella sono protetti dal diritto dei marchi. È vietato a terzi l´utilizzo e lo sfruttamento di tutti i marchi e i loghi. I prodotti e i servizi offerti su questo sito web non sono accessibili alle persone domiciliate in determinati paesi. Si prega di rispettare le limitazioni alla vendita vigenti per i vari prodotti e servizi.`,
      title_5: `5. Disposizioni in materia di protezione dati`,
      title_51: `5.1 Responsabilità e contatti`,
      content_51: `Responsabile per il trattamento dei dati personali è la Rivella SA, Neue Industriestrasse 10, 4852 Rothrist.

      Eventuali domande sul trattamento dei dati personali possono essere inviate a <a   href="mailto:marketing@rivella.ch"   target="_blank"   rel="noopener noreferrer" >   marketing@rivella.ch </a> o all’indirizzo sopra indicato.`,
      title_52: "5.2 Raccolta, trattamento e utilizzo dei dati personali",
      content_52: `<p>
      Quando si visita un sito web vengono elaborati i seguenti dati personali: indirizzo IP, dati di accesso, dati di posizione e informazioni sul sistema operativo o sul browser. Inoltre, alcuni dati di accesso, come ad esempio il nome del file richiesto, la data e l'ora di accesso, la durata della visita, il volume dei dati trasferiti e il provider richiedente, vengono memorizzati in file di registro del server. I dati sono raccolti utilizzando varie tecnologie, in particolare i "cookies" (cfr. punto 6)    </p>
    <p class="${s.Underline}">Moduli di contatto</p>
    <p>
    Se all’utente viene data la possibilità di inserire dati personali o aziendali (indirizzi e-mail, nomi, recapiti), tale inserimento è del tutto volontario. L'utilizzo di tutti i servizi offerti è consentito - se e per quanto tecnicamente possibile e ragionevole - senza indicazione di dati personali o con indicazione di dati anonimi o di uno pseudonimo. Rivella si impegna a trattare i dati personali in modo strettamente confidenziale, a non trasmetterli al di fuori del Gruppo Rivella e a non renderli accessibili a terzi. Per contro, i messaggi e le informazioni, quali commenti, suggerimenti, idee, ricette, ecc., che un utente pubblica sul sito web o invia al sito web via e-mail, non sono considerati riservati o proprietà intellettuale dell'utente e possono quindi essere utilizzati liberamente da Rivella. È vietato l’uso da parte di terzi di indirizzi postali, numeri di telefono o di fax e indirizzi e-mail, pubblicati nell’impressum o in note analoghe, per l’invio di informazioni non espressamente richieste. Ci riserviamo espressamente il diritto di intraprendere azioni legali contro i mittenti delle cosiddette e-mail di spam in caso di violazione di questo divieto.    </p>`,
      title_53: "5.3 Finalità del trattamento dei dati",
      content_53: `<ul class="${s.UnderlinedList}">
      <li>
      I dati personali saranno utilizzati per le seguenti finalità:
      </li>
      <li>
      rendere il sito web di facile consultazione nonché svilupparlo e migliorarlo ulteriormente;
      </li>
      <li>eseguire analisi e statistiche d’uso;</li>
      <li>
      riconoscere, impedire e analizzare eventuali attacchi al sito;
      </li>
      <li>
      essere in grado di elaborare le richieste degli utenti.
      </li>
    </ul>
    <p>
    Per le finalità sopra indicate, i dati personali possono essere inviati a fornitori di servizi informatici incaricati.
    </p>`,
      title_54: `5.4 Periodo di conservazione dei dati`,
      content_54: `I dati personali saranno conservati solo per il tempo ritenuto ragionevolmente necessario al fine di raggiungere le finalità di cui al punto 5.3.
<br />
I dati del sito web sono raccolti da Google Analytics e dopo 14 mesi di inattività dell'utente vengono cancellati il mese successivo. (Per ulteriori informazioni:
<a
  href="http://www.google.com/analytics/terms/de.html"
  target="_blank"
  rel="noopener noreferrer"
>
  http://www.google.com/analytics/terms/de.html
</a>
o
<a
  href="https://www.google.de/intl/de/policies/"
  target="_blank"
  rel="noopener noreferrer"
>
  https://www.google.fr/intl/fr/policies/
</a>.
<br />I dati personali trasmessi tramite i moduli di contatto vengono conservati da Rivella SA per 1 anno. Una memorizzazione più lunga avviene solo se ciò è necessario per elaborare la richiesta dell'utente.`,
      title_55: `5.5 Diritti dell’utente`,
      content_55: `L’utente ha il diritto all'informazione, alla rettifica e alla cancellazione dei dati personali memorizzati. Inoltre, ha il diritto di limitare il trattamento dei dati personali e la trasferibilità degli stessi.
<br /><br />
Il consenso al trattamento dei dati può essere revocato in qualsiasi momento all’indirizzo
<a
  href="mailto:marketing@rivella.ch"
  target="_blank"
  rel="noopener noreferrer"
>
  marketing@rivella.ch
</a>
widerrufen werden.`,
      title_6: `6. Google Analytics & Cookies`,
      content_6: `Questo sito web usa Google Analytics, un servizio di analisi web della Google Inc. Google Analytics usa i cosiddetti "cookies", file di testo che vengono memorizzati sul vostro computer e consentono un´analisi dell´uso del sito da parte vostra. Le informazioni generate dal cookie in merito al vostro uso del sito (compreso il vostro indirizzo IP) vengono trasmesse a un server di Google negli USA e lì memorizzate. Google userà tali informazioni per valutare il vostro uso del sito web, per redigere rapporti in merito alle attività del sito per il gestore del sito stesso e per fornire ulteriori prestazioni connesse all´uso del sito e di Internet. Inoltre, Google trasmetterà eventualmente tali informazioni a terzi, nella misura in cui ciò è previsto dalla legge o in quanto operanti per conto di Google. In nessun caso Google collegherà il vostro indirizzo IP ad altri dati di Google.<br />
      Il sito web utilizza Google Analytics con l'estensione "_anonymizeIp()". In questo modo vengono elaborati indirizzi IP accorciati, escludendo così qualsiasi riferimento personale. Se i dati raccolti sull'utente presentano riferimenti personali, questi verranno esclusi con conseguente cancellazione immediata dei personali.
<br />
L'installazione dei cookie può essere impedita scaricando un plug-in per il browser:
<a
  href="http://tools.google.com/dlpage/gaoptout?hl=de"
  target="_blank"
  rel="noopener noreferrer"
>
  http://tools.google.com/dlpage/gaoptout?hl=de
</a> In questo caso, tuttavia, non è possibile utilizzare tutte le funzioni di questo sito Web.
<br />
Ulteriori informazioni sulla protezione dei dati e sulle condizioni d'uso sono disponibili agli indirizzi<a
  href="http://www.google.com/analytics/terms/it.html"
  target="_blank"
  rel="noopener noreferrer"
>
  http://www.google.com/analytics/terms/it.html
</a>
et sur <a
  href="https://support.google.com/analytics/answer/6004245?hl=it"
  target="_blank"
  rel="noopener noreferrer"
>
  https://support.google.com/analytics/answer/6004245?hl=it
</a>.`,
      title_7: `7. Validità legale di questo disclaimer`,
      content_7: `Questo disclaimer va considerato come parte della offerta internet dalla quale si è fatto riferimento a questo sito web. Il fatto che alcune sezioni o singoli termini di questo testo potrebbero non più corrispondere, in parte o del tutto, al diritto vigente, non inficia il contenuto o la validità delle altre parti di questo documento.`,
    },
    copyright: "Copyright 2020 Rivella SA, Rothrist, Svizzera",
  },
}

class ImpressumSection extends React.Component {
  render() {
    const data = staticData[this.props.currentLang]
    const { section_2 } = data
    return (
      <section className={s.ImpressumSection}>
        <h1 className={s.Title}>{data.title}</h1>
        <div>
          <p className={s.Bold}>{data.section_1.bold_1}</p>
          <p className={s.Bold}>{data.section_1.bold_2}</p>
          <p className={s.SmallBold}>{data.section_1.normal_1}</p>
          <p dangerouslySetInnerHTML={{ __html: data.section_1.normal_2 }}></p>
        </div>
        <div>
          <p className={s.Bold}>{section_2.title_0}</p>
          <p>{section_2.content_0}</p>

          <p className={s.Bold}>{section_2.title_1}</p>
          <p>{section_2.content_1}</p>

          <p className={s.Bold}>{section_2.title_2}</p>
          <p>{section_2.content_2}</p>

          <p className={s.Bold}>{section_2.title_3}</p>
          <p>{section_2.content_3}</p>

          <p className={s.Bold}>{section_2.title_4}</p>
          <p>{section_2.content_4}</p>

          <p className={s.Bold}>{section_2.title_5}</p>
          <p className={s.Bold}>{section_2.title_51}</p>
          <p dangerouslySetInnerHTML={{ __html: section_2.content_51 }}></p>

          <p className={s.Bold}>{section_2.title_52}</p>
          <div dangerouslySetInnerHTML={{ __html: section_2.content_52 }}></div>

          <p className={s.Bold}>{section_2.title_53}</p>
          <div dangerouslySetInnerHTML={{ __html: section_2.content_53 }}></div>

          <p className={s.Bold}>{section_2.title_54}</p>
          <p dangerouslySetInnerHTML={{ __html: section_2.content_54 }}></p>

          <p className={s.Bold}>{section_2.title_55}</p>
          <p dangerouslySetInnerHTML={{ __html: section_2.content_55 }}></p>

          <p className={s.Bold}>{section_2.title_6}</p>
          <p dangerouslySetInnerHTML={{ __html: section_2.content_6 }}></p>

          <p className={s.Bold}>{section_2.title_7}</p>
          <p dangerouslySetInnerHTML={{ __html: section_2.content_7 }}></p>
        </div>

        <p className={s.Copyright}>{data.copyright}</p>
      </section>
    )
  }
}

export default ImpressumSection
