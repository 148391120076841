import React from "react"
import s from "./OnlySender.module.scss"

const OnlySender = (props) => {
  return (
    <div className={s.only_receiver_section}>
      <div className={s.row_title}>
        <h4>{props.formFieldsInfo.sender.header.title}</h4>
      </div>

      {/* ********************************************** */}

      <div className={[s.flex, s.padding_20].join(" ")}>
        <div className={s.two_column}>
          <select
            value={props.body.from_salutation}
            onChange={({ target: { value } }) =>
              props.setBody((p) => ({ ...p, from_salutation: value }))
            }
            className={`${s.form_select} ${
              props.isFieldInvalid("from_salutation") ? s.field_error : ""
            }`}
            placeholder={props.formFieldsInfo.sender.mr_mrs.placeholder}
          >
            <option selected hidden>
              {props.formFieldsInfo.sender.mr_mrs.placeholder}
            </option>
            <option value={props.formFieldsInfo.sender.mr_mrs.mr.value}>
              {props.formFieldsInfo.sender.mr_mrs.mr.label}
            </option>
            <option value={props.formFieldsInfo.sender.mr_mrs.mrs.value}>
              {props.formFieldsInfo.sender.mr_mrs.mrs.label}
            </option>
          </select>
        </div>

        {/* <div className={[s.two_column, s.left_padding].join(" ")}> */}
        {/* <select
            className={`${s.form_select} ${
              props.isFieldInvalid("from_title") ? s.field_error : ""
            }`}
            value={props.body.from_title}
            onChange={({ target: { value } }) =>
              props.setBody((p) => ({ ...p, from_title: value }))
            }
            placeholder={props.formFieldsInfo.sender.title.placeholder}
          >
            <option selected hidden>
              {props.formFieldsInfo.sender.title.placeholder}
            </option>
            <option value={props.formFieldsInfo.sender.title.empty.value}>
              {props.formFieldsInfo.sender.title.empty.label}
            </option>
            <option value={props.formFieldsInfo.sender.title.dr.value}>
              {props.formFieldsInfo.sender.title.dr.label}
            </option>
          </select> */}
        {/* </div> */}
      </div>

      {/* ********************************************** */}

      <div className={s.padding_20}>
        <input
          type="text"
          placeholder={props.formFieldsInfo.sender.first_name.placeholder}
          className={`${s.form_control} ${
            props.isFieldInvalid("from_first_name") ? s.field_error : ""
          }`}
          value={props.body.from_first_name}
          onChange={({ target: { value } }) =>
            props.setBody((p) => ({ ...p, from_first_name: value }))
          }
        />
      </div>

      {/* ********************************************** */}

      <div className={s.padding_20}>
        <input
          type="text"
          placeholder={props.formFieldsInfo.sender.last_name.placeholder}
          className={`${s.form_control} ${
            props.isFieldInvalid("from_last_name") ? s.field_error : ""
          }`}
          value={props.body.from_last_name}
          onChange={({ target: { value } }) =>
            props.setBody((p) => ({ ...p, from_last_name: value }))
          }
        />
      </div>

      {/* ********************************************** */}

      <div className={s.padding_20}>
        <input
          type="text"
          placeholder={props.formFieldsInfo.sender.email.placeholder}
          className={`${s.form_control} ${
            props.isFieldInvalid("from_email") ? s.field_error : ""
          }`}
          value={props.body.from_email}
          // onChange={({ target: { value } }) =>
          //   props.setBody((p) => ({ ...p, from_email: value }))
          // }
        />
      </div>

      {/* ********************************************** */}
    </div>
  )
}

export default OnlySender
