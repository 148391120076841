import React from "react"

import s from "./PrizeImageWithCount.module.scss"
import { ImgFluid } from "../"

class PrizeImageWithCount extends React.Component {
  render() {
    const { count, img, alt, className } = this.props
    return (
      <div className={[s.PrizeImageWithCount, className].join(" ")}>
        <span className={s.Count}>{count}</span>
        <div className={s.ImageWrapper}>
          <ImgFluid alt={alt} fileName={img} />
        </div>
      </div>
    )
  }
}

export default PrizeImageWithCount
