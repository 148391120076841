import React from "react"
import { Link } from "gatsby"
import { Button } from "../../../components"
import s from "./GeneralPromotionHero.module.scss"

const CircleBanner = (props) => {

  const {
    currentLang,
    button_link,
    button_one_text
  } = props;


  const button = (<Button btnClassName={s.Button} type={"default"}><span>
    {/* backend registered banner_text - button_one_text is frontend static */}
    {button_one_text}
  </span></Button>);

  return (
    <div
      className={s.CircleBanner}
    >
      <div
        className={`${s.CircleBannerChild} ${
          s[currentLang.lang]
        }`}
      >
        { button_link && (
          <Link
            to={button_link}
            className={s.LinkBanner}
          >
            { button }
          </Link>
        )}

        { !button_link && button }
      </div>
    </div>
  );
}


export default CircleBanner
