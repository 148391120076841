import React from "react"
import s from "./SocialMediaGrid.module.scss"
import { SkewedWrapper, ImgFluid, Button } from "../../../components"
import { extractImageNameFromUrl } from "../../../helper"
import FacebookIcon from "../../../assets/svgs/facebook_impression_logo.svg"
import YoutubeIcon from "../../../assets/svgs/youtube_impression_logo.svg"
import InstagramIcon from "../../../assets/svgs/instagram_impression_logo.svg"

const iconType = {
  facebook: <FacebookIcon />,
  instagram: <InstagramIcon />,
  youtube: <YoutubeIcon />,
}

class SocialMediaGrid extends React.Component {
  render() {
    const {
      gallery_images = [],
      title_one_text,
      title_one_color,
      title_two_text,
      title_two_color,
      // title_three_text,
      // title_three_color,
      loader_button_text,
      loader_button_type,
    } = this.props
    return (
      <section
        className={s.SocialMediaGrid}
        style={{ zIndex: this.props.sectionZindex }}
        ref={this.props.sectionRef}
      >
        <div className={s.TitlesWrapper}>
          <h3 className={[s.SubTitle, title_one_color].join(" ")}>
            {title_one_text}
          </h3>
          <h2 className={[s.Title, title_two_color].join(" ")}>
            {title_two_text}
          </h2>
        </div>
        <SkewedWrapper wrapperClassName={s.gallery_section}>
          <div className={s.GalleryWrapper}>
            {gallery_images.map(({ image, type }, index) => {
              const Icon = iconType[type]
              return (
                <div key={index} className={s.image_wrapper}>
                  <div className={s.icon_wrapper}>{Icon}</div>
                  <ImgFluid
                    cn={s.image_container}
                    fileName={extractImageNameFromUrl(image)}
                  />
                </div>
              )
            })}
            {/* <h5 className={[s.BottomTitle, title_three_color].join(" ")}>
              {title_three_text}
            </h5> */}
            {gallery_images && gallery_images.length ? (
              <Button
                btnClassName={[s.LoadMore, loader_button_type].join(" ")}
                type="default"
              >
                {loader_button_text}
              </Button>
            ) : null}
          </div>
        </SkewedWrapper>
      </section>
    )
  }
}

export default SocialMediaGrid
