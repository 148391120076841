import React, { Component } from "react"
import Helmet from "react-helmet"

import { PromotionSectionWrapper } from "../"
import { ChildrenButtonRenderer } from "../../"
import s from "./DiscoverDestinationSection.module.scss"
import { ImageFrame } from "../../../components"
import { extractImageNameFromUrl } from "../../../helper"

class DiscoverDestinationSection extends Component {
  render() {
    const {
      description,
      title_one_text,
      destination_img,
      firstSection,
      itemProp,
    } = this.props
    return (
      <PromotionSectionWrapper
        itemProp={itemProp}
        className={
          firstSection
            ? [
                s.discover_destination_section,
                s.discover_destination_section_first,
              ].join(" ")
            : s.discover_destination_section
        }
      >
        <Helmet>
          <meta property="og:description" content={title_one_text} />
        </Helmet>
        <div className={s.destination_details_wrapper}>
          <div className={s.destination_detail}>
            <h1
              className={s.destination_title}
              dangerouslySetInnerHTML={{ __html: title_one_text }}
            ></h1>
            <p
              className={s.destination_description}
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
            <ChildrenButtonRenderer buttonData={this.props.innerBlocks} />
          </div>
          <ImageFrame
            className={s.image_frame}
            imgFilename={extractImageNameFromUrl(destination_img)}
          />
        </div>
      </PromotionSectionWrapper>
    )
  }
}

export default DiscoverDestinationSection
