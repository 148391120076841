import React from "react"
import s from "./SkewedWrapper.module.scss"

class SkewedWrapper extends React.Component {
  render() {
    let wrapperClasses = [s.SkewedWrapper]

    const {
      wrapperClassName,
      type,
      isAnotherSkewChild,
      verticalInner,
      onClick,
      innerRef,
    } = this.props
    if (wrapperClassName) wrapperClasses.push(wrapperClassName)
    if (type === "primary") wrapperClasses.push(s.Primary)
    else if (type === "default") wrapperClasses.push(s.Default)
    // use when this is a child of another SkewedWrapper so it does not apply multiple skew
    if (isAnotherSkewChild) wrapperClasses.push(s.ResetSkew)
    // use when only the wrapper is skewed and not the inner children
    if (verticalInner) wrapperClasses.push(s.VerticalInner)

    return (
      <div
        onClick={onClick ? onClick : function () {}}
        className={wrapperClasses.join(" ")}
        ref={innerRef}
      >
        {this.props.children}
      </div>
    )
  }
}

export default React.forwardRef((props, ref) => (
  <SkewedWrapper innerRef={ref} {...props} />
))
