import React from "react"
import { Helmet } from "react-helmet"

import { SkewedHero } from "../../components"
import s from "./EventsPageHero.module.scss"
import ArrowDown from "../../assets/svgs/arrow_down.svg"
import { extractImageNameFromUrl } from "../../helper"

class EventsPageHero extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      latestEvent: null,
      isMobileDeviceExceptSamsungInternet: true,
      isMobileIos: false,
    }
    this.titleRef = React.createRef()
  }

  // componentDidMount() {
  //   if (typeof window !== "undefined") {
  //     this.setState({
  //       isMobileDeviceExceptSamsungInternet:
  //         checkMobileDeviceExceptSamsungInternet(window, Bowser),
  //       isMobileIos: checkMobileIos(window, Bowser),
  //     })
  //   }
  // }

  onArrowDown = () => {
    if (typeof this.props.onScrollTo === "function") {
      this.props.onScrollTo()
    }
  }

  animateHeroTitle = () => {
    const { current: titleElement } = this.titleRef
    setTimeout(() => titleElement.classList.add(s.animated_title), 300)
  }

  componentDidMount() {
    this.animateHeroTitle()
  }

  render() {
    const { isMobileDeviceExceptSamsungInternet, isMobileIos } = this.state
    const {
      title_one_text,
      title_two_text,
      background_img,
      background_type: is_video_background,
      background_video,
      link_labels = [],
      onScrollToSpecificTarget,
    } = this.props
    let sectionClasses = [s.events_page_hero]
    if (isMobileDeviceExceptSamsungInternet)
      sectionClasses.push(s.events_page_hero_MobileDevice)
    if (isMobileIos) sectionClasses.push(s.events_page_hero_IphoneSafari)
    return (
      <div
        className={s.hero_wrapper}
        style={{ zIndex: this.props.sectionZindex + 100 }}
        ref={this.props.sectionRef}
      >
        {/* <SkewedCarouselHero
          imagesData={background_imgs}
          cn={s.events_page_hero}
        >
          <h1 ref={this.titleRef} className={`${s.hero_title}`}>
            {title_one_text}
          </h1>
          <h2 className={s.BannerText}>{title_two_text}</h2>
          <ArrowDown className={s.ArrowDown} onClick={this.onArrowDown} />
        </SkewedCarouselHero> */}
        <Helmet>
          <meta
            name="description"
            content={
              "Rivella begleitet Menschen bei ihrem aktiven Lebensstil und engagiert sich seit jeher für Bewegung und Sport. Erfahre mehr über die Sportevents."
            }
          />
          <meta
            name="twitter:description"
            content={
              "Rivella begleitet Menschen bei ihrem aktiven Lebensstil und engagiert sich seit jeher für Bewegung und Sport. Erfahre mehr über die Sportevents."
            }
          />
        </Helmet>
        <SkewedHero
          imgAlt={title_one_text}
          // imgLink={latestEvent && latestEvent.eventImg}
          imgFilename={extractImageNameFromUrl(background_img)}
          videoFilename={is_video_background ? background_video : null}
          cn={sectionClasses.join(" ")}
        >
          <h1 ref={this.titleRef} className={`${s.hero_title}`}>
            {title_one_text}
          </h1>
          <h2 className={s.BannerText}>{title_two_text}</h2>
          <div className={s.LinkLabelsWrapper}>
            {link_labels.map((link_label, i) =>
              link_label.label ? (
                <span
                  key={link_label.label}
                  className={s.LinkLabel}
                  onClick={() => onScrollToSpecificTarget(i + 1)}
                  dangerouslySetInnerHTML={{ __html: link_label.label }}
                />
              ) : null
            )}
          </div>
          <ArrowDown className={s.ArrowDown} onClick={this.onArrowDown} />
        </SkewedHero>
      </div>
    )
  }
}

export default EventsPageHero
