import React from "react"

import { ImgFluid, Button, BottleWithDroplets } from "../"
import s from "./ProductNutritions.module.scss"
import {
  extractImageNameFromUrl,
  hexToRGB,
  hexToRGB_obj,
  paintCirclesAndPaths,
} from "../../helper"

// import Arrow from "../../assets/svgs/arrow-white.svg"
import CloseIcon from "../../assets/svgs/close-icon.svg"

class ProductNutritions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      customTitleSize: null,
    }
    this.closeBtnRef = React.createRef()
    this.triggerCloseBtn = () => {
      this.closeBtnRef.current.click()
    }
    this.nutritionIcons = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ]
  }

  componentDidMount() {
    this.setState({
      customTitleSize: this.calcFontSize(),
    })
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.fontSizeChangeCheck)
      // document.addEventListener("click", this.triggerCloseBtn)
    }
    this.paintIcons()
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.fontSizeChangeCheck)
      // document.removeEventListener("click", this.triggerCloseBtn)
    }
  }

  paintIcons = () => {
    const {
      productColor,
      // productSecondaryColor
    } = this.props
    const c = hexToRGB_obj(productColor)
    const rate = 0.7
    const nextColor = `rgb(${c.r + (255 - c.r) * rate}, ${
      c.g + (255 - c.g) * rate
    }, ${c.b + (255 - c.b) * rate})`
    this.nutritionIcons.forEach((r) =>
      paintCirclesAndPaths({
        ref: r,
        circleColor: productColor,
        pathColor: nextColor,
        ellipseColor: nextColor,
        circleOpacity: 1,
        pathOpacity: 1,
        removeClipPaths: true,
      })
    )
  }

  fontSizeChangeCheck = () => {
    const nextFontSize = this.calcFontSize()
    if (this.state.customTitleSize !== nextFontSize) {
      this.setState({
        customTitleSize: this.calcFontSize(),
      })
    }
  }

  calcFontSize = () => {
    const { title } = this.props.data || {}
    return this.props.calculateProductTitleSize(title)
  }

  renderDynamicNutritionBlock = ({ Svg, topText, bottomText, key }, index) => {
    const { productColor } = this.props

    return (
      <div className={s.NutriBlock} key={key}>
        <div className={s.NutriBlock__Icon} ref={this.nutritionIcons[index]}>
          <ImgFluid fileName={extractImageNameFromUrl(Svg)} />
        </div>
        <p
          className={s.NutriBlock__Top}
          style={{
            color: productColor,
          }}
          dangerouslySetInnerHTML={{ __html: topText }}
        ></p>
        <p
          className={s.NutriBlock__Bottom}
          style={{
            color: productColor,
            backgroundColor: hexToRGB(productColor, 0.3),
            letterSpacing: bottomText.length > 10 ? 0.5 : null,
          }}
          dangerouslySetInnerHTML={{ __html: bottomText }}
        ></p>
      </div>
    )
  }

  renderNutritionBlocks = () => {
    const { nutri_summary_title, nutri_summary_footer, nutritionBlockData } =
      this.props.data
    return (
      <div className={s.NutriSummaryBlocks}>
        <h6
          className={s.NutriSummaryBlockHeader}
          dangerouslySetInnerHTML={{ __html: nutri_summary_title }}
        />
        <div className={s.NutriBlocksWrapper}>
          {nutritionBlockData.map((n, i) =>
            this.renderDynamicNutritionBlock(n, i)
          )}
        </div>
        <p className={s.NutriSummaryBlockFooter}>{nutri_summary_footer}</p>
      </div>
    )
  }

  renderNutritionTable = () => {
    const { productColor } = this.props
    const { nutri_table_title, nutritionTableData } = this.props.data
    return (
      <div className={s.NutritionTable}>
        <h6
          className={s.NutritionTableTitle}
          // style={{
          //   color: productColor,
          // }}
        >
          {nutri_table_title}
        </h6>
        {nutritionTableData.map((d1) => (
          <div className={s.NutritionTableBlock} key={d1.title + d1.amount}>
            <div className={s.NutritionTableRow}>
              <span
                style={{
                  color: productColor,
                }}
              >
                {d1.title}
              </span>
              <span
                style={{
                  color: productColor,
                }}
              >
                {d1.amount}
              </span>
            </div>
            {d1.rows
              ? d1.rows.map((d2) => (
                  <div
                    key={d2.title + d2.amount}
                    className={s.NutritionTableRow}
                    style={{
                      borderTopColor: productColor,
                    }}
                  >
                    <span
                      style={{
                        color: productColor,
                      }}
                    >
                      {d2.title}
                    </span>
                    <span
                      style={{
                        color: productColor,
                      }}
                    >
                      {d2.amount}
                    </span>
                  </div>
                ))
              : null}
          </div>
        ))}
      </div>
    )
  }

  render() {
    let title = "Ernährungsinfos"
    if (this.props.data) {
      title = this.props.data.title
    }
    const { productName, productColor, fadeInAnimateExceptMainBottle } =
      this.props
    const sectionClasses = fadeInAnimateExceptMainBottle
      ? [s.ProductNutritionsFadeIn, s.ProductNutritions]
      : [s.ProductNutritions]
    return (
      <section
        className={sectionClasses.join(" ")}
        ref={this.props.refFromParent}
        // style={{ backgroundColor: productColor }}
        style={{ backgroundColor: "transparent" }}
      >
        <div className={s.WhiteBgWrapper}>
          <div className={s.WhiteBg}>
            <div
              className={s.TitleWrapper}
              style={{ fontSize: this.state.customTitleSize }}
            >
              {/* {productName ? (
                <h4
                  className={s.ProductName}
                  style={{
                    color: productColor,
                  }}
                >
                  {productName}
                </h4>
              ) : null} */}
              <div style={{ position: "relative" }}>
                {/* <Arrow
                  onClick={this.props.onToBack}
                  className={s.Arrow}
                  style={{
                    fill: productColor,
                    stroke: productColor,
                  }}
                /> */}
                <h2
                  className={s.TitleRed}
                  dangerouslySetInnerHTML={{ __html: title }}
                  style={{
                    color: productColor,
                  }}
                ></h2>
              </div>
            </div>
          </div>
        </div>
        <div className={s.RedBgWrapper}>
          <div
            className={s.RedBg}
            // style={{ background: productColor }}
            style={{ backgroundColor: "transparent" }}
          ></div>
        </div>
        <Button
          ref={this.closeBtnRef}
          onClick={this.props.onToBack}
          btnClassName={s.BackLink}
        >
          <CloseIcon />
        </Button>
        <div className={s.Content}>
          {this.renderNutritionBlocks()}
          <div className={s.BottleWrapper}>
            <BottleWithDroplets
              width="100%"
              height="100%"
              imageName={this.props.imageName}
              WrapperRef={this.props.bottleRef}
              // recalcMaskSignal={this.state.recalcMaskSignal}
              alt={productName}
            />
            {/* <ImgFluid cn={s.Bottle} fileName={this.props.imageName} /> */}
          </div>
          {this.renderNutritionTable()}
        </div>
      </section>
    )
  }
}

export default ProductNutritions
