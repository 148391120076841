import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

// import { Modal, ImgFluid } from "../../components"
import PlayButton from "../../assets/svgs/play-button.svg"
import s from "./EventPageCarousel.module.scss"

class EventPageCarousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 1,
    }
  }

  componentDidMount() {
    if (
      this.props.slickRef &&
      this.props.slickRef.current &&
      typeof this.props.slickRef.current.slickNext === "function"
    ) {
      this.props.slickRef.current.slickNext()
    }
  }

  afterChange = (newIndex) => {
    this.setState({
      currentIndex: newIndex,
    })
  }

  render() {
    const { imageData, title_one_text, title_two_text } = this.props
    return (
      <section
        className={s.EventPageCarousel}
        ref={this.eventsRef}
        style={{ zIndex: this.props.sectionZindex }}
      >
        <div className={s.EventsTitles}>
          <h5
            className={s.EventsSubTitle}
            dangerouslySetInnerHTML={{ __html: title_one_text }}
          ></h5>
          <h3
            className={s.EventsTitle}
            dangerouslySetInnerHTML={{ __html: title_two_text }}
          ></h3>
        </div>
        <div className={s.SliderWrapper}>
          {imageData && (
            <Slider
              dots
              ref={this.props.slickRef}
              infinite
              speed={500}
              // autoplay
              // autoplaySpeed={6000}
              slidesToShow={imageData.length < 3 ? imageData.length : 3}
              centerMode
              afterChange={this.afterChange}
              focusOnSelect
              slidesToScroll={1}
              useTransform={false}
              dotsClass={["slick-dots", s.Dots].join(" ")}
              cssEase="cubic-bezier(.29,.78,.61,.95)"
              pauseOnHover={false}
              // eslint-disable-next-line no-sparse-arrays
              responsive={[
                {
                  breakpoint: 1800,
                  settings: {
                    slidesToShow: imageData.length < 3 ? imageData.length : 3,
                  },
                },
                {
                  breakpoint: 1400,
                  settings: {
                    slidesToShow: 2.35,
                    centerMode: false,
                    infinite: imageData.length > 2,
                  },
                },
                {
                  breakpoint: 1000,
                  settings: {
                    slidesToShow: imageData.length < 2 ? imageData.length : 2,
                    centerMode: false,
                  },
                },
                ,
                {
                  breakpoint: 800,
                  settings: {
                    slidesToShow: 1,
                    centerMode: false,
                  },
                },
              ]}
            >
              {imageData.map((image, index) => (
                <div
                  className={s.SliderSpacer}
                  key={image.id}
                  onClick={() => {
                    if (
                      imageData.length < 4 ||
                      index === this.state.currentIndex
                    ) {
                      image.videoLink
                        ? this.props.onOpenModal(image.videoLink, "youtube")
                        : this.props.onOpenModal(image.url, "image")
                    }
                  }}
                >
                  <div className={s.SliderItemWrapper}>
                    {/* <ImgFluid
                      fileName={extractImageNameFromUrl(image.url)}
                      style={{
                        cursor: "pointer",
                      }}
                    /> */}
                    <img
                      src={image.url}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                    {image.videoLink && image.videoLink !== "#" && (
                      <PlayButton className={s.PlayButton} />
                    )}
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>
      </section>
    )
  }
}

export default EventPageCarousel
