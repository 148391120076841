import { HIKING_API_URL } from "../../constants"
import { ssrFriendlyFetch } from "../../helper"

export const getParameterByName = (name, url = window.location.href) => {
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, "\\$&")
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ""
  return decodeURIComponent(results[2].replace(/\+/g, " "))
}

export const submitRefreshV5Email = async (email, language) => {
  return ssrFriendlyFetch(
    // https://rivella.madebycolorelephant.com/wp-json/rivella-refresh
    // `https://${WP_API_URL}/wp-json/rivella-refresh/v2/submit_new_entry`,
    `${
      HIKING_API_URL.startsWith("http")
        ? HIKING_API_URL
        : `https://${HIKING_API_URL}`
    }/wp-json/rivella-refresh/v5/participate?email=${email}&language=${language}`,
    {
      //   body: {},
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json()
    })
    .catch((error) => {
      console.warn(error)
      return { result: "error", message: "internal" }
    })
}

export const submitRefreshV5WinnerForm = async (body) => {
  return ssrFriendlyFetch(
    // https://rivella.madebycolorelephant.com/wp-json/rivella-refresh
    // `https://${WP_API_URL}/wp-json/rivella-refresh/v2/submit_new_entry`,
    `${
      HIKING_API_URL.startsWith("http")
        ? HIKING_API_URL
        : `https://${HIKING_API_URL}`
    }/wp-json/rivella-refresh/v5/submit_winner_entry?type=${
      body.type
    }&from_name=${
      body.from_first_name
    }&from_surname=${body.from_last_name}&from_salutation=${
      body.from_salutation
    }&from_email=${body.from_email}&from_phone=${body.from_phone}&street=${
      body.street
    }&number=${body.number}&zip=${body.zip}&city=${body.city}&newsletter=${
      body.newsletter
    }&accepted=${body.accepted}&language=${body.language}&winning=${body.winning}&user_agent`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json()
    })
    .catch(() => {
      return { result: "error", message: "internal" }
    })
}

export const submitRefreshV5Entry = async (body) => {
  return ssrFriendlyFetch(
    // https://rivella.madebycolorelephant.com/wp-json/rivella-refresh
    // `https://${WP_API_URL}/wp-json/rivella-refresh/v2/submit_new_entry`,
    `${
      HIKING_API_URL.startsWith("http")
        ? HIKING_API_URL
        : `https://${HIKING_API_URL}`
    }/wp-json/rivella-refresh/v5/submit_new_entry?type=${
      body.type
    }&from_name=${
      body.from_first_name
    }&from_surname=${body.from_last_name}&from_salutation=${
      body.from_salutation
    }&from_email=${body.from_email}&from_phone=${body.from_phone}&street=${
      body.street
    }&number=${body.number}&zip=${body.zip}&city=${body.city}&newsletter=${
      body.newsletter
    }&accepted=${body.accepted}&language=${body.language}&winning=${body.winning}&user_agent`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json()
    })
    .catch(() => {
      return { result: "error", message: "internal" }
    })
}

export const confirmRefreshV5Entry = async (hash) => {
  return ssrFriendlyFetch(
    // https://rivella.madebycolorelephant.com/wp-json/rivella-refresh
    // `https://${WP_API_URL}/wp-json/rivella-refresh/v2/submit_new_entry`,
    `${
      HIKING_API_URL.startsWith("http")
        ? HIKING_API_URL
        : `https://${HIKING_API_URL}`
    }/wp-json/rivella-refresh/v5/confirm?hash=${hash}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json()
    })
    .catch(() => {
      return { result: "error", message: "internal" }
    })
}


export const confirmRefreshV5Win = async (hash) => {
  return ssrFriendlyFetch(
    // https://rivella.madebycolorelephant.com/wp-json/rivella-refresh
    // `https://${WP_API_URL}/wp-json/rivella-refresh/v2/submit_new_entry`,
    `${
      HIKING_API_URL.startsWith("http")
        ? HIKING_API_URL
        : `https://${HIKING_API_URL}`
    }/wp-json/rivella-refresh/v5/confirm-win?hash=${hash}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json()
    })
    .catch(() => {
      return { result: "error", message: "internal" }
    })
}


export const submitRefreshV5MainPrizeParticipation = async (body) => {
  return ssrFriendlyFetch(
    // https://rivella.madebycolorelephant.com/wp-json/rivella-refresh
    // `https://${WP_API_URL}/wp-json/rivella-refresh/v2/submit_new_entry`,
    `${
      HIKING_API_URL.startsWith("http")
        ? HIKING_API_URL
        : `https://${HIKING_API_URL}`
    }/wp-json/rivella-refresh/v5/submit_main_prizex_entry?type=${
      body.type
    }&from_title=${body.from_title}&from_name=${
      body.from_first_name
    }&from_surname=${body.from_last_name}&from_salutation=${
      body.from_salutation
    }&from_email=${body.from_email}&from_phone=${body.from_phone}&to_title=${body.to_title}&to_salutation=${
      body.to_salutation
    }&to_name=${body.to_first_name}&to_surname=${body.to_last_name}&street=${
      body.street
    }&number=${body.number}&zip=${body.zip}&city=${body.city}&newsletter=${
      body.newsletter
    }&accepted=${body.accepted}&language=${body.language}&user_agent`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json()
    })
    .catch(() => {
      return { result: "error", message: "internal" }
    })
}
