import React from "react"

import { ImgFluid } from "../../../components"
import { ChildrenButtonRenderer } from "../../"
import s from "./ContentWithImageOnSide.module.scss"
import { extractImageNameFromUrl } from "../../../helper"

class ContentWithImageOnSide extends React.Component {
  render() {
    const {
      title_one_text,
      title_one_color,
      title_two_text,
      title_two_color,
      title_three_text,
      title_three_color,
      description_one_text,
      description_one_color,
      side_image,
    } = this.props

    const isMigrosPromoPage =
      typeof window !== "undefined" &&
      window.location &&
      `${window.location.href}`.includes("spar")

    return (
      <section
        className={s.ContentWithImageOnSide}
        style={{ zIndex: this.props.sectionZindex }}
        ref={this.props.sectionRef}
      >
        <div className={s.Content}>
          <div className={[s.Col, s.ColLeft].join(" ")}>
            <h4 className={[s.SubtitleOne, title_one_color].join(" ")}>
              {title_one_text}
            </h4>
            <h3 className={[s.TitleMain, title_two_color].join(" ")}>
              {title_two_text}
            </h3>
            <div
              className={`${s.HideMobile} ${
                isMigrosPromoPage ? s.WideMargin : ""
              }`}
            >
              <h5 className={[s.SubtitleTwo, title_three_color].join(" ")}>
                {title_three_text}
              </h5>
              <p
                className={[s.Description, description_one_color].join(" ")}
                dangerouslySetInnerHTML={{ __html: description_one_text }}
              ></p>
              <ChildrenButtonRenderer buttonData={this.props.innerBlocks} />
            </div>
          </div>
          <div className={[s.Col, s.ColRight].join(" ")}>
            <ImgFluid fileName={extractImageNameFromUrl(side_image)} />
            {/* <div className={s.WhiteSkewed}></div> */}
          </div>
          <div className={[s.Col, s.MobileOnly].join(" ")}>
            <h5 className={[s.SubtitleTwo, title_three_color].join(" ")}>
              {title_three_text}
            </h5>
            <p
              className={[s.Description, description_one_color].join(" ")}
              dangerouslySetInnerHTML={{ __html: description_one_text }}
            ></p>
            <ChildrenButtonRenderer buttonData={this.props.innerBlocks} />
          </div>
        </div>
        <div className={s.RedBackground}></div>
      </section>
    )
  }
}

export default ContentWithImageOnSide
