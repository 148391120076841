import React from "react"
import { deleteCookie, getCurrentLanguage } from "../../../helper"
import { navigate } from "gatsby"
import { HikingAuthConsumer } from "../../../store"
import { winAndHike_routes } from "../../Footer/LanguagesData"
import s from "./LogoutBtn.module.scss"
import { HikeAndWinButton } from "../../../components"

class LogoutBtn extends React.Component {
  render() {
    const { location, label } = this.props
    const { lang } = getCurrentLanguage(location)
    return (
      <HikingAuthConsumer>
        {(store) => {
          const { logout } = store.actions
          return (
            <HikeAndWinButton
              className={s.LogoutBtn}
              onClick={(e) => {
                e.preventDefault()
                logout()
                deleteCookie("user_hash")
                deleteCookie("first_name")
                navigate(`/${lang}${winAndHike_routes[lang].gewinnspiel}`)
              }}
            >
              {label}
            </HikeAndWinButton>
          )
        }}
      </HikingAuthConsumer>
    )
  }
}

export default LogoutBtn
