import React from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"

import { SkewedCard, SkewedSectionWithBg, SkewedButton } from "../../components"
import s from "./EventsPageEngagement.module.scss"
import { routes } from "../Header/LanguagesData"
import { getCurrentLanguage } from "../../helper"

class EventsPageEngagement extends React.Component {
  render() {
    const {
      background_img,
      title_one_text,
      title_one_color,
      description_one_text,
      description_one_color,
      button_one_text,
      button_one_type,
      location,
    } = this.props
    if (
      !background_img ||
      !title_one_text ||
      !title_one_color ||
      !description_one_text ||
      !description_one_color ||
      !button_one_text ||
      !button_one_type
    )
      console.warn("EventsPageEngagement has undefined values")

    const currentLang = getCurrentLanguage(location).langHref

    const uri =
      routes[currentLang].aboutUs && routes[currentLang].aboutUs.startsWith("/")
        ? routes[currentLang].aboutUs
        : "/" + routes[currentLang].aboutUs
    const toAboutUs = `/${getCurrentLanguage(location).slug}${uri}#engagement`

    return (
      <section
        className={s.events_engagement_section}
        style={{ zIndex: this.props.sectionZindex }}
        ref={this.props.sectionRef}
      >
        <Helmet>
          <meta property="og:description" content={description_one_text} />
        </Helmet>
        <SkewedSectionWithBg
          bgImg={background_img}
          type="parallax"
          wrapperClassName={s.section_bg}
        >
          <SkewedCard cardOuterCn={s.section_bg_card}>
            <h2
              className={[s.section_bg_card_title, title_one_color].join(" ")}
            >
              {title_one_text}
            </h2>
            <p
              className={[
                s.section_bg_card_description,
                description_one_color,
              ].join(" ")}
              dangerouslySetInnerHTML={{
                __html: description_one_text,
              }}
            ></p>
            <Link to={toAboutUs} className={s.ButtonWrapper}>
              <SkewedButton
                wrapperClassName={s.more_info_btn}
                type={button_one_type}
              >
                {button_one_text}
              </SkewedButton>
            </Link>
          </SkewedCard>
        </SkewedSectionWithBg>
      </section>
    )
  }
}

export default EventsPageEngagement
