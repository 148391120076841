import React from "react"
import { Link } from "gatsby"

import s from "./MainSkewedSection.module.scss"
import { Button, SkewedSectionWithBg } from "../../../components"
import { getCurrentLanguage } from "../../../helper"

class MainSkewedSection extends React.Component {
  render() {
    const {
      background_img,
      title_one_text,
      title_one_color,
      button_one_text,
      button_one_type,
      button_link,
      location,
    } = this.props

    const currentLang = getCurrentLanguage(location)

    return (
      <section
        className={s.SectionWrapper}
        style={{ zIndex: this.props.sectionZindex }}
        ref={this.props.sectionRef}
      >
        <SkewedSectionWithBg
          bgImg={background_img}
          wrapperClassName={s.SectionBg}
          imageWrapperClass={s.ImageWrapper}
          type="parallax"
          cutWithClipPath
        >
          <div className={s.SkewedChildrenWrapper}>
            <div className={s.SkewedChildrenPositioner}>
              <h3 className={[s.Title, title_one_color].join(" ")}>
                {title_one_text}
              </h3>
              <Link
                to={
                  "/" +
                  currentLang.slug +
                  (button_link && !button_link.startsWith("/")
                    ? "/" + button_link
                    : button_link)
                }
              >
                <Button btnClassName={s.Button} type={button_one_type}>
                  {button_one_text}
                </Button>
              </Link>
            </div>
          </div>
        </SkewedSectionWithBg>
      </section>
    )
  }
}

export default MainSkewedSection
