import React from "react"
// import MapPinIcon from "../../assets/svgs/event_map_pin.svg"
import ClockIcon from "../../assets/svgs/clock-icon.svg"
import AlternatePinIcon from "../../assets/svgs/location-icon-black.svg"
import s from "./MapPin.module.scss"
import { Link } from "gatsby"

class MapPin extends React.Component {
  // name of the event, location, date, i..e. Summer Roadshow, Arosa, 10.-11.07.21
  renderEventDetails = (eventData) => {
    const { eventTitle, eventLocation, eventDateText, slug } = eventData

    return (
      <div className={s.Popup}>
        <div className={s.PopupContentWrapper}>
          <Link style={{ textDecoration: "none" }} to={slug}>
            <div className={s.popup_content_container}>
              <div className={s.popup_date_wrapper}>
                <ClockIcon width="18px" height="18px" />
                {eventDateText}
              </div>
              <div className={s.popup_title_wrapper}>{eventTitle}</div>
              <div className={s.popup_location_wrapper}>
                <AlternatePinIcon width="18px" height="18px" />
                {eventLocation}
              </div>
            </div>
          </Link>
        </div>
      </div>
    )
  }

  render() {
    const classNames = [s.icon_wrapper]
    const { children, handleClick, iconClassName, onMouseEnter, eventData } =
      this.props
    if (iconClassName) classNames.push(iconClassName)
    return (
      <button
        onClick={handleClick}
        className={classNames.join(" ")}
        onMouseEnter={onMouseEnter ? onMouseEnter : function () {}}
      >
        {this.renderEventDetails(eventData)}
        <span className={s.icon_inner}>{children}</span>
      </button>
    )
  }
}

export default MapPin
