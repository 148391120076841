import React from "react"
import s from "./Error.module.scss"

const Error = ({ failMessage }) => {
  const isInternal = failMessage === "internal"

  return (
    <section className={s.section}>
      <div className={s.failed_title}>
        <span>
          {failMessage ? (isInternal ? "Error!" : failMessage) : "Error"}
        </span>
        <br />
        <span>
          &nbsp;
          <a href="https://rivella.ch/de" className={s.description_link}>
            www.rivella.ch/de
          </a>
        </span>
      </div>
    </section>
  )
}

export default Error
