import React from "react"
import { Link } from "gatsby"

import s from "./ChildrenButtonRenderer.module.scss"
import { HikeAndWinButton, SkewedButton } from "../../components"

class ChildrenButtonRenderer extends React.Component {
  mapDataToButtons = (data = []) => {
    return data.map((d) => {
      if (d.blockName === "rivella/skewed-button") {
        const { is_internal, type, link, label } = d.attrs
        if (is_internal) {
          return (
            <div key={link}>
              <Link to={link}>
                <SkewedButton type={type}>{label}</SkewedButton>
              </Link>
            </div>
          )
        } else {
          return (
            <div key={link}>
              <a href={link} target="_blank" rel="noopener noreferrer">
                <SkewedButton type={type}>{label}</SkewedButton>
              </a>
            </div>
          )
        }
      } else if (d.blockName === "hiking/hiking-button") {
        const { is_internal, link, label } = d.attrs
        if (is_internal) {
          return (
            <div key={link}>
              <Link to={link}>
                <HikeAndWinButton>{label}</HikeAndWinButton>
              </Link>
            </div>
          )
        } else {
          return (
            <div key={link}>
              <a href={link} target="_blank" rel="noopener noreferrer">
                <HikeAndWinButton>{label}</HikeAndWinButton>
              </a>
            </div>
          )
        }
      }
      return null
    })
  }

  render() {
    return (
      <div className={s.ChildrenButtonRenderer}>
        {this.mapDataToButtons(this.props.buttonData)}
      </div>
    )
  }
}

export default ChildrenButtonRenderer
