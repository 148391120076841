import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import { ExternalSvg } from "../"

/**
 * Returns a fluid Img that fits to parent component, if you want to use fixed size image use
 * sibling component ImgFixed.js in this folder.
 *
 * @parameter `props` with following children:
 * fileName {String} - Example: "profile.jpg"
 * cn {String} - ClassName to append to Img tag
 * alt {String} - Description for html semantics
 *
 * @returns `React.Component`
 */
const ImgFluid = ({
  fileName,
  cn,
  alt,
  style,
  ref,
  itemProp,
  loading,
  durationFadeIn,
  fadeIn,
  objectFit,
  imgStyle,
  onLoad = function () {},
}) => {
  const reg = /.svg$/
  if (fileName && reg.test(fileName))
    return (
      <ExternalSvg
        fileName={fileName}
        cn={cn}
        // alt={alt}
        style={style}
        ref={ref}
      />
    )
  return (
    <StaticQuery
      query={graphql`
        query {
          allImageSharp {
            edges {
              node {
                fluid(jpegQuality: 85, pngQuality: 50, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const found = data.allImageSharp.edges.find((element) => {
          return element.node.fluid.src.split("/").pop() === fileName
        })
        if (!found) return null
        return (
          <Img
            fadeIn={fadeIn}
            ref={ref}
            style={style}
            fluid={found.node.fluid}
            itemProp={itemProp}
            className={cn}
            durationFadeIn={durationFadeIn}
            loading={loading}
            alt={alt ? alt : fileName}
            onLoad={onLoad}
            objectFit={objectFit}
            imgStyle={imgStyle}
          />
        )
      }}
    />
  )
}

export default ImgFluid
