import React from "react"

import { Button, ImgFluid, Modal } from "../../../components"
import s from "./ReachFriendForm.module.scss"
import FullscreenIcon from "../../../assets/svgs/fullscreen.svg"
import {
  getCurrentLanguage,
  submitRefreshYourFriend,
  getTotalRefreshFormSubmissions,
  confirmRefreshEntry,
  extractImageNameFromUrl,
  sanitizeEmojisInText,
  scrollToRef,
} from "../../../helper"
import { MAX_NUM_SUBMISSIONS } from "../../../constants"
import { validateSwissPostCode } from "../../../util/validateSwissPostCode"

const checkSpamMessage = {
  de: "Nach Abschluss dieses Vorgangs senden wir dir eine E-Mail, um den Versand zu bestätigen. Bitte prüfe deinen Spam-Ordner, falls du die Nachricht nicht umgehend erhältst.",
  fr: "Une fois ce processus terminé, vous recevrez un courrier électronique pour confirmer votre envoi. Veuillez vérifier votre boîte de courrier indésirable si vous ne recevez pas le message immédiatement.",
}

class ReachFriendForm extends React.Component {
  constructor(props) {
    super(props)
    this.formFieldRef = React.createRef()
    this.BlurHackRef = React.createRef()
    this.salutationOfSenderRef = React.createRef()
    this.titleOfSenderRef = React.createRef()
    this.salutationOfRecepientRef = React.createRef()
    this.titleOfRecepientRef = React.createRef()
    this.state = {
      selectedModalImageName: null,
      modalIsOpen: false,
      formIsDisabled: false,
      maxSubmissionLimit: 10,
      submitDisabled: false,
      currentSubmissionCount: null,
      displaySuccess: false,
      youSentAlready: false,
      confirmMessage: false,
      fieldsAreValid: true,
      showForm: false,
      showCountLimitMsg: false,
      // thisAddressGotItAlready: false,
      formData: {
        // Sender
        salutationOfSender: {
          name: "from_salutation",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        titleOfSender: {
          name: "from_title",
          value: "",
          ref: React.createRef(),
        },
        surnameOfSender: {
          // name: "from_surname",
          name: "from_name",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        nameOfSender: {
          // name: "from_name",
          name: "from_surname",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        emailOfSender: {
          name: "from_email",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        // Recepient
        salutationOfRecepient: {
          name: "to_salutation",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        titleOfRecepient: {
          name: "to_title",
          value: "",
          ref: React.createRef(),
        },
        nameOfRecepient: {
          name: "to_surname",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        surnameOfRecepient: {
          name: "to_name",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        // TODO: Remove the emailRecepient block once it's fixed at the backend as the
        // field is mandatory on the backend, so we added a static email as a bypass
        // emailOfRecepient: {
        //   name: "to_email",
        //   value: "random@email.com",
        //   required: true,
        //   ref: React.createRef(),
        // },
        streetOfRecepient: {
          name: "to_street",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        houseNoOfRecepient: {
          name: "to_number",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        postcodeOfRecepient: {
          name: "to_zip",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        townOfRecepient: {
          name: "to_city",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        // Message
        selectedPostcard: {
          name: "design",
          value: 1,
          required: true,
          ref: React.createRef(),
        },
        messageToFriend: {
          name: "message",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        newsletterSignup: {
          name: "newsletter",
          value: false,
          ref: React.createRef(),
        },
        termsAndConditions: {
          name: "accepted",
          value: false,
          required: true,
          ref: React.createRef(),
        },
      },
    }
  }

  onCloseModal = () => {
    this.setState(
      {
        modalIsOpen: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            selectedModalImageName: null,
          })
        }, 200)
      }
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.currentSubmissionCount &&
      this.state.currentSubmissionCount &&
      prevState.currentSubmissionCount >= this.state.maxSubmissionLimit &&
      !this.state.formIsDisabled
    ) {
      this.setFormDisabled()
    }
  }

  componentDidMount() {
    if (this.props.location.search.split("=")[0] === "?confirm") {
      this.confirmEntry()
    } else {
      getTotalRefreshFormSubmissions()
        .then((res) => {
          this.setState({
            currentSubmissionCount: res.count,
            showCountLimitMsg: MAX_NUM_SUBMISSIONS - res.count < 1,
            showForm: MAX_NUM_SUBMISSIONS - res.count > 0,
          })
        })
        .catch(() => {
          this.setState({
            currentSubmissionCount: 0,
            showCountLimitMsg: false,
          })
        })
    }
  }

  onOpenModal = (imageName) => {
    if (!imageName) return
    this.setState({
      selectedModalImageName: imageName,
      modalIsOpen: true,
    })
  }

  setFormDisabled = () => {
    this.setState({
      formIsDisabled: true,
    })
  }

  onSubmit = (e) => {
    if (e) e.preventDefault()
    if (this.state.submitDisabled) return
    const isValid = this.validateFields()
    this.setState({ fieldsAreValid: isValid, youSentAlready: false })

    if (isValid) this.submitForm()
  }

  scrollToConfirmationMsg = () => {
    const { current: formField } = this.formFieldRef
    if (formField) {
      window.scroll(0, formField.offsetTop - 300)
    }
  }

  submitForm = async () => {
    this.setState(
      {
        submitDisabled: true,
        confirmMessage: false,
      },
      () => {
        // in case an uncaught error happens, reenable button back after 5 seconds
        setTimeout(() => {
          if (this.state.submitDisabled) {
            this.setState({
              submitDisabled: false,
            })
          }
        }, 5000)
      }
    )
    const { formData } = this.state
    let body = {}
    body.lang = getCurrentLanguage(this.props.location).lang
    body.user_agent = navigator && navigator.userAgent
    // body.ip = "111.112.113.114"
    Object.keys(formData).forEach((key) => {
      const entry = formData[key]
      if (key === "newsletterSignup" || key === "termsAndConditions") {
        body[entry.name] = entry.value ? 1 : 0
      } else {
        body[entry.name] = entry.value
      }
    })

    submitRefreshYourFriend(JSON.stringify(body)).then((res) => {
      if (res) {
        if (res.result !== "error") {
          this.setState({
            submitDisabled: false,
            currentSubmissionCount: this.state.currentSubmissionCount + 1,
            displaySuccess: true,
            showForm: false,
          })
          this.scrollToConfirmationMsg()
        } else {
          if (
            res.message &&
            (res.message.includes("IP") ||
              res.message.toLowerCase().includes("email was already used"))
          ) {
            this.setState({
              submitDisabled: false,
              currentSubmissionCount: this.state.currentSubmissionCount + 1,
              youSentAlready: true,
            })
          }
          if (res.message.includes("Bad Email")) {
            this.setState({
              ...this.state,
              submitDisabled: false,
              fieldsAreValid: false,
              formData: {
                ...this.state.formData,
                emailOfSender: {
                  ...this.state.formData.emailOfSender,
                  warnRequired: true,
                },
              },
            })
          }
        }
      } else {
        this.setState({
          submitDisabled: false,
        })
      }
    })
  }

  confirmEntry = async () => {
    let body = {}
    body.hash = this.props.location.search.split("=")[1]
    this.scrollToConfirmationMsg()

    confirmRefreshEntry(JSON.stringify(body)).then((res) => {
      if (res) {
        if (res.result !== "error") {
          this.setState({
            confirmMessage: true,
            showForm: false,
          })
          this.scrollToConfirmationMsg()
        } else {
          this.setState({
            confirmMessage: false,
            showForm: false,
          })
          this.scrollToConfirmationMsg()
        }
      }
    })
  }

  setSelectVisible = (to, name) => {
    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        [name]: {
          ...this.state.formData[name],
          visible: to,
        },
      },
    })
  }

  onSelectChange = (value, name, refToBlur) => {
    const formValue = value
    this.setState(
      {
        ...this.state,
        formData: {
          ...this.state.formData,
          [name]: {
            ...this.state.formData[name],
            value: formValue,
            visible: false,
            warnRequired: false,
          },
        },
      },
      () => {
        if (refToBlur && refToBlur.current) {
          refToBlur.current.blur()
        }
      }
    )
  }

  onInputChange = (v, name, { numberOnly, sanitizeEmojis, maxLength } = {}) => {
    let value = v
    if (numberOnly) {
      if (isNaN(value)) return
      else value = +value
    }
    if (sanitizeEmojis) {
      value = sanitizeEmojisInText(value)
    }
    if (maxLength) {
      if (value.length > maxLength) return
    }

    this.setState({
      ...this.state,
      displaySuccess: false,
      formData: {
        ...this.state.formData,
        [name]: {
          ...this.state.formData[name],
          value,
          warnRequired: false,
        },
      },
    })
  }

  renderPostcardOption = (postcardName = "", no) => {
    const postcard = postcardName.startsWith("http")
      ? extractImageNameFromUrl(postcardName)
      : postcardName
    if (postcardName && postcardName.includes("image-placeholder")) return null
    return (
      <div className={[s.Medium, s.PostcardOptionWrapper].join(" ")}>
        {/* <input
          type="radio"
          name={this.state.formData.selectedPostcard.name}
          value={this.state.formData.selectedPostcard.value === postcardName}
          onClick={() => this.onInputChange(postcardName, "selectedPostcard")}
        /> */}
        <div
          className={[
            s.PostcardImageWrapper,
            this.state.formData.selectedPostcard.value === no
              ? s.PostCardImageWrapperSelected
              : null,
          ].join(" ")}
          // onClick={() => this.onInputChange(no, "selectedPostcard")}
          onClick={() => this.onOpenModal(postcard)}
        >
          <ImgFluid fileName={postcard} />
        </div>
        <FullscreenIcon
          className={s.FullscreenIcon}
          onClick={() => this.onOpenModal(postcard)}
        />
      </div>
    )
  }

  validateField = (fieldObj) => {
    const fieldValue =
      typeof fieldObj.value === "string"
        ? `${fieldObj.value}`.trim()
        : fieldObj.value
    if (
      (fieldObj.required && !fieldValue) ||
      (fieldObj.name.toLowerCase().includes("email") &&
        !fieldValue.includes("@"))
    ) {
      fieldObj.warnRequired = true
      return false
    }
    if (fieldObj.name === "to_zip" && !validateSwissPostCode(fieldValue)) {
      fieldObj.warnRequired = true
      return false
    }
    return true
  }

  validateFields = () => {
    let formDataCopied = { ...this.state.formData }
    let isValid = true

    Object.keys(formDataCopied).forEach((key) => {
      const nextIsValid = this.validateField(formDataCopied[key])
      if (!nextIsValid && isValid) {
        scrollToRef(formDataCopied[key].ref, {
          behavior: "smooth",
          block: "center",
        })
      }
      isValid = nextIsValid && isValid
    })

    if (!isValid) {
      this.setState({
        formData: formDataCopied,
      })
    }

    return isValid
  }

  // resetRequiredWarning = key => {
  //   this.setState({
  //     formData: {
  //       ...this.state.formData,
  //       [key]: {
  //         ...this.state.formData[key],
  //         warnRequired: false,
  //       },
  //     },
  //   })
  // }

  // the mouse over event bugs the tablet/mobile, so we only want mouseover
  // working when it's laptop or above
  selectMouseEventConstructor = (to, name) => {
    if (typeof window !== "undefined" && window.innerWidth > 1300)
      return this.setSelectVisible(to, name)
    return function () {}
  }

  renderForm = () => {
    const { form_title, location } = this.props
    const { sender, reciever, message, checkboxes, submit } =
      this.props.formFieldsInfo
    const { formData } = this.state
    const { lang } = getCurrentLanguage(location)

    const mailMessage = checkSpamMessage[lang]

    // const messageValue = formData.messageToFriend.value
    // let messageLength = 0
    // if (messageValue) {
    //   const messageArr = messageValue.split("")
    //   messageArr.forEach(c => {
    //     messageLength += c === "\n" ? 2 : 1
    //   })
    // }

    // const lang = getCurrentLanguage(this.props.location).lang
    return (
      <form onSubmit={this.onSubmit} className={s.Form} ref={this.BlurHackRef}>
        <h3 className={s.FormTitle}>{form_title}</h3>
        {/* <input
          type="text"
          // style={{ opacity: 0, position: "absolute" }}
        /> */}
        <div className={s.Row}>
          <div className={s.Col_2}>
            <h4 className={s.RowTitle}>{sender.header.title}</h4>
            <div className={`${s.Row} ${s.TwoColumns}`}>
              <div
                className={s.FormGroup}
                ref={formData.salutationOfSender.ref}
              >
                <label>{sender.mr_mrs.placeholder}</label>
                <div
                  tabIndex={0}
                  ref={this.salutationOfSenderRef}
                  className={[
                    s.Select,
                    s.Small,
                    formData.salutationOfSender.warnRequired
                      ? s.WarnRequired
                      : null,
                    formData.salutationOfSender.visible
                      ? s.SelectVisible
                      : null,
                  ].join(" ")}
                  onMouseEnter={() =>
                    this.selectMouseEventConstructor(true, "salutationOfSender")
                  }
                  onMouseLeave={() =>
                    this.selectMouseEventConstructor(
                      false,
                      "salutationOfSender"
                    )
                  }
                  onFocus={() =>
                    this.setSelectVisible(true, "salutationOfSender")
                  }
                  onBlur={() =>
                    this.setSelectVisible(false, "salutationOfSender")
                  }
                >
                  <input
                    disabled
                    value={formData.salutationOfSender.value}
                    name={formData.salutationOfSender.name}
                    required={formData.salutationOfSender.required}
                    placeholder={sender.mr_mrs.placeholder}
                  />
                  <ul>
                    <li
                      className={
                        formData.salutationOfSender.value ===
                        sender.mr_mrs.mr.value
                          ? s.Selected
                          : null
                      }
                      onClick={() =>
                        this.onSelectChange(
                          sender.mr_mrs.mr.value,
                          "salutationOfSender",
                          this.salutationOfSenderRef
                        )
                      }
                    >
                      {sender.mr_mrs.mr.label}
                    </li>
                    <li
                      className={
                        formData.salutationOfSender.value ===
                        sender.mr_mrs.mrs.value
                          ? s.Selected
                          : null
                      }
                      onClick={() =>
                        this.onSelectChange(
                          sender.mr_mrs.mrs.value,
                          "salutationOfSender",
                          this.salutationOfSenderRef
                        )
                      }
                    >
                      {sender.mr_mrs.mrs.label}
                    </li>
                  </ul>
                </div>
              </div>
              <div className={s.FormGroup} ref={formData.titleOfSender.ref}>
                <label>{sender.title.placeholder}</label>
                <div
                  tabIndex={0}
                  ref={this.titleOfSenderRef}
                  className={[
                    s.Select,
                    s.Small,
                    formData.titleOfSender.warnRequired ? s.WarnRequired : null,
                    formData.titleOfSender.visible ? s.SelectVisible : null,
                  ].join(" ")}
                  onMouseEnter={() =>
                    this.selectMouseEventConstructor(true, "titleOfSender")
                  }
                  onMouseLeave={() =>
                    this.selectMouseEventConstructor(false, "titleOfSender")
                  }
                  onFocus={() => this.setSelectVisible(true, "titleOfSender")}
                  onBlur={() => this.setSelectVisible(false, "titleOfSender")}
                >
                  <input
                    disabled
                    value={formData.titleOfSender.value}
                    name={formData.titleOfSender.name}
                    required={formData.titleOfSender.required}
                    placeholder={sender.title.placeholder}
                  />
                  <ul>
                    <li
                      className={
                        formData.titleOfSender.value ===
                        sender.title.empty.value
                          ? s.Selected
                          : null
                      }
                      onClick={() =>
                        this.onSelectChange(
                          sender.title.empty.value,
                          "titleOfSender",
                          this.titleOfSenderRef
                        )
                      }
                    >
                      {sender.title.empty.label}
                    </li>
                    <li
                      className={
                        formData.titleOfSender.value === sender.title.dr.value
                          ? s.Selected
                          : null
                      }
                      onClick={() =>
                        this.onSelectChange(
                          sender.title.dr.value,
                          "titleOfSender",
                          this.titleOfSenderRef
                        )
                      }
                    >
                      {sender.title.dr.label}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={s.Row}>
              <div className={s.FormGroup} ref={formData.surnameOfSender.ref}>
                <label>{sender.surname.placeholder}</label>
                <input
                  className={[
                    s.Medium,
                    formData.surnameOfSender.warnRequired
                      ? s.WarnRequired
                      : null,
                  ].join(" ")}
                  value={formData.surnameOfSender.value}
                  name={formData.surnameOfSender.name}
                  onChange={(e) =>
                    this.onInputChange(e.target.value, "surnameOfSender")
                  }
                  required={formData.surnameOfSender.required}
                />
              </div>
            </div>
            <div className={s.Row}>
              <div className={s.FormGroup} ref={formData.nameOfSender.ref}>
                <label>{sender.name.placeholder}</label>
                <input
                  className={[
                    s.Medium,
                    formData.nameOfSender.warnRequired ? s.WarnRequired : null,
                  ].join(" ")}
                  value={formData.nameOfSender.value}
                  name={formData.nameOfSender.name}
                  onChange={(e) =>
                    this.onInputChange(e.target.value, "nameOfSender")
                  }
                  required={formData.nameOfSender.required}
                />
              </div>
            </div>
            <div className={s.Row}>
              <div className={s.FormGroup} ref={formData.emailOfSender.ref}>
                <label>{sender.email.placeholder}</label>
                <input
                  className={[
                    s.Medium,
                    formData.emailOfSender.warnRequired ? s.WarnRequired : null,
                  ].join(" ")}
                  value={formData.emailOfSender.value}
                  name={formData.emailOfSender.name}
                  onChange={(e) =>
                    this.onInputChange(e.target.value, "emailOfSender")
                  }
                  required={formData.emailOfSender.required}
                />
              </div>
            </div>
          </div>
          <div className={s.Col_2}>
            <h4 className={s.RowTitle}>{reciever.header.title}</h4>
            <div className={`${s.Row} ${s.TwoColumns}`}>
              <div
                className={s.FormGroup}
                ref={formData.salutationOfRecepient.ref}
              >
                <label>{reciever.mr_mrs.placeholder}</label>
                <div
                  tabIndex={0}
                  ref={this.salutationOfRecepientRef}
                  className={[
                    s.Select,
                    s.Small,
                    formData.salutationOfRecepient.warnRequired
                      ? s.WarnRequired
                      : null,
                    formData.salutationOfRecepient.visible
                      ? s.SelectVisible
                      : null,
                  ].join(" ")}
                  onMouseEnter={() =>
                    this.selectMouseEventConstructor(
                      true,
                      "salutationOfRecepient"
                    )
                  }
                  onMouseLeave={() =>
                    this.selectMouseEventConstructor(
                      false,
                      "salutationOfRecepient"
                    )
                  }
                  onFocus={() =>
                    this.setSelectVisible(true, "salutationOfRecepient")
                  }
                  onBlur={() =>
                    this.setSelectVisible(false, "salutationOfRecepient")
                  }
                >
                  <input
                    disabled
                    value={formData.salutationOfRecepient.value}
                    name={formData.salutationOfRecepient.name}
                    required={formData.salutationOfRecepient.required}
                    placeholder={reciever.mr_mrs.placeholder}
                  />
                  <ul>
                    <li
                      className={
                        formData.salutationOfRecepient.value ===
                        reciever.mr_mrs.mr.value
                          ? s.Selected
                          : null
                      }
                      onClick={() =>
                        this.onSelectChange(
                          reciever.mr_mrs.mr.value,
                          "salutationOfRecepient",
                          this.salutationOfRecepientRef
                        )
                      }
                    >
                      {reciever.mr_mrs.mr.label}
                    </li>
                    <li
                      className={
                        formData.salutationOfRecepient.value ===
                        reciever.mr_mrs.mrs.value
                          ? s.Selected
                          : null
                      }
                      onClick={() =>
                        this.onSelectChange(
                          reciever.mr_mrs.mrs.value,
                          "salutationOfRecepient",
                          this.salutationOfRecepientRef
                        )
                      }
                    >
                      {reciever.mr_mrs.mrs.label}
                    </li>
                  </ul>
                </div>
              </div>
              <div className={s.FormGroup} ref={formData.titleOfRecepient.ref}>
                <label>{reciever.title.placeholder}</label>
                <div
                  tabIndex={0}
                  ref={this.titleOfRecepientRef}
                  className={[
                    s.Select,
                    s.Small,
                    formData.titleOfRecepient.warnRequired
                      ? s.WarnRequired
                      : null,
                    formData.titleOfRecepient.visible ? s.SelectVisible : null,
                  ].join(" ")}
                  onMouseEnter={() =>
                    this.selectMouseEventConstructor(true, "titleOfRecepient")
                  }
                  onMouseLeave={() =>
                    this.selectMouseEventConstructor(false, "titleOfRecepient")
                  }
                  onFocus={() =>
                    this.setSelectVisible(true, "titleOfRecepient")
                  }
                  onBlur={() =>
                    this.setSelectVisible(false, "titleOfRecepient")
                  }
                >
                  <input
                    disabled
                    value={formData.titleOfRecepient.value}
                    name={formData.titleOfRecepient.name}
                    required={formData.titleOfRecepient.required}
                    placeholder={reciever.title.placeholder}
                  />
                  <ul>
                    <li
                      className={
                        formData.titleOfRecepient.value ===
                        reciever.title.empty.value
                          ? s.Selected
                          : null
                      }
                      onClick={() =>
                        this.onSelectChange(
                          reciever.title.empty.value,
                          "titleOfRecepient",
                          this.titleOfRecepientRef
                        )
                      }
                    >
                      {reciever.title.empty.label}
                    </li>
                    <li
                      className={
                        formData.titleOfRecepient.value ===
                        reciever.title.dr.value
                          ? s.Selected
                          : null
                      }
                      onClick={() =>
                        this.onSelectChange(
                          reciever.title.dr.value,
                          "titleOfRecepient",
                          this.titleOfRecepientRef
                        )
                      }
                    >
                      {reciever.title.dr.label}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={s.Row}>
              <div
                className={s.FormGroup}
                ref={formData.surnameOfRecepient.ref}
              >
                <label>{reciever.surname.placeholder}</label>
                <input
                  className={[
                    s.Medium,
                    formData.surnameOfRecepient.warnRequired
                      ? s.WarnRequired
                      : null,
                  ].join(" ")}
                  value={formData.surnameOfRecepient.value}
                  name={formData.surnameOfRecepient.name}
                  onChange={(e) =>
                    this.onInputChange(e.target.value, "surnameOfRecepient")
                  }
                  required={formData.surnameOfRecepient.required}
                />
              </div>
            </div>
            <div className={s.Row}>
              <div className={s.FormGroup} ref={formData.nameOfRecepient.ref}>
                <label>{reciever.name.placeholder}</label>
                <input
                  className={[
                    s.Medium,
                    formData.nameOfRecepient.warnRequired
                      ? s.WarnRequired
                      : null,
                  ].join(" ")}
                  value={formData.nameOfRecepient.value}
                  name={formData.nameOfRecepient.name}
                  onChange={(e) =>
                    this.onInputChange(e.target.value, "nameOfRecepient")
                  }
                  required={formData.nameOfRecepient.required}
                />
              </div>
            </div>
            {/* <div className={s.Row}>
              <div className={s.FormGroup}>
                <label>{reciever.email.placeholder}</label>
                <input
                  className={[
                    s.Medium,
                    formData.emailOfRecepient.warnRequired
                      ? s.WarnRequired
                      : null,
                  ].join(" ")}
                  value={formData.emailOfRecepient.value}
                  name={formData.emailOfRecepient.name}
                  onChange={e =>
                    this.onInputChange(e.target.value, "emailOfRecepient")
                  }
                  required={formData.emailOfRecepient.required}
                />
              </div>
            </div> */}
            <div className={`${s.Row} ${s.TwoColumns}`}>
              <div
                className={`${s.FormGroup} ${s.Medium}`}
                ref={formData.streetOfRecepient.ref}
              >
                <label>{reciever.street.placeholder}</label>
                <input
                  className={[
                    s.Medium,
                    formData.streetOfRecepient.warnRequired
                      ? s.WarnRequired
                      : null,
                  ].join(" ")}
                  value={formData.streetOfRecepient.value}
                  name={formData.streetOfRecepient.name}
                  onChange={(e) =>
                    this.onInputChange(e.target.value, "streetOfRecepient")
                  }
                  required={formData.streetOfRecepient.required}
                />
              </div>
              <div
                className={`${s.FormGroup} ${s.Small}`}
                ref={formData.houseNoOfRecepient.ref}
              >
                <label>{reciever.house_no.placeholder}</label>
                <input
                  className={[
                    s.Small,
                    formData.houseNoOfRecepient.warnRequired
                      ? s.WarnRequired
                      : null,
                  ].join(" ")}
                  value={formData.houseNoOfRecepient.value}
                  name={formData.houseNoOfRecepient.name}
                  onChange={(e) =>
                    this.onInputChange(e.target.value, "houseNoOfRecepient")
                  }
                  required={formData.houseNoOfRecepient.required}
                />
              </div>
            </div>
            <div className={`${s.Row} ${s.TwoColumns}`}>
              <div
                className={`${s.FormGroup} ${s.Small}`}
                ref={formData.postcodeOfRecepient.ref}
              >
                <label>{reciever.postal_code.placeholder}</label>
                <input
                  className={[
                    s.Small,
                    formData.postcodeOfRecepient.warnRequired
                      ? s.WarnRequired
                      : null,
                  ].join(" ")}
                  value={formData.postcodeOfRecepient.value}
                  name={formData.postcodeOfRecepient.name}
                  onChange={(e) =>
                    this.onInputChange(e.target.value, "postcodeOfRecepient", {
                      numberOnly: true,
                    })
                  }
                  maxLength={4}
                  required={formData.postcodeOfRecepient.required}
                />
              </div>
              <div
                className={`${s.FormGroup} ${s.Medium}`}
                ref={formData.townOfRecepient.ref}
              >
                <label>{reciever.town.placeholder}</label>
                <input
                  className={[
                    s.Medium,
                    formData.townOfRecepient.warnRequired
                      ? s.WarnRequired
                      : null,
                  ].join(" ")}
                  value={formData.townOfRecepient.value}
                  name={formData.townOfRecepient.name}
                  onChange={(e) =>
                    this.onInputChange(e.target.value, "townOfRecepient")
                  }
                  required={formData.townOfRecepient.required}
                />
              </div>
            </div>
          </div>
        </div>

        <h4 className={s.RowTitle}>{message.postcard_title}</h4>
        <div
          className={[
            s.Row,
            s.RowWithPadding,
            s.PostcardsSection,
            formData.selectedPostcard.warnRequired ? s.WarnRequired : null,
          ].join(" ")}
          ref={formData.postcodeOfRecepient.ref}
        >
          {message.postcard_options.map((p, i) =>
            this.renderPostcardOption(p, i + 1)
          )}
        </div>
        <h5 className={s.Note}>{reciever.delivery_warning}</h5>
        <div className={s.Row}>
          <textarea
            className={[
              s.Medium,
              formData.messageToFriend.warnRequired ? s.WarnRequired : null,
            ].join(" ")}
            placeholder={message.message_content.placeholder}
            value={formData.messageToFriend.value}
            name={formData.messageToFriend.name}
            maxLength={200}
            onChange={(e) =>
              this.onInputChange(e.target.value, "messageToFriend", {
                sanitizeEmojis: true,
                maxLength: 200,
              })
            }
            ref={formData.messageToFriend.ref}
            required={formData.messageToFriend.required}
          />
        </div>
        <p className={s.LetterCount}>
          {formData.messageToFriend.value
            ? formData.messageToFriend.value.length
            : 0}{" "}
          {/* {messageLength}  */}/ 200
        </p>
        <h5 className={s.Note}>{mailMessage}</h5>
        <div
          className={[
            s.Row,
            s.RowWithPadding,
            formData.newsletterSignup.warnRequired ? s.WarnRequired : null,
          ].join(" ")}
          ref={formData.newsletterSignup.ref}
        >
          <input
            type="checkbox"
            className={s.Checkbox}
            checked={formData.newsletterSignup.value}
            name={formData.newsletterSignup.name}
            id={formData.newsletterSignup.name}
            onChange={() =>
              this.onInputChange(
                !formData.newsletterSignup.value,
                "newsletterSignup"
              )
            }
            required={formData.newsletterSignup.required}
          />
          <label
            className={s.Label}
            htmlFor={formData.newsletterSignup.name}
            dangerouslySetInnerHTML={{
              __html: checkboxes.newsletter.placeholder,
            }}
          ></label>
        </div>
        <div
          className={[
            s.Row,
            s.RowWithPadding,
            formData.termsAndConditions.warnRequired ? s.WarnRequired : null,
          ].join(" ")}
          ref={formData.termsAndConditions.ref}
        >
          <input
            type="checkbox"
            className={s.Checkbox}
            checked={formData.termsAndConditions.value}
            name={formData.termsAndConditions.name}
            id={formData.termsAndConditions.name}
            onChange={() =>
              this.onInputChange(
                !formData.termsAndConditions.value,
                "termsAndConditions"
              )
            }
            required={formData.termsAndConditions.required}
          />
          <span className={s.RequiredLabelAsterisk}>*</span>
          <label
            className={s.Label}
            htmlFor={formData.termsAndConditions.name}
            dangerouslySetInnerHTML={{
              __html: checkboxes.termsAndContidition.placeholder,
            }}
          ></label>
        </div>
        <div className={`${s.Row} ${s.FormFooter}`}>
          <Button
            type="primary"
            onClick={this.onSubmit}
            btnClassName={s.SubmitButton}
            disabled={this.state.submitDisabled}
          >
            {submit.placeholder}
          </Button>
        </div>
      </form>
    )
  }

  render() {
    const {
      modalIsOpen,
      selectedModalImageName,
      fieldsAreValid,
      displaySuccess,
      youSentAlready,
      confirmMessage,
      showForm,
      showCountLimitMsg,
    } = this.state
    const {
      confirmationMessages,
      outOfStockTranslation,
      successMessage,
      sentAlreadyMessage,
      errorMessage,
    } = this.props
    // const lang = getCurrentLanguage(this.props.location).lang
    const displayConfirmationMsg = displaySuccess || youSentAlready
    return (
      <section ref={this.formFieldRef} className={s.ReactFriendForm}>
        <Modal
          onClose={this.onCloseModal}
          visible={modalIsOpen}
          modelWrapperClassName={s.PostcardModal}
        >
          <ImgFluid fileName={selectedModalImageName} />
        </Modal>
        {confirmMessage && (
          <h6 className={`${s.FormMessage} ${s.GoodieBoxSent}`}>
            {confirmationMessages}
            {/* {confirmationMessages[lang]} */}
          </h6>
        )}
        {showForm && !showCountLimitMsg && this.renderForm()}
        {showCountLimitMsg && !confirmMessage && (
          <h6 className={`${s.FormMessage} ${s.FormError} ${s.OutOfStock}`}>
            {outOfStockTranslation}
            {/* {outOfStockTranslation[lang]} */}
          </h6>
        )}
        {displaySuccess && !showForm && (
          <h6 className={`${s.FormMessage} ${s.GoodieBoxSent}`}>
            {successMessage}
            {/* {successMessage[lang]} */}
          </h6>
        )}
        {youSentAlready && (
          <h6 className={`${s.FormMessage} ${s.FormError}`}>
            {sentAlreadyMessage}
            {/* {sentAlreadyMessage[lang]} */}
          </h6>
        )}
        {!fieldsAreValid && !displayConfirmationMsg && (
          <h6 className={`${s.FormMessage} ${s.FormError}`}>
            {errorMessage}
            {/* {errorMessage[lang]} */}
          </h6>
        )}
      </section>
    )
  }
}

export default ReachFriendForm
