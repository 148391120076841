import React from "react"
import s from "./NotWin.module.scss"
import { getCurrentLanguage } from "../../../../helper"

const NotWin = (props) => {
  const { lang } = getCurrentLanguage(props.location)

  const fr = {
    not_win: {
      title: `Dommage, tu n'as malheureusement pas gagné.`,
      text: `Procure-toi ton rafraîchissement dans les magasins dès maintenant ou visite-nous sur nos réseaux sociaux :`,
    },
    already: {
      title: "Nice Try.",
      text: `Cet e-mail a déjà été utilisé. Procure-toi ton rafraîchissement dans les magasins dès maintenant ou visite-nous sur nos réseaux sociaux :`,
    },
  }

  const translations = {
    de: {
      not_win: {
        title: "Schade, du hast leider nicht gewonnen.",
        text: `Hol dir jetzt deine Erfrischung auch im Handel oder besuche uns auf Social Media:`,
      },
      already: {
        title: "Nice Try.",
        text: `Diese E-Mail wurde bereits verwendet. Hol dir jetzt deine Erfrischung auch im Handel oder besuche uns auf Social Media:`,
      },
    },
    fr: fr,
    fr_fr: fr,
    lu_fr: fr,
  }

  return (
    <section className={s.section}>
      <div className={s.failed_title}>
        <h2>
          {translations[lang][props.already ? "already" : "not_win"].title}
        </h2>
      </div>

      <div className={s.failed_description}>
        <span>
          {translations[lang][props.already ? "already" : "not_win"].text}
        </span>
        <br />
        <span>
          <a
            href="https://www.facebook.com/Rivella/"
            className={s.description_link}
          >
            Facebook
          </a>
          {"  "}
          <a
            href="https://www.instagram.com/rivella_ch/"
            className={s.description_link}
          >
            Instagram
          </a>
        </span>
      </div>
    </section>
  )
}

export default NotWin
