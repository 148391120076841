import React from "react"
import { AboutUsIcon } from "../../components"
import s from "./AboutUsIconSection.module.scss"
import { extractImageNameFromUrl } from "../../helper"

class AboutUsIconSection extends React.Component {
  render() {
    const {
      title_one_text,
      title_one_color,
      title_two_text,
      title_two_color,
      icons,
    } = this.props

    return (
      <section
        className={s.icon_group_wrapper}
        style={{ zIndex: this.props.sectionZindex }}
        ref={this.props.sectionRef}
      >
        <h3
          className={[s.section_title, title_one_color].join(" ")}
          dangerouslySetInnerHTML={{ __html: title_one_text }}
        ></h3>
        <h2
          className={[s.section_subtitle, title_two_color].join(" ")}
          dangerouslySetInnerHTML={{ __html: title_two_text }}
        ></h2>
        <div className={s.icon_group}>
          {icons.map(({ icon, title, description }, index) => {
            return (
              <AboutUsIcon
                key={index}
                iconFile={extractImageNameFromUrl(icon)}
                iconTitle={title}
                iconWrapperClass={s.icon_wrapper}
              >
                <p
                  className={s.icon_description}
                  dangerouslySetInnerHTML={{ __html: description }}
                ></p>
              </AboutUsIcon>
            )
          })}
        </div>
      </section>
    )
  }
}

export default AboutUsIconSection
