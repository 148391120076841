import React, { Component } from "react"
import s from "./SkewedSectionWithBoxShadow.module.scss"
import { SkewedWrapper } from "../../../components"

class SkewedSectionWithBoxShadow extends Component {
  render() {
    const { top, bottom, children, className = "" } = this.props
    return (
      <section className={[s.skewed_wrapper, className].join(" ")}>
        {top && <SkewedWrapper wrapperClassName={s.top} />}
        {children}
        {bottom && <SkewedWrapper wrapperClassName={s.bottom} />}
      </section>
    )
  }
}

export default SkewedSectionWithBoxShadow
