import React, { Component } from "react"
import Helmet from "react-helmet"
import { Link } from "gatsby"

import { PromotionSectionWrapper, SkewedSectionWithBoxShadow } from "../"
import s from "./RivellaUnlimitedSection.module.scss"
import {
  // ImageFrame,
  ImgFluid,
} from "../../../components"
import { extractImageNameFromUrl, getCurrentLanguage } from "../../../helper"
// import { winAndHike_routes } from "../../../constants"
import { ChildrenButtonRenderer } from "../../"

// const DescriptionConstructor = (...elements) => {
//   return elements.map((e) => (Array.isArray(e) ? DescriptionConstructor(e) : e))
// }

class RivellaUnlimitedSection extends Component {
  render() {
    const {
      itemProp,
      description_one_text,
      title_one_text,
      title_two_text,
      description_two_text,
      title_three_text,
      img,
      description_three_text,
      location,
    } = this.props
    const {
      slug,
      //, langHref
    } = getCurrentLanguage(location)

    // eslint-disable-next-line no-useless-escape
    const linkRegex = new RegExp(`<a.*href="https?:\/\/(.*)".*>(.*)<\/a>`)
    let link_in_one_exists = true
    let desc_one_tmp = description_one_text
    let desk_one_data = []

    let link_in_two_exists = true
    let desc_two_tmp = description_two_text
    let desk_two_data = []

    let link_in_three_exists = true
    let desc_three_tmp = description_three_text
    let desk_three_data = []

    while (link_in_one_exists) {
      const res = linkRegex.exec(desc_one_tmp)
      if (res) {
        const sides = desc_one_tmp.split(res[0])
        const left = sides.length ? sides[0] : ""
        const link = (
          <Link
            itemProp="url"
            to={`/${slug}${res[1].startsWith("/") ? res[1] : "/" + res[1]}`}
            state={{ toMap: true }}
          >
            {res[2]}
          </Link>
        )
        desc_one_tmp = sides.map((v, i) => (i === 0 ? "" : v)).join("")
        desk_one_data.push(left)
        desk_one_data.push(link)
      } else {
        desk_one_data.push(desc_one_tmp)
        link_in_one_exists = false
      }
    }
    while (link_in_two_exists) {
      const res = linkRegex.exec(desc_two_tmp)
      if (res) {
        const sides = desc_two_tmp.split(res[0])
        const left = sides.length ? sides[0] : ""
        const link = (
          <Link
            itemProp="url"
            to={`/${slug}${res[1].startsWith("/") ? res[1] : "/" + res[1]}`}
            state={{ toMap: true }}
          >
            {res[2]}
          </Link>
        )
        desc_two_tmp = sides.map((v, i) => (i === 0 ? "" : v)).join("")
        desk_two_data.push(left)
        desk_two_data.push(link)
      } else {
        desk_two_data.push(desc_two_tmp)
        link_in_two_exists = false
      }
    }
    while (link_in_three_exists) {
      const res = linkRegex.exec(desc_three_tmp)
      if (res) {
        const sides = desc_three_tmp.split(res[0])
        const left = sides.length ? sides[0] : ""
        const link = (
          <Link
            itemProp="url"
            to={`/${slug}${res[1].startsWith("/") ? res[1] : "/" + res[1]}`}
            state={{ toMap: true }}
          >
            {res[2]}
          </Link>
        )
        desc_three_tmp = sides.map((v, i) => (i === 0 ? "" : v)).join("")
        desk_three_data.push(left)
        desk_three_data.push(link)
      } else {
        desk_three_data.push(desc_three_tmp)
        link_in_three_exists = false
      }
    }

    return (
      <SkewedSectionWithBoxShadow className={s.section_container}>
        <PromotionSectionWrapper
          itemProp={itemProp}
          itemScope
          itemType="https://schema.org/Thing"
          className={s.rivella_unlimited_section}
        >
          <Helmet>
            <meta
              property="og:description"
              content={title_one_text + " - " + description_one_text}
            />
          </Helmet>
          <div className={s.description_image_wrapper}>
            <div className={s.title_description_block}>
              <h1
                itemProp="alternateName"
                className={s.title}
                dangerouslySetInnerHTML={{ __html: title_one_text }}
              ></h1>
              <p itemProp="description" className={s.description}>
                {desk_one_data.map((d) =>
                  typeof d === "string" ? (
                    <span dangerouslySetInnerHTML={{ __html: d }} />
                  ) : (
                    d
                  )
                )}
                {/* {description_one_text} */}
              </p>
              <div className={s.subtext_wrapper}>
                {title_two_text && (
                  <h2
                    className={s.subtext_title}
                    dangerouslySetInnerHTML={{ __html: title_two_text }}
                  ></h2>
                )}
                {description_two_text && (
                  <p className={s.subtext_description}>
                    {desk_two_data.map((d) =>
                      typeof d === "string" ? (
                        <span dangerouslySetInnerHTML={{ __html: d }} />
                      ) : (
                        d
                      )
                    )}
                  </p>
                )}
                {title_three_text && (
                  <h2
                    className={s.subtext_title}
                    dangerouslySetInnerHTML={{ __html: title_three_text }}
                  ></h2>
                )}
                {description_three_text && (
                  <p className={s.subtext_description}>
                    {desk_three_data.map((d) =>
                      typeof d === "string" ? (
                        <span dangerouslySetInnerHTML={{ __html: d }} />
                      ) : (
                        d
                      )
                    )}
                  </p>
                )}
                <ChildrenButtonRenderer buttonData={this.props.innerBlocks} />
              </div>
            </div>
            <div className={s.rivella_unlimited_bottle}>
              <ImgFluid
                fileName={extractImageNameFromUrl(img)}
                itemProp="image"
              />
            </div>
          </div>
        </PromotionSectionWrapper>
      </SkewedSectionWithBoxShadow>
    )
  }
}

export default RivellaUnlimitedSection
