import React from "react"

class Spacer extends React.Component {
  constructor(props) {
    super(props)
    const { height } = this.props
    if (height && height.length === 3) {
      // height: [desktopWidth, tabletWidth, phoneWidth]
      this.state = {
        currentBreakPoint: 0,
        sizes: height,
      }
    }
  }
  componentDidMount() {
    this.shouldScreensizeUpdate()
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.shouldScreensizeUpdate)
    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.shouldScreensizeUpdate)
    }
  }

  shouldScreensizeUpdate = () => {
    const nextBreakPoint = this.calcBreakPoint()
    const { currentBreakPoint } = this.state
    if (currentBreakPoint !== nextBreakPoint) {
      return this.setState({
        ...this.state,
        currentBreakPoint: nextBreakPoint,
      })
    }
  }

  calcBreakPoint = () => {
    if (typeof window !== "undefined") {
      const { innerWidth } = window
      // 0 = desktop, 1 = tablet, 2 = mobile
      if (innerWidth > 1300) return 0
      else if (innerWidth > 800) return 1
      else return 2
    } else {
      return 0
    }
  }

  render() {
    const { currentBreakPoint, sizes } = this.state
    return sizes ? (
      <div style={{ height: sizes[currentBreakPoint] }}></div>
    ) : (
      <div></div>
    )
  }
}

export default Spacer
