import React from "react"
import s from "./CodeEntrySection.module.scss"
import PromotionSectionWrapper from "../PromotionSectionWrapper/PromotionSectionWrapper"
import { HikeAndWinButton } from "../../../components"
import AngleDownIcon from "../../../assets/svgs/angle_down_icon.svg"

class CodeEntrySection extends React.Component {
  state = {}

  handleInputChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  handleSubmit = (evt) => {
    evt.preventDefault()
    if (this.props.onFormSubmit) {
      this.props.onFormSubmit(evt)
    }
  }

  render() {
    const {
      isMobileDevice,
      button_text,
      onInputChange,
      value,
      form_type = "text",
      errorMessage,
      formMessage,
      className = "",
      formError = false,
      placeholder_text,
      itemType,
      showArrow = true,
    } = this.props

    const errorMsgClass = formError ? s.error_msg : ""

    return (
      <PromotionSectionWrapper className={s.form_wrapper}>
        {/* TODO: Remove conditions for form handling and submission */}
        <form
          className={`${s.code_entry_form} ${className}`}
          onSubmit={this.handleSubmit}
        >
          <section className={s.form_row} itemScope={true} itemType={itemType}>
            <input
              type={form_type}
              name="code"
              className={s.code_entry_box}
              onChange={onInputChange}
              placeholder={placeholder_text}
              required
              value={value}
            />
            {errorMessage ? (
              <span className={s.ErrorMessage}>{errorMessage}</span>
            ) : null}
          </section>
          <section className={s.form_row}>
            <HikeAndWinButton className={s.code_entry_button} type="submit">
              {button_text}
            </HikeAndWinButton>
          </section>
          {isMobileDevice && showArrow ? <AngleDownIcon /> : null}
        </form>
        {formMessage && (
          <h4 className={`${s.form_message} ${errorMsgClass}`}>
            {formMessage}
          </h4>
        )}
      </PromotionSectionWrapper>
    )
  }
}

export default CodeEntrySection
