/**
 * This file is called under Layout.js and configured according to current language there.
 * For pages which do not have languageOptions prop under Layout, you need to explicitly set the locale
 * otherwise it'll default to German
 */

const moment = require("moment")
const LANGUAGE_ABBREVATIONS = require("./constants").LANGUAGE_ABBREVATIONS

moment.relativeTimeThreshold("ss", null)
moment.relativeTimeThreshold("s", null)
moment.relativeTimeThreshold("m", null)
moment.relativeTimeThreshold("h", null)

// GERMAN LANGUAGE DEFINITIONS
moment.defineLocale(LANGUAGE_ABBREVATIONS.german.lang, {
  months: [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ],
  monthsShort: [
    "Jan",
    "Feb",
    "März",
    "Apr",
    "Mai",
    "Juni",
    "Juli",
    "Aug",
    "Sept",
    "Okt",
    "Nov",
    "Dez",
  ],
  weekdays: [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
  ],
  weekdaysMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
  relativeTime: {
    future: "in %s",
    past: "Vor %s",
    d: "ein Tag",
    dd: "%d Tagen",
    w: "eine Woche",
    ww: "%d Wochen",
    M: "einem Monat",
    MM: "%d Monaten",
    y: "ein Jahr",
    yy: "%d Jahre",
  },
})

// FRENCH LANGUAGE DEFINITIONS
moment.defineLocale(LANGUAGE_ABBREVATIONS.french.lang, {
  months: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Aout",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  monthsShort: [
    "Janv",
    "Févr",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juil",
    "Août",
    "Sept",
    "Oct",
    "Nov",
    "Déc",
  ],
  weekdays: [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ],
  weekdaysMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
  relativeTime: {
    future: "dans %s",
    past: "il y a %s",
    d: "un jour",
    dd: "%d jours",
    w: "une semaine",
    ww: "%d semaines",
    M: "un mois",
    MM: "%d mois",
    y: "une année",
    yy: "%d ans",
  },
})

// ITALIAN LANGUAGE DEFINITIONS
moment.defineLocale(LANGUAGE_ABBREVATIONS.italian.lang, {
  months: [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ],
  monthsShort: [
    "Genn",
    "Febbr",
    "Mar",
    "Apr",
    "Magg",
    "Giugno",
    "Luglio",
    "Ag",
    "Sett",
    "Ott",
    "Nov",
    "Dic",
  ],
  weekdays: [
    "Domenica",
    "Lunedì",
    "Martedì",
    "Mercoledì",
    "Giovedì",
    "Venerdì",
    "Sabato",
  ],
  weekdaysMin: ["Sab", "Do", "Lun", "Mar", "Mer", "Gio", "Ven"],
  relativeTime: {
    future: "tra %s",
    past: "%s fa",
    d: "un giorno",
    dd: "%d giorni",
    w: "una settimana",
    ww: "%d settimane",
    M: "un mese",
    MM: "%d mesi",
    y: "un anno",
    yy: "%d anni",
  },
})
