/* eslint-disable no-useless-escape */
import React, { Fragment } from "react"
import SenderReceiver from "./components/SenderReceiver/SenderReceiver"
import { getCurrentLanguage } from "../../../../helper"

import { submitRefreshV4WinnerForm } from "../../api"

import s from "./Form.module.scss"

const Form = (props) => {
  const [type, setType] = React.useState("self") // or "friend"
  const [submitted, setSubmitted] = React.useState(false)
  const [validation, setValidation] = React.useState({
    from_salutation: undefined,
    from_last_name: undefined,
    from_first_name: undefined,
    from_email: undefined,
    from_phone: undefined,
    street: undefined,
    number: undefined,
    zip: undefined,
    city: undefined,
    //
    newsletter: undefined,
    accepted: undefined,
  })
  const [body, setBody] = React.useState({
    from_salutation: "",
    from_last_name: "",
    from_first_name: "",
    from_email: props.email,
    from_phone: "",
    street: "",
    number: "",
    zip: "",
    city: "",
    //
    newsletter: "1",
    accepted: "",
    winning: props.winning,
  })
  const [formError, setFormError] = React.useState(undefined)

  const isFormValid = (vd) => {
    return Object.values(vd ? vd : validation).every((val) => val === true)
  }

  const isFieldInvalid = (field) => {
    return (
      submitted &&
      typeof validation[field] === "boolean" &&
      (validation[field] === false || validation[field] === "0")
    )
  }

  const validateForm = () => {
    const mailRe =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const numRe = /^\d+$/

    const nextValidation = { ...validation }

    // from
    nextValidation.from_salutation = body.from_salutation.length > 1
    nextValidation.from_last_name = body.from_last_name.length > 1
    nextValidation.from_first_name = body.from_first_name.length > 1
    nextValidation.from_email = mailRe.test(body.from_email)
    nextValidation.from_phone = body.from_phone.length > 5

    // addr
    nextValidation.street = body.street.length > 5
    nextValidation.number = numRe.test(body.number)
    nextValidation.zip = numRe.test(body.zip) && `${body.zip}`.length === 4
    nextValidation.city = body.city.length > 2

    // checkboxes
    nextValidation.newsletter = true
    nextValidation.accepted = body.accepted === "1"

    setValidation(nextValidation)
    return nextValidation
  }

  React.useEffect(() => {
    validateForm()
  }, [body])

  const submitForm = async () => {
    const current_validation = validateForm()

    setSubmitted(true)

    if (!isFormValid(current_validation)) {
      return
    }

    const { lang } = getCurrentLanguage(props.location)

    try {
      const response = await submitRefreshV4WinnerForm({
        ...body,
        type,
        language: lang,
      })
      if (response.result === "success") {
        const senderAddress = {
          salutation: body.from_salutation,
          last_name: body.from_last_name,
          first_name: body.from_first_name,
          email: body.from_email,
          phone: body.from_phone,
          street: type === "self" ? body.street : "",
          number: type === "self" ? body.number : "",
          zip: type === "self" ? body.zip : "",
          city: type === "self" ? body.city : "",
          newsletter: body.newsletter,
        }
        props.showSuccess(senderAddress)
      } else {
        setFormError(response.message)
      }
    } catch (err) {
      setFormError(err ? err.message : "Error")
    }
  }

  return (
    <Fragment>
      <section className={s.section}>
        <div className={s.congratulations_title}>
          { props.winning && <img
            className={s.winningImage}
            src={`/rivella-prize-2022-${props.winning}.jpg`}
            />
          }
          <h2>{props.congratulationsTitle}</h2>
        </div>

        <div className={s.fill_address_prompt}>
          <p>{props.fillAddressPrompt}</p>
        </div>
      </section>

      <section className={s.form_section}>
        <form>
          <div className={s.form_grid}>
            <SenderReceiver
              {...props}
              type="self"
              body={body}
              setBody={setBody}
              isFieldInvalid={isFieldInvalid}
            />


            {/* Checkboxes and submit */}
            <div className={s.form_check_box}>
              <div className={isFieldInvalid("newsletter") ? s.field_error : ""}>
                <label>
                  <input
                    type="checkbox"
                    className={s.newsletter_check_box}
                    checked={body.newsletter === "1" ? true : false}
                    onChange={({ target: { checked } }) =>
                      setBody((p) => ({ ...p, newsletter: checked ? "1" : "0" }))
                    }
                  />
                  {props.formFieldsInfo.checkboxes.newsletter.placeholder}
                </label>
              </div>

              <div className={isFieldInvalid("accepted") ? s.field_error : ""}>
                <label>
                  <input
                    type="checkbox"
                    className={s.terms_check_box}
                    checked={body.accepted === "1" ? true : false}
                    onChange={({ target: { checked } }) => {
                      setBody((p) => ({
                        ...p,
                        accepted: checked ? "1" : "0",
                      }))
                    }}
                  />
                  {props.formFieldsInfo.checkboxes.termsAndContidition.placeholder(
                    props.terms_link
                  )}
                </label>
              </div>
            </div>

            <div className={s.form_error_wrapper}>{formError}</div>

            <div className={s.submit_button_div}>
              <button
                className={s.submit_button}
                type="button"
                onClick={submitForm}
              >
                {props.formFieldsInfo.submit.placeholder}
              </button>
            </div>
          </div>
        </form>
      </section>
    </Fragment>
  )
}

export default Form
