import { HIKING_API_URL } from "../../constants"
import { ssrFriendlyFetch } from "../../helper"

export const getParameterByName = (name, url = window.location.href) => {
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, "\\$&")
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ""
  return decodeURIComponent(results[2].replace(/\+/g, " "))
}

export const submitRefreshV3Email = async (email, language, isMigros) => {
  return ssrFriendlyFetch(
    // https://rivella.madebycolorelephant.com/wp-json/rivella-refresh
    // `https://${WP_API_URL}/wp-json/rivella-refresh/v2/submit_new_entry`,
    `${
      HIKING_API_URL.startsWith("http")
        ? HIKING_API_URL
        : `https://${HIKING_API_URL}`
    }/wp-json/rivella-refresh/v3/participate?is_migros=${
      isMigros ? 1 : 0
    }&email=${email}&language=${language}`,
    {
      //   body: {},
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json()
    })
    .catch((error) => {
      console.warn(error)
      return { result: "error", message: "internal" }
    })
}

export const submitRefreshV3Form = async (body) => {
  return ssrFriendlyFetch(
    // https://rivella.madebycolorelephant.com/wp-json/rivella-refresh
    // `https://${WP_API_URL}/wp-json/rivella-refresh/v2/submit_new_entry`,
    `${
      HIKING_API_URL.startsWith("http")
        ? HIKING_API_URL
        : `https://${HIKING_API_URL}`
    }/wp-json/rivella-refresh/v3/submit_new_entry?type=${
      body.type
    }&from_title=${body.from_title}&from_name=${
      body.from_first_name
    }&from_surname=${body.from_last_name}&from_salutation=${
      body.from_salutation
    }&from_email=${body.from_email}&to_title=${body.to_title}&to_salutation=${
      body.to_salutation
    }&to_name=${body.to_first_name}&to_surname=${body.to_last_name}&street=${
      body.street
    }&number=${body.number}&zip=${body.zip}&city=${body.city}&newsletter=${
      body.newsletter
    }&accepted=${body.accepted}&language=${body.language}&user_agent`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json()
    })
    .catch(() => {
      return { result: "error", message: "internal" }
    })
}

export const confirmRefreshV3Entry = async (hash) => {
  return ssrFriendlyFetch(
    // https://rivella.madebycolorelephant.com/wp-json/rivella-refresh
    // `https://${WP_API_URL}/wp-json/rivella-refresh/v2/submit_new_entry`,
    `${
      HIKING_API_URL.startsWith("http")
        ? HIKING_API_URL
        : `https://${HIKING_API_URL}`
    }/wp-json/rivella-refresh/v3/confirm?hash=${hash}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json()
    })
    .catch(() => {
      return { result: "error", message: "internal" }
    })
}
