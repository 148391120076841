import moment from "moment"
import { getMetaDataOfEvent, formatEventDateToText } from "../helper"
import { langSpecificEventPrefix } from "../constants"

export const reshapeEventsData = (rawEvents, filterType, langSlug, lang) => {
  let allCategories = []
  const eventData = rawEvents
    .sort((a, b) => {
      const a_custom_meta = a.custom_meta
      let a_metaData = {}
      a_custom_meta.forEach((m) => {
        Object.keys(m).forEach((k) => {
          a_metaData[k] = m[k]
        })
      })
      const b_custom_meta = b.custom_meta
      let b_metaData = {}
      b_custom_meta.forEach((m) => {
        Object.keys(m).forEach((k) => {
          b_metaData[k] = m[k]
        })
      })

      if (
        Date.parse(a_metaData.target_date_key) <
        Date.parse(b_metaData.target_date_key)
      )
        return 1
      else if (
        Date.parse(a_metaData.target_date_key) >
        Date.parse(b_metaData.target_date_key)
      )
        return -1
      return 0
    })
    .map((e) => {
      try {
        let objToCast = {}
        let metaData = getMetaDataOfEvent(e)

        // filter only given event type if provided else ignore
        // allowed options: ["", "event", "contest", "news"], treats "" as event
        const currentType = metaData.type_of_page_key
          ? metaData.type_of_page_key
          : "event"
        if (filterType && currentType !== filterType) {
          return null
        }

        // expected output 2 length array where [X, Y]
        const coords = metaData.location_cordinate_key.split(",")

        const hero_block = e.blocksJson.find(
          (b) => b.blockName === "rivella/single-event-hero"
        )
        const content_block = e.blocksJson.find(
          (b) => b.blockName === "rivella/single-event-details"
        )
        objToCast.metaData = metaData
        objToCast.currentType = currentType
        objToCast.categories = e.categories
        if (e.categories)
          for (let c of e.categories) {
            if (!allCategories.some((ac) => ac.id === c.id)) {
              allCategories.push(c)
            }
          }
        if (hero_block) {
          objToCast.eventImg =
            metaData.teaser_image_key || hero_block.attrs.background_img
        }
        if (content_block) {
          objToCast.description = content_block.attrs.description_one_text
          objToCast.rawDate = content_block.attrs.date
          objToCast.rawAlternateDate = content_block.attrs.alternate_date
          objToCast.eventDate = content_block.attrs.date
            ? moment(content_block.attrs.date).format("dd, DD.MMM")
            : "Date not specified"
          objToCast.eventAlternateDate = content_block.attrs.alternate_date
            ? moment(content_block.attrs.alternate_date).format("dd, DD.MMM")
            : null
          const dateIsToBeDecided = content_block.attrs.date_is_to_be_decided
          objToCast.eventDateText = formatEventDateToText({
            lang,
            dateIsToBeDecided,
            startDate: content_block.attrs.date,
            endDate: content_block.attrs.end_date,
            alternateStartDate: content_block.attrs.alternate_date,
            alternateEndDate: content_block.attrs.alternate_end_date,
            moment,
          })
          objToCast.eventLocation = content_block.attrs.location
        }
        objToCast.descriptionPreview = metaData.overview_page_summary_key
        objToCast.id = e.id
        objToCast.slug = `/${langSlug}/${langSpecificEventPrefix[langSlug][currentType]}/${e.slug}`
        objToCast.eventTitle = (e.title && e.title.rendered) || "No title"
        objToCast.detailPageHideOption = metaData.detail_page_hidden_key
        objToCast.coords = {
          lat: coords.length === 2 ? coords[0] : null,
          lng: coords.length === 2 ? coords[1] : null,
        }

        return objToCast
      } catch (e) {
        console.warn(e)
        return null
      }
    })
  const filteredEvents = eventData.filter(
    (e) => e && e.detailPageHideOption !== "hide_listing"
  )
  return { eventData: filteredEvents, allCategories }
}
