import React from "react"
import Helmet from "react-helmet"

import { SkewedSectionWithBg, SkewedCard } from "../../components"
import { ChildrenButtonRenderer } from "../"
import s from "./AboutUsSectionBg.module.scss"
import { getPosition } from "../../helper"

class AboutUsSectionBg extends React.Component {
  cardTitleRef = React.createRef()
  state = {
    slidIn: false,
    distFromTopToSlideIn: 9999999,
  }

  componentDidMount() {
    this.calculateEleDistFromTop()
    if (typeof document !== "undefined") {
      document.addEventListener("scroll", this.shouldSlideIn)
    }
  }
  componentWillUnmount() {
    if (typeof document !== "undefined") {
      document.removeEventListener("scroll", this.shouldSlideIn)
    }
  }

  shouldSlideIn = () => {
    const { slidIn, distFromTopToSlideIn } = this.state
    if (!slidIn && distFromTopToSlideIn < window.scrollY) {
      this.setState({
        slidIn: true,
      })
    }
  }

  calculateEleDistFromTop = () => {
    const { y } = getPosition(this.cardTitleRef.current)
    const wrapper = this.props.sectionRef && this.props.sectionRef.current

    const calced = y - wrapper.clientHeight / 2 - window.innerHeight * 0.5
    const distFromTopToSlideIn = calced > 0 ? calced : 0

    this.setState({
      distFromTopToSlideIn,
    })
  }
  render() {
    const {
      background_img,
      title_one_text,
      title_two_text,
      title_one_color,
      title_two_color,
      description_one_text,
      description_one_color,
    } = this.props
    const cardClasses = this.state.slidIn
      ? [s.section_bg_card, s.section_bg_card_sliding_in]
      : [s.section_bg_card]
    const isMigrosPromoPage =
      typeof window !== "undefined" &&
      window.location &&
      `${window.location.href}`.includes("spar")
    return (
      <section
        className={`${s.section_wrapper} ${
          isMigrosPromoPage ? s.migros_wrapper : ""
        }`}
        style={{
          zIndex: this.props.sectionZindex,
        }}
        ref={this.props.sectionRef}
      >
        <Helmet>
          <meta property="og:description" content={description_one_text} />
        </Helmet>
        <SkewedSectionWithBg
          bgImg={background_img}
          wrapperClassName={s.section_bg}
          imageWrapperClass={s.image_wrapper}
          type="parallax"
        >
          <SkewedCard cardOuterCn={cardClasses.join(" ")}>
            <h3
              className={[s.section_bg_card_subtitle, title_two_color].join(
                " "
              )}
              dangerouslySetInnerHTML={{ __html: title_two_text }}
            ></h3>
            <h4
              ref={this.cardTitleRef}
              className={[s.section_bg_card_title, title_one_color].join(" ")}
              dangerouslySetInnerHTML={{ __html: title_one_text }}
            ></h4>
            <p
              className={[
                s.section_bg_card_description,
                description_one_color,
              ].join(" ")}
              dangerouslySetInnerHTML={{ __html: description_one_text }}
            ></p>
            <ChildrenButtonRenderer buttonData={this.props.innerBlocks} />
          </SkewedCard>
        </SkewedSectionWithBg>
      </section>
    )
  }
}

export default AboutUsSectionBg
