import React from "react"
import { Link } from "gatsby"
import { Button, SkewedWrapper } from "../../../components"
import s from "./GeneralPromotionHero.module.scss"

const RectangleBanner = (props) => {

  const {
    currentLang,
    button_link,
    button_one_text
  } = props;

  const button = (<Button btnClassName={s.Button} type={"default"}>
    {/* backend registered banner_text - button_one_text is frontend static */}
    {button_one_text}
  </Button>);

  return (
    <SkewedWrapper
      wrapperClassName={`${s.SkewedWrapperParent} ${
        s[currentLang.lang]
      }`}
    >
      { button_link && (
        <Link
          to={button_link}
          className={s.LinkBanner}
        >
          { button }
        </Link>
      )}

      { !button_link && button }

    </SkewedWrapper>
  );
}


export default RectangleBanner
