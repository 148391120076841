import React from "react"
import s from "./AboutUsIcon.module.scss"
import { ImgFluid } from "../../components/"
import SkewedButton from "../SkewedButton/SkewedButton"

class AboutUsIcon extends React.Component {
  render() {
    const { children, iconTitle, iconFile, iconWrapperClass = "" } = this.props
    return (
      <div className={`${s.icon_wrapper} ${iconWrapperClass}`}>
        <div className={s.icon}>
          <div className={s.IconWrapper}>
            <div className={s.icon_bg}>
              <ImgFluid fileName={iconFile} />
            </div>
          </div>
          <p
            className={s.icon_title}
            dangerouslySetInnerHTML={{ __html: iconTitle }}
          ></p>
          <SkewedButton type="default" btnClassName={s.link_wrapper}>
            Mehr Erfahren
          </SkewedButton>
        </div>
        <div className={s.hidden_details}>{children}</div>
      </div>
    )
  }
}

export default AboutUsIcon
