import React from "react"

class SlickButton extends React.Component {
  render() {
    const { CarouselBtn, ...props } = this.props

    return (
      <button {...props}>
        <CarouselBtn />
      </button>
    )
  }
}

export default SlickButton
