// class LoaderWithText extends React.Component {
//   render() {
//     return (
//       <div className={s.Loader}>
//         <div className={s.ldsCircle}>
//           <div></div>
//         </div>
//         <p className={s.Text}>{this.props.loaderText}</p>
//       </div>
//     )
//   }
// }

// export default LoaderWithText

import React, { useState, useEffect, useRef } from "react"
import s from "./LoaderWithText.module.scss"
import lottie from "lottie-web"
import { graphql, useStaticQuery } from "gatsby"

const LoaderWithText = (props) => {
  const [logoUrl, setLogoUrl] = useState(null)
  const ref = useRef()
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "loading_bottle" }) {
        publicURL
      }
    }
  `)
  useEffect(() => {
    setLogoUrl(data.file.publicURL)
  }, [])
  useEffect(() => {
    ref.current.innerHTML = ""
    lottie.loadAnimation({
      container: ref.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: logoUrl,
    })
  }, [logoUrl])

  const className = [props.className, s.Loader]

  return (
    <div {...props} className={className.join(" ")}>
      {/* <div className={s.ldsCircle}>
          <div></div>
        </div> */}
      <div ref={ref} className={s.Animation} />
      {props.loaderText ? <p className={s.Text}>{props.loaderText}</p> : null}
    </div>
  )
}

export default LoaderWithText
