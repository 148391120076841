import React from "react"
import s from "./MainQuote.module.scss"
// import DoubleArrow from "../../assets/svgs/double-arrow.svg"

class MainQuote extends React.Component {
  render() {
    const { quote, author, quote_color, author_color } = this.props
    return (
      <div className={s.MainQuote}>
        {/* <span>
          <DoubleArrow className={s.Arrow} />
        </span> */}
        <div className={s.Content}>
          <p className={[s.QuoteText, quote_color].join(" ")}>
            {quote}
            {/* <span className={s.ReverseIcon}>
              <DoubleArrow className={s.Arrow} />
            </span> */}
          </p>
          <p className={[s.QuoteAuthor, author_color].join(" ")}>{author}</p>
        </div>
      </div>
    )
  }
}

export default MainQuote
