import React from "react"
import s from "./SenderReceiver.module.scss"

const SenderReceiver = (props) => {
  return (
    <div
      className={s.sender_receiver_section}
      style={
        props.type === "self"
          ? {}
          : {
              borderLeft: "1px solid #63666A",
            }
      }
    >
      <div className={s.row_title}>
        {props.type === "self" ? (
          <h4>{props.formFieldsInfo.reciever.header.title}</h4>
        ) : (
          <h4>{props.formFieldsInfo.reciever.header_two.title}</h4>
        )}
      </div>

      {/* ********************************************** */}

      <div className={[s.flex, s.padding_20].join(" ")}>
        <div className={s.two_column}>
          <select
            className={`${s.form_select} ${
              props.isFieldInvalid(
                props.type === "self" ? "from_salutation" : "to_salutation"
              )
                ? s.field_error
                : ""
            }`}
            placeholder={props.formFieldsInfo.reciever.mr_mrs.placeholder}
            value={
              props.type === "self"
                ? props.body.from_salutation
                : props.body.to_salutation
            }
            onChange={({ target: { value } }) =>
              props.setBody((p) => ({
                ...p,
                [props.type === "self" ? "from_salutation" : "to_salutation"]:
                  value,
              }))
            }
          >
            <option selected hidden>
              {props.formFieldsInfo.reciever.mr_mrs.placeholder}
            </option>
            <option value={props.formFieldsInfo.reciever.mr_mrs.mr.value}>
              {props.formFieldsInfo.reciever.mr_mrs.mr.label}
            </option>
            <option value={props.formFieldsInfo.reciever.mr_mrs.mrs.value}>
              {props.formFieldsInfo.reciever.mr_mrs.mrs.label}
            </option>
          </select>
        </div>

        {/* <div className={[s.two_column, s.left_padding].join(" ")}> */}
        {/* <select
            className={`${s.form_select} ${
              props.isFieldInvalid(
                props.type === "self" ? "from_title" : "to_title"
              )
                ? s.field_error
                : ""
            }`}
            placeholder={props.formFieldsInfo.reciever.title.placeholder}
            value={
              props.type === "self"
                ? props.body.from_title
                : props.body.to_title
            }
            onChange={({ target: { value } }) =>
              props.setBody((p) => ({
                ...p,
                [props.type === "self" ? "from_title" : "to_title"]: value,
              }))
            }
          >
            <option selected hidden>
              {props.formFieldsInfo.reciever.title.placeholder}
            </option>
            <option value={props.formFieldsInfo.reciever.title.empty.value}>
              {props.formFieldsInfo.reciever.title.empty.label}
            </option>
            <option value={props.formFieldsInfo.reciever.title.dr.value}>
              {props.formFieldsInfo.reciever.title.dr.label}
            </option>
          </select> */}
        {/* </div> */}
      </div>

      {/* ********************************************** */}

      <div className={s.padding_20}>
        <input
          type="text"
          placeholder={props.formFieldsInfo.reciever.first_name.placeholder}
          className={`${s.form_control} ${
            props.isFieldInvalid(
              props.type === "self" ? "from_first_name" : "to_first_name"
            )
              ? s.field_error
              : ""
          }`}
          value={
            props.type === "self"
              ? props.body.from_first_name
              : props.body.to_first_name
          }
          onChange={({ target: { value } }) =>
            props.setBody((p) => ({
              ...p,
              [props.type === "self" ? "from_first_name" : "to_first_name"]:
                value,
            }))
          }
        />
      </div>

      {/* ********************************************** */}

      <div className={s.padding_20}>
        <input
          type="text"
          placeholder={props.formFieldsInfo.reciever.last_name.placeholder}
          className={`${s.form_control} ${
            props.isFieldInvalid(
              props.type === "self" ? "from_last_name" : "to_last_name"
            )
              ? s.field_error
              : ""
          }`}
          value={
            props.type === "self"
              ? props.body.from_last_name
              : props.body.to_last_name
          }
          onChange={({ target: { value } }) =>
            props.setBody((p) => ({
              ...p,
              [props.type === "self" ? "from_last_name" : "to_last_name"]:
                value,
            }))
          }
        />
      </div>

      {/* ********************************************** */}

      {props.type === "self" && (
        <div className={s.padding_20}>
          <input
            type="text"
            placeholder={props.formFieldsInfo.reciever.email.placeholder}
            className={`${s.form_control} ${
              props.isFieldInvalid("from_email") ? s.field_error : ""
            }`}
            value={props.body.from_email}
            // onChange={({ target: { value } }) =>
            //   props.setBody((p) => ({ ...p, from_email: value }))
            // }
          />
        </div>
      )}

      {/* ********************************************** */}

      <div className={[s.flex, s.padding_20].join(" ")}>
        <div className={s.two_column}>
          <input
            type="text"
            placeholder={props.formFieldsInfo.reciever.street.placeholder}
            className={`${s.form_control} ${
              props.isFieldInvalid("street") ? s.field_error : ""
            }`}
            value={props.body.street}
            onChange={({ target: { value } }) =>
              props.setBody((p) => ({ ...p, street: value }))
            }
          />
        </div>

        <div className={[s.two_column, s.left_padding].join(" ")}>
          <input
            type="text"
            placeholder={props.formFieldsInfo.reciever.house_no.placeholder}
            className={`${s.form_control} ${
              props.isFieldInvalid("number") ? s.field_error : ""
            }`}
            value={props.body.number}
            onChange={({ target: { value } }) =>
              props.setBody((p) => ({ ...p, number: value }))
            }
          />
        </div>
      </div>

      {/* ********************************************** */}

      <div className={[s.flex, s.padding_20].join(" ")}>
        <div className={s.two_column}>
          <input
            type="number"
            placeholder={props.formFieldsInfo.reciever.postal_code.placeholder}
            className={`${s.form_control} ${
              props.isFieldInvalid("zip") ? s.field_error : ""
            }`}
            value={props.body.zip}
            onChange={({ target: { value } }) => {
              if (value.length <= 4) {
                props.setBody((p) => ({ ...p, zip: value }))
              }
            }}
          />
        </div>

        <div className={[s.two_column, s.left_padding].join(" ")}>
          <input
            type="text"
            placeholder={props.formFieldsInfo.reciever.town.placeholder}
            className={`${s.form_control} ${
              props.isFieldInvalid("city") ? s.field_error : ""
            }`}
            value={props.body.city}
            onChange={({ target: { value } }) =>
              props.setBody((p) => ({ ...p, city: value }))
            }
          />
        </div>
      </div>
    </div>
  )
}

export default SenderReceiver
