import React, { Component } from "react"
import s from "./PartnerPageHero.module.scss"
import { SkewedHero } from "../../../components"
import { extractImageNameFromUrl } from "../../../helper"
import HeroMountainImageBlock from "../../../assets/svgs/mountain_block.svg"
import HeroMountainImageBlockMobile from "../../../assets/svgs/mountain_block_mobile.svg"

class PartnerPageHero extends Component {
  state = {
    isMobileDevice: false,
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize)
    this.resize()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize)
    this.resize()
  }

  resize = () => {
    // const { pageName } = this.props
    let bool = window.screen.width <= 600
    // if (
    //   pageName
    //   // && pageName === "Partnerseite"
    // ) {
    bool = window.screen.width <= 800
    // }
    this.setState({ isMobileDevice: bool })
  }

  render() {
    const {
      itemProp,
      sectionZindex,
      sectionRef,
      title_one_text,
      title_one_color,
      background_img,
      page_type = "",
      pageName,
    } = this.props

    return (
      <section
        className={s.hero_wrapper}
        style={{ zIndex: sectionZindex }}
        ref={sectionRef}
      >
        <SkewedHero
          itemProp={itemProp}
          imgFilename={extractImageNameFromUrl(background_img)}
          imgAlt="Promotion page hero background"
          cn={`${s.overview_skewed_hero} ${s[page_type]}`}
        >
          {title_one_text ? (
            <h1
              className={[s.hero_title, title_one_color].join(" ")}
              dangerouslySetInnerHTML={{ __html: title_one_text }}
            ></h1>
          ) : null}
          <div className={s.hero_content}>
            {this.state.isMobileDevice ? (
              <HeroMountainImageBlockMobile
                className={
                  pageName && pageName === "Partnerseite"
                    ? `${s.mountain_image_block} ${s.mountain_image_block_partners}`
                    : s.mountain_image_block
                }
              />
            ) : (
              <HeroMountainImageBlock className={s.mountain_image_block} />
            )}
          </div>
        </SkewedHero>
      </section>
    )
  }
}

export default PartnerPageHero
