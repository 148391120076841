import React from "react"
import s from "./WinHikeForm.module.scss"
import HikeAndWinButton from "../HikeAndWinButton/HikeAndWinButton"
import { fetchHikingData } from "../../helper"
import { HikingAuthConsumer } from "../../store"
import InputMask from "react-input-mask"

const formFields = {
  email: "",
  salutation: "",
  first_name: "",
  last_name: "",
  street: "",
  number: "",
  zip_code: "",
  city: "",
  country: "",
  birth_date: "",
  check_1: 0,
  check_2: 0,
  check_3: 0,
  last_question: "",
  user_hash: "",
}

// const countriesOption = {
//   en: ["Swiss", "Liechtenstein", "Luxembourg", "France"],
//   de: ["Schweiz", "Liechtenstein", "Luxemburg", "Frankreich"],
//   fr: ["Suisse", "Liechtenstein", "Luxembourg", "France"],
//   it: ["Svizzera", "Liechtenstein", "Lussemburgo", "Francia"],
// }

// const lastQuestionOptions = {
//   de: [
//     "Von Freunden/Bekannten",
//     "Im Handel (Code auf Flaschendeckel)",
//     "Über Social Media",
//     "Über die rivella Website",
//     "Über einen Online-Banner",
//     "Über die Google-Suche",
//     "Über den Rivella Newsletter",
//   ],
//   fr: [
//     "Par des amis/connaissances",
//     "Dans le commerce (code sur le bouchon de la bouteille)",
//     "Par les médias sociaux",
//     "Par le site web de Rivella",
//     "Par une bannière en ligne",
//     "Par une recherche Google",
//     "Par la lettre d'information Rivella",
//   ],
//   it: [
//     "Da amici/conoscenti",
//     "In commercio (codice sul tappo della bottiglia)",
//     "Attraverso i social media",
//     "Attraverso il sito web di Rivella",
//     "Tramite un banner online",
//     "Tramite una ricerca su Google",
//     "Dalla newsletter Rivella",
//   ],
// }

// const messageTranslations = {
//   en: {
//     reverted_win_msg: `Sorry, it seems you already have won a prize (based on the
//     postal address). We are, as per the Terms and Conditions,
//     unable to offer another one.`,
//     code_recorded_msg: `We have recorded your code and your participation.`,
//     thank_you_msg: `Thanks for registering`,
//     salutation: "Anrede*",
//     first_name: "Vorname*",
//     last_name: "Name*",
//     email: "E-Mail*",
//     street: "Strasse*",
//     number: "Nr*",
//     zip_code: "PLZ*",
//     city: "Ort*",
//     country: "Land*",
//     birth_date: "Geburtsdatum*",
//     age: "Ich bestätige, dass ich 12 Jahre oder älter bin. *",
//     terms: (
//       <>
//         Ich habe von den{" "}
//         <a href="/de/hiking/teilnahmebedingungen" target="_blank">
//           Teilnahmebedingungen
//         </a>{" "}
//         und der{" "}
//         <a
//           href="https://www.rivella-group.com/de/extras/impressum"
//           target="_blank"
//         >
//           Datenschutzinformation
//         </a>{" "}
//         Kenntnis genommen und akzeptiere diese. *
//       </>
//     ),
//     newsletter: "Gerne möchte ich den Newsletter erhalten.",
//     survey: "Wie wurdest du auf die Wanderpromotion aufmerksam?",
//     last_question: "Bitte wählen",
//     registration: "Registrieren",
//   },
//   de: {
//     reverted_win_msg: `Jemand in deinem Haushalt hat bereits einen Sofortpreis gewonnen.
//     Gemäss unseren Teilnahmebedingungen, können wir dir daher keinen weiteren Sofortpreis anbieten.
//     Für mehr Informationen, kannst du dich an den Rivella Kundendienst wenden.`,
//     code_recorded_msg: `Wir haben deinen Code und deine Teilnahme aufgezeichnet.`,
//     // thank_you_msg field missing
//     thank_you_msg: `Vielen Dank für deine Registrierung! Wir haben deinen Code und deine Teilnahme aufgezeichnet. Sammle weitere Codes, um deine Chance auf einen der attraktiven Hauptpreise zu steigern. Viel Glück! Dein Rivella Team`,
//     salutation: "Anrede*",
//     first_name: "Vorname*",
//     last_name: "Name*",
//     email: "E-Mail*",
//     street: "Strasse*",
//     number: "Nr*",
//     zip_code: "PLZ*",
//     city: "Ort*",
//     country: "Land*",
//     birth_date: "Geburtsdatum*",
//     age: "Ich bestätige, dass ich 12 Jahre oder älter bin. *",
//     terms: (
//       <>
//         Ich habe von den{" "}
//         <a href="/de/hiking/teilnahmebedingungen" target="_blank">
//           Teilnahmebedingungen
//         </a>{" "}
//         und der{" "}
//         <a
//           href="https://www.rivella-group.com/de/extras/impressum"
//           target="_blank"
//         >
//           Datenschutzinformation
//         </a>{" "}
//         Kenntnis genommen und akzeptiere diese. *
//       </>
//     ),
//     newsletter: "Gerne möchte ich den Newsletter erhalten.",
//     survey: "Wie wurdest du auf die Wanderpromotion aufmerksam?",
//     last_question: "Bitte wählen",
//     registration: "Registrieren",
//   },
//   it: {
//     reverted_win_msg: `Qualcuno nel tuo nucleo familiare ha già vinto un premio immediato. In base alle nostre
//     condizioni di partecipazione, non possiamo quindi offrirti un altro premio istantaneo. Per maggiori
//     informazioni puoi rivolgerti al servizio clienti di Rivella.`,
//     code_recorded_msg: `Abbiamo registrato il tuo codice e la tua partecipazione.`,
//     thank_you_msg: `Grazie per esserti registrato.`,
//     salutation: "Titolo*",
//     first_name: "Nome*",
//     last_name: "Cognome*",
//     email: "E-Mail*",
//     street: "Via*",
//     number: "N.*",
//     zip_code: "NPA*",
//     city: "Località*",
//     country: "Paese*",
//     birth_date: "Data di nascita*",
//     age: "Certifico di avere almeno 12 anni. *",
//     terms: (
//       <>
//         Ho letto e accetto le{" "}
//         <a href="/it/hiking/condizionipartecipazione" target="_blank">
//           condizioni di partecipazione
//         </a>{" "}
//         e le{" "}
//         <a
//           href="https://www.rivella-group.com/it/extras/footer-navigation-links/colphon"
//           target="_blank"
//         >
//           informazioni sulla protezione dei dati
//         </a>
//         . *
//       </>
//     ),
//     newsletter: "Desidero ricevere la newsletter.",
//     survey: "Come sei venuto a conoscenza della promozione delle escursioni?",
//     last_question: "Scegli",
//     registration: "Registrati",
//   },
//   fr: {
//     reverted_win_msg: `Une personne vivant à ton domicile a déjà gagné un prix immédiat. En fonction de nos conditions
//     de participation, nous ne pouvons malheureusement pas te remettre un autre prix immédiat. Tu peux t’adresser au
//     service clients Rivella, si tu souhaites de plus amples informations.`,
//     code_recorded_msg: `Nous Avons Enregistré Ton Code Et Ta Participation`,
//     thank_you_msg: `Merci de vous être inscrit.`,
//     salutation: "Salutation*",
//     first_name: "Prénom*",
//     last_name: "Nom*",
//     email: "E-Mail*",
//     street: "Rue*",
//     number: "No*",
//     zip_code: "Code postal*",
//     city: "Lieu*",
//     country: "Pays*",
//     birth_date: "Date de naissance*",
//     age: "Je certifie que j'ai 12 ans ou plus. *",
//     terms: (
//       <>
//         J'ai lu et j'accepte les{" "}
//         <a href="/fr/hiking/conditionsparticipation" target="_blank">
//           conditions de participation
//         </a>{" "}
//         et les{" "}
//         <a
//           href="https://www.rivella-group.com/fr/extras/footer-navigation-links/imprit"
//           target="_blank"
//         >
//           informations sur la protection des données
//         </a>
//         . *
//       </>
//     ),
//     newsletter: "Je souhaite recevoir le bulletin d'information.",
//     survey: "Comment as-tu entendu parler de la promotion de randonnée ?",
//     last_question: "Veuillez en choisir",
//     registration: "S'inscrire",
//   },
// }

// // codeCountMessage field missing
// const codeCountMessage = {
//   de: "Dein aktueller Codestand beträgt",
//   fr: "Ton score de code actuel est",
//   it: "Il tuo punteggio attuale del codice è",
// }

// const alreadyHaveAccountTranslations = {
//   de: {
//     prefix: "Du hast schon ein Konto.",
//     midMsg: "Hier",
//     suffix: "geht's zum Login.",
//   },
//   fr: {
//     prefix: "Tu as dèja un compte.",
//     midMsg: "Connecte-toi",
//     suffix: "ici",
//   },
//   it: {
//     prefix: "Hai già un account.",
//     midMsg: "Accedi",
//     suffix: "qui ora.",
//   },
// }

class WinHikeForm extends React.Component {
  state = {
    profile: { ...formFields },
    shouldPrefillData: false,
    enteredEmail: "",
    showForm: true,
    invalidFields: [],
    error: "",
    showRevertedWinMsg: false,
    showLoginLink: false,
  }

  formRef = React.createRef()

  getAge = (birthdate) => {
    const dateDiff = Date.now() - birthdate.getTime()
    const age = new Date(dateDiff)
    return Math.abs(age.getUTCFullYear() - 1970)
  }

  validateBirthdateField = () => {
    const {
      profile: { birth_date },
    } = this.state

    const dateString = birth_date.split(".").join("/")
    const [day, month, year] = dateString.split("/")

    const validDay = Number(day) >= 1 && Number(day) <= 31
    const validMonth = Number(month) >= 1 && Number(month) <= 12
    const validYear = Number(year) <= 2008 && Number(year) >= 1902

    const birthday = new Date(`${year}/${month}/${day}`)
    const age = this.getAge(birthday)

    return validDay && validMonth && validYear && age >= 12
  }

  validateFields = () => {
    const nonRequiredFields = ["user_hash", "last_question", "check_3"]
    const { profile } = this.state
    const fields = Object.keys(profile).filter(
      (field) => !nonRequiredFields.includes(field)
    )
    const invalidFields = fields.filter((field) => !profile[field])

    const isValidBirthDate = this.validateBirthdateField()
    if (!isValidBirthDate) {
      invalidFields.push("birth_date")
    }

    return invalidFields
  }

  componentDidMount() {
    const { profileDetails, enteredEmail, lang } = this.props
    if (enteredEmail) {
      const profile = { ...this.state.profile }
      profile.email = enteredEmail
      this.setState({ profile })
    }
    if (profileDetails) {
      if (profileDetails.user_hash) {
        this.setState({
          ...this.state,
          profile: {
            ...profileDetails,
            salutation: profileDetails.salutation.trim(),
          },
          lang,
          shouldPrefillData: true,
        })
      }
    }
  }

  handleChange = ({ target: { name, value } }) => {
    const profile = { ...this.state.profile }
    profile[name] = value
    this.setState({ profile, invalidFields: [], error: "" })
  }

  trackUserRegistration = () => {
    // Tracker code
    try {
      window.fdData = window.fdData || []
      window.fdData.push({ conversion: "registration" })
    } catch (e) {
      console.warn(e)
    }
  }

  handleKeypress = (e) => {
    const characterCode = e.key
    if (characterCode === "Backspace") return

    const characterNumber = Number(characterCode)
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return
      } else if (characterNumber === 0) {
        e.preventDefault()
      }
    } else {
      e.preventDefault()
    }
  }

  handleConfirmation = ({ target: { name, checked } }) => {
    const profile = { ...this.state.profile }
    profile[name] = checked ? 1 : 0
    this.setState({ profile, invalidFields: [], error: "" })
  }

  showLoginForm = (e, { displayLoginForm }) => {
    if (displayLoginForm) {
      displayLoginForm()
      window.scroll(0, this.formRef.current.offsetTop - 300)
    }
  }

  setCookie = (key, value) => {
    // Delete any associated cookie
    document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC"

    const today = new Date()
    const fourMonthsFromToday = new Date(today.setMonth(today.getMonth() + 4))
    document.cookie =
      key + "=" + value + "; expires=" + fourMonthsFromToday + "; path=/"
  }

  handleSubmit = async (evt, onRegisterSuccessful) => {
    evt.preventDefault()
    const { promoCode, lang, onRegisterCallback } = this.props
    let profileRequestBody =
      promoCode !== undefined
        ? { ...this.state.profile, code: promoCode }
        : this.state.profile
    let birth_date = profileRequestBody.birth_date
    birth_date = birth_date.replace(/\./gm, "-")
    profileRequestBody.birth_date = birth_date

    const invalidFields = this.validateFields()
    this.setState({ invalidFields })

    if (invalidFields.length === 0) {
      const response = await fetchHikingData("registration", {
        method: "POST",
        body: JSON.stringify({ ...profileRequestBody, lang }),
      })

      if (response.result === "success") {
        const { user_hash } = response

        if (onRegisterSuccessful) {
          onRegisterSuccessful()
          onRegisterCallback()

          this.trackUserRegistration()

          this.setState({ showForm: false })
          const codeTriesRequest = await fetchHikingData("hash_count", {
            method: "POST",
            body: JSON.stringify({ user_hash: user_hash }),
          })
          const { result, data } = codeTriesRequest
          if (result === "success") {
            this.setState({ codeTriesCount: data })
          }
        }

        this.setCookie("user_hash", user_hash)
        this.setCookie("first_name", this.state.profile.first_name)

        if (Number(response.reverted_win) === 1) {
          const { onRevertedWinCallback } = this.props
          if (onRevertedWinCallback) {
            onRevertedWinCallback()
          }
          this.setState({ showRevertedWinMsg: true })
        }
      } else if (response.result === "error") {
        this.setState({ error: response.message })
        // If Error message is triggered as a result of an existing email
        // This isn't appriopriate, as we are simply parsing the error message
        // TODO: Ask Ricardo to include an appropriate code for above said error
        if (response.message.toLowerCase().includes("mail")) {
          this.setState({ showLoginLink: true })
          onRegisterCallback(true)
        }
      }
    } else {
      if (this.formRef && this.formRef.current) {
        this.formRef.current.scrollIntoView()
      }
    }
  }

  highlightErrorField = (field) => {
    const { invalidFields } = this.state
    return invalidFields.length > 0 && invalidFields.includes(field)
  }

  render() {
    const {
      shouldPrefillData,
      profile,
      showForm,
      error,
      showRevertedWinMsg,
      showLoginLink,
    } = this.state
    const { promoCode, displayLoginForm, registerFormFields } = this.props
    const prefill_class = shouldPrefillData ? "prefilled_form" : ""
    const lang = this.props.lang || "de"

    const { country_options, how_did_hear_options, existingAccountMsg } =
      registerFormFields

    return (
      <HikingAuthConsumer>
        {(store) => {
          const registerSuccessful = store.actions.login
          return (
            <div ref={this.formRef} className={s.form_container}>
              {showRevertedWinMsg && (
                <h3 className={s.form_message}>
                  {registerFormFields.revertedWinMessage}
                </h3>
              )}
              {showForm && (
                <form
                  className={`${s.form_wrapper} ${s[prefill_class]}`}
                  onSubmit={(e) => this.handleSubmit(e, registerSuccessful)}
                >
                  <section className={s.form_group}>
                    <section className={s.form_column}>
                      <div className={s.form_row}>
                        <input
                          data-error={
                            this.highlightErrorField("salutation")
                              ? "true"
                              : null
                          }
                          placeholder={registerFormFields.salutation}
                          className={s.long}
                          name="salutation"
                          value={profile.salutation}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className={s.form_row}>
                        <input
                          data-error={
                            this.highlightErrorField("first_name")
                              ? "true"
                              : null
                          }
                          placeholder={registerFormFields.first_name}
                          name="first_name"
                          className={s.long}
                          value={profile.first_name}
                          type="text"
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className={s.form_row}>
                        <input
                          data-error={
                            this.highlightErrorField("last_name")
                              ? "true"
                              : null
                          }
                          placeholder={registerFormFields.last_name}
                          name="last_name"
                          className={s.long}
                          value={profile.last_name}
                          type="text"
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className={s.form_row}>
                        <input
                          data-error={
                            this.highlightErrorField("email") ? "true" : null
                          }
                          placeholder={registerFormFields.email}
                          name="email"
                          className={s.long}
                          value={profile.email}
                          type="email"
                          onChange={this.handleChange}
                        />
                      </div>
                    </section>
                    <section className={s.form_column}>
                      <div className={s.form_row}>
                        <input
                          data-error={
                            this.highlightErrorField("street") ? "true" : null
                          }
                          placeholder={registerFormFields.street}
                          name="street"
                          className={s.mid}
                          value={profile.street}
                          type="text"
                          onChange={this.handleChange}
                        />
                        <input
                          data-error={
                            this.highlightErrorField("number") ? "true" : null
                          }
                          placeholder={registerFormFields.number}
                          name="number"
                          className={s.short}
                          value={profile.number}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className={s.form_row}>
                        <input
                          data-error={
                            this.highlightErrorField("zip_code") ? "true" : null
                          }
                          placeholder={registerFormFields.zip_code}
                          name="zip_code"
                          className={s.short}
                          value={profile.zip_code}
                          type="number"
                          onChange={this.handleChange}
                          onKeyDown={this.handleKeypress}
                          min={1}
                        />
                        <input
                          data-error={
                            this.highlightErrorField("city") ? "true" : null
                          }
                          placeholder={registerFormFields.city}
                          name="city"
                          className={s.mid}
                          value={profile.city}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className={s.form_row}>
                        <select
                          data-error={
                            this.highlightErrorField("country") ? "true" : null
                          }
                          className={s.long}
                          name="country"
                          onChange={this.handleChange}
                        >
                          <option value="" selected={!shouldPrefillData}>
                            {registerFormFields.country}
                          </option>
                          {country_options.map((country) => (
                            <option
                              key={country}
                              value={country}
                              selected={profile.country === country}
                            >
                              {country}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className={s.form_row}>
                        <InputMask
                          data-error={
                            this.highlightErrorField("birth_date")
                              ? "true"
                              : null
                          }
                          mask="99.99.9999"
                          onChange={this.handleChange}
                          value={profile.birth_date}
                          className={s.long}
                          name="birth_date"
                          inputMode="decimal"
                          placeholder={registerFormFields.birth_date}
                        />
                      </div>
                    </section>
                  </section>
                  <section className={s.checkbox_group}>
                    <div className={s.checkbox_row}>
                      <input
                        data-error={
                          this.highlightErrorField("check_1") ? "true" : null
                        }
                        className={s.checkbox}
                        type="checkbox"
                        name="check_1"
                        id="age"
                        checked={Boolean(Number(profile.check_1))}
                        onChange={this.handleConfirmation}
                      />
                      <label
                        htmlFor="age"
                        dangerouslySetInnerHTML={{
                          __html: registerFormFields.older_than_twelve,
                        }}
                      ></label>
                    </div>
                    <div className={s.checkbox_row}>
                      <input
                        data-error={
                          this.highlightErrorField("check_2") ? "true" : null
                        }
                        className={s.checkbox}
                        type="checkbox"
                        name="check_2"
                        id="terms"
                        onChange={this.handleConfirmation}
                        checked={Boolean(Number(profile.check_2))}
                      />
                      <label
                        htmlFor="terms"
                        dangerouslySetInnerHTML={{
                          __html: registerFormFields.terms_conditions_read,
                        }}
                      ></label>
                    </div>
                    <div className={s.checkbox_row}>
                      <input
                        className={s.checkbox}
                        type="checkbox"
                        name="check_3"
                        id="newsletter"
                        onChange={this.handleConfirmation}
                        checked={Boolean(Number(profile.check_3))}
                      />
                      <label
                        htmlFor="newsletter"
                        dangerouslySetInnerHTML={{
                          __html: registerFormFields.newsletter,
                        }}
                      ></label>
                    </div>
                  </section>
                  <section className={s.survey_section}>
                    <p>{registerFormFields.how_did_hear}</p>
                    <div className={s.form_row}>
                      {how_did_hear_options && how_did_hear_options.length ? (
                        <select
                          className={s.long}
                          name="last_question"
                          onChange={this.handleChange}
                        >
                          {how_did_hear_options.map((question) => (
                            <option
                              key={question}
                              selected={profile.last_question === question}
                              value={question}
                            >
                              {question}
                            </option>
                          ))}
                        </select>
                      ) : null}
                    </div>
                  </section>
                  {error && !showLoginLink && (
                    <p className={s.error_message}>{error}</p>
                  )}
                  {showLoginLink && (
                    <div className={s.LoginLink}>
                      <span>{existingAccountMsg.title}</span>
                      <button
                        className={`${s[lang]}`}
                        onClick={(evt) =>
                          this.showLoginForm(evt, { displayLoginForm })
                        }
                      >
                        <span>{existingAccountMsg.title_cta_bold}</span>
                        <span>{existingAccountMsg.title_cta}</span>
                      </button>
                    </div>
                  )}
                  <section className={s.form_footer}>
                    <HikeAndWinButton type="submit" className={s.register_btn}>
                      {registerFormFields.register_button}
                    </HikeAndWinButton>
                  </section>
                </form>
              )}
              {/* {!showForm && !showRevertedWinMsg && (
                <h4 className={s.form_message}>
                  {messageTranslations[lang].thank_you_msg}
                </h4>
              )} */}
              {!showForm && promoCode !== undefined && (
                <h4 className={s.form_message}>
                  {registerFormFields.codeSubmissionMsg}
                </h4>
              )}
              {/* {Boolean(codeTriesCount) && (
                <h4 className={s.form_message}>
                  {`${codeCountMessage[lang]} ${codeTriesCount}`}
                </h4>
              )} */}
            </div>
          )
        }}
      </HikingAuthConsumer>
    )
  }
}

export default WinHikeForm
