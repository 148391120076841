import React from "react"
import CodeEntrySection from "../CodeEntrySection/CodeEntrySection"
import { LANGUAGE_ABBREVATIONS, HIKING_API_URL } from "../../../constants"
import {
  getCurrentLanguage,
  getCookie,
  checkHikingCode,
  fetchHikingData,
  getUrlParameter,
} from "../../../helper"
import { HikingAuthConsumer } from "../../../store"

const { de, fr, it } = {
  de: LANGUAGE_ABBREVATIONS.german.lang,
  fr: LANGUAGE_ABBREVATIONS.french.lang,
  it: LANGUAGE_ABBREVATIONS.italian.lang,
}

const alreadyUsedWarning = {
  [de]: "Dieser Code wurde bereits verwendet, sorry!",
  [fr]: "Ce code a déjà été utilisé, désolé!",
  [it]: "Questo codice è già stato utilizzato, spiacenti!",
}

const userDoesntExist = {
  [de]: "Benutzer ist noch nicht registriert.",
  [fr]: "Cet utilisateur n’est pas encore enregistré.",
  [it]: "L’utente non è ancora registrato.",
}

const tooManyRequests = {
  [de]: "Du hast die maximal erlaubte Anzahl an Codes/Logins erreicht. Versuche es nochmals in 24 Stunden.",
  [fr]: "Tu as atteint le nombre maximal de codes/logins autorisés. Retente ta chance dans 24 heures.",
  [it]: "Hai raggiunto il numero massimo di tentativi di accesso. Riprova tra 24 ore.",
}

const codeInvalidError = {
  [de]: "Der code ist ungültig. Bitte überprüfe, ob deine Eingabe richtig ist und versuche es nochmals.",
  [fr]: "Le code de login n’est pas valable. Vérifie, s’il te plaît, si tes données sont correctes et retente ta chance.",
  [it]: "Il codice di accesso non è valido. Ti preghiamo di controllare che quanto hai scritto sia corretto e poi riprovarci.",
}

const TeilnehmenButtonText = {
  [de]: "Teilnehmen",
  [fr]: "Participer",
  [it]: "Participate",
}

const placeholderText = {
  [de]: "Dein Gewinncode",
  [fr]: "Ton Code Gagnant",
  [it]: "Tuo Codice Vincente",
}

export const CODE_STATUS = { WIN: "WIN", LOSE: "LOSE" }

const itemType = {
  [CODE_STATUS.WIN]: {
    schema: "https://schema.org/WinAction",
  },
  [CODE_STATUS.LOSE]: {
    schema: "https://schema.org/LoseAction",
  },
}

class CodeSubmissionBox extends React.Component {
  constructor(props) {
    super(props)
    const existingCode = getUrlParameter("code")
    this.state = {
      isMobileDevice: false,
      formData: {
        pleaseWaitError: false,
        alreadyUsedWarning: false,
        userNotExisting: false,
        codeInvalidError: false,
        code: {
          name: "code",
          value: existingCode ? existingCode : "",
        },
      },
    }
  }

  componentDidMount() {
    this.checkCodeTries()
    // Track code entry
    window.dataLayer = window.dataLayer || []
  }

  trackCodeEntry = () => {
    // Tracker code
    try {
      window.fdData = window.fdData || []
      window.fdData.push({ conversion: "step" })
    } catch (e) {
      console.warn(e)
    }
  }

  onInputChange = ({
    name,
    value,
    resetCodeForm,
    codeStatus,
    setCodeStatus,
  }) => {
    resetCodeForm(false)
    setCodeStatus({ ...codeStatus, submitted: false })
    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        alreadyUsedWarning: false,
        somethingWentWrong: false,
        pleaseWaitError: false,
        codeInvalidError: false,
        [name]: {
          ...this.state.formData[name],
          value,
        },
      },
    })
  }

  updateCodeValidationTracker = ({ codeValue, codeValidation }) => {
    const { location } = this.props
    const dataLayerObj = {
      event: "gaGenericEvent",
      eventCategory: "wanderpromo 2020",
      eventAction: "codeeingabe",
      eventLabel: location.pathname,
      codeValidation,
      eventValue: codeValue,
    }

    window.dataLayer.push(dataLayerObj)
  }

  onFormSubmit = (e, { setCodeStatus, setCodeValue }) => {
    this.setState({ showAccountSection: false, hideMessageBox: false })
    e.preventDefault()
    let body = {}
    const code = this.state.formData.code.value
    setCodeValue(code)
    if (!code) return
    const lang = getCurrentLanguage(this.props.location).lang
    body.lang = lang
    body.code = code
    body.user_hash = getCookie("user_hash")
    // const addressToFetch =
    //   process.env.HIKING_API_URL ||
    //   "https://rivellaplugins.madebycolorelephant.com"
    const addressToFetch = HIKING_API_URL
    const base = "/wp-json/rivella-hiking/v1/"
    let statusCode = 0

    checkHikingCode(`${addressToFetch}${base}check_code`, body)
      .then((res) => {
        statusCode = res.status
        this.trackCodeEntry()
        return res.json()
      })
      .then((res) => {
        const { show_registration, error_code } = res

        // winning logic
        if (statusCode === 200) {
          const codeStatus = {
            type: CODE_STATUS.WIN,
            showMessageBox: true,
            shouldRegister: !!show_registration,
            submitted: true,
          }

          setCodeStatus(codeStatus)
          this.updateCodeValidationTracker({
            codeValue: code,
            codeValidation: true,
          })
          // losing logic
        } else {
          // losing and error above input box logic
          if (
            error_code === 8 ||
            error_code === 10 ||
            error_code === 14 ||
            error_code === 15
          ) {
            setCodeStatus({
              type: "",
              showMessageBox: false,
              shouldRegister: false,
            })
            // message near input
            if (error_code === 8) {
              this.setState({
                ...this.state,
                formData: {
                  ...this.state.formData,
                  pleaseWaitError: true,
                },
              })
            } else if (error_code === 10) {
              this.updateCodeValidationTracker({
                codeValue: code,
                codeValidation: "double",
              })
              this.setState({
                ...this.state,
                formData: {
                  ...this.state.formData,
                  alreadyUsedWarning: true,
                },
              })
            } else if (error_code === 14) {
              this.setState({
                ...this.state,
                formData: {
                  ...this.state.formData,
                  userNotExisting: true,
                },
              })
            } else if (error_code === 15) {
              this.updateCodeValidationTracker({
                codeValue: code,
                codeValidation: false,
              })
              this.setState({
                ...this.state,
                formData: {
                  ...this.state.formData,
                  codeInvalidError: true,
                },
              })
            }
            // losing and error as below box logic
          } else {
            this.updateCodeValidationTracker({
              codeValue: code,
              codeValidation: true,
            })
            if (error_code === 9) {
              const codeStatus = {
                type: CODE_STATUS.LOSE,
                showMessageBox: true,
                shouldRegister: !!show_registration,
                submitted: true,
              }
              setCodeStatus(codeStatus)
            }
          }
        }
      })
    this.checkCodeTries()
  }

  checkCodeTries = async () => {
    const isLoggedIn = getCookie("user_hash")
    if (isLoggedIn) {
      const { setTotalCodeTries } = this.props
      const codeTriesRequest = await fetchHikingData("hash_count", {
        method: "POST",
        body: JSON.stringify({ user_hash: getCookie("user_hash") }),
      })
      const { result, data } = codeTriesRequest
      if (result === "success") {
        setTotalCodeTries({ count: data })
      }
    }
  }

  render() {
    const { formData } = this.state
    const { isMobileDevice, location } = this.props
    const { lang } = getCurrentLanguage(location)

    let errorMessage = null

    if (formData.alreadyUsedWarning) errorMessage = alreadyUsedWarning[lang]
    else if (formData.userDoesntExist) errorMessage = userDoesntExist[lang]
    else if (formData.pleaseWaitError) errorMessage = tooManyRequests[lang]
    else if (formData.codeInvalidError) errorMessage = codeInvalidError[lang]

    return (
      <HikingAuthConsumer>
        {(store) => {
          const { shouldResetCodeForm, codeStatus } = store.state
          const { setCodeStatus, setCodeValue, resetCodeForm } = store.actions
          return (
            <CodeEntrySection
              isMobileDevice={isMobileDevice}
              value={shouldResetCodeForm ? "" : formData.code.value}
              errorMessage={shouldResetCodeForm ? "" : errorMessage}
              name={formData.code.name}
              button_text={TeilnehmenButtonText[lang]}
              placeholder_text={placeholderText[lang]}
              itemType={itemType[codeStatus.type]?.schema}
              showArrow={false}
              onInputChange={(e) => {
                this.onInputChange({
                  name: formData.code.name,
                  value: e.target.value,
                  resetCodeForm,
                  codeStatus,
                  setCodeStatus,
                })
              }}
              onFormSubmit={(e) =>
                this.onFormSubmit(e, {
                  setCodeStatus,
                  setCodeValue,
                })
              }
              location={location}
            />
          )
        }}
      </HikingAuthConsumer>
    )
  }
}

export default CodeSubmissionBox
