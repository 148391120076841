import React from "react"
import CodeSubmissionBox from "../CodeSubmissionBox/CodeSubmissionBox"
import GewinnNiete from "../GewinnNiete/GewinnNiete"
import { HikingAuthConsumer } from "../../../store"
import s from "./WinLoseWrapper.module.scss"
import LoginForm from "../LoginForm/LoginForm"

class WinLoseWrapper extends React.Component {
  render() {
    const {
      isMobileDevice,
      location,
      login_box,
      register_form,
      isAccountSection,
    } = this.props
    const formProps = { login_box, register_form }

    return (
      <section className={s.WinLoseWrapper}>
        <HikingAuthConsumer>
          {(store) => {
            const { totalCodeCount, codeStatus, codeValue } = store.state
            const { resetCodeForm, setTotalCodeTries, setCodeValue } =
              store.actions

            const showGewinnNiete = codeStatus.showMessageBox
            const showRegistration = codeStatus.shouldRegister

            return (
              <>
                <CodeSubmissionBox
                  isMobileDevice={isMobileDevice}
                  location={location}
                  setTotalCodeTries={setTotalCodeTries}
                />
                {showGewinnNiete && (
                  <GewinnNiete
                    isAccountSection={isAccountSection}
                    resetCodeForm={resetCodeForm}
                    setCodeValue={setCodeValue}
                    codeStatus={codeStatus}
                    codeTriesCount={totalCodeCount}
                    location={location}
                  />
                )}
                {showRegistration && (
                  <LoginForm
                    location={location}
                    promoCode={codeValue}
                    {...formProps}
                  />
                )}
              </>
            )
          }}
        </HikingAuthConsumer>
      </section>
    )
  }
}

export default WinLoseWrapper
