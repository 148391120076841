import React, { useReducer, createContext } from "react"

import { ROUTING_ANIMATION_FADEIN_LENGTH } from "../constants"

const CustomTransitionContext = createContext()

CustomTransitionContext.displayName = "CustomTransitionContext"

const initialState = {
  isRouting: false,
  touched: false,
}

const actionTypes = {
  START_ROUTING: "CustomTransitionContext.START_ROUTING",
  COMPLETE_ROUTING: "CustomTransitionContext.COMPLETE_ROUTING",
}

const reducer = (state, { type }) => {
  switch (type) {
    case actionTypes.START_ROUTING:
      return {
        ...state,
        isRouting: true,
        touched: true,
      }
    case actionTypes.COMPLETE_ROUTING:
      return {
        ...state,
        isRouting: false,
      }
    default:
      return state
  }
}

const startRouting = () => ({
  type: actionTypes.START_ROUTING,
})

const completeRouting = () => ({
  type: actionTypes.COMPLETE_ROUTING,
})

const actions = ({ dispatch }) => ({
  triggerRouteAnimation: () => {
    dispatch(startRouting())
    return setTimeout(() => {
      return dispatch(completeRouting())
    }, ROUTING_ANIMATION_FADEIN_LENGTH)
  },
})

export const CustomTransitionConsumer = CustomTransitionContext.Consumer

export const CustomTransitionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <CustomTransitionContext.Provider
      value={{
        state,
        dispatch,
        actions: actions({
          dispatch,
          getState: () => state,
        }),
      }}
    >
      {children}
    </CustomTransitionContext.Provider>
  )
}
