import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import ImgFluid from "../Img/ImgFluid"
import s from "./AboutUsBottleSlider.module.scss"
// import PrevBtn from "../../assets/svgs/prev_btn.svg"
// import NextBtn from "../../assets/svgs/next_btn.svg"
// import PlusIcon from "../../assets/svgs/plus_icon.svg"
// import SkewedWrapper from "../SkewedWrapper/SkewedWrapper"
import Slider from "react-slick"
// import { SlickNavButton } from ".."
import { extractImageNameFromUrl, scrollToRef } from "../../helper"

class AboutUsBottleSlider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 0,
      showMiniContent: false,
      showModalTrigger: true,
    }
    this.bottleSliderWrapperRef = React.createRef()
  }

  modalRef = React.createRef()

  componentDidUpdate() {
    const bottleSliderItems = document.querySelectorAll(".slick-cloned")
    const { currentIndex } = this.state
    if (currentIndex > 1) {
      Array.from(bottleSliderItems).forEach((el) => {
        el.style.opacity = 1
      })
    }
  }

  componentDidMount() {
    const { location } = this.props
    document.addEventListener("click", this.closeModal)
    if (
      location.hash &&
      location.hash.includes("exists-since") &&
      this.bottleSliderWrapperRef.current
    ) {
      scrollToRef(this.bottleSliderWrapperRef)
      setTimeout(() => scrollToRef(this.bottleSliderWrapperRef), 100)
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.closeModal)
  }

  handleModalTrigger = ({ target, currentTarget }) => {
    if (this.isChildNode(currentTarget, target)) {
      this.setState((prevState) => ({
        showModalTrigger: !prevState.showModalTrigger,
      }))
    }
  }

  closeModal = ({ target }) => {
    const { showMiniContent } = this.state
    const isModalNode = this.modalRef.current === target
    if (!isModalNode && showMiniContent) {
      this.setState({ showMiniContent: false })
    }
  }

  isChildNode = (parentNode, childNode) => {
    if ("contains" in parentNode) {
      return parentNode.contains(childNode) && parentNode !== childNode
    } else {
      return parentNode.compareDocumentPosition(childNode) % 16
    }
  }

  render() {
    const { currentIndex } = this.state
    // const miniContentClass = showMiniContent ? s.modal_open : ""
    const { slides } = this.props

    const sliderSettings = {
      slidesToShow: 3,
      slidesToScroll: 1,
      speed: 500,
      infinite: true,
      dots: true,
      arrow: true,
      swipeToSlide: true,
      // nextArrow: <SlickNavButton CarouselBtn={NextBtn} />,
      // prevArrow: <SlickNavButton CarouselBtn={PrevBtn} />,
      centerMode: true,
      focusOnSelect: true,
      className: "bottle_slider",
      responsive: [
        {
          breakpoint: 500,
          settings: {
            centerMode: false,
          },
        },
      ],
      beforeChange: (prevIndex, nextIndex) => {
        this.setState({
          showMiniContent: false,
          currentIndex: nextIndex,
          showModalTrigger: false,
        })
      },
      afterChange: () => {
        this.setState({ showModalTrigger: true })
      },
      customPaging: (index) => {
        return (
          <div className={s.indicator_wrapper} key={index}>
            <div className={s.indicator_outer}></div>
            <span role="button" className={`${s.indicator}`}></span>
            <span className={s.year_block}>{slides[index].year}</span>
          </div>
        )
      },
    }

    return (
      <section className={s.carousel_section}>
        <div className={s.carousel_group} ref={this.bottleSliderWrapperRef}>
          {/* <div className={s.carousel_description_wrapper}> */}
          {slides.map(({ title, subtitle, description }, index) => {
            const active = index === currentIndex ? s.active : ""
            return (
              <React.Fragment key={index}>
                <div
                  className={`${s.carousel_description_inner} ${active}`}
                  key={index + subtitle + Math.random()}
                >
                  <h3
                    className={s.section_title}
                    dangerouslySetInnerHTML={{ __html: title }}
                  ></h3>
                  <h2
                    className={s.section_subtitle}
                    dangerouslySetInnerHTML={{ __html: subtitle }}
                  ></h2>
                </div>
                <div
                  className={`${s.carousel_description_inner} ${s.carousel_description} ${active}`}
                  key={index}
                >
                  <p
                    className={s.drink_description}
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></p>
                </div>
              </React.Fragment>
            )
          })}
          {/* </div> */}
          <div
            className={s.carousel_outer}
            onMouseDown={this.handleModalTrigger}
            onMouseUp={this.handleModalTrigger}
            onTouchStart={this.handleModalTrigger}
            onTouchEnd={this.handleModalTrigger}
            onTouchCancel={this.handleModalTrigger}
          >
            <Slider {...sliderSettings}>
              {slides.map(({ image, subtitle }, index) => {
                return (
                  <div key={index + subtitle + Math.random()}>
                    <div className={s.carousel_inner}>
                      <ImgFluid
                        alt={subtitle}
                        fileName={extractImageNameFromUrl(image)}
                      />
                    </div>
                  </div>
                )
              })}
            </Slider>
          </div>
        </div>
        {/* {showModalTrigger && (
          <>
            <button
              className={`${s.modal_trigger} ${miniContentClass}`}
              onClick={evt => {
                evt.nativeEvent.stopImmediatePropagation()
                this.setState(prevState => ({
                  showMiniContent: !prevState.showMiniContent,
                }))
              }}
            >
              <PlusIcon />
            </button>
            <SkewedWrapper
              verticalInner
              type="primary"
              wrapperClassName={`${s.modal_wrapper} ${miniContentClass}`}
              ref={this.modalRef}
            >
              {slides[currentIndex].modalContent}
            </SkewedWrapper>
          </>
        )} */}
      </section>
    )
  }
}

export default AboutUsBottleSlider
