import React from "react"
import SkewedWrapper from "../SkewedWrapper/SkewedWrapper"
import s from "./SkewedCard.module.scss"
import KeyVisual from "../KeyVisual/KeyVisual"

class SkewedCard extends React.Component {
  render() {
    const {
      children,
      cardOuterCn = "",
      cardInnerCn = "",
      wrapperRef,
      keyVisual,
    } = this.props
    return (
      <SkewedWrapper
        wrapperClassName={`${s.skewed_card_outer} ${cardOuterCn} ${
          keyVisual ? s.whiteTop : ""
        }`}
        innerRef={wrapperRef}
        verticalInner
      >
        {keyVisual ? <KeyVisual className={s.KeyVisual} /> : null}
        <div className={cardInnerCn}>{children}</div>
      </SkewedWrapper>
    )
  }
}

export default SkewedCard
