import React, { useState, useEffect, useRef } from "react"
import lottie from "lottie-web"
import { graphql, useStaticQuery } from "gatsby"

const WinAndHikeLogoFunc = (props) => {
  const [logoUrl, setLogoUrl] = useState(null)
  const ref = useRef()
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "win_and_hike_footer" }) {
        publicURL
      }
    }
  `)
  useEffect(() => {
    setLogoUrl(data.file.publicURL)
  }, [])
  useEffect(() => {
    ref.current.innerHTML = ""
    lottie.loadAnimation({
      container: ref.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: logoUrl,
    })
  }, [logoUrl])

  return <div {...props} ref={ref}></div>
}

export default WinAndHikeLogoFunc
