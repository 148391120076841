import React from "react"
import s from "./SocialMediaGalleryGrid.module.scss"
import { SkewedWrapper, Button } from "../../../components"
import { fetchInstagramPhotos } from "../../../helper"
import FacebookIcon from "../../../assets/svgs/facebook_impression_logo.svg"
import YoutubeIcon from "../../../assets/svgs/youtube_impression_logo.svg"
import InstagramIcon from "../../../assets/svgs/instagram_impression_logo.svg"
import ArrowDownIcon from "../../../assets/svgs/down_arrow.svg"

const iconType = {
  facebook: <FacebookIcon />,
  instagram: <InstagramIcon />,
  youtube: <YoutubeIcon />,
}

class SocialMediaGalleryGrid extends React.Component {
  constructor(props) {
    super(props)
    this.galleryRef = React.createRef()
    this.state = {
      showArrowUp: false,
      showArrowDown: false,
      arrowUpClick: false,
      arrowDownClick: false,
      direction: 0,
      galleryImages: null,
      displayCount: 6,
    }
  }

  componentDidMount() {
    this.init()
  }

  init = async () => {
    const { location } = this.props
    const res = await fetchInstagramPhotos(location && location.origin)
    if (res.result === "success") {
      this.setState({
        // galleryImages: res.photos.slice(0, 18),
        galleryImages: res.photos.slice(0, 6),
        showArrowDown: res.photos && res.photos.length > 2,
      })
    }
  }

  onShowMore = () => {
    const { displayCount } = this.state
    if (displayCount === 18) {
      return
    }
    this.setState({
      displayCount: displayCount + 6,
    })
  }

  handleScroll = () => {
    const far = this.galleryRef.current.clientHeight * this.state.direction + 10
    this.galleryRef.current.scrollTo({
      left: 0,
      top: far,
      behavior: "smooth",
    })

    if (far === 0) {
      this.toggleState("showArrowUp", false)
    }
  }

  toggleState = (state, bool) => {
    this.setState({ [state]: bool })
  }

  onScrollUp = () => {
    this.setState(
      (prevState) => ({
        direction: prevState.direction > 0 ? prevState.direction - 1 : 0,
      }),
      () => {
        this.handleScroll()
      }
    )
  }

  onScrollDown = () => {
    const { showArrowUp } = this.state
    this.setState(
      (prevState) => ({
        direction:
          prevState.direction < prevState.galleryImages.length / 2
            ? prevState.direction + 1
            : prevState.direction,
      }),
      () => {
        this.handleScroll()
      }
    )

    if (!showArrowUp) {
      this.toggleState("showArrowUp", true)
    }
  }

  render() {
    const { showArrowUp, showArrowDown, galleryImages, displayCount } =
      this.state
    const {
      title_one_text,
      title_one_color,
      title_two_text,
      title_two_color,
      button_one_text,
    } = this.props
    let arrowUpHtml = null
    let arrowDownHtml = null
    if (showArrowUp) {
      arrowUpHtml = (
        <button className={s.scroll_up_btn} onClick={this.onScrollUp}>
          <ArrowDownIcon />
        </button>
      )
    }
    if (showArrowDown) {
      arrowDownHtml = (
        <button className={s.scroll_down_btn} onClick={this.onScrollDown}>
          <ArrowDownIcon />
        </button>
      )
    }
    return (
      <section className={s.gallery_section} ref={this.props.sectionRef}>
        {galleryImages ? (
          <>
            <div className={s.TitleWrapper}>
              <h3 className={[s.SubTitle, title_two_color].join(" ")}>
                {title_two_text}
              </h3>
              <h2 className={[s.Title, title_one_color].join(" ")}>
                {title_one_text}
              </h2>
            </div>
            {arrowUpHtml}
            <SkewedWrapper wrapperClassName={s.gallery_section_wrapper}>
              <section className={s.gallery_grid} ref={this.galleryRef}>
                {galleryImages.map(({ ig_id, permalink, media_url }, index) => {
                  if (index + 1 > displayCount) return null
                  const Icon = iconType["instagram"]
                  return (
                    <div
                      key={ig_id}
                      className={s.image_wrapper}
                      style={{ zIndex: index }}
                    >
                      <a
                        href={permalink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className={s.icon_wrapper}>{Icon}</div>
                        <div className={s.image_container}>
                          <img src={media_url} />
                        </div>
                      </a>
                    </div>
                  )
                })}
                <div className={s.ShowMore}>
                  <a
                    href="https://www.instagram.com/rivella_ch/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button type="default">{button_one_text}</Button>
                  </a>
                </div>
              </section>
            </SkewedWrapper>
            {arrowDownHtml}
          </>
        ) : null}
      </section>
    )
  }
}

export default SocialMediaGalleryGrid
