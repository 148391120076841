import React, { Component } from "react"
import { Link } from "gatsby"
import s from "./ExperienceSection.module.scss"
import { PromotionSectionWrapper } from "../"
import { ImgFluid } from "../../../components"
import { extractImageNameFromUrl, getCurrentLanguage } from "../../../helper"
import { winAndHike_routes } from "../../../constants"

class ExperienceSection extends Component {
  render() {
    const { logos, title_one_text, location, itemProp } = this.props
    const { slug } = getCurrentLanguage(location)
    const base = slug && winAndHike_routes[slug].partnerdestinationen
    return (
      <section className={s.experience_section}>
        {logos.length && (
          <PromotionSectionWrapper
            itemProp={itemProp}
            itemScope
            itemType="https://schema.org/Place"
            className={s.logos_wrapper}
          >
            <h1
              className={s.section_title}
              dangerouslySetInnerHTML={{
                __html: title_one_text,
              }}
            ></h1>
            <div className={s.logos_gallery}>
              {logos.map((logo, index) => {
                return (
                  <Link
                    key={index}
                    className={s.image_wrapper}
                    to={`/${slug}${base}${
                      logo.slug ? logo.slug.replace("é", "e") : logo.slug
                    }`}
                  >
                    <ImgFluid
                      fileName={extractImageNameFromUrl(logo.logo_img)}
                      alt="From the Experience gallery"
                      itemProp="logo"
                    />
                  </Link>
                )
              })}
            </div>
          </PromotionSectionWrapper>
        )}
      </section>
    )
  }
}

export default ExperienceSection
