import React from "react"
import s from "./WinHikeFormSection.module.scss"
import { PromotionSectionWrapper } from ".."
import { WinHikeForm } from "../../../components"

class WinHikeFormSection extends React.Component {
  render() {
    const { title_one_text, description_one_text } = this.props
    return (
      <PromotionSectionWrapper className={s.win_hike_form_section}>
        <h2 className={s.form_title}>{title_one_text}</h2>
        <p className={s.form_description}>{description_one_text}</p>
        <WinHikeForm />
      </PromotionSectionWrapper>
    )
  }
}

export default WinHikeFormSection
