import "core-js/es6/map"
import "core-js/es6/set"
import "raf/polyfill"
import React from "react"

import {
  language_code_to_static,
  product_range_route,
  LANGUAGE_ABBREVATIONS,
} from "../../constants"
import { getCurrentLanguage } from "../../helper"
import { Layout, AssortmentProductsList } from ".."

const pageDescription = `Erfahre mehr über die Zutaten und Besonderheiten der erfrischenden Rivella Getränke.`

class AssortmentProductsListBuilder extends React.Component {
  render() {
    const { pageContext, location } = this.props
    const currentLang = getCurrentLanguage(location)
    const title = product_range_route[currentLang.combined].title
    const subtitle = product_range_route[currentLang.combined].subtitle

    const langObj = {
      currentLang,
      options: Object.keys(language_code_to_static)
        .map((key) =>
          key !== LANGUAGE_ABBREVATIONS.english.combined &&
          key !== LANGUAGE_ABBREVATIONS.italian.combined
            ? {
                ...language_code_to_static[key],
                language_code: key,
                slug: `${key}/${product_range_route[key].slug}`,
              }
            : null
        )
        .filter((L) => !!L),
    }

    return (
      <Layout
        pageName={pageContext.pageName}
        location={location}
        languageOptions={langObj}
        headerIsTransparent={pageContext.headerIsTransparent}
        allProducts={pageContext.allProducts}
        allEvents={pageContext.allEvents}
        siteScope={pageContext.siteScope}
        pageDescription={pageDescription}
      >
        <AssortmentProductsList
          title={title}
          subtitle={subtitle}
          location={location}
          allProducts={pageContext.allProducts}
          allEvents={pageContext.allEvents}
        />
      </Layout>
    )
  }
}

export default AssortmentProductsListBuilder
