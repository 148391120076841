import React from "react"
import s from "./GewinnNiete.module.scss"
import PromotionSectionWrapper from "../PromotionSectionWrapper/PromotionSectionWrapper"
import AngleDownIcon from "../../../assets/svgs/angle_down_icon.svg"
import { getCurrentLanguage, scrollToRef } from "../../../helper"
import { LANGUAGE_ABBREVATIONS } from "../../../constants"
import { CODE_STATUS } from "../CodeSubmissionBox/CodeSubmissionBox"
import { WinAndHikeGewinnAnimation } from "../../../components"

const {
  german: { lang: de },
  french: { lang: fr },
  italian: { lang: it },
} = LANGUAGE_ABBREVATIONS

const messages = {
  [CODE_STATUS.WIN]: {
    title: {
      [de]: "Herzlichen Glückwunsch",
      [fr]: "Félicitations,",
      [it]: "Congratulazioni,",
    },
    registerMsg: {
      [de]: "Du musst dich registrieren, damit wir dir deinen Gewinn mit der Post zusenden können.",
      [fr]: "Inscris-toi afin que nous puissions t'envoyer ton prix par la poste.",
      [it]: "Registrati, così possiamo inviarti il tuo premio per posta.",
    },
    noRegisterMsg: {
      [de]: "du hast eine Rivella Unlimited Bottle gewonnen. Wir haben deinen Gewinn registriert. Die Rivella Unlimited Bottle wird dir innerhalb von 10 Tagen an die angegebene Adresse zugestellt. Wir wünschen dir viel Vergnügen und erfrischende Momente beim Erkunden unserer Partnergebiete!",
      [fr]: "tu as gagné une bouteille Rivella Unlimited. Nous avons enregistré ton gain. La bouteille Rivella Unlimited sera livrée à l'adresse que tu as indiquée dans un délai de 10 jours. Nous te souhaitons beaucoup de plaisir et de moments rafraîchissements dans l'exploration de nos régions partenaires!",
      [it]: "hai vinto una Rivella Unlimited Bottle. Abbiamo registrato le tue vincite. La Bottiglia Rivella Unlimited sarà consegnata entro 10 giorni all'indirizzo indicato. Vi auguriamo tanto divertimento e momenti rinfrescanti esplorando le nostre aree partner!",
    },
  },
  [CODE_STATUS.LOSE]: {
    title: {
      [de]: "Leider nicht gewonnen.",
      [fr]: "Tu n’as malheureusement pas gagné.",
      [it]: "Purtroppo non hai vinto.",
    },
    registerMsg: {
      [de]: `Jetzt registrieren, E-Mail eingeben & einen Hauptpreis gewinnen!`,
      [fr]: `Inscris-toi maintenant, entre ton e-mail et gagne un grand prix!`,
      [it]: `Iscriviti ora, inserisci il tuo indirizzo e-mail e vinci un grande premio!`,
    },
    noRegisterMsg: {
      [de]: `Aber keine Sorge, der Code war nicht vergebens und bringt dich einem der Hauptpreise einen kleinen Schritt näher. Gib weiter Codes ein, um beim nächsten Mal eine Rivella Unlimited Bottle zu gewinnen und/oder deine Chance auf einen der attraktiven Hauptpreise zu erhöhen.`,
      [fr]: `Mais ne te fais pas de souci, tu n’as pas reçu le code en vain car ce dernier va te permettre de te rapprocher d’un des prix principaux. Continue à saisir des codes en vue de gagner, la prochaine fois, une gourde «Rivella Unlimited Bottle» et/ou d’augmenter tes chances de gagner un des prix principaux attrayants.`,
      [it]: `Ma non preoccuparti, il codice non è stato giocato invano e ti fa fare un piccolo passo verso uno dei premi principali. Continua a inserire codici, per vincere una Rivella Unlimited Bottle la prossima volta e/o aumentare le tue possibilità di aggiudicarti uno degli allettanti premi principali.`,
    },
  },
}

const reEnterCodeTranslations = {
  en: "Enter code again",
  de: "Neuen Code eingeben.",
  fr: "Entre un nouveau code",
  it: "Inserire un nuovo codice.",
}

const codeCountMessage = {
  [de]: "Dein aktueller Codestand beträgt",
  [fr]: "Ton score de code actuel est",
  [it]: "Il tuo punteggio attuale del codice è",
}

class GewinnNiete extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      haveScrolled: false,
    }
  }
  componentDidMount() {
    this.scrollToRef()
  }

  componentDidUpdate() {
    const { codeStatus } = this.props
    if (codeStatus.submitted && !this.state.haveScrolled) {
      this.scrollToRef()
      this.setState({
        haveScrolled: true,
      })
    }
  }

  scrollToRef = () => {
    setTimeout(() => {
      scrollToRef(this.messageBoxRef, null)
    }, 200)
  }

  messageBoxRef = React.createRef()

  handleSubmit = (evt) => {
    evt.preventDefault()
    if (this.props.onFormSubmit) {
      this.props.onFormSubmit(evt)
    }
  }

  render() {
    const {
      resetCodeForm,
      codeTriesCount,
      codeStatus,
      showRegistration,
      location,
      setCodeValue,
      isAccountSection,
    } = this.props

    const { lang } = getCurrentLanguage(location)

    const codeStatusType = CODE_STATUS[codeStatus?.type]
    let registerKey = codeStatus?.shouldRegister
      ? "registerMsg"
      : "noRegisterMsg"

    return (
      <>
        <PromotionSectionWrapper className={s.win_hike_message_section}>
          {!isAccountSection && (
            <WinAndHikeGewinnAnimation loop className={s.WinPath} />
          )}
          <div className={s.message_box} ref={this.messageBoxRef}>
            <h2 className={s.message}>
              {codeStatusType && (
                <span>{messages[codeStatusType].title[lang]}</span>
              )}
              {codeStatusType === CODE_STATUS.LOSE && (
                <button
                  className={s.link}
                  onClick={() => {
                    if (setCodeValue && resetCodeForm) {
                      resetCodeForm(true)
                      setCodeValue("")
                      if (typeof document !== "undefined") {
                        document.querySelector("body").scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        })
                      }
                    }
                  }}
                >
                  {reEnterCodeTranslations[lang]}
                </button>
              )}
            </h2>
            {codeStatusType && (
              <p className={s.message_description}>
                {messages[codeStatusType][registerKey][lang]}
              </p>
            )}
            {codeTriesCount !== undefined && (
              <p
                className={s.message_description}
              >{`${codeCountMessage[lang]} ${codeTriesCount}`}</p>
            )}
          </div>
          {showRegistration ? <AngleDownIcon /> : null}
        </PromotionSectionWrapper>
      </>
    )
  }
}

export default GewinnNiete
