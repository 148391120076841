/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import s from "./PrizeListSection.module.scss"
import PromotionSectionWrapper from "../PromotionSectionWrapper/PromotionSectionWrapper"
import { ImgFluid, ImageFrame } from "../../../components"
import { extractImageNameFromUrl } from "../../../helper"

class PrizeListSection extends React.Component {
  render() {
    const { prize_list, itemProp } = this.props
    return (
      <PromotionSectionWrapper
        itemScope
        itemProp={itemProp}
        itemType="https://schema.org/Action"
        className={s.prize_detail_section}
      >
        {prize_list.map((prize, index) => {
          const {
            prize_title_one_text,
            prize_title_two_text,
            prize_subtitle_one_text,
            prize_description_text,
            location_img,
            partner_logo,
            partner_logo_link,
            prize_count,
            prize_benefits = [],
            prize_benefits_two = [],
            day_one_text,
            day_two_text,
          } = prize
          return (
            <section
              itemProp="location"
              itemScope
              itemType="https://schema.org/Place"
              key={index}
              className={s.prize_detail_wrapper}
            >
              <h2
                itemProp="address"
                className={s.prize_title}
                dangerouslySetInnerHTML={{ __html: prize_title_one_text }}
              ></h2>
              {prize.prize_subtitle_one_text ? (
                <h3
                  className={s.prize_benefits_title}
                  dangerouslySetInnerHTML={{ __html: prize_subtitle_one_text }}
                ></h3>
              ) : null}
              <div
                className={
                  prize_subtitle_one_text
                    ? [s.location_img_wrapper, s.location_img_wrapper_two].join(
                        " "
                      )
                    : s.location_img_wrapper
                }
              >
                <div
                  className={s.prize_count}
                  dangerouslySetInnerHTML={{ __html: prize_count }}
                ></div>
                <ImageFrame
                  className={s.image_frame}
                  itemProp="photo"
                  imgFilename={extractImageNameFromUrl(location_img)}
                />
              </div>
              <div itemProp="description" className={s.prize_detail}>
                <p
                  className={s.prize_description}
                  dangerouslySetInnerHTML={{ __html: prize_description_text }}
                ></p>

                {prize_title_two_text !== undefined && (
                  <h3
                    className={s.prize_benefits_title}
                    dangerouslySetInnerHTML={{ __html: prize_title_two_text }}
                  ></h3>
                )}
                {day_one_text ? (
                  <p
                    className={s.prize_itenery}
                    dangerouslySetInnerHTML={{ __html: day_one_text }}
                  ></p>
                ) : null}
                {prize_benefits.length > 0 && (
                  <ul className={s.prize_benefits_list}>
                    {prize_benefits.map((benefit, index) => (
                      <li key={index} className={s.benefit_item}>
                        - {benefit}
                      </li>
                    ))}
                  </ul>
                )}
                {day_two_text ? (
                  <p
                    className={s.prize_itenery}
                    dangerouslySetInnerHTML={{ __html: day_two_text }}
                  ></p>
                ) : null}
                {prize_benefits_two && prize_benefits_two.length ? (
                  <ul className={s.prize_benefits_list}>
                    {prize_benefits_two.map((benefit, index) => (
                      <li key={index} className={s.benefit_item}>
                        - {benefit}
                      </li>
                    ))}
                  </ul>
                ) : null}
                <a
                  href={partner_logo_link}
                  rel={partner_logo_link ? "noopener noreferer" : null}
                  target={partner_logo_link ? "_blank" : null}
                >
                  {partner_logo !== undefined && (
                    <ImgFluid
                      cn={s.partner_logo}
                      fileName={extractImageNameFromUrl(partner_logo)}
                      itemProp="logo"
                    />
                  )}
                </a>
              </div>
            </section>
          )
        })}
      </PromotionSectionWrapper>
    )
  }
}

export default PrizeListSection
