import React from "react"
import Helmet from "react-helmet"

import s from "./PartnerDetailSection.module.scss"
import { PromotionSectionWrapper, SkewedSectionWithBoxShadow } from "../"
import { extractImageNameFromUrl } from "../../../helper"
// import { LANGUAGE_ABBREVATIONS } from "../../../constants"
import { ImageFrame, HikeAndWinButton, ImgFluid } from "../../../components"

// const {
//   german: { lang: de },
//   french: { lang: fr },
//   italian: { lang: it },
// } = LANGUAGE_ABBREVATIONS

// const zurWebsite = {
//   [de]: "Zur Website",
//   [fr]: "Vers le site web",
//   [it]: "Al sito web",
// }
// const zumTourenportal = {
//   [de]: "Zum CAS-Tourenportal",
//   [fr]: "Vers le portail des courses",
//   [it]: "Al portale escursionistico",
// }

class PartnerDetailSection extends React.Component {
  render() {
    const {
      cta_1,
      cta_2,
      itemProp,
      description,
      title_one_text,
      locationImg,
      partner_logo_img,
      page_type = "partner",
      description_two,
      isSACHutPage,
      // lang,
      partner_url,
      partner_logo_visible,
      button_one_text,
      button_two_text,
      button_one_visible,
      button_two_visible,
    } = this.props
    const sacHutClass = isSACHutPage ? "sac_hut" : ""
    return (
      <SkewedSectionWithBoxShadow className={s.destination_section}>
        {description && (
          <Helmet>
            <meta property="og:description" content={description} />
          </Helmet>
        )}
        <PromotionSectionWrapper
          itemProp={itemProp}
          itemScope
          itemType="https://schema.org/Thing"
          className={`${s.partner_details_section} ${s[sacHutClass]}`}
        >
          <h1
            itemProp="alternateName"
            className={s.partner_detail_title}
            dangerouslySetInnerHTML={{ __html: title_one_text }}
          ></h1>
          <div className={`${s.partner_detail} ${s[page_type]}`}>
            {description ? (
              <p
                itemProp="description"
                className={s.partner_detail_description}
                dangerouslySetInnerHTML={{ __html: description }}
              ></p>
            ) : null}
            {button_one_visible ? (
              <div className={s.hike_win_button_wrapper}>
                <HikeAndWinButton
                  itemProp="identifier"
                  type="link"
                  target="_blank"
                  rel="noopener noreferrer"
                  to={cta_1}
                >
                  {button_one_text}
                </HikeAndWinButton>
              </div>
            ) : null}
            {description_two && (
              <p
                className={s.partner_detail_description}
                dangerouslySetInnerHTML={{ __html: description_two }}
              ></p>
            )}
            {button_two_visible ? (
              <div className={s.hike_win_button_wrapper}>
                <HikeAndWinButton
                  itemProp="identifier"
                  type="link"
                  target="_blank"
                  rel="noopener noreferrer"
                  to={cta_2}
                >
                  {button_two_text}
                </HikeAndWinButton>
              </div>
            ) : null}
          </div>
          <div className={s.image_frame_wrapper}>
            <ImageFrame
              className={s.image_frame}
              imgFilename={
                locationImg && locationImg.startsWith("http")
                  ? extractImageNameFromUrl(locationImg)
                  : locationImg
              }
              itemProp="image"
            />
          </div>
          {partner_logo_visible && (
            <a
              href={partner_url}
              target="_blank"
              rel="noopener noreferrer"
              className={s.partner_url}
              itemProp="url"
            >
              <div className={s.partner_logo_img}>
                <ImgFluid
                  itemProp="image"
                  fileName={extractImageNameFromUrl(partner_logo_img)}
                />
              </div>
            </a>
          )}
        </PromotionSectionWrapper>
      </SkewedSectionWithBoxShadow>
    )
  }
}

export default PartnerDetailSection
