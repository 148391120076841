import React from "react"
import { Link } from "gatsby"

import s from "./LanguagePicker.module.scss"
import { ImgFluid } from "../"
import {
  extractDomainExtention,
  getUrlParameter,
  getCurrentLanguage,
  getCommonIdentifier,
} from "../../helper"
import {
  SITE_URL,
  LANGUAGE_ABBREVATIONS,
  language_code_to_static,
  winAndHike_routes,
} from "../../constants"
import ArrowDown from "../../assets/svgs/arrow_down.svg"
import {
  winAndHikeHeader,
  mainSiteHeader,
} from "../../containers/Header/LanguagesData"

class LanguagePicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileLangPickerOpen: false,
    }
  }

  toggleMobileLangPickerOpen = () => {
    if (typeof window !== "undefined" && window.innerWidth < 1100) {
      if (this.props.setMobileLangPickerOpen) {
        this.props.setMobileLangPickerOpen(!this.state.mobileLangPickerOpen)
      }
      this.setMobileLangPickerOpen(!this.state.mobileLangPickerOpen)
    }
  }

  setMobileLangPickerOpen = (to) => {
    if (
      typeof window !== "undefined" &&
      // if you need to update this make sure it corresponds to value in
      // _variables.scss $header-breaking-point variable and `toggleMobileLangPickerOpen`
      window.innerWidth < 1100
    ) {
      if (this.props.setMobileLangPickerOpen) {
        this.props.setMobileLangPickerOpen(to)
      }
      this.setState({
        mobileLangPickerOpen: to,
      })
    }
  }

  constructLanguageSwitchLink = (locationObj, rawSlug, curLangKey) => {
    if (!locationObj) throw Error("Requires `location` param from props.")
    let slug = rawSlug.replace("index", "")
    slug = slug.startsWith("/") ? slug : "/" + slug
    let objToCast = {}

    let { origin } = locationObj
    if (!origin) origin = SITE_URL
    // origin = SITE_URL
    // origin = "https://staging.rivella.ch"

    if (
      origin.includes("localhost") ||
      origin.includes("blueglass") ||
      origin.includes("dev.rivella") ||
      origin.includes("staging")
    ) {
      objToCast = {
        route: `/${LANGUAGE_ABBREVATIONS[curLangKey].slug}`,
        isInternalLink: true,
      }
    } else {
      // const origin_without_http = origin.replace(/http.?:\/\//, "")
      // const regexToExtractDomainData = /\b((?=[a-z0-9-]{1,63}.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*.)+([a-z]{2,63})\b/g
      // const res = regexToExtractDomainData.exec(origin_without_http)

      // // in a https://rivella.ch route this is equal to 'ch'
      // const oldDomainExtention = res[4]
      const oldDomainExtention = extractDomainExtention(locationObj)

      let newExtention, newLang, oldLangExtention
      const splitSlug = slug.split("/")
      loop: for (let i of splitSlug) {
        if (i && i !== "/" && i.includes("-") && i.length === 5) {
          // in slug /at_de/sortiment, this is equal to 'at_de'
          oldLangExtention = i
          const split = i.split("-")
          // equal to 'at' in example above
          newExtention = split[0]
          // equal to 'de' in example above
          // newLang = split[1]
          newLang = i
          break loop
        }
      }
      // this happens when lang is length 2 instead of 5 like 'de' instead of 'at_de'
      if (!newExtention) {
        loop2: for (let i of splitSlug) {
          if (i && i !== "/" && i.length === 2) {
            // in slug /at_de/sortiment, this is equal to 'at_de'
            oldLangExtention = i
            newExtention = "ch"
            newLang = i
            break loop2
          }
        }
      }
      let newUrl = origin + slug
      if (newExtention) {
        // convert https://rivella.ch/de to https://rivella.at/de if user wants to go to https://rivella.ch/at_de
        newUrl = newUrl
          .replace(oldDomainExtention, newExtention)
          .replace(oldLangExtention, newLang)
      }
      objToCast = {
        route: newUrl,
        isInternalLink: false,
      }
    }

    return objToCast
  }

  renderCurrentLang = (imgName, label, isHikingDesign) => {
    return (
      <a className={s.CurrentLang}>
        {isHikingDesign ? null : (
          <span className={s.Flag}>
            <ImgFluid fileName={imgName} />
          </span>
        )}
        {label}
        {isHikingDesign ? <ArrowDown className={s.HikingArrowDown} /> : null}
      </a>
    )
  }

  getLastVisitedHikingRoute = (commonIdentifier, combined) => {
    const {
      location: { pathname },
    } = this.props
    if (commonIdentifier === "hikingIndex") {
      return winAndHikeHeader[combined].logo
    } else if (commonIdentifier === "account") {
      return { uri: "/hiking/account" }
    } else if (commonIdentifier === "hikingTerms") {
      return { uri: winAndHike_routes[combined].teilnahmebedingungen }
    } else if (commonIdentifier === "huts" || commonIdentifier === "partners") {
      const langLength = pathname.split("/")[1].length
      const langSuffix = pathname.slice(langLength + 1)
      return { uri: `${langSuffix}` }
    } else {
      return winAndHikeHeader[combined].leftLinks.find(
        (el) => el.commonIdentifier === commonIdentifier
      )
    }
  }

  render() {
    const { location, className, type, isHikingDesign = false } = this.props
    const commonIdentifier = getCommonIdentifier()
    const langKey =
      getCurrentLanguage(location).langHref || getUrlParameter("lang")
    const currentLangObj = language_code_to_static[langKey]
    let classes = [s.LanguagePicker, className]
    if (type === "footer") {
      classes.push(s.FooterLanguagePicker)
    } else if (type === "header") {
      classes.push(s.HeaderLanguagePicker)
    }
    return (
      <div className={classes.join(" ")}>
        {this.renderCurrentLang(
          currentLangObj.imageName,
          isHikingDesign ? currentLangObj.language : currentLangObj.label,
          isHikingDesign
        )}
        <ul className={s.AllLanguageOptions}>
          {Object.keys(LANGUAGE_ABBREVATIONS)
            .filter(
              (key) =>
                key !== "english" &&
                (!isHikingDesign ||
                  LANGUAGE_ABBREVATIONS[key].combined.length < 3)
            )
            .map((key) => {
              if (isHikingDesign) {
                const { name, slug, combined } = LANGUAGE_ABBREVATIONS[key]
                const visitedLinkBeforeSwitch = this.getLastVisitedHikingRoute(
                  commonIdentifier,
                  combined
                )
                const restOfSlug = winAndHike_routes[combined].gewinnspiel

                return (
                  <li key={name}>
                    <Link
                      to={
                        visitedLinkBeforeSwitch
                          ? `/${slug}${visitedLinkBeforeSwitch.uri}`
                          : `/${slug}${restOfSlug}`
                      }
                      className={s.LangOption}
                      activeClassName={s.ChosenLangOption}
                    >
                      {name}
                    </Link>
                  </li>
                )
              } else {
                const lang =
                  language_code_to_static[LANGUAGE_ABBREVATIONS[key].combined]
                const { route, isInternalLink } =
                  this.constructLanguageSwitchLink(
                    this.props.location,
                    `/${LANGUAGE_ABBREVATIONS[key].slug}`,
                    key
                  )

                if (isInternalLink) {
                  const { slug, combined } = LANGUAGE_ABBREVATIONS[key]

                  const visitedLinkBeforeSwitch =
                    commonIdentifier === "mainIndex"
                      ? mainSiteHeader[combined].logo
                      : mainSiteHeader[combined].leftLinks.find(
                          (el) => el.commonIdentifier === commonIdentifier
                        )

                  return (
                    <li key={lang.label}>
                      <Link
                        to={
                          visitedLinkBeforeSwitch
                            ? `/${slug}${visitedLinkBeforeSwitch.uri}`
                            : `${route}`
                        }
                        className={s.LangOption}
                        activeClassName={s.ChosenLangOption}
                        onClick={() => {
                          document.cookie =
                            "current_lang=; expires=Thu, 01 Jan 1970 00:00:00 UTC"
                          const browserLanguage =
                            LANGUAGE_ABBREVATIONS[key].combined
                          document.cookie =
                            "current_lang=" + browserLanguage + "; path=/"
                        }}
                      >
                        <span className={s.Flag}>
                          <ImgFluid fileName={lang.imageName} />
                        </span>
                        <span>{lang.label}</span>
                      </Link>
                    </li>
                  )
                } else {
                  return (
                    <li key={lang.label}>
                      <a
                        href={`${route}`}
                        className={s.LangOption}
                        activeClassName={s.ChosenLangOption}
                      >
                        <span className={s.Flag}>
                          <ImgFluid fileName={lang.imageName} />
                        </span>
                        {lang.label}
                      </a>
                    </li>
                  )
                }
              }
            })}
        </ul>
      </div>
    )
  }
}

export default LanguagePicker
