require("dotenv").config({
  path: `.env.${process.env.NODE_ENV}`,
})

/**
 * FOR ENVIRONMENT VARIABLE
 * ADD EITHER .env.development
 * OR .env.production
 * TO ROOT OF PROJECT
 */

// exports.SITE_URL = process.env.SITE_URL || "https://blueglass.rivella.net" // DEVELOPMENT FALLBACK

exports.SITE_URL = process.env.SITE_URL || "https://rivella.ch" // PRODUCTION FALLBACK

const WP_API_URL = process.env.WP_API_URL || "rivella-backend.local"
exports.WP_API_URL = WP_API_URL
exports.GOOGLE_MAPS_API_KEY =
  process.env.GOOGLE_MAPS_API_KEY || "AIzaSyDi_pFoT1Xr6JmrsntCWzUEl4vIujUp8XQ" // production

exports.DEPLOY_ENV = process.env.DEPLOY_ENV || "production" // PRODUCTION FALLBACK
exports.SENTRY_DSN = process.env.SENTRY_DSN

// exports.GOOGLE_MAPS_API_KEY =
//   process.env.GOOGLE_MAPS_API_KEY || "AIzaSyCmWC3nmPzItZn7jHogC0jtblkAbGkY5dA" // localhost test

exports.HIKING_API_URL =
  process.env.HIKING_API_URL || "https://rivellaplugins.madebycolorelephant.com"

const LANGUAGE_ABBREVATIONS = {
  english: {
    lang: "en",
    loc: "ch",
    combined: "en",
    slug: "en",
    name: "English",
  },
  german: {
    lang: "de",
    loc: "ch",
    combined: "de",
    slug: "de",
    name: "Deutsch",
  },
  french: {
    lang: "fr",
    loc: "ch",
    combined: "fr",
    slug: "fr",
    name: "Français",
  },
  italian: {
    lang: "it",
    loc: "ch",
    combined: "it",
    slug: "it",
    name: "Italiano",
  },
  france: {
    lang: "fr",
    loc: "fr",
    combined: "fr_fr",
    slug: "fr-fr",
    name: "Français",
  },
  luxembourg: {
    lang: "fr",
    loc: "lu",
    combined: "lu_fr",
    slug: "lu-fr",
    name: "Français",
  },
}

exports.LANGUAGE_ABBREVATIONS = LANGUAGE_ABBREVATIONS

exports.FILTER_TITLE = {
  [LANGUAGE_ABBREVATIONS.german.combined]: {
    title: "Event Filtern",
  },
  [LANGUAGE_ABBREVATIONS.french.combined]: {
    title: "Filtrer les événements",
  },
}

exports.POP_UP_TEXT = {
  [LANGUAGE_ABBREVATIONS.german.combined]: {
    text: "Bitte beachte, dass Internet Explorer nicht unterstützt wird. Wir empfehlen dir, einen der folgenden Browser zu nutzen (idealerweise mit Versionsangaben):",
    suffix: "Danke für die Kenntnisnahme",
  },
  [LANGUAGE_ABBREVATIONS.italian.combined]: {
    text: "Si prega di notare che Internet Explorer non è supportato. Si consiglia di utilizzare uno dei seguenti browser:",
    suffix: "Grazie per averlo notato.",
  },
  [LANGUAGE_ABBREVATIONS.french.combined]: {
    text: "Merci de noter que l’internet explorer n’est pas pris en charge. Nous te recommandons d'utiliser l'un des navigateurs suivants:",
    suffix: "Merci de l'avoir remarqué.",
  },
  [LANGUAGE_ABBREVATIONS.france.combined]: {
    text: "Merci de noter que l’internet explorer n’est pas pris en charge. Nous te recommandons d'utiliser l'un des navigateurs suivants:",
    suffix: "Merci de l'avoir remarqué.",
  },
}

exports.supported_browsers_list = [
  "Google Chrome",
  "Mozilla Firefox",
  "Microsoft Edge",
  "Safari",
]

exports.language_code_to_static = {
  [LANGUAGE_ABBREVATIONS.english.combined]: {
    label: "English",
    imageName: "uk.png",
    language: "English",
  },
  [LANGUAGE_ABBREVATIONS.german.combined]: {
    label: "Schweiz",
    imageName: "sw.png",
    language: "Deutsch",
  },
  [LANGUAGE_ABBREVATIONS.french.combined]: {
    label: "Suisse",
    imageName: "sw.png",
    language: "Français",
  },
  [LANGUAGE_ABBREVATIONS.italian.combined]: {
    label: "Svizzera",
    imageName: "sw.png",
    language: "Italiano",
  },
  [LANGUAGE_ABBREVATIONS.france.combined]: {
    label: "France",
    imageName: "fr.png",
    language: "Français",
  },
  [LANGUAGE_ABBREVATIONS.luxembourg.combined]: {
    label: "Luxembourg",
    imageName: "lu.png",
    language: "Français",
  },
}

exports.product_range_route = {
  [LANGUAGE_ABBREVATIONS.english.combined]: {
    slug: "product-range",
    title: "Our Product Range",
    subtitle: "What colour is your thirst?",
  },
  [LANGUAGE_ABBREVATIONS.german.combined]: {
    slug: "sortiment",
    title: "Unser Sortiment",
    subtitle: "Welche Farbe hat dein Durst?",
  },
  [LANGUAGE_ABBREVATIONS.french.combined]: {
    slug: "assortiment",
    title: "Assortiment",
    subtitle: "De quelle couleur est votre soif?",
  },
  [LANGUAGE_ABBREVATIONS.italian.combined]: {
    slug: "gamma-di-prodotti",
    title: "Assortimento",
    subtitle: "Che colore ha la tua sete?",
  },
  [LANGUAGE_ABBREVATIONS.france.combined]: {
    slug: "assortiment",
    title: "Assortiment",
    subtitle: "De quelle couleur est votre soif?",
  },
  [LANGUAGE_ABBREVATIONS.luxembourg.combined]: {
    slug: "assortiment",
    title: "Assortiment",
    subtitle: "De quelle couleur est votre soif?",
  },
}

exports.learn_more = {
  [LANGUAGE_ABBREVATIONS.english.combined]: "Learn more",
  [LANGUAGE_ABBREVATIONS.german.combined]: "Mehr Erfahren",
  [LANGUAGE_ABBREVATIONS.french.combined]: "Apprendre Encore Plus",
  [LANGUAGE_ABBREVATIONS.italian.combined]: "Per Saperne Di Più",
  [LANGUAGE_ABBREVATIONS.france.combined]: "Apprendre Encore Plus",
  [LANGUAGE_ABBREVATIONS.luxembourg.combined]: "Apprendre Encore Plus",
}
exports.learn_more_alternate = {
  [LANGUAGE_ABBREVATIONS.english.combined]: "Learn more",
  [LANGUAGE_ABBREVATIONS.german.combined]: "Mehr Erfahren",
  [LANGUAGE_ABBREVATIONS.french.combined]: "Participe maintenant",
  [LANGUAGE_ABBREVATIONS.italian.combined]: "Per Saperne Di Più",
  [LANGUAGE_ABBREVATIONS.france.combined]: "Participe maintenant",
  [LANGUAGE_ABBREVATIONS.luxembourg.combined]: "Participe maintenant",
}
exports.learn_more_third_alternate = {
  [LANGUAGE_ABBREVATIONS.english.combined]: "Learn more",
  [LANGUAGE_ABBREVATIONS.german.combined]: "Mehr Erfahren",
  [LANGUAGE_ABBREVATIONS.french.combined]: "Détails",
  [LANGUAGE_ABBREVATIONS.italian.combined]: "Per Saperne Di Più",
  [LANGUAGE_ABBREVATIONS.france.combined]: "Détails",
  [LANGUAGE_ABBREVATIONS.luxembourg.combined]: "Détails",
}
exports.for_sampling = {
  [LANGUAGE_ABBREVATIONS.english.combined]: "For Sampling",
  [LANGUAGE_ABBREVATIONS.german.combined]: "Zum Event",
  [LANGUAGE_ABBREVATIONS.french.combined]: "À l'événement",
  [LANGUAGE_ABBREVATIONS.italian.combined]: "Zum Event",
  [LANGUAGE_ABBREVATIONS.france.combined]: "À l'événement",
  [LANGUAGE_ABBREVATIONS.luxembourg.combined]: "À l'événement",
}

exports.starts_in = {
  [LANGUAGE_ABBREVATIONS.english.combined]: "Starts in",
  [LANGUAGE_ABBREVATIONS.german.combined]: "Startet In",
  [LANGUAGE_ABBREVATIONS.french.combined]: "Commence Dans",
  [LANGUAGE_ABBREVATIONS.italian.combined]: "Inizio Tra",
  [LANGUAGE_ABBREVATIONS.france.combined]: "Commence Dans",
  [LANGUAGE_ABBREVATIONS.luxembourg.combined]: "Commence Dans",
}

exports.andWordTranslation = {
  [LANGUAGE_ABBREVATIONS.german.lang]: "und",
  [LANGUAGE_ABBREVATIONS.french.lang]: "et",
  [LANGUAGE_ABBREVATIONS.italian.lang]: "e",
}

exports.ROUTING_ANIMATION_FADEIN_LENGTH = 400
exports.ROUTING_ANIMATION_FADEOUT_LENGTH = 800

exports.SITE_SCOPES = {
  main: "main",
  winAndHike: "winAndHike",
}

// exports.winterPromotionRoutes = {
//   [LANGUAGE_ABBREVATIONS.german.combined]: {
//     winterPromotionRoute: "/promotion/winterpromotion",
//     termsConditionsRoute: "/promotion/winterpromotion/tnb",
//   },
//   [LANGUAGE_ABBREVATIONS.french.combined]: {
//     winterPromotionRoute: "/promotion/promotion-dhiver",
//     termsConditionsRoute: "/promotion/promotion-dhiver/tnb",
//   },
//   [LANGUAGE_ABBREVATIONS.france.combined]: {
//     winterPromotionRoute: "/promotion/promotion-new",
//     termsConditionsRoute: "/promotion/promotion-new/tnb",
//   },
//   [LANGUAGE_ABBREVATIONS.luxembourg.combined]: {
//     winterPromotionRoute: "/promotion/promotion-new",
//     termsConditionsRoute: "/promotion/promotion-new/tnb",
//   },
// };

exports.customTermsAndConditionsPage = {
  [LANGUAGE_ABBREVATIONS.german.combined]: {
    termsConditionsRoute: "/tnb",
  },
  [LANGUAGE_ABBREVATIONS.french.combined]: {
    termsConditionsRoute: "/cdp",
  },
  [LANGUAGE_ABBREVATIONS.france.combined]: {
    termsConditionsRoute: "/cdp",
  },
  [LANGUAGE_ABBREVATIONS.luxembourg.combined]: {
    termsConditionsRoute: "/cdp",
  },
}
exports.customCheckoutPage = {
  [LANGUAGE_ABBREVATIONS.german.slug]: {
    checkoutRoute: "/checkout",
  },
  [LANGUAGE_ABBREVATIONS.french.slug]: {
    checkoutRoute: "/checkout",
  },
  [LANGUAGE_ABBREVATIONS.italian.slug]: {
    checkoutRoute: "/checkout",
  },
}
exports.customNewsletterPage = {
  [LANGUAGE_ABBREVATIONS.german.slug]: {
    formRoute: "/newsletter",
  },
  [LANGUAGE_ABBREVATIONS.french.slug]: {
    formRoute: "/newsletter",
  },
  [LANGUAGE_ABBREVATIONS.italian.slug]: {
    formRoute: "/newsletter",
  },
}

exports.winAndHike_routes = {
  [LANGUAGE_ABBREVATIONS.german.combined]: {
    startseite: `/hiking/startseite`,
    gewinnspiel: `/promotion/wanderpromotion`,
    sofortpreis: `/hiking/sofortpreis`,
    hauptpreise: `/hiking/hauptpreise`,
    overview: "/hiking/partnerregionen",
    partnerdestinationen: `/hiking/partners`,
    fragen: `/hiking/fragen`,
    impressionen: `/hiking/impressionen`,
    login: `/hiking/login`,
    huts: `/hiking/huts`,
    teilnahmebedingungen: `/hiking/teilnahmebedingungen`,
    account: `/hiking/account`,
  },
  [LANGUAGE_ABBREVATIONS.french.combined]: {
    startseite: `/hiking/startseite`,
    gewinnspiel: `/promotion/promotion-de-randonnee`,
    sofortpreis: `/hiking/prix-immediat`,
    hauptpreise: `/hiking/prix-principaux`,
    overview: "/hiking/region-partenaire",
    partnerdestinationen: `/hiking/partners`,
    fragen: `/hiking/questions`,
    impressionen: `/hiking/impression`,
    login: `/hiking/login`,
    huts: `/hiking/huts`,
    teilnahmebedingungen: `/hiking/conditionsparticipation`,
    account: `/hiking/account`,
  },

  [LANGUAGE_ABBREVATIONS.italian.combined]: {
    startseite: `/hiking/startseite`,
    gewinnspiel: "/promozione/promozione-a-piedi",
    sofortpreis: `/hiking/premio-immediato`,
    hauptpreise: `/hiking/premi-principali`,
    overview: "/hiking/regione-partner",
    partnerdestinationen: `/hiking/partners`,
    fragen: `/hiking/domande`,
    impressionen: `/hiking/impressioni`,
    login: `/hiking/login`,
    huts: `/hiking/huts`,
    teilnahmebedingungen: `/hiking/condizionipartecipazione`,
    account: `/hiking/account`,
  },
}

exports.langSpecificEventPrefix = {
  [LANGUAGE_ABBREVATIONS.german.slug]: {
    event: "events",
    news: "news",
    contest: "wettbewerbe",
  },
  [LANGUAGE_ABBREVATIONS.french.slug]: {
    event: "evenements",
    news: "nouvelles",
    contest: "concours",
  },
  [LANGUAGE_ABBREVATIONS.italian.slug]: {
    event: "events",
    news: "news",
    contest: "wettbewerbe",
  },
  [LANGUAGE_ABBREVATIONS.france.slug]: {
    event: "evenements",
    news: "nouvelles",
    contest: "concours",
  },
  [LANGUAGE_ABBREVATIONS.luxembourg.slug]: {
    event: "evenements",
    news: "nouvelles",
    contest: "concours",
  },
}

exports.newsletter_languages = ["de", "fr", "it"]

exports.MAX_NUM_SUBMISSIONS = 15048
