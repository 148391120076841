import React from "react"
import Slider from "react-slick"

import { extractImageNameFromUrl } from "../../helper"
import ImgFluid from "../Img/ImgFluid"
import s from "./SkewedCarouselHero.module.scss"

class SkewedCarouselHero extends React.Component {
  constructor(props) {
    super(props)
    this.slider = React.createRef()
  }
  render() {
    const { children, cn = "", imagesData } = this.props
    const slickClasses = []
    return (
      <section className={`${s.skewed_carousel_hero} ${cn}`}>
        <div className={s.skewed_hero_inner}>
          <Slider
            dots
            ref={this.slider}
            speed={400}
            slidesToScroll={1}
            slidesToShow={1}
            infinite={true}
            centerMode
            className={slickClasses.join(" ")}
            cssEase="ease-out"
            dotsClass={["slick-dots", s.Dots].join(" ")}
          >
            {imagesData.map((imgData, i) => (
              <div className={s.ImageWrapper} key={i}>
                <ImgFluid
                  cn={s.Image}
                  loading="eager"
                  fadeIn={false}
                  fileName={extractImageNameFromUrl(imgData.link)}
                  // alt={imgAlt}
                  // itemProp={itemProp}
                />
              </div>
            ))}
          </Slider>
          {/* {imgFilename && (
            <ImgFluid
              cn={s.Image}
              loading="eager"
              fadeIn={false}
              fileName={imgFilename}
              alt={imgAlt}
              itemProp={itemProp}
            />
          )}
          {imgLink && (
            <img
              className={s.Image}
              src={imgLink}
              alt={imgAlt}
              itemProp={itemProp}
            />
          )} */}
        </div>
        <div className={s.WhiteCorner}></div>
        {children}
      </section>
    )
  }
}

export default SkewedCarouselHero
