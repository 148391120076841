import React from "react"
import s from "./AboutUsBottleSliderSection.module.scss"

import { AboutUsBottleSlider } from "../../components"

class AboutUsBottleSliderSection extends React.Component {
  render() {
    const { props } = this
    return (
      <section
        className={s.carousel_wrapper}
        style={{ zIndex: this.props.sectionZindex }}
        ref={this.props.sectionRef}
        id="exists-since"
      >
        <section className={s.image_carousel}>
          <AboutUsBottleSlider {...props} />
        </section>
      </section>
    )
  }
}

export default AboutUsBottleSliderSection
