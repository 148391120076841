import React from "react"
import Helmet from "react-helmet"

import s from "./GeneralPromotionHero.module.scss"
import { SkewedHero } from "../../../components"
import { extractImageNameFromUrl, getCurrentLanguage } from "../../../helper"
import CircleBanner from "./CircleBanner"
import RectangleBanner from "./RectangleBanner"

class GeneralPromotionHero extends React.Component {
  render() {
    const {
      title_one_text,
      title_one_color,
      background_img,
      hide_banner,
      banner_is_hidden,
      button_link,
      button_one_text,
      banner_is_circle,
      location,
      className
    } = this.props

    const currentLang = getCurrentLanguage(location)

    let toLink = button_link;

    if(toLink && !(toLink.startsWith("#") || toLink.startsWith("http") || toLink.startsWith("/"))) {
      toLink = "/" + currentLang.slug + "/" + toLink;
    } else if(toLink && toLink.startsWith("/")) {
      toLink = "/" + currentLang.slug + toLink;
    }

    return (
      <section
        className={`${s.HeroWrapper} ${s[this.props.className]}`}
        style={{ zIndex: this.props.sectionZindex }}
        ref={this.props.sectionRef}
      >
        <SkewedHero
          imgFilename={extractImageNameFromUrl(background_img)}
          imgAlt={title_one_text}
          cn={s.SkewedHeroWrapper}
        >
          <h1
            className={[s.MainTitle, title_one_color].join(" ")}
            dangerouslySetInnerHTML={{ __html: title_one_text }}
          ></h1>
          <Helmet>
            <meta property="og:description" content={title_one_text} />
          </Helmet>
          {hide_banner || banner_is_hidden ? null : ( banner_is_circle ?
            <CircleBanner
              currentLang={currentLang}
              button_link={toLink}
              button_one_text={button_one_text}
            /> : <RectangleBanner
            currentLang={currentLang}
            button_link={toLink}
            button_one_text={button_one_text}
          />
          )}
          {/* )} */}
        </SkewedHero>
      </section>
    )
  }
}

export default GeneralPromotionHero
