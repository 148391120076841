import React from "react"
import Helmet from "react-helmet"

import { SkewedSectionWithBg, SkewedCard } from "../../components"
import { ChildrenButtonRenderer } from "../"
import s from "./AboutUsBlockText.module.scss"
import { getPosition } from "../../helper"

class AboutUsBlockText extends React.Component {
  componentDidMount() {
  }
  componentWillUnmount() {
  }

  render() {
    const {
      title,
      description,
    } = this.props

    return (
      <section
        className={`${s.section_wrapper}`}
        style={{
          zIndex: this.props.sectionZindex,
        }}
        ref={this.props.sectionRef}
      >
            <h4
              ref={title}
              className={`${s.section_title}`}
              dangerouslySetInnerHTML={{ __html: title }}
            ></h4>
            <p
              className={[
                s.section_description,
              ].join(" ")}
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
      </section>
    )
  }
}

export default AboutUsBlockText
