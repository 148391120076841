import React from "react"
import s from "./WinAndHikeFooter.module.scss"
import { WinAndHikeFooterAnimation } from "../"

class WinAndHikeFooter extends React.Component {
  render() {
    return (
      <section className={s.hiking_animation}>
        <div className={s.footer_animation_wrapper}>
          <WinAndHikeFooterAnimation className={s.footer_animation} />
        </div>
      </section>
    )
  }
}

export default WinAndHikeFooter
