import React from "react"
import InputMask from "react-input-mask"

import { Button, ImgFluid } from "../../components"
import s from "./WinterPromotionForm.module.scss"
import {
  getCurrentLanguage,
  confirmWinterpromotionEntry,
  extractImageNameFromUrl,
  sanitizeEmojisInText,
  scrollToRef,
  submitWinterpromotion,
} from "../../helper"
import { validateSwissPostCode } from "../../util/validateSwissPostCode"

class WinterPromotionForm extends React.Component {
  constructor(props) {
    super(props)
    this.formFieldRef = React.createRef()
    this.formRef = React.createRef()
    this.salutationOfUserRef = React.createRef()
    this.titleOfUserRef = React.createRef()
    this.state = {
      modalIsOpen: false,
      formIsDisabled: false,
      submitDisabled: false,
      fieldsAreValid: true,
      showForm: false,
      confirmMessage: false,
      displayAnswerWrong: false,
      displayAnswerCorrectYouWin: false,
      displayEmailUsed: false,
      displayAnswerCorrectYouDidntWin: false,
      answerCorrectYouWinMessage: null,
      answerCorrectYouWinImage: null,
      // thisAddressGotItAlready: false,
      formData: {
        // Multiple Choice
        multipleChoise: {
          name: "answer",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        email: {
          name: "email",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        salutationOfUser: {
          name: "salutation",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        // titleOfUser: {
        //   name: "title",
        //   value: "",
        //   ref: React.createRef(),
        // },
        nameOfUser: {
          name: "surname",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        surnameOfUser: {
          name: "name",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        phone_no: {
          name: "phone_no",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        streetOfUser: {
          name: "street",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        houseNoOfUser: {
          name: "number",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        postcodeOfUser: {
          name: "zip",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        townOfUser: {
          name: "city",
          value: "",
          required: true,
          ref: React.createRef(),
        },
        newsletterSignup: {
          name: "newsletter",
          value: false,
          ref: React.createRef(),
        },
        termsAndConditions: {
          name: "accepted",
          value: false,
          required: true,
          ref: React.createRef(),
        },
      },
    }
  }

  componentDidMount() {
    if (this.props.location.search.split("=")[0] === "?confirm") {
      this.confirmEntry()
    }
  }

  setFormDisabled = () => {
    this.setState({
      formIsDisabled: true,
    })
  }

  renderRequiredMessage = () => {
    const messagesMap = {
      de: "Dieses Feld ist ein Pflichtfeld",
      fr: "Ce champ est obligatoire",
    }
    const lang = getCurrentLanguage(this.props.location).lang
    const message = messagesMap[lang]
    return <p className={s.RequiredMessage}>{message}</p>
  }

  onSubmit = (e) => {
    if (e) e.preventDefault()
    if (this.state.submitDisabled) return
    const isValid = this.validateFields()
    this.setState({ fieldsAreValid: isValid })

    if (isValid) this.submitForm()
  }

  scrollToConfirmationMsg = () => {
    const { current: formField } = this.formFieldRef
    if (formField) {
      window.scroll(0, formField.offsetTop - 300)
    }
  }

  submitForm = async () => {
    this.setState(
      {
        submitDisabled: true,
        confirmMessage: false,
      },
      () => {
        // in case an uncaught error happens, reenable button back after 5 seconds
        setTimeout(() => {
          if (this.state.submitDisabled) {
            this.setState({
              submitDisabled: false,
            })
          }
        }, 5000)
      }
    )
    const { formData } = this.state
    let body = {}
    body.lang = getCurrentLanguage(this.props.location).lang
    body.user_agent = navigator && navigator.userAgent
    body.form_id = this.props.form_id
    Object.keys(formData).forEach((key) => {
      const entry = formData[key]
      if (key === "phone_no") {
        const sanitizedPhone = entry.value.replace(/\D/gm, "")
        body["phone_number"] = sanitizedPhone
      } else {
        body[entry.name] = entry.value
      }
    })

    submitWinterpromotion(JSON.stringify(body)).then((res) => {
      if (res) {
        this.scrollToConfirmationMsg()

        if (res.result === "success") {
          const { prizes, answerCorrectYouWin, answerCorrectYouWinSkiPass } =
            this.props
          const { ski_pass, bottle_pack, sledge } = prizes
          const won_prize_key = res.prize

          let won_image_name = null
          let message = answerCorrectYouWin
          if (won_prize_key === "slide") {
            message = message.replace("{{prize_name}}", sledge.name)
            won_image_name = sledge.img
          } else if (won_prize_key === "bottles") {
            message = message.replace("{{prize_name}}", bottle_pack.name)
            won_image_name = bottle_pack.img
          } else {
            // key == "pass" or unrecognized
            message = answerCorrectYouWinSkiPass
            won_image_name = ski_pass.img
          }
          this.setState({
            submitDisabled: true,
            showForm: false,
            displayAnswerCorrectYouWin: true,
            answerCorrectYouWinMessage: message,
            answerCorrectYouWinImage: won_image_name,
            displayAnswerCorrectYouDidntWin: false,
          })
        } else if (res.result === "repeat_email") {
          this.setState({
            submitDisabled: true,
            showForm: false,
            displayEmailUsed: true,
          })
        } else if (res.result === "right_but_no_win") {
          this.setState({
            submitDisabled: true,
            showForm: false,
            displayAnswerCorrectYouDidntWin: true,
            displayAnswerCorrectYouWin: false,
          })
        } else if (res.result === "wrong" || res.result === "error") {
          this.setState({
            submitDisabled: true,
            showForm: false,
            displayAnswerWrong: true,
          })
        }
      } else {
        this.setState({
          submitDisabled: false,
        })
      }
    })
  }

  confirmEntry = async () => {
    let body = {}
    body.hash = this.props.location.search.split("=")[1]
    this.scrollToConfirmationMsg()
    confirmWinterpromotionEntry(JSON.stringify(body)).then((res) => {
      if (res) {
        if (res.result !== "error" && res.code !== "rest_no_route") {
          this.setState({
            confirmMessage: true,
            showForm: false,
          })
          this.scrollToConfirmationMsg()
        } else {
          this.setState({
            confirmMessage: false,
            showForm: false,
          })
        }
      }
    })
  }

  setSelectVisible = (to, name) => {
    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        [name]: {
          ...this.state.formData[name],
          visible: to,
        },
      },
    })
  }

  onSelectChange = (value, name, refToBlur) => {
    const formValue = value
    this.setState(
      {
        ...this.state,
        formData: {
          ...this.state.formData,
          [name]: {
            ...this.state.formData[name],
            value: formValue,
            visible: false,
            warnRequired: false,
          },
        },
      },
      () => {
        if (refToBlur && refToBlur.current) {
          refToBlur.current.blur()
        }
      }
    )
  }

  onInputChange = (
    v,
    name,
    { numberOnly, sanitizeEmojis, phoneValid } = {}
  ) => {
    let value = v
    if (numberOnly) {
      if (isNaN(value.replace(/ /gm, ""))) return
    }
    if (sanitizeEmojis) {
      value = sanitizeEmojisInText(value)
    }
    if (phoneValid) {
      // eslint-disable-next-line no-useless-escape
      const isValidRegex = /[\d| |\(|\)]*/gm
      const isValid = isValidRegex.test(value)
      if (!isValid) return
    }

    this.setState({
      ...this.state,
      displaySuccess: false,
      formData: {
        ...this.state.formData,
        [name]: {
          ...this.state.formData[name],
          value,
          warnRequired: false,
        },
      },
    })
  }

  validateField = (fieldObj) => {
    const fieldValue =
      typeof fieldObj.value === "string"
        ? `${fieldObj.value}`.trim()
        : fieldObj.value
    if (
      (fieldObj.required && !fieldValue) ||
      (fieldObj.name === "email" && !fieldValue.includes("@")) ||
      (fieldObj.name === "phone_no" &&
        fieldValue.toString().replace(/\D/gm, "").length < 10)
    ) {
      fieldObj.warnRequired = true
      return false
    }
    if (
      fieldObj.name === "zip" &&
      (this.props.isFranceMode
        ? String(fieldValue).length !== 5
        : !validateSwissPostCode(fieldValue))
    ) {
      fieldObj.warnRequired = true
      return false
    }
    return true
  }

  validateFields = () => {
    let formDataCopied = { ...this.state.formData }
    let isValid = true

    Object.keys(formDataCopied).forEach((key) => {
      const nextIsValid = this.validateField(formDataCopied[key])
      if (!nextIsValid && isValid) {
        scrollToRef(formDataCopied[key].ref, {
          behavior: "smooth",
          block: "center",
        })
      }
      isValid = nextIsValid && isValid
    })

    if (!isValid) {
      this.setState({
        formData: formDataCopied,
      })
    }

    return isValid
  }

  // the mouse over event bugs the tablet/mobile, so we only want mouseover
  // working when it's laptop or above
  selectMouseEventConstructor = (to, name) => {
    if (typeof window !== "undefined" && window.innerWidth > 1300)
      return this.setSelectVisible(to, name)
    return function () {}
  }

  renderForm = () => {
    const { form_title, isFranceMode } = this.props
    const { user, checkboxes, submit } = this.props.formFieldsInfo
    const { formData } = this.state
    return (
      <form onSubmit={this.onSubmit} className={s.Form} ref={this.formRef}>
        <h3 className={s.FormTitle}>{form_title}</h3>
        <div className={s.Row}>
          <div className={s.Col_2}>
            <div className={s.Row}>
              <div className={s.FormGroup} ref={formData.email.ref}>
                <label>{user.email.placeholder}</label>
                <input
                  className={[
                    s.Medium,
                    formData.email.warnRequired ? s.WarnRequired : null,
                  ].join(" ")}
                  value={formData.email.value}
                  name={formData.email.name}
                  onChange={(e) => this.onInputChange(e.target.value, "email")}
                  required={formData.email.required}
                />
                {formData.email.warnRequired && !formData.email.value
                  ? this.renderRequiredMessage()
                  : null}
              </div>
            </div>
            <div className={`${s.Row}`}>
              {/* <div className={`${s.Row} ${s.TwoColumns}`}> */}
              <div className={s.FormGroup} ref={formData.salutationOfUser.ref}>
                <label>{user.mr_mrs.placeholder}</label>
                <div
                  tabIndex={0}
                  ref={this.salutationOfUserRef}
                  className={[
                    s.Select,
                    s.Small,
                    formData.salutationOfUser.warnRequired
                      ? s.WarnRequired
                      : null,
                    formData.salutationOfUser.visible ? s.SelectVisible : null,
                  ].join(" ")}
                  onMouseEnter={() =>
                    this.selectMouseEventConstructor(true, "salutationOfUser")
                  }
                  onMouseLeave={() =>
                    this.selectMouseEventConstructor(false, "salutationOfUser")
                  }
                  onFocus={() =>
                    this.setSelectVisible(true, "salutationOfUser")
                  }
                  onBlur={() =>
                    this.setSelectVisible(false, "salutationOfUser")
                  }
                >
                  <input
                    disabled
                    value={formData.salutationOfUser.value}
                    name={formData.salutationOfUser.name}
                    required={formData.salutationOfUser.required}
                    placeholder={user.mr_mrs.placeholder}
                  />
                  <ul>
                    <li
                      className={
                        formData.salutationOfUser.value === user.mr_mrs.mr.value
                          ? s.Selected
                          : null
                      }
                      onClick={() =>
                        this.onSelectChange(
                          user.mr_mrs.mr.value,
                          "salutationOfUser",
                          this.salutationOfUserRef
                        )
                      }
                    >
                      {user.mr_mrs.mr.label}
                    </li>
                    <li
                      className={
                        formData.salutationOfUser.value ===
                        user.mr_mrs.mrs.value
                          ? s.Selected
                          : null
                      }
                      onClick={() =>
                        this.onSelectChange(
                          user.mr_mrs.mrs.value,
                          "salutationOfUser",
                          this.salutationOfUserRef
                        )
                      }
                    >
                      {user.mr_mrs.mrs.label}
                    </li>
                  </ul>
                </div>
                {formData.salutationOfUser.warnRequired &&
                !formData.salutationOfUser.value
                  ? this.renderRequiredMessage()
                  : null}
              </div>
            </div>
            <div className={s.Row}>
              <div className={s.FormGroup} ref={formData.nameOfUser.ref}>
                <label>{user.name.placeholder}</label>
                <input
                  className={[
                    s.Medium,
                    formData.nameOfUser.warnRequired ? s.WarnRequired : null,
                  ].join(" ")}
                  value={formData.nameOfUser.value}
                  name={formData.nameOfUser.name}
                  onChange={(e) =>
                    this.onInputChange(e.target.value, "nameOfUser")
                  }
                  required={formData.nameOfUser.required}
                />
                {formData.nameOfUser.warnRequired && !formData.nameOfUser.value
                  ? this.renderRequiredMessage()
                  : null}
              </div>
            </div>
            <div className={s.Row}>
              <div className={s.FormGroup} ref={formData.surnameOfUser.ref}>
                <label>{user.surname.placeholder}</label>
                <input
                  className={[
                    s.Medium,
                    formData.surnameOfUser.warnRequired ? s.WarnRequired : null,
                  ].join(" ")}
                  value={formData.surnameOfUser.value}
                  name={formData.surnameOfUser.name}
                  onChange={(e) =>
                    this.onInputChange(e.target.value, "surnameOfUser")
                  }
                  required={formData.surnameOfUser.required}
                />
                {formData.surnameOfUser.warnRequired &&
                !formData.surnameOfUser.value
                  ? this.renderRequiredMessage()
                  : null}
              </div>
            </div>
            <div className={`${s.Row} ${s.TwoColumns}`}>
              <div
                className={`${s.FormGroup} ${s.Medium}`}
                ref={formData.streetOfUser.ref}
              >
                <label>{user.street.placeholder}</label>
                <input
                  className={[
                    s.Medium,
                    formData.streetOfUser.warnRequired ? s.WarnRequired : null,
                  ].join(" ")}
                  value={formData.streetOfUser.value}
                  name={formData.streetOfUser.name}
                  onChange={(e) =>
                    this.onInputChange(e.target.value, "streetOfUser")
                  }
                  required={formData.streetOfUser.required}
                />
                {formData.streetOfUser.warnRequired &&
                !formData.streetOfUser.value
                  ? this.renderRequiredMessage()
                  : null}
              </div>
              <div
                className={`${s.FormGroup} ${s.Small}`}
                ref={formData.houseNoOfUser.ref}
              >
                <label>{user.house_no.placeholder}</label>
                <input
                  className={[
                    s.Small,
                    formData.houseNoOfUser.warnRequired ? s.WarnRequired : null,
                  ].join(" ")}
                  value={formData.houseNoOfUser.value}
                  name={formData.houseNoOfUser.name}
                  onChange={(e) =>
                    this.onInputChange(e.target.value, "houseNoOfUser")
                  }
                  required={formData.houseNoOfUser.required}
                />
                {formData.houseNoOfUser.warnRequired &&
                !formData.houseNoOfUser.value
                  ? this.renderRequiredMessage()
                  : null}
              </div>
            </div>
            <div className={`${s.Row} ${s.TwoColumns}`}>
              <div
                className={`${s.FormGroup} ${s.Small}`}
                ref={formData.postcodeOfUser.ref}
              >
                <label>{user.postal_code.placeholder}</label>
                <input
                  className={[
                    s.Small,
                    formData.postcodeOfUser.warnRequired
                      ? s.WarnRequired
                      : null,
                  ].join(" ")}
                  value={formData.postcodeOfUser.value}
                  name={formData.postcodeOfUser.name}
                  onChange={(e) =>
                    this.onInputChange(e.target.value, "postcodeOfUser", {
                      numberOnly: true,
                    })
                  }
                  maxLength={isFranceMode ? 5 : 4}
                  minLength={isFranceMode ? 5 : 4}
                  required={formData.postcodeOfUser.required}
                />
                {formData.postcodeOfUser.warnRequired &&
                !formData.postcodeOfUser.value
                  ? this.renderRequiredMessage()
                  : null}
              </div>
              <div
                className={`${s.FormGroup} ${s.Medium}`}
                ref={formData.townOfUser.ref}
              >
                <label>{user.town.placeholder}</label>
                <input
                  className={[
                    s.Medium,
                    formData.townOfUser.warnRequired ? s.WarnRequired : null,
                  ].join(" ")}
                  value={formData.townOfUser.value}
                  name={formData.townOfUser.name}
                  onChange={(e) =>
                    this.onInputChange(e.target.value, "townOfUser")
                  }
                  required={formData.townOfUser.required}
                />
                {formData.townOfUser.warnRequired && !formData.townOfUser.value
                  ? this.renderRequiredMessage()
                  : null}
              </div>
            </div>
            <div className={s.Row}>
              <div className={s.FormGroup} ref={formData.phone_no.ref}>
                <label>{user.phone_no.placeholder}</label>
                <InputMask
                  data-error={formData.phone_no.warnRequired}
                  className={[
                    s.Medium,
                    formData.phone_no.warnRequired ? s.WarnRequired : null,
                  ].join(" ")}
                  mask={isFranceMode ? "99 99 99 99 99" : "999 999 99 99"}
                  placeholder={
                    isFranceMode ? "07 91 11 22 33" : "079 111 22 33"
                  }
                  onChange={(e) =>
                    this.onInputChange(e.target.value, "phone_no", {
                      phoneValid: true,
                    })
                  }
                  value={formData.phone_no.value}
                  name={formData.phone_no.name}
                  inputMode="tel"
                />
                {formData.phone_no.warnRequired && !formData.phone_no.value
                  ? this.renderRequiredMessage()
                  : null}
              </div>
            </div>
          </div>
        </div>
        {!isFranceMode ? (
          <div
            className={[
              s.Row,
              s.RowWithPadding,
              formData.newsletterSignup.warnRequired ? s.WarnRequired : null,
            ].join(" ")}
            ref={formData.newsletterSignup.ref}
          >
            <input
              type="checkbox"
              className={s.Checkbox}
              checked={formData.newsletterSignup.value}
              name={formData.newsletterSignup.name}
              id={formData.newsletterSignup.name}
              onChange={() =>
                this.onInputChange(
                  !formData.newsletterSignup.value,
                  "newsletterSignup"
                )
              }
              required={formData.newsletterSignup.required}
            />
            <label
              className={s.Label}
              htmlFor={formData.newsletterSignup.name}
              dangerouslySetInnerHTML={{
                __html: checkboxes.newsletter.placeholder,
              }}
            />
          </div>
        ) : null}
        <div
          className={[
            s.Row,
            s.RowWithPadding,
            formData.termsAndConditions.warnRequired ? s.WarnRequired : null,
          ].join(" ")}
          ref={formData.termsAndConditions.ref}
        >
          <input
            type="checkbox"
            className={s.Checkbox}
            checked={formData.termsAndConditions.value}
            name={formData.termsAndConditions.name}
            id={formData.termsAndConditions.name}
            onChange={() =>
              this.onInputChange(
                !formData.termsAndConditions.value,
                "termsAndConditions"
              )
            }
            required={formData.termsAndConditions.required}
          />
          <span className={s.RequiredLabelAsterisk}>*</span>
          <label
            className={s.Label}
            htmlFor={formData.termsAndConditions.name}
            dangerouslySetInnerHTML={{
              __html: checkboxes.termsAndContidition.placeholder(
                this.props.terms_link
              ),
            }}
          />
        </div>
        <div className={`${s.Row} ${s.FormFooter}`}>
          <Button
            type="primary"
            onClick={this.onSubmit}
            btnClassName={s.SubmitButton}
            disabled={this.state.submitDisabled}
          >
            {submit.placeholder}
          </Button>
        </div>
      </form>
    )
  }

  onMultipleSelectChange = (v, name) => {
    let value = v
    const prevValue = this.state.formData[name].value

    // if (!prevValue) {
    this.setState(
      {
        ...this.state,
        displaySuccess: false,
        showForm: true,
        formData: {
          ...this.state.formData,
          [name]: {
            ...this.state.formData[name],
            value,
            warnRequired: false,
          },
        },
      },
      () => {
        if (!prevValue) {
          setTimeout(() => {
            scrollToRef(this.formRef)
          }, 50)
        }
      }
    )
    // }
  }

  renderMultipleSelectionOption = (options) => {
    if (!Array.isArray(options)) return null

    return (
      <div className={s.MultipleSelectionOptionsWrapper}>
        {options.map((o) => {
          let buttonClassname = [s.OptionButton]
          if (this.state.formData.multipleChoise.value) {
            if (o.value === this.state.formData.multipleChoise.value) {
              buttonClassname.push(s.OptionButtonActive)
            }
            // else {
            //   buttonClassname.push(s.OptionButtonDeactivated)
            // }
          }
          return (
            <Button
              key={o.value}
              type={
                o.value === this.state.formData.multipleChoise.value
                  ? "default"
                  : "primary"
              }
              btnClassName={buttonClassname.join(" ")}
              onClick={() =>
                this.onMultipleSelectChange(o.value, "multipleChoise")
              }
            >
              {o.label}
            </Button>
          )
        })}
      </div>
    )
  }

  renderMultipleSelectionSection = () => {
    const {
      title_one_text,
      title_one_color,
      title_two_text,
      title_two_color,
      title_three_text,
      title_three_color,
      // description_one_text,
      // description_one_color,
      options,
      options_color,
      side_image,
    } = this.props
    return (
      <section className={s.MultipleSelection}>
        <div className={s.Content}>
          <div className={[s.Col, s.ColLeft].join(" ")}>
            <h4 className={[s.SubtitleOne, title_one_color].join(" ")}>
              {title_one_text}
            </h4>
            <div className={s.HideMobile}>
              <h3 className={[s.TitleMain, title_two_color].join(" ")}>
                {title_two_text}
              </h3>
              <h5 className={[s.SubtitleTwo, title_three_color].join(" ")}>
                {title_three_text}
              </h5>
              {this.renderMultipleSelectionOption(
                options,
                options_color,
                false
              )}
            </div>
          </div>
          <div className={[s.Col, s.ColRight].join(" ")}>
            <ImgFluid fileName={extractImageNameFromUrl(side_image)} />
          </div>
          <div className={[s.Col, s.MobileOnly].join(" ")}>
            <h3 className={[s.TitleMain, title_two_color].join(" ")}>
              {title_two_text}
            </h3>
            <h5 className={[s.SubtitleTwo, title_three_color].join(" ")}>
              {title_three_text}
            </h5>
            {/* <p
              className={[s.Description, description_one_color].join(" ")}
              dangerouslySetInnerHTML={{ __html: description_one_text }}
            ></p> */}
            {this.renderMultipleSelectionOption(options, options_color, true)}
          </div>
        </div>
        <div className={s.RedBackground}></div>
      </section>
    )
  }

  render() {
    const {
      showForm,
      displayAnswerCorrectYouWin,
      displayEmailUsed,
      displayAnswerCorrectYouDidntWin,
      displayAnswerWrong,
      answerCorrectYouWinMessage,
      confirmMessage,
    } = this.state
    const { emailUsed, answerCorrectYouDidntWin, answerWrong, entryConfirmed } =
      this.props

    let formWrapperClasses = [s.FormWrapper]
    if (displayAnswerCorrectYouWin) {
      formWrapperClasses.push(s.PrizeWonForm)
    }
    // const lang = getCurrentLanguage(this.props.location).lang
    return (
      <>
        {!confirmMessage ? this.renderMultipleSelectionSection() : null}
        <section
          ref={this.formFieldRef}
          className={formWrapperClasses.join(" ")}
        >
          {showForm && !confirmMessage && this.renderForm()}
          {displayAnswerCorrectYouWin && (
            <div className={s.WonPrize}>
              <h6
                className={`${s.FormMessage} ${s.GoodieBoxSent}`}
                dangerouslySetInnerHTML={{ __html: answerCorrectYouWinMessage }}
              />
              {/* <div className={s.PrizeImageWrapper}>
                <ImgFluid fileName={answerCorrectYouWinImage} />
              </div> */}
            </div>
          )}
          {confirmMessage ? (
            <div className={s.WonPrize}>
              <h6 className={`${s.FormMessage} ${s.GoodieBoxSent}`}>
                {entryConfirmed}
              </h6>
            </div>
          ) : null}
          {displayEmailUsed && (
            <h6 className={`${s.FormMessage} ${s.GoodieBoxSent}`}>
              {emailUsed}
            </h6>
          )}
          {displayAnswerCorrectYouDidntWin && (
            <h6 className={`${s.FormMessage} ${s.GoodieBoxSent}`}>
              {answerCorrectYouDidntWin}
            </h6>
          )}
          {displayAnswerWrong && (
            <h6 className={`${s.FormMessage} ${s.GoodieBoxSent}`}>
              {answerWrong}
            </h6>
          )}
        </section>
      </>
    )
  }
}

export default WinterPromotionForm
