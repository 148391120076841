import React from "react"
import s from "./SkewedButton.module.scss"
import Button from "../Button/Button"

class SkewedButton extends React.Component {
  render() {
    const classNames = [s.skewed_btn_wrapper]
    const { wrapperClassName, isSmallerSkew } = this.props
    if (wrapperClassName) classNames.push(wrapperClassName)
    if (isSmallerSkew) classNames.push(s.smaller_skew)

    return (
      <div className={classNames.join(" ")}>
        <Button {...this.props} />
      </div>
    )
  }
}

export default SkewedButton
