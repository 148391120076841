import React from "react"
import s from "./RivellaUnlimitedPartnerSection.module.scss"
import PromotionSectionWrapper from "../PromotionSectionWrapper/PromotionSectionWrapper"
import { ImageFrame, ImgFluid } from "../../../components"
import { extractImageNameFromUrl } from "../../../helper"
import { Link } from "gatsby"
import { ChildrenButtonRenderer } from "../../"

class RivellaUnlimitedPartnerSection extends React.Component {
  render() {
    const {
      itemType,
      itemScope,
      partner_image,
      partner_logo_one,
      partner_logo_link_one,
      title_one_text,
      description_one_text,
      description_two_text,
      description_three_text,
      partner_logo_two,
      partner_logo_link_two,
      bold_text,
      bold_text_two,
      bold_text_description,
      lang,
    } = this.props
    return (
      <PromotionSectionWrapper
        itemType={itemType}
        itemScope={itemScope}
        className={s.partner_section}
      >
        <div className={s.partner_details_wrapper}>
          <h1
            className={s.title}
            dangerouslySetInnerHTML={{ __html: title_one_text }}
          ></h1>
          <ImageFrame
            className={s.image_frame}
            imgFilename={extractImageNameFromUrl(partner_image)}
          />
          <div className={`${s.partner_detail} ${s[lang]}`}>
            <div className={s.partner_detail_block}>
              <p
                className={s.description}
                dangerouslySetInnerHTML={{ __html: description_one_text }}
              ></p>
            </div>
            <Link to={partner_logo_link_one}>
              <ImgFluid
                cn={s.partner_logo_img}
                fileName={extractImageNameFromUrl(partner_logo_one)}
              />
            </Link>
          </div>
        </div>
        <section className={s.subtext_wrapper}>
          <div className={s.subtext_description_wrapper}>
            <p
              className={s.subtext_description}
              dangerouslySetInnerHTML={{ __html: description_two_text }}
            ></p>
            <ChildrenButtonRenderer buttonData={this.props.innerBlocks} />
            <p
              className={s.subtext_description}
              dangerouslySetInnerHTML={{ __html: description_three_text }}
            ></p>
          </div>
          <div className={s.subtext_description_wrapper}>
            {bold_text ? (
              <p
                className={s.subtext_bold}
                dangerouslySetInnerHTML={{ __html: bold_text }}
              ></p>
            ) : null}
            {bold_text_two ? (
              <p
                className={s.subtext_bold}
                dangerouslySetInnerHTML={{ __html: bold_text_two }}
              ></p>
            ) : null}
            {bold_text_description ? (
              <p
                className={s.subtext_description}
                dangerouslySetInnerHTML={{ __html: bold_text_description }}
              ></p>
            ) : null}
            {/* {link_text ? <p className={s.subtext_description}><Link className={s.subtext_description} to={link_text}>{link_desc}</Link></p> : null}
            {details && details.length ? details.map(el => <p className={s.subtext_description}>{el}</p>): null} */}
          </div>
          <a
            href={partner_logo_link_two}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ImgFluid
              cn={s.sponsor_logo}
              fileName={extractImageNameFromUrl(partner_logo_two)}
              alt="Sigg, a sponsor's logo"
            />
          </a>
        </section>
      </PromotionSectionWrapper>
    )
  }
}

export default RivellaUnlimitedPartnerSection
