import React from "react"
import s from "./MainPrize.module.scss"
import formStyle from "../Form/Form.module.scss"
import SenderReceiver from "../Form/components/SenderReceiver/SenderReceiver"
import { getCurrentLanguage } from "../../../../helper"
import { submitRefreshV4Entry } from "../../api"

const MainPrize = (props) => {
  const { lang } = getCurrentLanguage(props.location)

  const fr = {
    infos: {
      title: `Souhaites-tu également participer au tirage au sort du premier prix?`,
      intro: `Premier prix : Profite d'une pause bien méritée en passant la nuit au bord du lac de Hallwil.`,
      // title: "Trage dich jetzt ein und gewinne mit etwas Glück eines von zwei Wellnessweekends für zwei Personen.",
      // intro: `Hauptpreis: Genusspause am Hallwilersee... Geniess eine wohlverdiente Pause bei einer Übernachtung.`,
      listTitle: `La pause détente comprend :`,
      listItems: [
        "Une nuitée au Seerose Resort & Spa, avec buffet de petit-déjeuner copieux compris",
        "Apéritif de bienvenue à l’arrivée",
        "Pension privilège au restaurant Seerose ou au restaurant Cocon",
        "Une bouteille de champagne Drappier dans la chambre",
        "Libre accès au Cocon Thai Spa",
        "Wifi et parking extérieur gratuits"
      ],
      outro: "Le bon est valable pour deux personnes et peut être utilisé pour une arrivée et un départ entre le dimanche et le vendredi. Il est possible de réserver sur demande une nuitée durant le week-end, sous réserve de disponibilité et en tenant compte du séjour minimum.",
      warning: "Attention: nous n’envoyons qu’un seul colis par adresse postale.",
      cta: "Participer maintenant",
    },
    form: {
      title: "S'inscrire pour tenter de gangner le prix principal!"
    },
    already: {
      title: "Nice Try.",
      text: `Cet e-mail a déjà été utilisé. Procure-toi ton rafraîchissement dans les magasins dès maintenant ou visite-nous sur nos réseaux sociaux :`,
    },
  }

  const it = {
    infos: {
      title: `Vuoi ancora partecipare all'estrazione del primo premio?`,
      intro: `Primo premio: godetevi una meritata pausa trascorrendo una notte al lago di Hallwil.`,
      // title: "Trage dich jetzt ein und gewinne mit etwas Glück eines von zwei Wellnessweekends für zwei Personen.",
      // intro: `Hauptpreis: Genusspause am Hallwilersee... Geniess eine wohlverdiente Pause bei einer Übernachtung.`,
      listTitle: `La pausa di puro piacere comprende:`,
      listItems: [
        "Un pernottamento presso il Seerose Resort & Spa, inclusa una ricca colazione a buffet",
        "Aperitivo di benvenuto all’arrivo",
        "Trattamento di pensione completa presso il ristorante Seerose o il ristorante Cocon",
        "Una bottiglia di Champagne Drappier in camera",
        "Libero accesso alla Cocon Thai Spa",
        "Connessione internet gratuita e parcheggio esterno"
      ],
      outro: "Il buono è valido per due persone e può essere utilizzato per arrivi e partenze tra la domenica e il venerdì. È possibile prenotare un pernottamento durante il week-end anche all’ultimo momento, in base alla disponibilità e al soggiorno minimo previsto nella struttura.",
      warning: "Attenzione: Spediamo un solo pacchetto per indirizzo postale.",
      cta: "Partecipa ora",
    },
    form: {
      title: "Modulo di contatto: registrati ora per vincere il primo premio!"
    },
    already: {
      title: "Nice Try.",
      text: `Questa e-mail è già stata utilizzata. Rinfrescatevi subito nei negozi o venite a trovarci sui nostri social network:`,
    },
  }

  const [submitted, setSubmitted] = React.useState(false)
  const [step, setStep] = React.useState('intro')
  const [validation, setValidation] = React.useState({
    from_salutation: undefined,
    from_last_name: undefined,
    from_first_name: undefined,
    from_email: undefined,
    from_phone: undefined,
    street: undefined,
    number: undefined,
    zip: undefined,
    city: undefined,
    //
    newsletter: undefined,
    accepted: 1,
  })

  const isFieldInvalid = (field) => {
    return (
      submitted &&
      typeof validation[field] === "boolean" &&
      (validation[field] === false || validation[field] === "0")
    )
  }

  const translations = {
    de: {
      title: "Willst du noch an der Verlosung des Hauptpreises teilnehmen?",
      infos: {
        title: "Trage dich jetzt ein und gewinne mit etwas Glück eines von zwei Wellnessweekends für zwei Personen.",
        intro: `Hauptpreis: Genusspause am Hallwilersee... Geniess eine wohlverdiente Pause bei einer Übernachtung.`,
        listTitle: `Die Genusspause beinhaltet:`,
        listItems: [
          "Eine Übernachtung im Seerose Resort & Spa inklusive herzhaftem Frühstück vom Buffet",
          "Willkommensapéro bei Anreise",
          "Verwöhnpension im Restaurant Seerose oder Restaurant Cocon",
          "Eine Flasche Champagner Drappier im Zimmer",
          "Freie Nutzung des Cocon Thai Spa",
          "Kostenfreies WLAN sowie Aussenparkplatz"
        ],
        outro: "Der Gutschein ist gültig für zwei Personen und einlösbar mit An- und Abreise zwischen Sonntag & Freitag. Eine Übernachtung über das Wochenende ist auf kurzfristige Anfrage bei Verfügbarkeit und unter Berücksichtigung des Mindestaufenthaltes buchbar.",
        warning: "Achtung: Wir verschicken pro Postadresse nur ein Paket.",
        cta: "Jetzt mitmachen"
      },
      form: {
        title: 'Für den Hauptpreis registrieren!'
      },
      already: {
        title: "Nice Try.",
        text: `Diese E-Mail wurde bereits verwendet. Hol dir jetzt deine Erfrischung auch im Handel oder besuche uns auf Social Media:`,
      },
    },
    fr: fr,
    fr_fr: fr,
    lu_fr: fr,
    it: it,
  }

  const [body, setBody] = React.useState({
    from_title: "",
    from_salutation: props.address.salutation,
    from_last_name: props.address.last_name,
    from_first_name: props.address.first_name,
    from_email: props.email,
    from_phone: props.address.phone,
    //
    to_title: "",
    to_salutation: "",
    to_last_name: "",
    to_first_name: "",
    //
    street: props.address.street,
    number: props.address.number,
    zip: props.address.zip,
    city: props.address.city,
    //
    newsletter: props.address.newsletter,
    accepted: "",
  })

  const [formError, setFormError] = React.useState(undefined)

  const isFormValid = (vd) => {
    return Object.values(vd ? vd : validation).every((val) => val === true)
  }


  const validateForm = () => {
    const mailRe =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const numRe = /^\d+$/

    const nextValidation = { ...validation }

    // from
    nextValidation.from_salutation = body.from_salutation.length > 1
    nextValidation.from_last_name = body.from_last_name.length > 1
    nextValidation.from_first_name = body.from_first_name.length > 1
    nextValidation.from_email = mailRe.test(body.from_email)
    nextValidation.from_phone = body.from_phone.length > 3

    // addr
    nextValidation.street = body.street.length > 5
    nextValidation.number = numRe.test(body.number)
    nextValidation.zip = numRe.test(body.zip) && `${body.zip}`.length === 4
    nextValidation.city = body.city.length > 2

    // checkboxes
    nextValidation.newsletter = true
    nextValidation.accepted = body.accepted === "1"

    setValidation(nextValidation)
    return nextValidation
  }

  React.useEffect(() => {
    validateForm()
  }, [body])


  const submitForm = async () => {
    const current_validation = validateForm()

    setSubmitted(true)

    if (!isFormValid(current_validation)) {
      return
    }

    const { lang } = getCurrentLanguage(props.location)

    try {
      const response = await submitRefreshV4Entry({
        ...body,
        language: lang,
      })
      if (response.result === "success") {
        props.showSuccess()
      } else {
        setFormError(response.message)
      }
    } catch (err) {
      setFormError(err ? err.message : "Error")
    }
  }

  return (
    step !== "pre" &&
    <section className={s.section}>
      <div className={s.container}>

        <div className={s.mainTitle}>
          <h2>{translations[lang].title}</h2>
        </div>

        <div className={s.columns}>
          <div className={s.column}>
            <img
              src="/Seerose-Resort-Spa.jpg"
            />
          </div>

          <div  className={s.column}>
            {step === 'intro' && <div className={s.intro}>
              <h3 className={s.subtitle}>
                {translations[lang].infos.title}
              </h3>
              <p>{translations[lang].infos.intro}</p>
              <p>{translations[lang].infos.listTitle}</p>
              <ul>{translations[lang].infos.listItems.map(string => <li key={string}>{string}</li>)}</ul>
              <p>{translations[lang].infos.outro}</p>
              <p>{translations[lang].infos.warning}</p>
              <div className={s.submit_button_div}>
                <button
                  className={formStyle.submit_button}
                  onClick={() => setStep('form')}
                >{translations[lang].infos.cta}</button>
              </div>
            </div>}
            {step === 'form' && <div className={s.failed_description}>
              <h3 className={s.formTitle}>
                {translations[lang].form.title}
              </h3>
              <div className={s.form_error_wrapper}>{formError}</div>
              <div className={s.SenderReceiver_wrapper}>
                <SenderReceiver
                    {...props}
                    withTitle={false}
                    type="self"
                    body={body}
                    setBody={setBody}
                    isFieldInvalid={isFieldInvalid}
                  />

                {/* Checkboxes and submit */}
                <div className={formStyle.form_check_box}>

                  <div className={isFieldInvalid("newsletter") ? formStyle.field_error : ""}>
                    <label>
                      <input
                        type="checkbox"
                        className={formStyle.newsletter_check_box}
                        checked={body.newsletter === "1" ? true : false}
                        onChange={({ target: { checked } }) =>
                          setBody((p) => ({ ...p, newsletter: checked ? "1" : "0" }))
                        }
                      />
                      {props.formFieldsInfo.checkboxes.newsletter.placeholder}
                    </label>
                  </div>


                    <div className={isFieldInvalid("accepted") ? formStyle.field_error : ""}>
                      <label>
                        <input
                          type="checkbox"
                          className={formStyle.terms_check_box}
                          checked={body.accepted === "1" ? true : false}
                          onChange={({ target: { checked } }) => {
                            setBody((p) => ({
                              ...p,
                              accepted: checked ? "1" : "0",
                            }))
                          }}
                        />
                        {props.formFieldsInfo.checkboxes.termsAndContidition.placeholder(
                          props.terms_link
                        )}
                      </label>
                    </div>
                  </div>
              </div>

              <div className={formStyle.form_error_wrapper}>{formError}</div>

              <div className={s.submit_button_div}>
                <button
                  className={formStyle.submit_button}
                  type="button"
                  onClick={submitForm}
                >
                  {props.formFieldsInfo.submit.placeholder}
                </button>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </section>
  )
}

export default MainPrize
