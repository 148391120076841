import React from "react"
import s from "./SenderReceiver.module.scss"

const SenderReceiver = (props) => {
  const withTitle = props.withTitle
  return (
    <div className={s.sender_receiver_section}>
      { withTitle &&
      <div className={s.row_title}>
          <h4>{props.formFieldsInfo.reciever.header.title}</h4>
      </div>
      }

      {/* ********************************************** */}

      <div className={[s.flex, s.padding_20].join(" ")}>
        <div className={s.two_column}>
          <select
            className={`${s.form_select} ${
              props.isFieldInvalid("from_salutation")
                ? s.field_error
                : ""
            }`}
            placeholder={props.formFieldsInfo.reciever.mr_mrs.placeholder}
            value={props.body.from_salutation}
            onChange={({ target: { value } }) =>
              props.setBody((p) => ({
                ...p,
                "from_salutation": value,
              }))
            }
          >
            <option selected hidden>
              {props.formFieldsInfo.reciever.mr_mrs.placeholder}
            </option>
            <option value={props.formFieldsInfo.reciever.mr_mrs.mr.value}>
              {props.formFieldsInfo.reciever.mr_mrs.mr.label}
            </option>
            <option value={props.formFieldsInfo.reciever.mr_mrs.mrs.value}>
              {props.formFieldsInfo.reciever.mr_mrs.mrs.label}
            </option>
          </select>
        </div>
      </div>

      {/* ********************************************** */}

      <div className={s.padding_20}>
        <input
          type="text"
          placeholder={props.formFieldsInfo.reciever.first_name.placeholder}
          className={`${s.form_control} ${
            props.isFieldInvalid("from_first_name")
              ? s.field_error
              : ""
          }`}
          value={props.body.from_first_name}
          onChange={({ target: { value } }) =>
            props.setBody((p) => ({
              ...p,
              "from_first_name":
                value,
            }))
          }
        />
      </div>

      {/* ********************************************** */}

      <div className={s.padding_20}>
        <input
          type="text"
          placeholder={props.formFieldsInfo.reciever.last_name.placeholder}
          className={`${s.form_control} ${
            props.isFieldInvalid("from_last_name")
              ? s.field_error
              : ""
          }`}
          value={props.body.from_last_name}
          onChange={({ target: { value } }) =>
            props.setBody((p) => ({
              ...p,
              "from_last_name":
                value,
            }))
          }
        />
      </div>


      <div className={s.padding_20}>
        <input
          type="text"
          placeholder={props.formFieldsInfo.reciever.email.placeholder}
          className={`${s.form_control} ${
            props.isFieldInvalid("from_email") ? s.field_error : ""
          }`}
          value={props.body.from_email}
          // onChange={({ target: { value } }) =>
          //   props.setBody((p) => ({ ...p, from_email: value }))
          // }
        />
      </div>

      <div className={s.padding_20}>
        <input
          type="text"
          placeholder={props.formFieldsInfo.reciever.phone.placeholder}
          className={`${s.form_control} ${
            props.isFieldInvalid("from_phone") ? s.field_error : ""
          }`}
          value={props.body.from_phone}
          onChange={({ target: { value } }) =>
            props.setBody((p) => ({ ...p, from_phone: value }))
          }
        />
      </div>

      {/* ********************************************** */}

      <div className={[s.flex, s.padding_20].join(" ")}>
        <div className={s.two_column}>
          <input
            type="text"
            placeholder={props.formFieldsInfo.reciever.street.placeholder}
            className={`${s.form_control} ${
              props.isFieldInvalid("street") ? s.field_error : ""
            }`}
            value={props.body.street}
            onChange={({ target: { value } }) =>
              props.setBody((p) => ({ ...p, street: value }))
            }
          />
        </div>

        <div className={[s.two_column, s.left_padding].join(" ")}>
          <input
            type="text"
            placeholder={props.formFieldsInfo.reciever.house_no.placeholder}
            className={`${s.form_control} ${
              props.isFieldInvalid("number") ? s.field_error : ""
            }`}
            value={props.body.number}
            onChange={({ target: { value } }) =>
              props.setBody((p) => ({ ...p, number: value }))
            }
          />
        </div>
      </div>

      {/* ********************************************** */}

      <div className={[s.flex, s.padding_20].join(" ")}>
        <div className={s.two_column}>
          <input
            type="number"
            placeholder={props.formFieldsInfo.reciever.postal_code.placeholder}
            className={`${s.form_control} ${
              props.isFieldInvalid("zip") ? s.field_error : ""
            }`}
            value={props.body.zip}
            onChange={({ target: { value } }) => {
              if (value.length <= 4) {
                props.setBody((p) => ({ ...p, zip: value }))
              }
            }}
          />
        </div>

        <div className={[s.two_column, s.left_padding].join(" ")}>
          <input
            type="text"
            placeholder={props.formFieldsInfo.reciever.town.placeholder}
            className={`${s.form_control} ${
              props.isFieldInvalid("city") ? s.field_error : ""
            }`}
            value={props.body.city}
            onChange={({ target: { value } }) =>
              props.setBody((p) => ({ ...p, city: value }))
            }
          />
        </div>
      </div>
    </div>
  )
}

SenderReceiver.defaultProps = {
  withTitle: true
}

export default SenderReceiver
