import React, { PureComponent } from "react"
import { CodeEntrySection as LoginBox } from "../../HikeAndWinContainers"
import {
  fetchHikingData,
  getCurrentLanguage,
  setCommonIdentifier,
} from "../../../helper"
import { winAndHike_routes } from "../../../constants"
import RegistrationForm from "../RegistrationForm/RegistrationForm"
import { navigateTo } from "gatsby"
import s from "./LoginForm.module.scss"
import { HikingAuthConsumer } from "../../../store"

// const formMessages = {
//   "4": {
//     en: "User is not registered. Enter your details below to register.",
//     de:
//       "Benutzer ist noch nicht registriert. Bitte gebe deine Daten unten ein.",
//     fr:
//       "Cet utilisateur n’est pas encore enregistré. Saisis tes coordonnées ci-dessous.",
//     it: "L’utente non è ancora registrato. Inserisci i tuoi dati qui sotto.",
//   },
//   "5": {
//     en:
//       "There was an error sending the email with the authentication code. Please try again later.",
//     de:
//       "Beim Versenden der E-Mail mit dem Bestätigungscode ist ein Fehler aufgetreten. Bitte versuche es später nochmals.",
//     fr:
//       "Une erreur est survenue lors de l’envoi de l’e-mail contenant le code de confirmation. Retente ta chance plus tard, s’il te plaît.",
//     it:
//       "Si è verificato un errore durante l’invio dell’e-mail con il codice di conferma. Ti preghiamo di riprovare più tardi.",
//   },
//   "6": {
//     en:
//       "The login code isn't valid. Please confirm your input is correct and try again.",
//     de:
//       "Der Logincode ist ungültig. Bitte überprüfe, ob deine Eingabe richtig ist und versuche es nochmals.",
//     fr:
//       "Le code de login n’est pas valable. Vérifie, s’il te plaît, si tes données sont correctes et retente ta chance.",
//     it:
//       "Il codice di accesso non è valido. Ti preghiamo di controllare che quanto hai scritto sia corretto e poi riprovarci.",
//   },
//   "7": {
//     en: "The email you entered is invalid.",
//     de: "Du hast eine ungültige E-Mail Adresse eingegeben.",
//     fr: "L’adresse e-mail que tu as indiquée n’est pas valable.",
//     it: "Hai inserito un indirizzo e-mail non valido.",
//   },
// }

// const placeholderTranslations = {
//   email: {
//     en: "Enter Email",
//     de: "Deine E-Mail",
//     fr: "Votre email",
//     it: "La tua email",
//   },
//   code: {
//     en: "Enter Code",
//     de: "Code eingeben",
//     fr: "Entrer le code",
//     it: "Inserire il codice",
//   },
// }

// const submitButtonTranslations = {
//   email_button: {
//     en: "Submit",
//     de: "Email senden",
//     it: "Manda il email",
//     fr: "Envoyer le email",
//   },
//   code_button: {
//     en: "Send Code",
//     de: "Code senden",
//     it: "Manda il codice",
//     fr: "Envoyer le code",
//   },
// }

// const codeSubmissionMsgTranslations = {
//   de: "Wir haben deinen Code und deine Teilnahme aufgezeichnet.",
//   en: "We have recorded your code and your participation.",
//   it: "Abbiamo registrato il tuo codice e la tua partecipazione",
//   fr: "Nous avons enregistré ton code et ta participation.",
// }

// const resendCodeBtnTranslations = {
//   en: "Resend Code",
//   de: "Code erneut senden",
//   it: "Codice di rispedizione",
//   fr: "Renvoyer le code",
// }

// const codeRequestMsgTranslations = {
//   en: "A code was sent to your email. Enter received code",
//   de:
//     "Wir haben dir den Logincode an deine angegebene E-Mail Adresse geschickt.",
//   fr:
//     "Nous t’avons envoyé un code de login à l’adresse e-mail que tu nous as indiquée.",
//   it: "Ti abbiamo inviato il codice di accesso all’indirizzo e-mail indicato.",
// }

// const prefillFormTitleTranslations = {
//   en: "Please complete your registration",
//   de: "Bitte vervollständigen Sie Ihre Registrierung",
//   it: "Si prega di completare la registrazione",
//   fr: "Veuillez compléter votre inscription",
// }

// const alreadyHaveAccountTranslations = {
//   de: {
//     prefix: "Du hast schon ein Konto?",
//     midMsg: "Hier",
//     suffix: "geht's zum Login.",
//   },
//   fr: {
//     prefix: "Tu as dèja un compte?",
//     midMsg: "Connecte-toi",
//     suffix: "ici.",
//   },
//   it: {
//     prefix: "Hai già un account?",
//     midMsg: "Accedi",
//     suffix: "qui ora.",
//   },
// }

// const prevAccountStillValidTranslations = {
//   en: "Note: logins from last year are still valid.",
//   de: "Hinweis: Logins von letztem Jahr sind noch gültig.",
//   fr: "Note : Les adresses e-mail de l'année dernière sont toujours valables.",
//   it: "Nota: I login dell'anno scorso sono ancora validi.",
// }

// const revertedWinMessages = {
//   en: `Sorry, it seems you already have won a prize (based on the
//     postal address). We are, as per the Terms and Conditions,
//     unable to offer another one.`,
//   de: `Jemand in deinem Haushalt hat bereits einen Sofortpreis gewonnen.
//       Gemäss unseren Teilnahmebedingungen, können wir dir daher keinen weiteren Sofortpreis anbieten.
//       Für mehr Informationen, kannst du dich an den Rivella Kundendienst wenden.`,
//   fr: `Qualcuno nel tuo nucleo familiare ha già vinto un premio immediato. In base alle nostre
//       condizioni di partecipazione, non possiamo quindi offrirti un altro premio istantaneo. Per maggiori
//       informazioni puoi rivolgerti al servizio clienti di Rivella.`,
//   it: `Une personne vivant à ton domicile a déjà gagné un prix immédiat. En fonction de nos conditions
//       de participation, nous ne pouvons malheureusement pas te remettre un autre prix immédiat. Tu peux t’adresser au
//       service clients Rivella, si tu souhaites de plus amples informations.`,
// }

class LoginForm extends PureComponent {
  state = {
    email: "",
    userExists: false,
    checkedEmail: false,
    formType: "email",
    message: "",
    formMessage: "",
    loginCode: "",
    prefillUser: false,
    userHash: "",
    userIsValidated: false,
    loading: true,
    lang: "",
    promoCode: undefined,
    formError: false,
    showResendCodeLink: false,
    hideLoginForm: false,
    showLoginLink: false,
    showRevertedWinMessage: false,
  }

  formMessageRef = React.createRef()

  resendLoginCode = () => {
    const { email, lang } = this.state
    const { login_box } = this.props
    this.setState({
      formMessage: login_box?.weSendedCodeToEmail,
      formError: false,
    })
    fetchHikingData("login_request", {
      method: "POST",
      body: JSON.stringify({ email, lang }),
    })
  }

  onRegisterCallback = (hideLoginLink) => {
    const { lang, slug, prefillUser } = this.state
    if (hideLoginLink) {
      this.setState({ showLoginLink: false })
    } else {
      this.setState({
        hideLoginForm: true,
        formMessage: "",
        showResendCodeLink: false,
        showLoginLink: false,
      })
      if (prefillUser) {
        navigateTo(`/${slug}${winAndHike_routes[lang].gewinnspiel}`)
      }
    }
  }

  displayLoginForm = () => {
    this.setState({ hideLoginForm: false, showLoginLink: false })
  }

  componentDidMount() {
    const { location, promoCode } = this.props
    const { langHref, slug } = getCurrentLanguage(location)
    this.setState({ lang: langHref, slug })

    const userHash = document.cookie.split("user_hash=")[1]
    if (promoCode) {
      this.setState({
        promoCode,
        loading: false,
        hideLoginForm: true,
        showLoginLink: true,
      })
    }
    if (userHash) {
      this.setState({ userHash })
    } else {
      this.setState({ userExists: false, checkedEmail: false })
    }
  }

  setCookie = (key, value) => {
    // Delete any associated cookie
    document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC"

    const today = new Date()
    const fourMonthsFromToday = new Date(today.setMonth(today.getMonth() + 4))
    document.cookie =
      key + "=" + value + "; expires=" + fourMonthsFromToday + "; path=/"
  }

  onFormSubmit = async ({ target }, { loginUser, setTotalCodeTries }) => {
    const { location, login_box, onRevertedWinCallback } = this.props
    const { langHref, slug } = getCurrentLanguage(location)

    const { userExists, email, loginCode, promoCode } = this.state
    const requestBody = { email }
    let endpoint = ""

    this.setState({ lang: langHref })

    const ENDPOINTS = {
      login_request: "login_request",
      login_confirm: "login_confirm",
    }

    if (userExists) {
      requestBody.code = loginCode
      endpoint = ENDPOINTS.login_confirm
    } else {
      requestBody.lang = langHref
      endpoint = ENDPOINTS.login_request
    }

    if (promoCode && endpoint === ENDPOINTS.login_confirm) {
      requestBody.tried_code = promoCode
    }

    try {
      this.setState({ loading: true })
      const response = await fetchHikingData(`${endpoint}`, {
        method: "POST",
        body: JSON.stringify(requestBody),
      })

      const { result, error_code, user_hash = "", needs_update = "" } = response

      if (!userExists && result === "success") {
        this.setState({
          userExists: true,
          checkedEmail: true,
          formType: "text",
          loading: false,
          formMessage: login_box?.weSendedCodeToEmail,
          showResendCodeLink: true,
        })
        target.reset()
      } else if (userExists && result === "success") {
        this.setState({
          userIsValidated: true,
          showResendCodeLink: true,
        })

        if (promoCode === undefined) {
          if (Number(needs_update) === 1) {
            this.setState({
              prefillUser: true,
              formMessage: login_box?.loginSuccessfulFormPrefilled,
              userHash: user_hash,
              showResendCodeLink: false,
            })
          } else {
            if (loginUser) loginUser()
            this.setCookie("user_hash", user_hash)
            this.setCookie("first_name", response.first_name)
            setCommonIdentifier("account")
            navigateTo(`/${slug}${winAndHike_routes[langHref].account}`)
          }
        } else {
          this.setState({ codeTriesCount: 2 })
          this.setCookie("user_hash", user_hash)
          this.setCookie("first_name", response.first_name)
          if (Number(needs_update) === 1) {
            this.setState({
              prefillUser: true,
              formMessage: login_box?.loginSuccessfulFormPrefilled,
              userHash: user_hash,
              showResendCodeLink: false,
            })
          } else {
            loginUser()
            if (Number(response.reverted_win) === 1) {
              onRevertedWinCallback()
              this.setState({ showRevertedWinMessage: true })
              this.formMessageRef.current.scrollIntoView()
            }
          }
          this.setState({ showResendCodeLink: false })
          fetchHikingData("code_to_user", {
            method: "POST",
            body: JSON.stringify({ user_hash, code: promoCode }),
          })
          const codeTriesRequest = await fetchHikingData("hash_count", {
            method: "POST",
            body: JSON.stringify({ user_hash: user_hash }),
          })
          const { result, data } = codeTriesRequest
          if (result === "success") {
            setTotalCodeTries({ count: data })
          }
        }
        this.setState({ loading: false })
      } else if (error_code) {
        const { checkedEmail, userIsValidated } = this.state
        if (error_code === 4) {
          this.setState({
            userExists: false,
            checkedEmail: true,
            formMessage: login_box?.formMessages[error_code],
            prefillUser: false,
            loading: false,
            formError: false,
          })
        } else {
          this.setState({
            userExists: checkedEmail && !userIsValidated,
            checkedEmail: true,
            formMessage: login_box?.formMessages[error_code],
            prefillUser: false,
            loading: false,
            formError: true,
          })
        }
      } else {
        this.setState({
          formMessage: login_box?.formMessages[error_code],
          userExists: false,
          checkedEmail: false,
          loginCode: "",
          loading: false,
          formError: true,
        })
      }
    } catch (error) {
      console.warn(error)
    }
  }

  onInputChange = ({ value }) => {
    const { userExists } = this.state
    if (userExists) {
      this.setState({ loginCode: value })
    } else {
      this.setState({
        email: value,
        checkedEmail: false,
        formMessage: "",
        message: "",
      })
    }
  }

  render() {
    const {
      formType,
      email,
      loginCode,
      checkedEmail,
      userExists,
      prefillUser,
      userHash,
      userIsValidated,
      loading,
      lang,
      promoCode,
      formMessage,
      formError,
      showResendCodeLink,
      hideLoginForm,
      showLoginLink,
      showRevertedWinMessage,
    } = this.state

    const { isMobileDevice, onRevertedWinCallback, login_box } = this.props

    const inputPlaceholder =
      checkedEmail && userExists
        ? login_box?.code_input_label
        : login_box?.input_label

    const submitBtnText =
      checkedEmail && userExists
        ? login_box?.code_button_label
        : login_box?.button_label

    const value = userExists ? loginCode : email
    const userDoesNotExist = !userExists && !userIsValidated
    const showFreshRegistrationForm =
      (checkedEmail && userDoesNotExist) || hideLoginForm
    const showFreshLoginForm = !userIsValidated && !hideLoginForm

    const revertedWinMessage =
      login_box?.error_messages?.postal_code_already_won

    const codeSubmissionMsg = login_box?.codeSubmissionMsgTranslations

    const uniqueFormProps = {
      lang,
      promoCode,
      onRevertedWinCallback,
      onRegisterCallback: this.onRegisterCallback,
      registerFormFields: {
        revertedWinMessage,
        codeSubmissionMsg,
        existingAccountMsg: {
          title: login_box?.title,
          title_cta_bold: login_box?.title_cta_bold,
          title_cta: login_box?.title_cta,
        },
        ...this.props.register_form,
      },
    }

    return (
      <>
        <HikingAuthConsumer>
          {(store) => {
            const { login: loginUser, setTotalCodeTries } = store.actions
            const { shouldResetCodeForm } = store.state
            return (
              <>
                {promoCode && showLoginLink && (
                  <div className={s.LoginMsgBlock}>
                    <div className={s.LoginLink}>
                      <span
                        dangerouslySetInnerHTML={{ __html: login_box?.title }}
                      ></span>
                      <button
                        className={`${s[lang]}`}
                        onClick={() =>
                          this.setState({
                            hideLoginForm: false,
                            showLoginLink: false,
                          })
                        }
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: login_box?.title_cta_bold,
                          }}
                        ></span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: login_box?.title_cta,
                          }}
                        ></span>
                      </button>
                    </div>
                    <p
                      className={s.text_small}
                      dangerouslySetInnerHTML={{
                        __html: login_box?.description,
                      }}
                    ></p>
                  </div>
                )}
                {showFreshLoginForm && (
                  <LoginBox
                    placeholder_text={inputPlaceholder}
                    button_text={submitBtnText}
                    isMobileDevice={isMobileDevice}
                    onInputChange={({ target }) => this.onInputChange(target)}
                    value={value}
                    form_type={formType}
                    location={this.props.location}
                    formMessage={formMessage}
                    className={s.login_input_box}
                    formError={formError}
                    shouldResetCodeForm={shouldResetCodeForm}
                    itemType="https://schema.org/LoginAction"
                    onFormSubmit={(evt) =>
                      this.onFormSubmit(evt, {
                        loginUser,
                        setTotalCodeTries,
                      })
                    }
                  />
                )}
              </>
            )
          }}
        </HikingAuthConsumer>
        {userIsValidated && hideLoginForm && (
          <h4
            className={s.form_message}
            dangerouslySetInnerHTML={{ __html: codeSubmissionMsg }}
          ></h4>
        )}
        {showRevertedWinMessage && (
          <h4
            ref={this.formMessageRef}
            className={s.form_message}
            dangerouslySetInnerHTML={{ __html: revertedWinMessage }}
          ></h4>
        )}
        {showResendCodeLink && (
          <button
            className={s.resend_link_btn}
            onClick={this.resendLoginCode}
            dangerouslySetInnerHTML={{
              __html: login_box?.resendCodeBtnTranslations,
            }}
          ></button>
        )}
        {!loading && prefillUser && (
          <RegistrationForm
            formMessage={formMessage}
            userHash={userHash}
            prefillUserData={prefillUser}
            {...uniqueFormProps}
          />
        )}
        {!loading && showFreshRegistrationForm && (
          <RegistrationForm
            email={email}
            displayLoginForm={this.displayLoginForm}
            {...uniqueFormProps}
          />
        )}
      </>
    )
  }
}

export default LoginForm
