import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react"

import lottie from "lottie-web"
import { graphql, useStaticQuery, navigate } from "gatsby"
import { getCurrentLanguage } from "../../../helper"
import { winAndHike_routes } from "../../../containers/Footer/LanguagesData"
import { HikingAuthConsumer } from "../../../store"

const isChildNode = (parentNode, childNode) => {
  if ("contains" in parentNode) {
    return parentNode.contains(childNode) && parentNode !== childNode
  } else {
    return parentNode.compareDocumentPosition(childNode) % 16
  }
}

const WinAndHikeLogoFunc = (props) => {
  const [logoUrl, setLogoUrl] = useState(null)
  const ref = useRef()
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "win_and_hike_logo" }) {
        publicURL
      }
    }
  `)

  const navigateToHome = useCallback(() => {
    const { location } = props
    const { lang } = getCurrentLanguage(location)
    navigate(`/${lang}${winAndHike_routes[lang].gewinnspiel}`)
  }, [])

  useEffect(() => {
    setLogoUrl(data.file.publicURL)
  }, [])

  useEffect(() => {
    ref.current.innerHTML = ""
    lottie.loadAnimation({
      container: ref.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: logoUrl,
    })
  }, [logoUrl])

  const context = useContext(HikingAuthConsumer)
  const { codeStatus } = context.state
  const { setCodeStatus } = context.actions

  useEffect(() => {
    const handleLogoClick = ({ target, currentTarget }) => {
      const { children: svgNodes } = currentTarget
      if (svgNodes.length > 0) {
        const gNodeGroup = svgNodes[0].children[1]
        const bigWinLogoParentNode = gNodeGroup.children[2].children[0]
        if (isChildNode(bigWinLogoParentNode, target)) {
          setCodeStatus({ ...codeStatus, submitted: false })
          navigateToHome()
        }
      }
    }

    if (ref && ref.current) {
      ref.current.addEventListener("click", (evt) => handleLogoClick(evt))
    }

    return ref.current.removeEventListener("click", (evt) =>
      handleLogoClick(evt)
    )
  }, [navigateToHome])

  return (
    <>
      <div {...props} ref={ref} style={{ cursor: "pointer" }}></div>
      {props.children}
    </>
  )
}

export default WinAndHikeLogoFunc
