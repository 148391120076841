import React from "react"

import s from "./ProductIngredients.module.scss"
import { ImgFluid, Button, BottleWithDroplets } from "../"
import {
  extractImageNameFromUrl,
  paintCirclesAndPaths,
  hexToRGB_obj,
} from "../../helper"

import CloseIcon from "../../assets/svgs/close-icon.svg"

class ProductIngredients extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      customTitleSize: null,
    }
    this.closeBtnRef = React.createRef()
    this.triggerCloseBtn = () => {
      this.closeBtnRef.current.click()
    }

    const length = props.data && props.data.list && props.data.list.length
    this.iconsRef = []
    if (length) {
      for (let i = 0; i < length; i++) {
        this.iconsRef.push(React.createRef())
      }
    } else {
      this.iconsRef = [
        React.createRef(),
        React.createRef(),
        React.createRef(),
        React.createRef(),
        React.createRef(),
        React.createRef(),
        React.createRef(), // work on to target each icon the style path and circle with javascript
      ]
    }
  }
  componentDidMount() {
    this.setState({
      customTitleSize: this.calcFontSize(),
    })
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.fontSizeChangeCheck)
      // document.addEventListener("click", this.triggerCloseBtn)
    }
    this.paintIcons()
  }

  paintIcons = () => {
    const { productColor } = this.props
    const c = hexToRGB_obj(productColor)
    const rate = 0
    const nextColor = `rgb(${c.r + (255 - c.r) * rate}, ${
      c.g + (255 - c.g) * rate
    }, ${c.b + (255 - c.b) * rate})`
    this.iconsRef.forEach((r) =>
      paintCirclesAndPaths({
        ref: r,
        circleColor: nextColor,
        ellipseColor: nextColor,
        pathColor: "white",
        circleOpacity: 0.7,
        ellipseOpacity: 0.7,
        pathOpacity: 1,
        removeClipPaths: true,
      })
    )
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.fontSizeChangeCheck)
      // document.removeEventListener("click", this.triggerCloseBtn)
    }
  }

  fontSizeChangeCheck = () => {
    const nextFontSize = this.calcFontSize()
    if (this.state.customTitleSize !== nextFontSize) {
      this.setState({
        customTitleSize: this.calcFontSize(),
      })
    }
  }

  calcFontSize = () => {
    const { title } = this.props.data || {}
    return this.props.calculateProductTitleSize(title)
  }
  render() {
    let iconsLeft = [],
      iconMiddle,
      iconsRight = [],
      title
    const length =
      this.props.data && this.props.data.list && this.props.data.list.length
    if (this.props.data) {
      title = this.props.data.title
      if (length === 7) {
        this.props.data.list.forEach((d, i) => {
          if (i < 3) iconsLeft.push(d)
          else if (i === 3) iconMiddle = d
          if (3 < i && i < 7) iconsRight.push(d)
        })
      } else if (length === 9) {
        this.props.data.list.forEach((d, i) => {
          if (i < 4) iconsLeft.push(d)
          else if (i === 4) iconMiddle = d
          else if (i < 9) iconsRight.push(d)
        })
      }
    }
    const { productName, productColor, fadeInAnimateExceptMainBottle } =
      this.props

    const sectionClasses = fadeInAnimateExceptMainBottle
      ? [s.ProductIngredientsFadeIn, s.ProductIngredients]
      : [s.ProductIngredients]
    return (
      <section
        className={sectionClasses.join(" ")}
        ref={this.props.refFromParent}
        // style={{ backgroundColor: productColor }}
        style={{ backgroundColor: "transparent" }}
      >
        <div className={s.RedBgWrapper}>
          <div
            className={s.RedBg}
            // style={{ background: productColor }}
            style={{ backgroundColor: "transparent" }}
          >
            <div
              className={s.TitleWrapper}
              style={{
                fontSize: this.state.customTitleSize,
              }}
            >
              {/* {productName ? (
                <h4
                  className={s.ProductName}
                  style={{
                    color: productColor,
                  }}
                >
                  {productName}
                </h4>
              ) : null} */}
              <div style={{ position: "relative" }}>
                {/* <Arrow
                  onClick={this.props.onToBack}
                  className={s.Arrow}
                  style={{
                    fill: productColor,
                    stroke: productColor,
                  }}
                /> */}
                <h2
                  className={s.TitleRed}
                  dangerouslySetInnerHTML={{ __html: title }}
                  style={{
                    color: productColor,
                  }}
                ></h2>
              </div>
            </div>
          </div>
        </div>
        <div className={s.Content}>
          <div className={s.ProductIngredientsLeft}>
            {iconsLeft.map((d, i) => {
              const fileName = extractImageNameFromUrl(d.icon)
              return (
                <div
                  key={d.text}
                  className={[
                    s.IconWrapper,
                    length === 9 && s.IconWrapperCloser,
                    // s[`IconWrapper_${i + 1}`]
                  ].join(" ")}
                  style={{ visibility: d.isHidden ? "hidden" : null }}
                >
                  <div className={s.Icon} ref={this.iconsRef[i]}>
                    <ImgFluid fileName={fileName} alt={d.text} />
                  </div>
                  <span
                    itemProp="material"
                    dangerouslySetInnerHTML={{ __html: d.text }}
                  ></span>
                </div>
              )
            })}
          </div>
          <div className={s.ProductIngredientsMiddle}>
            <div className={s.BottleWrapper} ref={this.props.bottleRef}>
              <BottleWithDroplets
                width="100%"
                height="100%"
                imageName={this.props.imageName}
                alt={productName}
                // recalcMaskSignal={this.state.recalcMaskSignal}
              />
              {/* <ImgFluid cn={s.Bottle} fileName={this.props.imageName} /> */}
            </div>
            {
              <div
                className={[s.IconWrapper, s.IconWrapper_Middle].join(" ")}
                style={{ visibility: iconMiddle.isHidden ? "hidden" : null }}
              >
                <div
                  className={s.Icon}
                  ref={length === 7 ? this.iconsRef[3] : this.iconsRef[4]}
                >
                  <ImgFluid
                    fileName={extractImageNameFromUrl(iconMiddle.icon)}
                    alt={iconMiddle.text}
                  />
                </div>
                <span
                  dangerouslySetInnerHTML={{ __html: iconMiddle.text }}
                  itemProp="material"
                ></span>
              </div>
            }
          </div>
          <div className={s.ProductIngredientsRight}>
            {iconsRight.map((d, i) => {
              const fileName = extractImageNameFromUrl(d.icon)
              return (
                <div
                  key={d.text}
                  className={[
                    s.IconWrapper,
                    length === 9 && s.IconWrapperCloser,
                    // s[`IconWrapper_${i + 5}`]
                  ].join(" ")}
                  style={{ visibility: d.isHidden ? "hidden" : null }}
                >
                  <div
                    className={s.Icon}
                    ref={
                      length === 7 ? this.iconsRef[i + 4] : this.iconsRef[i + 5]
                    }
                  >
                    <ImgFluid fileName={fileName} alt={d.text} />
                  </div>
                  <span
                    dangerouslySetInnerHTML={{ __html: d.text }}
                    itemProp="material"
                  ></span>
                </div>
              )
            })}
          </div>
        </div>
        <Button
          ref={this.closeBtnRef}
          onClick={this.props.onToBack}
          btnClassName={s.BackLink}
        >
          <CloseIcon />
        </Button>
      </section>
    )
  }
}

export default ProductIngredients
