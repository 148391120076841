import React from "react"
import Helmet from "react-helmet"

import { HikeAndWinButton, ImageFrame } from "../../../components"
import { PromotionSectionWrapper } from "../"
import s from "./IntroInfoSection.module.scss"
import { extractImageNameFromUrl, getCurrentLanguage } from "../../../helper"
import CurvedArrow from "../../../assets/svgs/curved_arrow.svg"
import { HikingAuthConsumer } from "../../../store"

class IntroInfoSection extends React.Component {
  imageToRender = (image) => {
    return (
      <ImageFrame
        className={s.ImageFrame}
        imgFilename={extractImageNameFromUrl(image)}
      />
    )
  }
  render() {
    const {
      title_one_text,
      description_one_text,
      button_one_text,
      sub_title_one_text,
      description_two_text,
      button_two_text,
      framedImage,
      button_one_slug,
      button_two_slug,
      location,
      button_one_visible,
      button_two_visible,
    } = this.props

    const { slug } = getCurrentLanguage(location)
    return (
      <HikingAuthConsumer>
        {(store) => {
          const { codeStatus } = store.state
          const showGewinnNiete = codeStatus.showMessageBox
          return (
            <>
              {!showGewinnNiete ? (
                <PromotionSectionWrapper className={s.IntroInfoSection}>
                  <Helmet>
                    <meta property="og:description" content={title_one_text} />
                  </Helmet>
                  <span className={s.curved_arrow}>
                    <CurvedArrow />
                  </span>
                  <h1
                    className={s.MainTitle}
                    dangerouslySetInnerHTML={{ __html: title_one_text }}
                  ></h1>
                  <div className={[s.Column, s.ColumnRight].join(" ")}>
                    {this.imageToRender(framedImage)}
                  </div>
                  <div className={[s.Column, s.ColumnLeft].join(" ")}>
                    <div className={s.Row}>
                      <p
                        className={s.Description}
                        dangerouslySetInnerHTML={{
                          __html: description_one_text,
                        }}
                      ></p>
                      {button_one_visible ? (
                        <div className={s.Button}>
                          <HikeAndWinButton
                            type="link"
                            to={`/${slug}${button_one_slug}`}
                          >
                            {button_one_text}
                          </HikeAndWinButton>
                        </div>
                      ) : null}
                    </div>
                    <div className={s.Row}>
                      <p
                        className={[s.Description, s.DescriptionBold].join(" ")}
                        dangerouslySetInnerHTML={{ __html: sub_title_one_text }}
                      ></p>
                      <p
                        className={s.Description}
                        dangerouslySetInnerHTML={{
                          __html: description_two_text,
                        }}
                      ></p>
                      {button_two_visible ? (
                        <div className={s.Button}>
                          <HikeAndWinButton
                            type="link"
                            to={`/${slug}${button_two_slug}`}
                          >
                            {button_two_text}
                          </HikeAndWinButton>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </PromotionSectionWrapper>
              ) : null}
            </>
          )
        }}
      </HikingAuthConsumer>
    )
  }
}

export default IntroInfoSection
