import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

import s from "./BottleSlick.module.scss"
import { Img400pxMaxFluid, BottleWithDroplets } from "../../components"
import { navigate } from "gatsby"

class BottleSlick extends React.Component {
  constructor(props) {
    super(props)
    this.slider = React.createRef()
    this.state = {
      renderVideo: false,
    }
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      this.updateRenderVideo()
    }
    if (typeof document !== "undefined") {
      if (typeof NodeList.prototype.forEach !== "function") {
        const divs = document.querySelectorAll(`.${s.Slick} .slick-slide div`)
        divs.forEach((divEle) => divEle.classList.add(s.BottleWrapper))
      }
    }
  }

  updateRenderVideo = () => {
    this.setState({ renderVideo: true })
  }

  componentDidUpdate(prevProps) {
    const length = this.props.imagesData && this.props.imagesData.length
    const { productIndex, prevSignal, nextSignal } = this.props
    if (prevSignal !== prevProps.prevSignal) {
      if (productIndex === 0) {
        this.slider.current.slickGoTo(length - 1)
      } else {
        this.slider.current.slickPrev()
      }
    }
    if (nextSignal !== prevProps.nextSignal) {
      if (productIndex === length - 1) {
        this.slider.current.slickGoTo(0)
      } else {
        this.slider.current.slickNext()
      }
    }
  }

  goTo = (i) => {
    this.slider.current.slickGoTo(i)
  }

  onSwipe = (direction) => {
    const { productIndex } = this.props
    const length = this.props.imagesData && this.props.imagesData.length
    const slider = this.slider.current
    if (direction === "left") {
      if (productIndex !== length - 1) slider.slickGoTo(productIndex + 1)
      else slider.slickGoTo(0)
    } else if (direction === "right") {
      if (productIndex !== 0) slider.slickGoTo(productIndex - 1)
      else slider.slickGoTo(length - 1)
    }
  }

  render() {
    const { imagesData } = this.props
    const SlickClassNames = [
      s.Slick,
      imagesData.length > 3 && s.SlickSliderActive,
    ].join(" ")
    if (!imagesData) return null
    return (
      <Slider
        dots
        ref={this.slider}
        speed={400}
        slidesToScroll={1}
        slidesToShow={3}
        swipeToSlide={false}
        infinite={true}
        // infinite={imagesData.length > 3}
        centerMode
        appendDots={(dots) => (
          <ul>
            {dots.map((dot, i) => {
              const isActive = !!dot.props.className
              if (isActive) {
                const { product_primary_color } = this.props.imagesData[i]
                return (
                  <li
                    key={product_primary_color}
                    className="slick-active"
                    style={{ color: product_primary_color }}
                  >
                    {dot.props.children}
                  </li>
                )
              }
              return dot
            })}
          </ul>
        )}
        // draggable={false}
        // touchMove={false}
        // onSwipe={this.onSwipe}
        className={SlickClassNames}
        afterChange={this.props.onProductChange}
        cssEase="ease-out"
        dotsClass={["slick-dots", s.Dots].join(" ")}
        responsive={[
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2.35,
              centerMode: true,
              // infinite: imagesData.length > 3,
            },
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              centerMode: true,
              // infinite: imagesData.length > 3,
            },
          },
        ]}
      >
        {this.props.imagesData.map((d, i) => (
          <div
            className={s.Bottle}
            key={d.id}
            onClick={() => {
              navigate(d.productLink)
              this.goTo(i)
            }}
          >
            {this.state.renderVideo ? (
              <BottleWithDroplets
                imageName={d.image}
                alt={d.alt}
                isSmallBottle
                deferVideoLoad={i !== this.props.productIndex}
              />
            ) : (
              <Img400pxMaxFluid fileName={d.image} alt={d.alt} />
            )}
          </div>
        ))}
      </Slider>
    )
  }
}

export default BottleSlick
