// module for ie9-11 fetch support
import "isomorphic-fetch"
import nodeFetch from "node-fetch"

import {
  WP_API_URL,
  LANGUAGE_ABBREVATIONS,
  SITE_URL,
  HIKING_API_URL,
} from "./constants"

export const ssrFriendlyFetch = (...args) => {
  return typeof fetch === "function"
    ? fetch(args && args[0] ? args[0] : null, args && args[1] ? args[1] : null)
    : nodeFetch(
        args && args[0] ? args[0] : null,
        args && args[1] ? args[1] : null
      )
}

export const getTotalRefreshFormSubmissions = () => {
  const endpoint = `${HIKING_API_URL}/wp-json/rivella-refresh/v2/get-total`
  // const endpoint = `https://${WP_API_URL}/wp-json/rivella-refresh/v2/get-total`
  return ssrFriendlyFetch(endpoint).then((res) => {
    if (res.status < 400) {
      return res.json()
    } else {
      return false
    }
  })
}

export const submitRefreshYourFriend = (body) => {
  return ssrFriendlyFetch(
    // `https://${WP_API_URL}/wp-json/rivella-refresh/v2/submit_new_entry`,
    `${HIKING_API_URL}/wp-jöson/rivella-refresh/v2/submit_new_entry`,
    {
      body,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((res) => {
    return res.json()
  })
}

export const confirmRefreshEntry = (body) => {
  return ssrFriendlyFetch(
    // `https://${WP_API_URL}/wp-json/rivella-refresh/v2/confirm_entry`,
    `${HIKING_API_URL}/wp-json/rivella-refresh/v2/confirm_entry`,
    {
      body,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((res) => {
    return res.json()
  })
}

export const submitWinterpromotion = (body) => {
  /*
  https://documenter.getpostman.com/view/1874507/TzXxmK2e
  https://rivella.madebycolorelephant.com/wp-json/rivella-promos/v1/submit_entry?
  salutation=M.&
  form_id=8&
  lang=fr&
  user_agent=NULL&
  accepted=1&
  answer=My Answer&
  city=Lausanne&
  zip=10001&
  number=3A&
  street=Street of Gold&
  email=black@colorelephant.com&
  name=Ricardo&
  surname=Sousa&
  phone_number=0916743868
  */

  return ssrFriendlyFetch(
    `${HIKING_API_URL}/wp-json/rivella-promos/v1/submit_entry`,
    // `https://${WP_API_URL}/wp-json/rivella-winter/v1/submit_entry`,
    // `${HIKING_API_URL}/wp-json/rivella-winter/v1/submit_entry`,
    {
      body,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((res) => {
    return res.json()
  })
}

export const confirmWinterpromotionEntry = (body) => {
  return ssrFriendlyFetch(
    `${HIKING_API_URL}/wp-json/rivella-winter/v1/confirm_entry`,
    // `https://${WP_API_URL}/wp-json/rivella-winter/v1/confirm_entry`,
    {
      body,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((res) => {
    return res.json()
  })
}

export const getCookie = (name) => {
  if (typeof document === "undefined" || !name) return null
  // Split cookie string and get all individual name=value pairs in an array
  let cookieArr = document.cookie.split(";")
  // Loop through the array elements
  for (let i = 0; i < cookieArr.length; i++) {
    let cookiePair = cookieArr[i].split("=")
    /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
    if (name == cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1])
    }
  }
  // Return null if not found
  return null
}
export const setCookie = (cname, cvalue, days) => {
  const d = new Date() //Create an date object
  d.setTime(d.getTime() + 1000 * 60 * 60 * days)
  const expires = "expires=" + d.toGMTString() //Compose the expirartion date
  window.document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/" //Set the cookie with name and the expiration date
}
export const deleteCookie = (cname) => {
  const d = new Date() //Create an date object
  d.setTime(d.getTime() - 1000 * 60 * 60 * 24) //Set the time to the past. 1000 milliseonds = 1 second
  const expires = "expires=" + d.toGMTString() //Compose the expirartion date
  window.document.cookie = cname + "=" + "; " + expires + "; path=/" //Set the cookie with name and the expiration date
}

export const paintCirclesAndPaths = ({
  ref,
  circleColor,
  circleStrokeColor,
  ellipseColor,
  ellipseStrokeColor,
  pathColor,
  pathStrokeColor,
  circleOpacity,
  ellipseOpacity,
  removeClipPaths,
  pathOpacity,
  attempt = 0,
}) => {
  const el = ref.current
  if (!el) return
  const paths = el.querySelectorAll("path")
  const circles = el.querySelectorAll("circle")
  const ellipses = el.querySelectorAll("ellipse")
  const gs = el.querySelectorAll("g")
  if (
    circles.length === 0 &&
    paths.length === 0 &&
    ellipses.length === 0 &&
    attempt < 600
  ) {
    return setTimeout(() => {
      paintCirclesAndPaths({
        ref,
        circleColor,
        circleStrokeColor,
        ellipseColor,
        ellipseStrokeColor,
        pathColor,
        pathStrokeColor,
        circleOpacity,
        ellipseOpacity,
        removeClipPaths,
        pathOpacity,
        attempt: ++attempt,
      })
    }, 25)
  }
  if (removeClipPaths) {
    gs.forEach((node) => {
      const clipPath = node.attributes["clip-path"]
      if( clipPath ) {
        clipPath.value = ""
      }
    })
  }
  ellipses.forEach((node) => {
    node.style.fill = ellipseColor ? ellipseColor : ""
    node.style.stroke = ellipseStrokeColor ? ellipseStrokeColor : ""
    node.style.clipPath = ""
    node.style.opacity = ellipseOpacity ? ellipseOpacity : ""
  })
  circles.forEach((node) => {
    node.style.fill = circleColor ? circleColor : ""
    node.style.stroke = circleStrokeColor ? circleStrokeColor : ""
    node.style.clipPath = ""
    node.style.opacity = circleOpacity ? circleOpacity : ""
  })
  paths.forEach((node) => {
    node.style.fill = pathColor ? pathColor : ""
    node.style.stroke = pathStrokeColor ? pathStrokeColor : ""
    node.style.clipPath = ""
    node.style.opacity = pathOpacity ? pathOpacity : ""
  })
}

export const getPosition = (element) => {
  var xPosition = 0
  var yPosition = 0

  while (element) {
    xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft
    yPosition += element.offsetTop - element.scrollTop + element.clientTop
    element = element.offsetParent
  }

  return { x: xPosition, y: yPosition }
}

export const scrollToRef = (ref, options) => {
  try {
    ref.current.scrollIntoView(
      !options
        ? {
            behavior: "smooth",
            block: "start",
          }
        : options
    )
  } catch (error) {
    console.warn(error)
  }
}

export const extractImageNameFromUrl = (url) => {
  if (!url || typeof url.split !== "function") return null

  const imgSplit = url.split("/")
  return imgSplit[imgSplit.length - 1]
}

export const wpFetch = (url) => {
  return fetch(`https://${WP_API_URL}${url}`).then((res) => {
    return res.json()
  })
}

export const checkHikingCode = (url, body) => {
  // return fetch(`${HIKING_API_BASE}${url}`, options).then(res => {
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  })
}

export const checkClipPathSupport = (window, bowser) => {
  if (!window || !bowser) {
    throw Error(
      "checkClipPathSupport requires `window` and `bowser` properties"
    )
  }
  const browser = bowser.getParser(window.navigator.userAgent)
  return browser.satisfies({
    chrome: ">=24",
    firefox: ">=4",
    edge: ">=79",
    opera: ">=15",
    macos: {
      safari: ">=9",
    },
    mobile: {
      safari: ">=10",
    },
  })
}

export const checkDesktopSafari = (window, bowser) => {
  if (!window || !bowser) {
    throw Error("checkDesktopSafari requires `window` and `bowser` properties")
  }
  const browser = bowser.getParser(window.navigator.userAgent)
  return browser.satisfies({
    macos: {
      safari: ">=8",
    },
  })
}

export const checkFirefox = (window, bowser) => {
  if (!window || !bowser) {
    throw Error("checkFirefox requires `window` and `bowser` properties")
  }
  const browser = bowser.getParser(window.navigator.userAgent)
  return browser.satisfies({
    firefox: ">=4",
  })
}

export const checkMobileDeviceExceptSamsungInternet = (window, bowser) => {
  if (!window || !bowser) {
    throw Error(
      "checkMobileDeviceExceptSamsungInternet requires `window` and `bowser` properties"
    )
  }
  const browser = bowser.getParser(window.navigator.userAgent)
  const satisfies = browser.satisfies({
    android: {
      safari: ">=8",
      chrome: ">24",
      firefox: ">10",
      opera: ">=10",
    },
    ios: {
      safari: ">=8",
      chrome: ">24",
    },
  })
  return satisfies
}
export const checkMobileIos = (window, bowser) => {
  if (!window || !bowser) {
    throw Error("checkMobileIos requires `window` and `bowser` properties")
  }
  const browser = bowser.getParser(window.navigator.userAgent)
  const satisfies = browser.satisfies({
    ios: {
      safari: ">=8",
      chrome: ">24",
    },
  })
  return satisfies
}

export const fetchHikingData = (url, config = {}) => {
  try {
    const options = { ...config }
    const path = "/wp-json/rivella-hiking/v1/"
    const base = `${HIKING_API_URL}${path}`
    if (config.method === "GET" || config.method === "HEADER") {
      options.delete("body")
    }
    options.headers = {
      "Content-Type": "application/json",
    }
    return fetch(`${base}${url}`, options).then((res) => {
      return res.json()
    })
  } catch (error) {
    console.warn(error)
  }
}

export const sanitizeEmojisInText = (text) => {
  if (typeof text !== "string") return text
  const emojiReplaceRegex =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/gm
  return text.replace(emojiReplaceRegex, "")
}

export const fetchInstagramPhotos = async (siteUrl) => {
  let url = `${HIKING_API_URL}/wp-json/rivella-insta/v1/get_photos`
  if (
    siteUrl &&
    (siteUrl.includes("localhost") || siteUrl.includes("rivella.blueglass.net"))
  ) {
    url = `https://${WP_API_URL}/wp-json/rivella-insta/v1/get_photos`
  }
  return await fetch(url).then((res) => {
    return res.json()
  })
}

export const fetchEvents = async (lang) => {
  if (!lang) throw Error("Missing required parameter in `fetchEvents` call.")
  let langReq
  try {
    // if the active language is chosen on WP, it behaves weird and returns CORS error
    langReq = await wpFetch(`/wp-json/wp/v2/event/?lang=${lang}&per_page=100`)
  } catch (err) {
    // then if that said error happens we fetch the default lang slug event
    langReq = await wpFetch("/wp-json/wp/v2/event/?per_page=100")
  }
  return langReq
}

export const fetchLatestEventsByCount = async (count, lang) => {
  // return wpFetch(
  //   `/wp-json/wp/v2/event?per_page=${count}&orderBy=date_gmt&order=desc`
  // )

  if (!count || !lang)
    throw Error("Missing required parameter in `fetchSingleEvent` call.")
  let langReq
  try {
    // if the active language is chosen on WP, it behaves weird and returns CORS error
    langReq = await wpFetch(
      `/wp-json/wp/v2/event/?per_page=${count}&orderBy=date_gmt&order=desc&lang=${lang}`
    )
  } catch (err) {
    // then if that said error happens we fetch the default lang slug event
    langReq = await wpFetch(
      `/wp-json/wp/v2/event/?per_page=${count}&orderBy=date_gmt&order=desc`
    )
  }
  return langReq
}

export const fetchSingleEvent = (eventId) => {
  if (!eventId)
    throw Error("Missing required parameter in `fetchSingleEvent` call.")
  return wpFetch(`/wp-json/wp/v2/event/${eventId}`)
}

export const fetchSingleEventBySlug = async (slug, lang) => {
  if (!slug || !lang)
    throw Error("Missing required parameter in `fetchSingleEvent` call.")
  let langReq
  try {
    // if the active language is chosen on WP, it behaves weird and returns CORS error
    langReq = await wpFetch(`/wp-json/wp/v2/event/?slug=${slug}&lang=${lang}`)
  } catch (err) {
    // then if that said error happens we fetch the default lang slug event
    langReq = await wpFetch(`/wp-json/wp/v2/event/?slug=${slug}`)
  }
  return langReq
}

export const getMetaDataOfEvent = (event) => {
  const { custom_meta } = event
  let metaData = {}
  custom_meta.forEach((m) => {
    Object.keys(m).forEach((k) => {
      metaData[k] = m[k]
    })
  })
  return metaData
}

export const getUrlParameter = (key) => {
  if (typeof window === "undefined" || !key) return null

  // eslint-disable-next-line no-useless-escape
  key = key.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]")
  var regex = new RegExp("[\\?&]" + key + "=([^&#]*)")
  var results = regex.exec(window.location.search)
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "))
}

export const getCurrentLanguage = (locationObj) => {
  if (!locationObj) throw Error("Requires `location` param from props.")
  let { pathname, origin } = locationObj
  if (!origin) origin = SITE_URL
  // origin = "https://rivella.fr"
  // origin = SITE_URL
  // origin = "https://staging.rivella.ch"

  let objToReturn = {}
  if (
    !origin ||
    origin.includes("localhost") ||
    origin.includes("blueglass") ||
    origin.includes("dev.rivella") ||
    origin.includes("staging")
  ) {
    const splitPathname = pathname.split("/")
    for (let i of splitPathname) {
      if (
        i &&
        i !== "/" &&
        (i.length === 2 || (i.includes("-") && i.length === 5))
      ) {
        const foundLangDataKey = Object.keys(LANGUAGE_ABBREVATIONS).find(
          (key) => LANGUAGE_ABBREVATIONS[key].slug === i
        )
        objToReturn = {
          ...LANGUAGE_ABBREVATIONS[foundLangDataKey],
          combined: LANGUAGE_ABBREVATIONS[foundLangDataKey].combined,
          langHref: LANGUAGE_ABBREVATIONS[foundLangDataKey].combined,
        }
        return objToReturn
      }
    }
    objToReturn = {
      ...LANGUAGE_ABBREVATIONS.german,
      combined: LANGUAGE_ABBREVATIONS.german.combined,
      langHref: LANGUAGE_ABBREVATIONS.german.combined,
    }
  } else {
    let lang
    const splitPathname = pathname.split("/")
    loop: for (let i of splitPathname) {
      if (
        i &&
        i !== "/" &&
        (i.length === 2 || (i.includes("-") && i.length === 5))
      ) {
        // if (i.length === 5) {
        //   lang = i.split("_")[1]
        // } else {
        //   lang = i
        // }
        lang = i
        break loop
      }
    }
    const foundLangDataKey = Object.keys(LANGUAGE_ABBREVATIONS).find(
      (key) => LANGUAGE_ABBREVATIONS[key].slug === lang
    )
    if (foundLangDataKey) {
      objToReturn = {
        ...LANGUAGE_ABBREVATIONS[foundLangDataKey],
        combined: LANGUAGE_ABBREVATIONS[foundLangDataKey].lang,
        langHref: LANGUAGE_ABBREVATIONS[foundLangDataKey].combined,
      }
    } else {
      objToReturn = {
        ...LANGUAGE_ABBREVATIONS.german,
        combined: LANGUAGE_ABBREVATIONS.german.lang,
        langHref: LANGUAGE_ABBREVATIONS.german.combined,
      }
    }
  }
  return objToReturn
}

// in a https://rivella.ch route this is equal to 'ch'
export const extractDomainExtention = (locationObj) => {
  if (!locationObj) throw Error("Requires `location` param from props.")
  let { origin } = locationObj
  if (!origin) origin = SITE_URL
  // origin = SITE_URL

  // origin = "https://rivella.fr"
  // origin = "https://staging.rivella.ch"

  const origin_without_http = origin.replace(/http.?:\/\//, "")
  const regexToExtractDomainData =
    /\b((?=[a-z0-9-]{1,63}.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*.)+([a-z]{2,63})\b/g
  const res = regexToExtractDomainData.exec(origin_without_http)
  // in a https://rivella.ch route this is equal to 'ch'
  return res[4]
}

// export const splitCountryAndLanguageSlug = (locationObj, slug) => {
//   if (!locationObj || !slug)
//     throw Error("Requires `location` param from props and slug.")
//   let newExtention, newLang, oldLangExtention
//   const splitSlug = slug.split("/")
//   loop: for (let i of splitSlug) {
//     if (i && i !== "/" && i.includes("_")) {
//       // in slug /at_de/sortiment, this is equal to 'at_de'
//       oldLangExtention = i
//       const split = i.split("_")
//       // equal to 'at' in example above
//       newExtention = split[0]
//       // equal to 'de' in example above
//       newLang = split[1]
//       break loop
//     }
//   }
//   if (newExtention) {
//     return {
//       newExtention,
//       newLang,
//       oldLangExtention,
//     }
//   } else {
//     getCurrentLanguage(locationObj)
//   }
// }

export const calculateProductLinkLangPrefix = (
  bottlePath,
  currentLangCombined
) => {
  let bottle_link = bottlePath.startsWith("/") ? bottlePath : "/" + bottlePath
  let bottle_link_split = bottle_link.split("/")
  bottle_link_split[1] = currentLangCombined
  bottle_link = bottle_link_split.join("/")
  return bottle_link
}

export const hexToRGB_obj = (hex, a) => {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16)

  return { r, g, b, a }
}

export const hexToRGB = (hex, alpha) => {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")"
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")"
  }
}

export const setCommonIdentifier = (commonIdentifier) => {
  commonIdentifier &&
    sessionStorage.setItem("commonIdentifier", commonIdentifier)
}

export const getCommonIdentifier = () =>
  typeof window !== "undefined" && sessionStorage.getItem("commonIdentifier")

export const formatEventDateToText = ({
  startDate,
  endDate,
  alternateStartDate,
  alternateEndDate,
  dateIsToBeDecided,
  lang,
  moment,
}) => {
  const translations = {
    de: "Datum folgt demnächst",
    fr: "date à suivre prochainement",
  }
  if (dateIsToBeDecided)
    return lang && translations[lang] ? translations[lang] : translations.de

  let dateToReturn = ""
  if (startDate && endDate) {
    const startDateMoment = moment(new Date(startDate))
    const endDateMoment = moment(new Date(endDate))

    if(startDateMoment.format("DDMMyyyy") === endDateMoment.format("DDMMyyyy")) {
      dateToReturn += moment(new Date(endDate)).format("DD.MM.yyyy")
    } else if(startDateMoment.format("MMyyyy") === endDateMoment.format("MMyyyy")) {
      dateToReturn +=
      moment(new Date(startDate)).format("DD.") +
      " - " +
      moment(new Date(endDate)).format("DD.MM.yyyy")
    } else if(startDateMoment.format("yyyy") === endDateMoment.format("yyyy")) {
      dateToReturn +=
      moment(new Date(startDate)).format("DD.MM.") +
      " - " +
      moment(new Date(endDate)).format("DD.MM.yyyy")
    } else {
      dateToReturn +=
      moment(new Date(startDate)).format("DD.MM.yyyy") +
      " - " +
      moment(new Date(endDate)).format("DD.MM.yyyy")
    }
  } else if (startDate) {
    dateToReturn += moment(new Date(startDate)).format("DD.MM.yyyy")
  }
  if (alternateStartDate && alternateEndDate) {
    dateToReturn +=
      " | ";
    const startDateMoment = moment(new Date(alternateStartDate))
    const endDateMoment = moment(new Date(alternateEndDate))

    if(startDateMoment.format("DDMMyyyy") === endDateMoment.format("DDMMyyyy")) {
      dateToReturn += moment(new Date(endDate)).format("DD.MM.yyyy")
    } else if(startDateMoment.format("MMyyyy") === endDateMoment.format("MMyyyy")) {
      dateToReturn +=
      moment(new Date(startDate)).format("DD.") +
      " - " +
      moment(new Date(endDate)).format("DD.MM.yyyy")
    } else if(startDateMoment.format("yyyy") === endDateMoment.format("yyyy")) {
      dateToReturn +=
      moment(new Date(startDate)).format("DD.MM.") +
      " - " +
      moment(new Date(endDate)).format("DD.MM.yyyy")
    } else {
      dateToReturn +=
      moment(new Date(startDate)).format("DD.MM.yyyy") +
      " - " +
      moment(new Date(endDate)).format("DD.MM.yyyy")
    }
  } else if (alternateStartDate) {
    dateToReturn +=
      " | " + moment(new Date(alternateStartDate)).format("DD.MM.yyyy")
  }
  return dateToReturn
}

const replaceAll = (base, replace, replacement) => {
  let str = base
  while (str.indexOf(replace) > -1) {
    str = str.replace(replace, replacement)
  }
  return str
}

export const submitMigrosEntry = (body, ln) => {
  const url = `${HIKING_API_URL}/wp-json/rivella-migros/v1/submit_new_entry/?language=${
    body.lang ? body.lang : ln ? ln : "de"
  }&first_name=${body.first_name}&last_name=${body.last_name}&email=${
    body.email
  }&phone=${replaceAll(body.phone, " ", "")}&dob=${body.dob}&address=${
    body.address
  }&zip=${body.zip}&city=${body.city}&country=${body.country}&newsletter=${
    body.newsletter ? 1 : 0
  }&privacy=${body.privacy ? 1 : 0}&salutation=${body.salutation}&user_agent=${
    body.user_agent
  }`
  return ssrFriendlyFetch(url, {
    method: "POST",
    redirect: "follow",
    headers: {
      // "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json()
    })
    .catch(() => {
      return {
        result: "error",
      }
    })
}

export const confirmMigrosEntry = (hash) => {
  return ssrFriendlyFetch(
    //`https://${WP_API_URL}/wp-json/rivella-migros/v1/confirm_entry?hash=${hash}`,
     `${HIKING_API_URL}/wp-json/rivella-migros/v1/confirm_entry?hash=${hash}`,
    {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((res) => {
    return res.json()
  })
}

export const submitSupEntry = (body, ln) => {
  const _API_URL_ = "rivella.com/backend";
  const url = `https://${_API_URL_}/wp-json/rivella-migros/v1/submit_new_entry/?language=${
    body.lang ? body.lang : ln ? ln : "de"
  }&first_name=${body.first_name}&last_name=${body.last_name}&email=${
    body.email
  }&phone=${replaceAll(body.phone, " ", "")}${
    body.dob ? `&dob=${body.dob}` : ""
  }&address=${body.address}&zip=${body.zip}&city=${body.city}&country=${
    body.country
  }&newsletter=${body.newsletter ? 1 : 0}&privacy=${
    body.privacy ? 1 : 0
  }&salutation=${body.salutation}&user_agent=${body.user_agent}`
  return ssrFriendlyFetch(url, {
    method: "POST",
    redirect: "follow",
    headers: {
      // "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json()
    })
    .catch((err) => {
      return {
        result: "error",
      }
    })
}

export const confirmSupEntry = (hash) => {
  return ssrFriendlyFetch(
    `${HIKING_API_URL}/wp-json/rivella-migros/v1/confirm_entry?hash=${hash}`,
    {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((res) => {
    return res.json()
  })
}

export const submitRefreshAR22Entry = (body, ln) => {
  const url = `${HIKING_API_URL}/wp-json/rivella-refresh-ar-2022/v1/submit_entry/?language=${
    body.lang ? body.lang : ln ? ln : "de"
  }&email=${
    body.email
  }&user_agent=${body.user_agent
  }&form_id=${body.form_id}`
  return ssrFriendlyFetch(url, {
    method: "POST",
    redirect: "follow",
    headers: {
      // "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json()
    })
    .catch((err) => {
      return {
        result: "error",
      }
    })
}

export const confirmRefreshAR22Entry = (hash, form_id) => {
  return ssrFriendlyFetch(
    `${HIKING_API_URL}/wp-json/rivella-refresh-ar-2022/v1/confirm_entry?hash=${hash}&form_id=${form_id}`,
    // `${HIKING_API_URL}/wp-json/rivella-refresh/v2/confirm_entry`,
    {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((res) => {
    return res.json()
  })
}


export const submitRefreshHeissluftballon22Entry = (body, ln) => {
  const url = `${HIKING_API_URL}/wp-json/rivella-refresh-heissluftballon-2022/v1/submit_entry/?language=${
    body.lang ? body.lang : ln ? ln : "de"
  }&name=${
    body.name
  }&age=${
    body.age
  }&email=${
    body.email
  }&user_agent=${body.user_agent
  }&form_id=${body.form_id}`
  return ssrFriendlyFetch(url, {
    method: "POST",
    redirect: "follow",
    headers: {
      // "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json()
    })
    .catch((err) => {
      return {
        result: "error",
      }
    })
}

export const confirmRefreshHeissluftballon22Entry = (hash, form_id) => {
  return ssrFriendlyFetch(
    `${HIKING_API_URL}/wp-json/rivella-refresh-heissluftballon-2022/v1/confirm_entry?hash=${hash}&form_id=${form_id}`,
    // `${HIKING_API_URL}/wp-json/rivella-refresh/v2/confirm_entry`,
    {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((res) => {
    return res.json()
  })
}


export const isValidDate = (dateString, dateFormat = /^\d{1,2}\.\d{1,2}\.\d{4}$/, dateSeparator = '.') =>{
  // First check for the pattern
  if(!dateFormat.test(dateString))
      return false;

  // Parse the date parts to integers
  var parts = dateString.split(dateSeparator);
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10);
  var year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if(year < 1910 || year > 2010 || month == 0 || month > 12)
      return false;

  var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

  // Adjust for leap years
  if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
      monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
};
