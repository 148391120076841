import React from "react"

import s from "./EventsPartnersSection.module.scss"

import { ImgFluid } from "../../components"
import { extractImageNameFromUrl, scrollToRef } from "../../helper"

class EventsPartnersSection extends React.Component {
  constructor(props) {
    super(props)
    this.partnerTitleRef = React.createRef()
    this.partnerRefs = []
    for (let i = 0; i < props.partner_informations.length; i++)
      this.partnerRefs.push(React.createRef())
    this.state = {
      currentChosenPartner: 0,
      maxHeightIndex: -1,
      maxHeightValue: 0,
    }
  }

  partnerClick(to) {
    this.setCurrentChosenPartner(to, false)
  }

  partnerEnter(to) {
    this.setCurrentChosenPartner(to, true)
  }

  setCurrentChosenPartner = (to, isFromMouseEnter) => {
    const innerWidth = window && window.innerWidth
    const isMobileOrTablet = innerWidth < 1050
    // disable mouse enter event on mobile
    if (isMobileOrTablet && isFromMouseEnter) return

    const { currentChosenPartner } = this.state
    if (to !== currentChosenPartner) {
      if (innerWidth && isMobileOrTablet) scrollToRef(this.partnerTitleRef)
      this.setState({
        currentChosenPartner: to,
      })
    }
  }

  componentDidMount() {
    // UI reshape initialization takes time, if done instantly, calculates it wrong
    setTimeout(() => this.calcMaxPartnerHeight(), 1000)
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.calcMaxPartnerHeight)
    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.calcMaxPartnerHeight)
    }
  }

  calcMaxPartnerHeight = () => {
    const { partnerRefs } = this
    let max = 0,
      maxIndex = -1
    partnerRefs.forEach((partnerRef, i) => {
      const { current } = partnerRef
      if (current && current.clientHeight > max) {
        max = current.clientHeight
        maxIndex = i
      }
    })
    this.setState({
      maxHeightIndex: maxIndex,
      maxHeightValue: max,
    })
  }

  render() {
    const {
      description_level,
      general_description,
      title_one_text,
      title_one_color,
      title_two_text,
      title_two_color,
      partner_title_color,
      partner_description_color,
      partner_informations,
    } = this.props
    const { currentChosenPartner, maxHeightIndex, maxHeightValue } = this.state

    if (!partner_informations || partner_informations.length === 0) return null

    let eleHeight = null
    if (
      typeof window !== "undefined" &&
      window.innerWidth > 1050 &&
      maxHeightValue
    ) {
      eleHeight = maxHeightValue
    }

    return (
      <section className={s.EventsPartnerSection}>
        <div ref={this.props.sectionRef} className={s.angkor} />
        <div className={s.Content}>
          <div className={[s.Col, s.ColLeft].join(" ")}>
            <div className={[
                s.PartnerImagesGrid,
                partner_informations.length <= 4 ? s.Partners2col : "",
                partner_informations.length == 1 ? s.PartnersSingle : "",
              ].join(" ")}>
              <div className={s.PartnerImagesGridBackground} />
              {partner_informations.map((partner_info, index) => {
                if(description_level == 'general') {
                  let classNames = `${s.PartnerDetailWrapperSelected} ${s.PartnerImageWrapper}`
                  if (partner_informations.length > 4) classNames += ' ' + s.PartnerImageWrapperSmaller
                  return <a
                    key={index}
                    href={partner_info.partner_link}
                    target="_blank"
                    className={classNames}
                  >
                    <ImgFluid
                      fileName={extractImageNameFromUrl(
                        partner_info.partner_image
                      )}
                      alt={partner_info.title}
                    />
                  </a>
                } else {
                  return <div
                    key={index}
                    onClick={() => this.partnerClick(index)}
                    onMouseEnter={() => this.partnerEnter(index)}
                    className={[
                      s.PartnerImageWrapper,
                      partner_informations.length > 4 ? s.PartnerImageWrapperSmaller : null,
                      index === currentChosenPartner
                        ? s.PartnerDetailWrapperSelected
                        : null,
                    ].join(" ")}
                  >
                    <ImgFluid
                      fileName={extractImageNameFromUrl(
                        partner_info.partner_image
                      )}
                      alt={partner_info.title}
                    />
                  </div>
                }
              })}
            </div>
          </div>
          <div className={[s.Col, s.ColRight].join(" ")}>
            <div className={s.TitleWrapper}>
              <h4
                className={[s.SubtitleOne, title_one_color].join(" ")}
                dangerouslySetInnerHTML={{ __html: title_one_text  }}
              ></h4>
              <h3
                className={[s.TitleMain, title_two_color].join(" ")}
                dangerouslySetInnerHTML={{ __html: title_two_text }}
                ref={this.partnerTitleRef}
              ></h3>
            </div>
            { description_level == 'general' && <div
                className={s.PartnerDetailWrapper}
              >
                <p
                  className={[
                    s.Description,
                    partner_description_color,
                    general_description.length < 200 ? s.DescriptionLarge : null,
                  ].join(" ")}
                  dangerouslySetInnerHTML={{
                    __html: general_description,
                  }}
                ></p>
              </div>
            }
            {description_level !== 'general' && partner_informations.map((partner_info, index) => {
              if (maxHeightIndex === -1 || currentChosenPartner === index) {
                return (
                  <div
                    className={s.PartnerDetailWrapper}
                    ref={this.partnerRefs[index]}
                    style={{
                      height: eleHeight,
                      visibility: maxHeightIndex < 0 ? "hidden" : null,
                      opacity: maxHeightIndex < 0 ? 0 : null,
                    }}
                    key={index}
                  >
                    <h5
                      className={[s.SubtitleTwo, partner_title_color].join(" ")}
                      dangerouslySetInnerHTML={{
                        __html: partner_info.partner_title,
                      }}
                    ></h5>
                    <p
                      className={[
                        s.Description,
                        partner_description_color,
                      ].join(" ")}
                      dangerouslySetInnerHTML={{
                        __html: partner_info.partner_description,
                      }}
                    ></p>
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
        </div>
        <div className={s.RedBackground}></div>
      </section>
    )
  }
}

export default EventsPartnersSection
