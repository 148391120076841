import React from "react"
import GoogleMap from "google-map-react"

import { GOOGLE_MAPS_API_KEY } from "../../constants"

export const MAP_OPTIONS = {
  defaultZoom: 8,
  defaultCenter: { lat: 47.0, lng: 8.7 },
}

const Map = React.forwardRef(
  (
    {
      zoomValue,
      mapCenterCoords,
      handleMapChange,
      currentCenterCoords,
      customMapOptions = {},
      children,
      ...rest
    },
    ref
  ) => {
    const onMapChange = handleMapChange ? handleMapChange : () => {}

    return (
      <GoogleMap
        bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
        defaultZoom={MAP_OPTIONS.defaultZoom}
        defaultCenter={mapCenterCoords || MAP_OPTIONS.defaultCenter}
        center={currentCenterCoords ? currentCenterCoords : null}
        onChange={onMapChange}
        zoom={zoomValue || MAP_OPTIONS.defaultZoom}
        options={{
          styles: [
            {
              elementType: "geometry",
              stylers: [
                {
                  color: "#f5f5f5",
                },
              ],
            },
            {
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#f5f5f5",
                },
              ],
            },
            {
              featureType: "administrative.land_parcel",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#bdbdbd",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "geometry",
              stylers: [
                {
                  color: "#eeeeee",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [
                {
                  color: "#e5e5e5",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "geometry",
              stylers: [
                {
                  color: "#ffffff",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [
                {
                  color: "#dadada",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
            {
              featureType: "transit.line",
              elementType: "geometry",
              stylers: [
                {
                  color: "#e5e5e5",
                },
              ],
            },
            {
              featureType: "transit.station",
              elementType: "geometry",
              stylers: [
                {
                  color: "#eeeeee",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [
                {
                  color: "#c9c9c9",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
            {
              featureType: "administrative.country",
              elementType: "geometry.stroke",
              stylers: [
                {
                  color: "#393939",
                  weight: 3,
                },
              ],
            },
          ],
          ...customMapOptions,
        }}
        {...rest}
        ref={ref}
      >
        {children}
      </GoogleMap>
    )
  }
)

Map.displayName = "Map"

export default Map
